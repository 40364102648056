import React, { useState, useEffect, useRef } from "react";
import { Steps } from "primereact/steps";
import { Toast } from "primereact/toast";
import { TabView, TabPanel } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { usePaymentSchema } from "../../context/PaymentSchemaContext";
import CatalogsService from "../../api/CatalogsService";
import { LastStepButton, NextStepButton, FinishButton } from "./AuxComponents";
import TabulatorType from "./TabulatorSections";
import { periodTypes } from "./constants";

/**
 *
 *  Constantes para mover a otro archivo
 *
 */

const initialItemStep = {
  label: "Información General",
  id: "infoGeneral",
};

const ModifyPaymentSchemaDialog = ({ schema, setSchemaDialog }) => {
  const [tabuladores, setTabuladores] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [itemsSteps, setItemsSteps] = useState([{ ...initialItemStep }]);
  const [tabActive, setTabActive] = useState(itemsSteps[activeIndex].id);
  const [tabActiveContent, setTabActiveContent] = useState(
    itemsSteps[activeIndex].content
  );

  /**
   *
   *  Importamos el contexto
   *
   */

  const {
    locations,
    setLocations,
    jobPositions,
    setJobPositions,
    setCommissionTypes,
    setPaymentPeriods,
  } = usePaymentSchema();

  const toast = useRef();

  /**
   *
   *  Funciones
   *
   */

  /**
   *
   *  Funciones para cargar catalogos en el contexto
   *
   */

  const activateTab = () => {
    setActiveIndex(activeIndex + 1);
    setTabActive(itemsSteps[activeIndex + 1].id);
    setTabActiveContent(itemsSteps[activeIndex + 1].content);
  };

  const prev = () => {
    setActiveIndex(activeIndex - 1);
    setTabActive(itemsSteps[activeIndex - 1].id);
    setTabActiveContent(itemsSteps[activeIndex - 1].content);
  };

  const next = () => {
    activateTab();
  };

  const finish = () => {
    setSchemaDialog(false);
  };

  useEffect(() => {
    if (itemsSteps.length > 1 && activeIndex === 0) {
      next();
    }
  }, [itemsSteps]);

  // Cargar Tabuladores por Plaza
  const fetchTabulatorsPerJobPosition = async (idLocation) => {
    const response = await CatalogsService.getTabulatorTypes(idLocation);
    const tabs = [];

    switch (response.data.status) {
      case "OK":
        response.data.result.map((item) => (
          <>
            {tabs.push({
              id: item.id,
              label: item.tabulatorName,
            })}
            {itemsSteps.push({
              id: item.id,
              label: item.tabulatorName,
            })}
          </>
        ));

        break;

      case "ERROR":
        toast.current.show({
          severity: "error",
          summary: `${response.data.message}`,
        });
        setItemsSteps([initialItemStep]);
        break;
      default:
        break;
    }

    setTabuladores(tabs);
  };

  useEffect(() => {
    if (tabuladores.length > 0 && tabActive === 0) {
      next();
    }
  }, [tabuladores, tabActive]);

  // Cargar Secciones por Plaza
  const fetchTabulatorSectionsPerPaymentSchema = async (
    idLocation,
    idPaymentSchema
  ) => {
    const response = await CatalogsService.getTabulatorSections(
      idLocation,
      idPaymentSchema
    );
    const tabs = [];
    const items = [initialItemStep];

    const schemaAux = { ...schema };
    schemaAux.id = idPaymentSchema;

    response.result.map((item) => (
      <>
        {tabs.push({
          id: item.id,
          label: item.tabulatorName,
        })}
        {items.push({
          id: item.id,
          label: item.tabulatorName,
          content: TabulatorType({
            tabulator: item,
            tabType: item.tabulatorCode,
            schema: schemaAux,
            puestos: jobPositions,
            // puestosSelected,
          }),
        })}
      </>
    ));

    setTabuladores([...tabs]);
    setItemsSteps([...items]);
  };

  const fetchLocationsToContext = () => {
    CatalogsService.getLocations().then(({ result }) => {
      setLocations(result);
    });
  };

  const fetchCommissionTypesToContext = () => {
    CatalogsService.getCommissionTypes().then(({ result }) => {
      setCommissionTypes(result);
    });
  };

  const fetchPaymentPeriodsToContext = () => {
    CatalogsService.getPaymentPeriods().then(({ result }) => {
      setPaymentPeriods(result);
    });
  };

  const fetchJobPositionsToContext = (location) => {
    CatalogsService.getJobPositions(location).then(({ result }) => {
      setJobPositions(result);
    });
  };

  const dynamicTabs = tabuladores.map((item) => (
    <TabPanel
      key={item.id}
      header={item.label}
      id={item.id}
      disabled={tabActive === item.id ? "" : true}
    >
      {tabActiveContent}
    </TabPanel>
  ));

  useEffect(() => {
    if (JSON.stringify({}) !== JSON.stringify(schema)) {
      fetchTabulatorsPerJobPosition(schema.idPlazaFortia);
      fetchTabulatorSectionsPerPaymentSchema(schema.idPlazaFortia, schema.id);
    }
  }, [schema]);

  useEffect(() => {
    if (locations && locations.length > 0) {
      const result = locations.find(
        ({ location_id }) => location_id === schema.idPlazaFortia
      );
      if (result) {
        fetchJobPositionsToContext(result.location_name);
      }
    }
  }, [locations, schema.idPlazaFortia]);

  useEffect(() => {
    fetchCommissionTypesToContext();
    fetchLocationsToContext();
    fetchPaymentPeriodsToContext();
  }, []);

  return (
    <>
      <div className="px-3">
        <Toast ref={toast} />
        <div className="pt-5">
          <Steps model={itemsSteps} activeIndex={activeIndex} />
        </div>

        {/**
         *
         *  Boton para pasos
         *
         */}
        <div className="p-grid">
          <div className="p-col" />
          <div className="p-col" />
          <div className="p-col">
            <div className="columns mt-5">
              <div className="column">
                {activeIndex > 0 && <LastStepButton callback={prev} />}
              </div>
              <div className="column">
                {activeIndex < itemsSteps.length - 1 && (
                  <NextStepButton callback={next} />
                )}
              </div>
              <div className="column">
                {itemsSteps.length > 1 &&
                  activeIndex === itemsSteps.length - 1 && (
                    <FinishButton callback={finish} />
                  )}
              </div>
            </div>
          </div>
        </div>

        {/**
         *
         *  Información General del Tabulador
         *
         */}

        <div>
          <div className="mt-3 columns pt-1">
            <div className="column is-one-quarter">
              <h5>Nombre del esquema de pago</h5>
              <InputText value={schema.schemaName} disabled />
            </div>
            <div className="column is-one-quarter">
              <h5>Plaza</h5>
              <Dropdown
                placeholder="Selecciona una plaza"
                value={schema.idPlazaFortia}
                options={locations}
                optionLabel="location_name"
                optionValue="location_id"
                disabled
              />
            </div>
            <div className="column">
              <label htmlFor="period_name">Periodo de pago</label>
              <Dropdown
                value={schema.period_name || null}
                options={[...periodTypes]}
                optionValue="value"
                placeholder="Selecciona un tipo de pago"
                disabled={tabActive !== "infoGeneral" || !schema.newSchema}
                required
                style={{ width: "160px" }}
              />
            </div>
            <div className="column is-one-quarter">
              <label htmlFor="icon">Vigencia</label>
              <Calendar
                id="validity"
                disabled
                showIcon
                dateFormat="yy-mm-dd"
                value={schema.validity && new Date(schema.validity)}
              />
            </div>
            <div className="column is-one-quarter">
              <h5>Archivo</h5>
              <Button
                label={schema.file ? schema.file.fileName : ""}
                className="p-button-text"
                disabled
              />
            </div>
          </div>
        </div>
      </div>

      {/* TABS */}
      <div
        className="tabview-demo"
        style={{
          width: "100%",
          height: "300px",
        }}
      >
        <div className="card">
          <TabView activeIndex={activeIndex}>{dynamicTabs}</TabView>
        </div>
      </div>
    </>
  );
};

export default (props) => <ModifyPaymentSchemaDialog {...props} />;
