import axios from "../axios/custom-axios";
import { API_URL_TABULATORS } from "../constants";

class BaseSalaryService {
  getLocations = async () => axios.get(`${API_URL_TABULATORS}/plazas`);

  saveSalary = async (id, body) =>
    axios.put(`${API_URL_TABULATORS}/plazas/${id}`, body);
}

export default new BaseSalaryService();
