/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { InputNumber } from "primereact/inputnumber";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import { Message } from "primereact/message";
import TabulatorOtifService from "../../../api/TabulatorOtifService";
import { usePaymentSchema } from "../../../context/PaymentSchemaContext";
import { emptyLitersSold } from "../../../constants";
import { periodTypes } from "../constants";

const BonosLitrajesSurtido = ({ tabulator, tabulatorCode, sectionCode }) => {
  const toast = useRef(null);

  const {
    paymentSchemaSelected,
    setPaymentSchemaSelected,
    paymentPeriods,
    jobPositions,
    editing,
    editValuesOnly,
  } = usePaymentSchema();

  const sectionInfo = {
    ...paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode],
  };

  const { idJobPositionsFortia } = {
    ...paymentSchemaSelected.tabs[tabulatorCode],
  };

  const {
    idPaymentPeriod,
    idEvaluation,
    litersSoldSchema,
    commissionLiters,
    commissionPercentage,
  } = {
    ...sectionInfo,
  };

  const [btAdd, setBtAdd] = useState(false);
  const [btRemove, setBtRemove] = useState(false);
  const [vRule, setVRule] = useState(false);
  const [positionsEdit, setPositionsEdit] = useState([]);

  const [submitted, setSubmitted] = useState(false);
  const [isEditingRow, setIsEditingRow] = useState(false);
  const [editingRows, setEditingRows] = useState({ null: true });
  const [originalRows, setOriginalRows] = useState([]);

  const filteredJobPositions = jobPositions.filter(({ work_station_id }) =>
    idJobPositionsFortia.find((id) => id === work_station_id)
  );

  const idValidator = () => {
    setVRule(Boolean(litersSoldSchema.find((row) => row.id !== null)));
  };

  const rowEditorValidator = (rowData) =>
    rowData.bonusAmount !== 0 && rowData.idJobPositionFortia !== null;

  const positionsValidatos = (data) =>
    setPositionsEdit(
      filteredJobPositions.filter(
        (position) =>
          !litersSoldSchema.find((row) =>
            data
              ? row.idJobPositionFortia === position.work_station_id &&
                !(data.idJobPositionFortia === row.idJobPositionFortia)
              : row.idJobPositionFortia === position.work_station_id
          )
      )
    );

  const hasPositions = () =>
    filteredJobPositions.filter(
      (position) =>
        !litersSoldSchema.find(
          (row) => row.idJobPositionFortia === position.work_station_id
        )
    ).length > 0;

  useEffect(() => {
    if (litersSoldSchema && litersSoldSchema.length > 0) {
      setBtAdd(
        Boolean(litersSoldSchema.find((row) => row.id === null)) ||
          !hasPositions()
      );
      setBtRemove(litersSoldSchema.length === 1);
    }
  }, [litersSoldSchema]);

  useEffect(() => {
    const elements = document.getElementsByClassName("p-row-editor-init");
    // eslint-disable-next-line no-restricted-syntax
    for (const element of elements) {
      element.style.display = isEditingRow ? "none" : "block";
    }
  }, [isEditingRow]);

  const emptyRow = {
    id: null,
    idPaymentPeriod: null,
    idTabulatorType: null,
    idRuleSchema: null,
    commissionLiters: null,
    commissionPercentage: null,
    idPaymentSchema: null,
    idEvaluation: null,
    idJobPositionFortia: null,
    idRTabSectionsTabTypes: null,
    bonusAmount: null,
  };

  const onFormInputChange = (e, field) => {
    const _paymentSchemaSelected = { ...paymentSchemaSelected };
    const section = {
      ...paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode],
      [field]: e.value,
    };

    _paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode] = {
      ...section,
    };

    setPaymentSchemaSelected(_paymentSchemaSelected);
  };

  const onEditorValueChange = (props, value) => {
    const _paymentSchemaSelected = { ...paymentSchemaSelected };
    const _litersSoldSchema = [...litersSoldSchema];

    _litersSoldSchema[props.rowIndex][props.field] = value;
    _paymentSchemaSelected.tabs[tabulatorCode].sections[
      sectionCode
    ].litersSoldSchema = [..._litersSoldSchema];

    setPaymentSchemaSelected({ ..._paymentSchemaSelected });
  };

  const getPuestoLabel = (jobPositionFortia) => {
    const puestoSeleccionado = filteredJobPositions.find(
      (val) => val.work_station_id === jobPositionFortia
    );

    return puestoSeleccionado !== undefined
      ? puestoSeleccionado.work_station_name
      : "";
  };

  const puestosBodyTemplate = (rowData) => {
    const id = rowData.idJobPositionFortia
      ? rowData.idJobPositionFortia
      : rowData.jobPositionFortia;
    return getPuestoLabel(id);
  };

  const puestoEditor = (props) => (
    <>
      <Dropdown
        value={props.rowData[props.field]}
        options={positionsEdit}
        optionLabel="work_station_name"
        optionValue="work_station_id"
        onChange={(e) => onEditorValueChange(props, e.value)}
        style={{ width: "100%" }}
        placeholder="Selecciona un puesto"
        itemTemplate={(option) => (
          <span className="product-badge">{option.work_station_name}</span>
        )}
        disabled={editValuesOnly}
        required
      />
    </>
  );

  const inputNumberEditor = (props) => (
    <>
      <InputNumber
        value={props.rowData[props.field]}
        onValueChange={(e) => onEditorValueChange(props, e.value)}
        mode="currency"
        currency="MXN"
        locale="es-MX"
        minFractionDigits={4}
        required
        className={classNames({
          "p-invalid": submitted && props.rowData[props.field] === 0,
        })}
      />
      {submitted && !(props.rowData[props.field] !== 0) && (
        <Message severity="error" text="Ingresa un dato valido" />
      )}
    </>
  );

  const currencyBodyTemplate = (rowData, field) => (
    <p>$ {rowData[field].toFixed(4)}</p>
  );

  const addNewRow = () => {
    const _paymentSchemaSelected = { ...paymentSchemaSelected };
    const _litersSoldSchema = [...litersSoldSchema];

    setEditingRows({ null: true });
    _paymentSchemaSelected.tabs[tabulatorCode].sections[
      sectionCode
    ].litersSoldSchema = [..._litersSoldSchema, { ...emptyRow }];

    setPaymentSchemaSelected({ ..._paymentSchemaSelected });
    positionsValidatos();
    setIsEditingRow(true);
  };

  const onRowDelete = (rowData) => {
    const _paymentSchemaSelected = { ...paymentSchemaSelected };
    const _litersSoldSchema = [...litersSoldSchema];

    const _table = [..._litersSoldSchema];
    const index = _table.findIndex((val) => val === rowData);

    if (rowData.id) {
      TabulatorOtifService.deleteLiterSoldSchema(rowData.id)
        .then((response) => {
          const { message } = response.data;
          _table.splice(index, 1);

          if (_table.length === 0) _table.push(emptyRow);

          _paymentSchemaSelected.tabs[tabulatorCode].sections[
            sectionCode
          ].litersSoldSchema = [..._table];
          setPaymentSchemaSelected({ ..._paymentSchemaSelected });
          setOriginalRows(_table);

          toast.current.show({
            severity: "success",
            summary: message,
          });
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: `${error.response.data.message}`,
          });
        });
    } else {
      _table.splice(index, 1);

      if (_table.length === 0) _table.push(emptyRow);

      _paymentSchemaSelected.tabs[tabulatorCode].sections[
        sectionCode
      ].litersSoldSchema = [..._table];
      setPaymentSchemaSelected({ ..._paymentSchemaSelected });
      setOriginalRows(_table);
    }
  };

  const actionsBody = (rowData) => (
    <>
      <Button
        icon="pi pi-trash"
        className="trash p-mr-2"
        onClick={() => onRowDelete(rowData)}
        disabled={editValuesOnly || editing || btRemove}
      />
      <Button
        icon="pi pi-plus"
        className="p-button-success p-button-rounded"
        onClick={() => addNewRow(rowData)}
        disabled={editValuesOnly || editing || btAdd}
      />
    </>
  );

  const ruleValidator = () => {
    if (
      isEditingRow &&
      !(sectionInfo.commissionLiters && sectionInfo.commissionPercentage)
    ) {
      toast.current.show({
        severity: "error",
        summary: `Ingresa los valores de la regla para el bono`,
      });
      return false;
    }
    return true;
  };

  const onRowEditChange = (event) => {
    if (ruleValidator()) setEditingRows(event.data);
  };

  const onRowEditInit = (event) => {
    setIsEditingRow(true);
    const _originalRows = [...originalRows];
    _originalRows[event.index] = { ...litersSoldSchema[event.index] };
    setOriginalRows(_originalRows);
    positionsValidatos(event.data);
  };

  const onRowEditCancel = (event) => {
    setIsEditingRow(false);
    setSubmitted(false);

    const _paymentSchemaSelected = { ...paymentSchemaSelected };
    const _litersSoldSchema = [...litersSoldSchema];
    if (event.index === null || event.data.id === null) {
      _litersSoldSchema.splice(event.index, 1);
    } else {
      _litersSoldSchema[event.index] = originalRows[event.index];
    }

    _paymentSchemaSelected.tabs[tabulatorCode].sections[
      sectionCode
    ].litersSoldSchema = _litersSoldSchema;

    setPaymentSchemaSelected({ ..._paymentSchemaSelected });
  };

  const onRowEditSave = ({ data, index }) => {
    setSubmitted(true);

    if (!ruleValidator()) return;

    const _table = [...litersSoldSchema];
    data.idEvaluation = sectionInfo.idEvaluation;
    data.idPaymentPeriod = sectionInfo.idPaymentPeriod;
    data.commissionLiters = sectionInfo.commissionLiters;
    data.commissionPercentage = sectionInfo.commissionPercentage;
    data.idPaymentSchema = paymentSchemaSelected.id;
    data.idTabulatorType = tabulator.id;

    data.idRTabSectionsTabTypes =
      sectionInfo.idRTabSectionsTabTypes ||
      tabulator.sections[2].idTabulatorSection;
    data.idRuleSchema = 2;

    setIsEditingRow(false);

    if (!data.id) {
      TabulatorOtifService.createLiterSoldSchema(data)
        .then((response) => {
          const { result, message } = response.data;

          const _paymentSchemaSelected = {
            ...paymentSchemaSelected,
          };
          const _litersSoldSchema = [...litersSoldSchema];
          _litersSoldSchema[index].id = result.id;

          _paymentSchemaSelected.tabs[tabulatorCode].sections[
            sectionCode
          ].litersSoldSchema = [..._litersSoldSchema];

          setPaymentSchemaSelected({ ..._paymentSchemaSelected });
          idValidator();

          toast.current.show({
            severity: "success",
            summary: message,
          });
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: `${error.message}`,
          });
        });
    } else {
      TabulatorOtifService.updateLiterSoldSchema(data)
        .then((response) => {
          const { message } = response.data;
          _table[index] = data;
          toast.current.show({
            severity: "success",
            summary: message,
          });
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: `${error.message}`,
          });
        });
    }
  };

  useEffect(() => {
    if (litersSoldSchema && litersSoldSchema.length === 0) {
      const _paymentSchemaSelected = { ...paymentSchemaSelected };
      const _litersSoldSchema = [...litersSoldSchema];
      const bodyToPush = {
        ...emptyRow,
        idJobPositionFortia:
          filteredJobPositions[0] && filteredJobPositions[0].work_station_id
            ? filteredJobPositions[0].work_station_id
            : [],
      };
      _litersSoldSchema.push(bodyToPush);
      _paymentSchemaSelected.tabs[tabulatorCode].sections[
        sectionCode
      ].litersSoldSchema = [..._litersSoldSchema];
      setPaymentSchemaSelected(_paymentSchemaSelected);
      setOriginalRows(_litersSoldSchema);
      positionsValidatos();
    }
  }, [litersSoldSchema]);

  useEffect(() => {
    if (litersSoldSchema && litersSoldSchema.length > 0) {
      if (
        litersSoldSchema[0] &&
        litersSoldSchema[0].ruleSchema &&
        litersSoldSchema[0].ruleSchema.commissionLiters &&
        litersSoldSchema[0].ruleSchema.commissionPercentage
      ) {
        const _paymentSchemaSelected = { ...paymentSchemaSelected };

        _paymentSchemaSelected.tabs[tabulatorCode].sections[
          sectionCode
        ].commissionLiters = litersSoldSchema[0].ruleSchema.commissionLiters;
        _paymentSchemaSelected.tabs[tabulatorCode].sections[
          sectionCode
        ].commissionPercentage =
          litersSoldSchema[0].ruleSchema.commissionPercentage;

        setPaymentSchemaSelected(_paymentSchemaSelected);
      }
      const _litersSoldSchema = [...litersSoldSchema];
      setOriginalRows(_litersSoldSchema);
      positionsValidatos();
      idValidator();
    } else {
      const _paymentSchemaSelected = { ...paymentSchemaSelected };
      if (_paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode]) {
        _paymentSchemaSelected.tabs[tabulatorCode].sections[
          sectionCode
        ].litersSoldSchema = [];
        setPaymentSchemaSelected({ ..._paymentSchemaSelected });
      } else {
        const findSectionInTabulator = tabulator.sections.find(
          (sec) => sec.sectionCode === sectionCode
        );
        const sectionToAdd = {
          ...emptyLitersSold,
          ...findSectionInTabulator,
        };
        _paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode] = {
          ...sectionToAdd,
        };
        setPaymentSchemaSelected({ ..._paymentSchemaSelected });
      }
    }
  }, []);

  let paymentType;
  let evaluationType;

  if (editValuesOnly) {
    paymentType = paymentPeriods.find(({ id }) => id === idPaymentPeriod);
    evaluationType = paymentPeriods.find(({ id }) => id === idEvaluation);
  }

  const addSectionWithPaymentPeriod = () => {
    const option = periodTypes.find(
      (period) => period.value === paymentSchemaSelected.period_name
    );
    const _paymentSchemaSelected = { ...paymentSchemaSelected };
    const aux = {
      ...paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode],
    };
    _paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode] = {
      ...aux,
      idPaymentPeriod: option.id,
      idEvaluation: option.id,
    };

    setPaymentSchemaSelected(_paymentSchemaSelected);
  };

  useEffect(() => {
    addSectionWithPaymentPeriod();
  }, []);

  return (
    <>
      <Toast ref={toast} />
      <div>
        <form className="m-5">
          <div className="p-formgrid p-grid">
            <div className="p-field p-col-auto">Se otorgará solo si el</div>
            <div className="p-field p-col-1">
              <InputNumber
                value={commissionPercentage}
                onValueChange={(e) =>
                  onFormInputChange(e, "commissionPercentage")
                }
                suffix="%"
                showButtons
                min={0}
                max={100}
                required
                disabled={editValuesOnly || vRule}
              />
            </div>
            <div className="p-field p-col-auto">
              de los surtidos corresponden a notas mayores
            </div>
            <div className="p-field p-col-1">
              <InputNumber
                value={commissionLiters}
                onValueChange={(e) => onFormInputChange(e, "commissionLiters")}
                required
                disabled={editValuesOnly || vRule}
              />
            </div>
            <div className="p-field p-col-auto">litros</div>
          </div>
        </form>
      </div>
      <div>
        <DataTable
          value={litersSoldSchema}
          dataKey="id"
          emptyMessage="Datos no encontrados"
          editMode="row"
          className="editable-cells-table"
          editingRows={editingRows}
          onRowEditChange={onRowEditChange}
          onRowEditInit={onRowEditInit}
          onRowEditCancel={onRowEditCancel}
          onRowEditSave={onRowEditSave}
          rowEditorValidator={rowEditorValidator}
        >
          <Column
            field="id"
            header="ID"
            style={{
              width: "40px",
              display: "none",
            }}
          />
          <Column
            field="idJobPositionFortia"
            header="Puesto"
            body={puestosBodyTemplate}
            editor={puestoEditor}
          />
          <Column
            field="bonusAmount"
            header="Bono"
            editor={inputNumberEditor}
            body={(rowData) => currencyBodyTemplate(rowData, "bonusAmount")}
          />
          <Column
            rowEditor
            header="Editar"
            headerStyle={{ width: "7rem " }}
            bodyStyle={{ textAlign: "center" }}
            style={{ width: "150px" }}
          />
          <Column body={actionsBody} style={{ width: "150px" }} />
        </DataTable>
      </div>
    </>
  );
};

export default BonosLitrajesSurtido;
