/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import React, { useState, useRef, useEffect } from "react";
import { Toast } from "primereact/toast";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { usePaymentSchema } from "../../../context/PaymentSchemaContext";
import TabulatorOtifService from "../../../api/TabulatorOtifService";
import TypeLitersService from "../../../api/TypeLitersService";
import { emptyPonderacionDetail } from "../../../constants";
import BonoConceptsService from "../../../api/BonoConceptsService";
import "./Ponderaciones.css";
import { periodTypes } from "../constants";

const Ponderaciones = ({ tabulator, tabulatorCode, sectionCode }) => {
  const [bonos, setBonos] = useState([]);
  const [idTabulatorSection, setIdTabulatorSection] = useState(null);

  useEffect(() => {
    if (tabulator) {
      const result = tabulator.sections.find(
        (section) => section.sectionCode === sectionCode
      );
      setIdTabulatorSection(result.idTabulatorSection);
    }
  }, []);

  const {
    paymentPeriods,
    paymentSchemaSelected,
    setPaymentSchemaSelected,
    jobPositions,
    editing,
    editValuesOnly,
  } = usePaymentSchema();

  const toast = useRef(null);

  const { idJobPositionsFortia } = paymentSchemaSelected.tabs[tabulatorCode];

  const {
    idRTabSectionsTabTypes,
    idPaymentPeriod,
    idEvaluation,
  } = paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode]
    ? {...paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode]}
    : {};

  const filteredJobPositions = jobPositions.filter(({ work_station_id }) =>
    idJobPositionsFortia.find((id) => id === work_station_id)
  );

  const emptyRange = {
    id: null,
    concept: null,
    percentage: null,
  };

  const { sections } = {
    ...paymentSchemaSelected.tabs[tabulatorCode],
  };

  const sectionInfo = {
    ...sections[sectionCode],
  };

  let { rangeDetailsOtifSchema } = {
    ...paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode],
  };
  // METODOS

  const handleNewTypeClick = (work_station_id) => {
    const _paymentSchemaSelected = {
      ...paymentSchemaSelected,
    };

    _paymentSchemaSelected.tabs[tabulatorCode].sections[
      sectionCode
    ].rangeDetailsOtifSchema = [
      ...rangeDetailsOtifSchema,
      { ...emptyRange, jobPositionFortia: work_station_id },
    ];

    setPaymentSchemaSelected({
      ..._paymentSchemaSelected,
    });
  };

  const handleDeleteTypeClick = (id, jobPositionFortia) => {
    if (id) {
      TabulatorOtifService.deleteRangeDetailOtifSchema(id)
        .then(() => {
          const _rangeDetailsOtifSchema = [...rangeDetailsOtifSchema];
          const _paymentSchemaSelected = {
            ...paymentSchemaSelected,
          };
          const index = _rangeDetailsOtifSchema.findIndex(
            (range) =>
              range.id === id && range.jobPositionFortia === jobPositionFortia
          );

          _rangeDetailsOtifSchema.splice(index, 1);

          _paymentSchemaSelected.tabs[tabulatorCode].sections[
            sectionCode
          ].rangeDetailsOtifSchema = [..._rangeDetailsOtifSchema];

          setPaymentSchemaSelected({
            ..._paymentSchemaSelected,
          });

          toast.current.show({
            severity: "success",
            summary: "Tipo Eliminado",
            detail: "El tipo se elimino correctamente",
            life: 3000,
          });
        })
        .catch(({ message }) => {
          toast.current.show({
            severity: "error",
            summary: message,
            life: 3000,
          });
        });
    } else {
      const _rangeDetailsOtifSchema = [...rangeDetailsOtifSchema];
      const _paymentSchemaSelected = {
        ...paymentSchemaSelected,
      };
      const index = _rangeDetailsOtifSchema.findIndex(
        (range) =>
          range.id === id && range.jobPositionFortia === jobPositionFortia
      );

      _rangeDetailsOtifSchema.splice(index, 1);

      _paymentSchemaSelected.tabs[tabulatorCode].sections[
        sectionCode
      ].rangeDetailsOtifSchema = [..._rangeDetailsOtifSchema];

      setPaymentSchemaSelected({
        ..._paymentSchemaSelected,
      });
    }
  };

  const handleInputTextChange = (e, id, jobPositionFortia) => {
    const _rangeDetailsOtifSchema = [...rangeDetailsOtifSchema];
    const _paymentSchemaSelected = {
      ...paymentSchemaSelected,
    };
    const index = _rangeDetailsOtifSchema.findIndex(
      (range) =>
        range.id === id && range.jobPositionFortia === jobPositionFortia
    );

    _rangeDetailsOtifSchema[index][e.target.id] = e.target.value;

    _paymentSchemaSelected.tabs[tabulatorCode].sections[
      sectionCode
    ].rangeDetailsOtifSchema = [..._rangeDetailsOtifSchema];

    setPaymentSchemaSelected({
      ..._paymentSchemaSelected,
    });
  };

  const handleInputNumberChange = (
    originalEvent,
    value,
    id,
    jobPositionFortia
  ) => {
    const _rangeDetailsOtifSchema = [...rangeDetailsOtifSchema];
    const _paymentSchemaSelected = {
      ...paymentSchemaSelected,
    };
    const index = _rangeDetailsOtifSchema.findIndex(
      (range) =>
        range.id === id && range.jobPositionFortia === jobPositionFortia
    );

    _rangeDetailsOtifSchema[index][originalEvent.target.id] = parseFloat(
      value || 0
    );

    _paymentSchemaSelected.tabs[tabulatorCode].sections[
      sectionCode
    ].rangeDetailsOtifSchema = [..._rangeDetailsOtifSchema];

    setPaymentSchemaSelected({
      ..._paymentSchemaSelected,
    });
  };

  const handleDropdownChange = (inputId, value, id, jobPositionFortia) => {
    const _rangeDetailsOtifSchema = [...rangeDetailsOtifSchema];
    const _paymentSchemaSelected = {
      ...paymentSchemaSelected,
    };
    const index = _rangeDetailsOtifSchema.findIndex(
      (range) =>
        range.id === id && range.jobPositionFortia === jobPositionFortia
    );

    _rangeDetailsOtifSchema[index][inputId] = value;

    _paymentSchemaSelected.tabs[tabulatorCode].sections[
      sectionCode
    ].rangeDetailsOtifSchema = [..._rangeDetailsOtifSchema];

    setPaymentSchemaSelected({
      ..._paymentSchemaSelected,
    });
  };

  const onFormInputChange = (e, field) => {
    const _paymentSchemaSelected = { ...paymentSchemaSelected };
    const _section = {
      ...paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode],
      [field]: e.value,
    };

    _paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode] = {
      ..._section,
    };

    setPaymentSchemaSelected(_paymentSchemaSelected);
  };

  const saveDetailRangeSchema = (range, id, jobPositionFortsia) => {
    const option = periodTypes.find(
      (period) => period.value === paymentSchemaSelected.period_name
    );
    const formAux = paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode];

    const body = {
      ...range,
      idPaymentPeriod: option.id,
      idTabulatorType: tabulator.id,
      idPaymentSchema: paymentSchemaSelected.id,
      idEvaluation: option.id,
      idRTabSectionsTabTypes:  idTabulatorSection || idRTabSectionsTabTypes,
      idJobPositionFortia: range.jobPositionFortia,
    };

    if (formAux.rangeDetailsOtifSchema.id) {
      TabulatorOtifService.updateRangeDetailOtifSchema(body)
        .then((response) => {
          const _rangeDetailsOtifSchema = [...rangeDetailsOtifSchema];
          const _paymentSchemaSelected = {
            ...paymentSchemaSelected,
          };

          const index = _rangeDetailsOtifSchema.findIndex(
            (aux) =>
              aux.id === id && aux.jobPositionFortia === jobPositionFortsia
          );

          if (id !== -1) {
            _rangeDetailsOtifSchema[index].id = response.data.result.id;

            _paymentSchemaSelected.tabs[tabulatorCode].sections[
              sectionCode
            ].rangeDetailsOtifSchema = [..._rangeDetailsOtifSchema];

            setPaymentSchemaSelected({
              ..._paymentSchemaSelected,
            });
          }

          toast.current.show({
            severity: "success",
            summary: "Tipo Actualizado",
            detail: "El tipo se actualizo exitosamente",
            life: 3000,
          });
        })
        .catch(({ message }) => {
          toast.current.show({
            severity: "error",
            summary: "Error al crear",
            detail: message,
            life: 3000,
          });
        });
    } else {
      TabulatorOtifService.createRangeDetailOtifSchema(body)
        .then((response) => {
          const _rangeDetailsOtifSchema = [...rangeDetailsOtifSchema];
          const _paymentSchemaSelected = {
            ...paymentSchemaSelected,
          };

          const index = _rangeDetailsOtifSchema.findIndex(
            (aux) =>
              aux.id === id && aux.jobPositionFortia === jobPositionFortsia
          );

          if (id !== -1) {
            _rangeDetailsOtifSchema[index].id = response.data.result.id;

            _paymentSchemaSelected.tabs[tabulatorCode].sections[
              sectionCode
            ].rangeDetailsOtifSchema = [..._rangeDetailsOtifSchema];

            setPaymentSchemaSelected({
              ..._paymentSchemaSelected,
            });
          }

          toast.current.show({
            severity: "success",
            summary: "Tipo Creado",
            detail: "El nuevo tipo se creo exitosamente",
            life: 3000,
          });
        })
        .catch(
          ({
            response: {
              data: { message },
            },
          }) => {
            toast.current.show({
              severity: "error",
              summary: "Error al crear",
              detail: message,
              life: 3000,
            });
          }
        );
    }
  };

  let paymentType;
  let evaluationType;

  if (editValuesOnly) {
    paymentType = paymentPeriods.find(({ id }) => id === idPaymentPeriod);
    evaluationType = paymentPeriods.find(({ id }) => id === idEvaluation);
  }

  useEffect(() => {
    BonoConceptsService.getBonoConcepts()
      .then((response) => {
        setBonos(response.data.result);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (!paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode]) {
      const findSectionInTabulator = tabulator.sections.find(
        (sec) => sec.sectionCode === sectionCode
      );
      const sectionToAdd = {
        ...findSectionInTabulator,
        ...emptyPonderacionDetail,
      };
      const paymentSchemaSelectedAux = { ...paymentSchemaSelected };
      paymentSchemaSelectedAux.tabs[tabulatorCode].sections[sectionCode] = {
        ...sectionToAdd,
      };
      setPaymentSchemaSelected({ ...paymentSchemaSelectedAux });
    } else if (typeof rangeDetailsOtifSchema !== "object") {
      const paymentSchemaSelectedAux = { ...paymentSchemaSelected };
      paymentSchemaSelectedAux.tabs[tabulatorCode].sections[
        sectionCode
      ].rangeDetailsOtifSchema = [];
      setPaymentSchemaSelected({ ...paymentSchemaSelectedAux });
    }
  }, []);


  const addSectionWithPaymentPeriod = () => {
    const option = periodTypes.find(
      (period) => period.value === paymentSchemaSelected.period_name
    );
    const _paymentSchemaSelected = { ...paymentSchemaSelected };
    const aux = {
      ...paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode],
    };
    _paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode] = {
      ...aux,
      idPaymentPeriod: option.id,
      idEvaluation: option.id,
    };

    setPaymentSchemaSelected(_paymentSchemaSelected);
  };

  // useEffect(() => {
  //   addSectionWithPaymentPeriod();
  // }, []);

  return (
    <>
      <Toast ref={toast} />
      <div className="commisions-container">
        {filteredJobPositions.map(({ work_station_name, work_station_id }) => {
          if (rangeDetailsOtifSchema) {
            const ranges = rangeDetailsOtifSchema.filter(
              ({ jobPositionFortia }) => jobPositionFortia === work_station_id
            );
            const filteredBonos = bonos.map((type) => {
              const newType = { ...type };
              if (ranges.find(({ concept }) => concept === type.concept)) {
                newType.disabled = true;
              }

              return newType;
            });

            return (
              <div className="comission-row">
                <div className="commission-job-position">
                  <h1>{work_station_name}</h1>
                </div>
                {ranges.map((range) => (
                  <table className="comission-form">
                    <tr className="commision-form-row">
                      <th
                        style={{
                          padding: "0 !important",
                        }}
                      >
                        Concepto
                      </th>
                      <th
                        style={{
                          padding: "0 !important",
                        }}
                      >
                        <Dropdown
                          id="concept"
                          inputId="concept"
                          options={filteredBonos}
                          optionLabel="name"
                          optionValue="name"
                          placeholder="Tipo 1"
                          onChange={({ value }) =>
                            handleDropdownChange(
                              "concept",
                              value,
                              range.id,
                              range.jobPositionFortia
                            )
                          }
                          value={range.concept}
                        />
                      </th>
                      <td
                        colSpan="3"
                        style={{
                          padding: "0 !important",
                        }}
                      >
                        <button
                          type="button"
                          className="button is-danger ml-3"
                          onClick={() =>
                            handleDeleteTypeClick(
                              range.id,
                              range.jobPositionFortia
                            )
                          }
                          disabled={editValuesOnly}
                        >
                          <span className="icon">
                            <i className="fas fa-trash has-text-white" />
                          </span>
                        </button>
                      </td>
                    </tr>

                    <tr className="commision-form-row">
                      <th
                        style={{
                          padding: "0 !important",
                        }}
                      >
                        %
                      </th>
                      <td
                        style={{
                          padding: "0 !important",
                        }}
                      >
                        <InputNumber
                          id="percentage"
                          inputId="percentage"
                          value={range.percentage}
                          minFractionDigits={0}
                          min="0"
                          max="100"
                          onChange={({ originalEvent, value }) =>
                            handleInputNumberChange(
                              originalEvent,
                              value,
                              range.id,
                              range.jobPositionFortia
                            )
                          }
                          placeholder="Porcentaje"
                        />
                      </td>
                      <td
                        style={{
                          padding: "0 !important",
                        }}
                      >
                        <button
                          type="button"
                          className="button is-success"
                          onClick={() =>
                            saveDetailRangeSchema(
                              range,
                              range.id,
                              range.jobPositionFortia
                            )
                          }
                        >
                          Guardar
                        </button>
                      </td>
                    </tr>
                  </table>
                ))}
                <div>
                  <button
                    className="button is-link "
                    type="button"
                    onClick={() => handleNewTypeClick(work_station_id)}
                    disabled={
                      ranges.find(({ id }) => id === null) ||
                      (ranges.length === 0 && editing) ||
                      editValuesOnly ||
                      editing
                    }
                  >
                    +
                  </button>
                </div>
              </div>
            );
          }
        })}
      </div>
    </>
  );
};

export default Ponderaciones;
