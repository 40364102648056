import axios from '../axios/custom-axios';
import { API_URL_CONCEPTS } from '../constants';

class TypeLitersService {
    async getTypeLiters() {
        const response = await axios.get(`${API_URL_CONCEPTS}/types-liters`);
        return response;
    }

    async createTypeLiter(body) {
        const response = await axios.post(
            `${API_URL_CONCEPTS}/types-liters`,
            body
        );
        return response;
    }

    async updateTypeLiter(body) {
        const response = await axios.put(
            `${API_URL_CONCEPTS}/types-liters/${body.id}`,
            body
        );
        return response;
    }

    async deleteTypeLiter(id) {
        const response = await axios.delete(
            `${API_URL_CONCEPTS}/types-liters/${id}`
        );
        return response;
    }
}

export default new TypeLitersService();
