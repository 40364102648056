import React from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import LoaderBlue from "../Login/LoaderBlue";

const TabulatorsConfigurator = ({
  // VARIABLES
  sections,
  plazasFortia,
  paymentSchemaConfig,
  tabulatorSelected,
  tabulatorDialog,
  newTabulatorName,
  plazaSelected,
  loading,
  tabulatorsFiltered,
  // FUNCTIONS
  addTabulator,
  handleTabulatorSelection,
  addCreatedTabulator,
  addNewTabulator,
  onTabulatorDialogHide,
  setNewTabulatorName,
  deleteTabulator,
  handleSectionChange,
  handlePlazaChange,
  saveConfiguration,
}) => (
  <div className="container px-3 ">
    <p className="is-size-3">Administración de Tabuladores</p>
    <p>
      Aquí puedes configurar un nuevo tabulador para una plaza o editar uno ya
      existente.
    </p>
    {loading ? <LoaderBlue /> : null}
    <div className="mt-5">
      <div className="columns">
        <div className="column">
          <p style={{ fontWeight: "bold" }} className="is-size-5">
            Selecciona una plaza:
          </p>
          <Dropdown
            className="mt-3"
            value={plazaSelected}
            options={plazasFortia}
            optionLabel="location_name"
            optionValue="location_id"
            onChange={(e) => handlePlazaChange(e.value)}
            placeholder="Selecciona una plaza"
            filter
            showFilterClear
            filterBy="location_name"
          />
        </div>
        <div className="column">
          <Button
            className="p-button-success"
            label="Guardar Configuración"
            onClick={saveConfiguration}
          />
        </div>
      </div>
      <div className="mt-3">
        <Button
          label="Agregar Tabulador"
          onClick={addTabulator}
          disabled={!plazaSelected}
        />
      </div>
      <div className="mt-5">
        <Accordion>
          {paymentSchemaConfig.map((tabulator, tabulatorIndex) => (
            <AccordionTab
              header={tabulator.nombre}
              // eslint-disable-next-line react/no-array-index-key
              key={`${tabulator.tabulatorCode}-${tabulatorIndex}`}
            >
              <>
                <div className="columns">
                  <div className="column">
                    <h5 className="mb-3">
                      Selecciona las secciones deseadas para el tabulador.
                    </h5>
                  </div>
                  <div className="column">
                    <Button
                      icon="pi pi-trash"
                      label="Eliminar Tabulador"
                      className="trash"
                      onClick={() => deleteTabulator(tabulatorIndex)}
                    />
                  </div>
                </div>

                {sections.map((section) => (
                  <div className="field-checkbox">
                    <Checkbox
                      name={section.sectionName}
                      value={section.id}
                      onChange={(e) =>
                        handleSectionChange(tabulatorIndex, e.value)
                      }
                      checked={tabulator.secciones.indexOf(section.id) !== -1}
                    />
                    <label className="ml-2">{section.sectionName}</label>
                  </div>
                ))}
              </>
            </AccordionTab>
          ))}
        </Accordion>
      </div>
    </div>

    {/* DIALOG */}
    <Dialog
      header="Agregar tabulador"
      visible={tabulatorDialog}
      style={{ width: "50vw" }}
      onHide={() => onTabulatorDialogHide()}
    >
      <div className="columns">
        <div className="column">
          <p>Agregar tabulador existente: </p>
        </div>
        <div className="column">
          <Dropdown
            style={{ width: "300px" }}
            options={[
              ...tabulatorsFiltered,
              { id: null, tabulatorName: "Vacio" },
            ]}
            optionLabel="tabulatorName"
            optionValue="id"
            value={tabulatorSelected}
            placeholder="Selecciona un tabulador"
            onChange={handleTabulatorSelection}
            disabled={newTabulatorName}
          />
        </div>
        <div className="column">
          <Button
            label="Agregar tabulador"
            onClick={addCreatedTabulator}
            disabled={!tabulatorSelected}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <p>Agregar tabulador nuevo: </p>
        </div>
        <div className="column">
          <InputText
            value={newTabulatorName}
            onChange={(e) => setNewTabulatorName(e.target.value)}
            style={{ width: "300px" }}
            placeholder="Ingresa el nombre del nuevo tabulador"
            disabled={tabulatorSelected}
          />
        </div>
        <div className="column">
          <Button
            label="Agregar nuevo tabulador"
            onClick={addNewTabulator}
            disabled={!newTabulatorName}
          />
        </div>
      </div>
    </Dialog>
  </div>
);

export default TabulatorsConfigurator;
