import React from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import ProtectedRoute from '../common/ProtectedRoute';
import Login from '../components/Login/Login';
import { components } from '../config/roles';
import Error from '../components/Error';
import AuthProvider from '../context/AuthContext/AuthContext';
import PasswordRecovery from '../components/Login/PasswordRecovery';

function Router() {
    const { pathname } = useLocation();
    const authenticatedUser = JSON.parse(
        sessionStorage.getItem('authenticatedUser')
    );

    // Función para redireccionar en caso de acceder a una URL invalida
    const fallbackRedirect = () => {
        if (authenticatedUser && authenticatedUser.isAuthenticated) {
          //   Verificar si la URL es valida o no
          return authenticatedUser.modules.find(
            ({ name }) => `/${name}` === pathname
          ) ? null : (
            <Route path="*">
              <Redirect to="/home" />
            </Route>
          );
        }

        return (
            <Route path="*">
                <Redirect to="/login" />
            </Route>
        );
    };

    return (
        <Switch>
            <AuthProvider>
                <Route path="/login" exact component={Login} />
                <Route
                    path="/password-recovery"
                    exact
                    component={PasswordRecovery}
                />
                {components && authenticatedUser && authenticatedUser.modules
                    ? authenticatedUser.modules.map(({ name }) => {
                          if (components[name]) {
                              const { path, component, id } = components[name];
                              return (
                                  <ProtectedRoute
                                      path={path}
                                      exact
                                      component={component}
                                      key={id}
                                  />
                              );
                          }
                      })
                    : null}
                {authenticatedUser ? (
                    fallbackRedirect()
                ) : (
                    <Route path="*">
                        <Redirect to="login" />
                    </Route>
                )}
            </AuthProvider>
            <Route component={Error} />
        </Switch>
    );
}

export default Router;
