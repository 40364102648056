import React from 'react';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const ConfirmDeleteSchema = ({
    deleteSchemaDialog,
    deleteSchemaDialogFooter,
    hideDeleteSchemaDialog,
    schemaSelected,
    pendingSchema,
    plazaBody,
}) => (
    <Dialog
        visible={deleteSchemaDialog}
        style={{ width: '650px' }}
        header="Confirm"
        modal
        footer={deleteSchemaDialogFooter}
        onHide={hideDeleteSchemaDialog}
    >
        <div className="confirmation-content">
            <i
                className="pi pi-exclamation-triangle p-mr-3"
                style={{ fontSize: '2rem' }}
            />
            {schemaSelected && (
                <>
                    <span>
                        ¿Desear eliminar el esquema de pago{' '}
                        <b>{schemaSelected.schemaName}</b>?
                    </span>
                    {pendingSchema && (
                        <div>
                            <br />
                            El Esquema de Pago en activarse es:
                            <DataTable
                                value={pendingSchema}
                                className="p-datatable-sm"
                            >
                                <Column
                                    field="id"
                                    header="ID"
                                    headerStyle={{ width: '5rem' }}
                                />
                                <Column
                                    header="Nombre de Esquema"
                                    field="schemaName"
                                />
                                <Column header="Plaza" body={plazaBody} />
                                <Column header="Vigencia" field="validity" />
                            </DataTable>
                        </div>
                    )}
                </>
            )}
        </div>
    </Dialog>
);

export default ConfirmDeleteSchema;
