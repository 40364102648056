import OpeningService from "../../api/OpeningService";
import ShiftsService from "../../api/ShiftsService";

class OpeningHelpers {
  fetchEmployees = (catalogs, setCatalogs, location_id, setLoading) => {
    OpeningService.getEmployeesByPosition(location_id)
      .then(({ data }) => {
        const { driver, driverAssistant, third } = data.result;
        setCatalogs({
          ...catalogs,
          drivers: driver || [],
          driverAssistants: driverAssistant || [],
          thirds: third || [],
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error: ", error);
      })
      .then(() => {});
  };

  fetchShifts = (catalogs, setCatalogs) => {
    ShiftsService.getShifts()
      .then(({ data: { result } }) => {
        setCatalogs({
          ...catalogs,
          shifts: result,
        });
      })
      .catch((error) => console.log(error));
  };

  fetchZones = (location_id) => OpeningService.getZonesByIdFortia(location_id);
}

export default new OpeningHelpers();
