/* eslint-disable eqeqeq */
/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import TabulatorOtifService from "../../../api/TabulatorOtifService";
import { usePaymentSchema } from "../../../context/PaymentSchemaContext";
import { IMG_DIRECTORY } from "../../../constants";

const DetallesEsquema = ({ commissionDisabled, tabulatorCode, tabulator }) => {
  const toast = useRef(null);

  const {
    jobPositions,
    paymentSchemaSelected,
    setPaymentSchemaSelected,
    commissionTypes,
    editValuesOnly,
  } = usePaymentSchema();

  const [detailsCreated, setDetailsCreated] = useState(true);
  const [readyToCreate, setReadyToCreate] = useState(false);
  const [addingItemsToList, setAddingItemsToList] = useState(false);

  const { idJobPositionsFortia, idCommissionType } = {
    ...paymentSchemaSelected.tabs[tabulatorCode],
  };

  // Si commissionDisabled es true, se agrega -1 por defualt a comision en form
  useEffect(() => {
    if (commissionDisabled) {
      const _paymentSchemaSelected = { ...paymentSchemaSelected };
      const aux = { ..._paymentSchemaSelected.tabs[tabulatorCode] };

      _paymentSchemaSelected.tabs[tabulatorCode] = {
        ...aux,
        idCommissionType: -1,
      };

      setPaymentSchemaSelected({ ..._paymentSchemaSelected });
    }
  }, []);

  const findNullValues = () => {
    const tabulatorDetails = {
      ...paymentSchemaSelected.tabs[tabulatorCode],
    };

    let response = false;

    if (
      tabulatorDetails.idCommissionType === null ||
      tabulatorDetails.idCommissionType === 0 ||
      tabulatorDetails.idJobPositionsFortia === null ||
      typeof tabulatorDetails.idJobPositionsFortia !== "object" ||
      tabulatorDetails.idJobPositionsFortia.length === 0
    ) {
      response = true;
    }

    return response;
  };

  const saveTabulatorInfo = (edit) => {
    let body = {
      idPaymentSchema: null,
      idTabulatorType: null,
      idJobPositionFortia: null,
      idCommissionType: null,
    };

    const aux = { ...paymentSchemaSelected.tabs[tabulatorCode] };

    body = {
      ...body,
      idPaymentSchema: paymentSchemaSelected.id,
      idTabulatorType: aux.idTabulator || tabulator.id,
      idJobPositionFortia: aux.idJobPositionsFortia.map((id) =>
        typeof id === "number" ? id : id.work_station_id
      ),
      idCommissionType: aux.idCommissionType,
    };

    if (edit) {
      TabulatorOtifService.updateTabulatorDetails(body)
        .then((response) => {
          switch (response.data.status) {
            case "OK":
              toast.current.show({
                severity: "success",
                summary: `${response.data.message}`,
              });
              break;

            case "ERROR":
              toast.current.show({
                severity: "error",
                summary: `${response.data.message}`,
              });
              break;
            default:
          }
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: `${error.response.data.message}`,
          });
        });
    } else {
      TabulatorOtifService.createTabulatorDetails(body)
        .then((response) => {
          switch (response.data.status) {
            case "OK":
              toast.current.show({
                severity: "success",
                summary: `${response.data.message}`,
              });

              setDetailsCreated(true);

              break;

            default:
          }
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: `${error.response.data.message}`,
          });
        });
    }
  };

  const handleOnBlur = (input) => {
    if (input === "multiselect") {
      console.log("User stop adding items");
      setAddingItemsToList(false);
    }

    if (!findNullValues() && !addingItemsToList) {
      // saveTabulatorInfo(detailsCreated);
      // This section should not be updated.
      saveTabulatorInfo(false);
    }
  };

  const handlePuestoChange = (e) => {
    const puesto = e.value;
    const _paymentSchemaSelected = { ...paymentSchemaSelected };
    const aux = { ..._paymentSchemaSelected.tabs[tabulatorCode] };
    if (
      puesto.length > 0 &&
      aux.idJobPositionsFortia.findIndex(
        (value) =>
          value.work_station_id === puesto[puesto.length - 1].work_station_id
      ) === -1
    ) {
      aux.idJobPositionsFortia = puesto;
    } else {
      aux.idJobPositionsFortia = puesto;
    }

    _paymentSchemaSelected.tabs[tabulatorCode] = { ...aux };
    setPaymentSchemaSelected({ ..._paymentSchemaSelected });
  };

  const handleInputChange = (e) => {
    const _paymentSchemaSelected = { ...paymentSchemaSelected };
    const aux = { ..._paymentSchemaSelected.tabs[tabulatorCode] };
    if (
      e.originalEvent &&
      e.originalEvent.target &&
      e.originalEvent.target.id
    ) {
      _paymentSchemaSelected.tabs[tabulatorCode] = {
        ...aux,
        [e.originalEvent.target.id]: e.value,
      };
    } else {
      _paymentSchemaSelected.tabs[tabulatorCode] = {
        ...aux,
        [e.target.id]: e.target.value,
      };
    }

    setPaymentSchemaSelected({ ..._paymentSchemaSelected });

    if (!findNullValues()) {
      saveTabulatorInfo(false);
    }
  };

  let jobPositionsFiltered;

  if (editValuesOnly) {
    jobPositionsFiltered = jobPositions.filter(({ work_station_id }) =>
      idJobPositionsFortia.find((id) => work_station_id === id)
    );
  }

  const commissionTypesFiltered = commissionTypes.filter((commission) => {
    if (tabulatorCode === "tabPortatil" || tabulatorCode == "tabOtif") {
      return commission.id === 2 || commission.id === 3;
    }
    if (tabulatorCode == "tabTanqueEstacionario") {
      return commission.id === 1;
    }

    return null;
  });

  useEffect(() => {
    if (findNullValues() || addingItemsToList) {
      setDetailsCreated(false);
    } else {
      setDetailsCreated(true);
      setReadyToCreate(true);
    }
  }, [idJobPositionsFortia, idCommissionType]);

  useEffect(() => {
    if (!addingItemsToList && !findNullValues() && !detailsCreated) {
      saveTabulatorInfo(false);
    }
  }, [addingItemsToList]);

  return (
    <div>
      <Toast ref={toast} />
      <div>
        <div className="columns">
          <div className="column is-one-third">
            {editValuesOnly ? (
              <>
                <h5>Puestos seleccionados</h5>
                <br />
                <ul>
                  {jobPositionsFiltered.map(({ work_station_name }) => (
                    <li>{`- ${work_station_name}`}</li>
                  ))}
                </ul>
              </>
            ) : (
              <>
                <h5>Seleccione los puestos</h5>
                <MultiSelect
                  id="idJobPositionsFortia"
                  className="mt-2"
                  value={idJobPositionsFortia}
                  options={jobPositions}
                  onChange={handlePuestoChange}
                  optionLabel="work_station_name"
                  optionValue="work_station_id"
                  placeholder="Selecciona un puesto"
                  onHide={() => handleOnBlur("multiselect")}
                  disabled={false}
                  onFocus={() => {
                    console.log("Focus un multiselect");
                    setAddingItemsToList(true);
                  }}
                  filter
                  showFilterClear
                  filterBy="work_station_name"
                />
              </>
            )}
          </div>
          {!commissionDisabled && (
            <div className="column is-one-third">
              <h5>Tipo de comisión</h5>
              <Dropdown
                id="idCommissionType"
                inputId="idCommissionType"
                className="w-full mt-2"
                value={idCommissionType}
                options={commissionTypesFiltered}
                optionLabel="commissionName"
                optionValue="id"
                onChange={handleInputChange}
                placeholder="Tipo de comisión"
                onBlur={handleOnBlur}
                disabled={false}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetallesEsquema;
