import axios from "../axios/custom-axios";
import { API_URL_OPENINGS } from "../constants";

class OpeningService {
  getEmployeesByPosition = async (locationId) =>
    axios.get(
      `${API_URL_OPENINGS}/employee/jobPosition?fortiaLocationId=${locationId}`
    );

  async getOperations() {
    const { data } = await axios.get(`${API_URL_OPENINGS}/operation`);
    return data;
  }

  getEmployees = async (locationId, shiftId) =>
    axios.get(
      `${API_URL_OPENINGS}/employee?fortiaLocationId=${locationId}&shiftId=${shiftId}`
    );

  async getComplements() {
    const { data } = await axios.get(`${API_URL_OPENINGS}/complement`);
    return data;
  }

  getComplementList = () => axios.get(`${API_URL_OPENINGS}/complement`);

  updateComplement(userId, complementId, shiftId, body) {
    return axios.put(
      `${API_URL_OPENINGS}/employee/${userId}/complement/${complementId}?shiftId=${shiftId}`,
      body
    );
  }

  getOpenings = async (locationId, shiftId, date) =>
    axios.get(
      `${API_URL_OPENINGS}/opening?fortiaLocationId=${locationId}&idShift=${shiftId}&openingDate=${date}`
    );

  updateOpening = async (body) =>
    axios.put(`${API_URL_OPENINGS}/opening/${body.id}`, body);

  liquidacion = async () =>
    axios.get("http://localhost:3001/estacionario/liquidacion");

  portatilLiquidacion = async () =>
    axios.get("http://localhost:3001/new/estacionario/liquidacion", {
      responseType: "pdf",
    });

  //

  getEmployeesByLocationAndJobPosition = (jobPositionId, locationId) =>
    axios.get(
      `${API_URL_OPENINGS}/employee/drivers?workStationId=${jobPositionId}&fortiaLocationId=${locationId}`
    );

  getSectores = () => axios.get(`${API_URL_OPENINGS}/sector/index`);

  getZonesByIdFortia = async (locationId) =>
    axios.get(
      `${API_URL_OPENINGS}/opening/zonesByIdFortia?fortiaLocationId=${locationId}`
    );
}

export default new OpeningService();
