import axios from '../axios/custom-axios';
import { API_URL_CONCEPTS } from '../constants';

class HolidaysService {
    async getHolidays() {
        const response = await axios.get(`${API_URL_CONCEPTS}/holidays`);
        return response;
    }

    async createHoliday(body) {
        const response = await axios.post(
            `${API_URL_CONCEPTS}/holidays`,
            body
        );
        return response;
    }

    async updateHoliday(body) {
        const response = await axios.put(
            `${API_URL_CONCEPTS}/holidays/${body.id}`,
            body
        );
        return response;
    }

    async deleteHoliday(id) {
        const response = await axios.delete(
            `${API_URL_CONCEPTS}/holidays/${id}`
        );
        return response;
    }
}

export default new HolidaysService();
