/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import DetallesEsquema from '../Comunes/DetallesEsquema';
import BonosProductividad from './BonosProductividad';
import BonosLitrajeSurtido from './BonosLitrajeSurtido';
import CatalogsService from '../../../api/CatalogsService';
import Comisiones from './Comisiones';

const TabuladorEstacionario = ({ tabulator, schema, puestos, tabType }) => {
    const tabulatorCode = 'tabTanqueEstacionario';
    const initialForm = {
        puestos: [],
        sueldo: 0,
        comision: null,
    };

    const [form, setForm] = useState(initialForm);

    const [paymentPeriods, setPaymentPeriods] = useState([]);
    useState(null);
    const [commissionTypes, setCommissionTypes] = useState([]);

    useEffect(() => {
        async function fetchPaymentPeriods() {
            const paymentPeriodsResp =
                await CatalogsService.getPaymentPeriods();
            if (paymentPeriodsResp.status === 'OK') {
                setPaymentPeriods(paymentPeriodsResp.result);
            }
        }

        async function fetchCommissionTypes() {
            const commissionTypesResp =
                await CatalogsService.getCommissionTypes();
            if (commissionTypesResp.status === 'OK') {
                setCommissionTypes(commissionTypesResp.result);
            }
        }

        fetchPaymentPeriods();
        fetchCommissionTypes();
    }, []);

    return (
        <>
            <DetallesEsquema
                form={form}
                setForm={setForm}
                schema={schema}
                commissionTypes={commissionTypes}
                listaPuestos={puestos}
                tabType={tabType}
                // NEW PROPS
                tabulator={tabulator}
                tabulatorCode={tabulatorCode}
            />
            <div className="mt-5">
                <Accordion>
                    <AccordionTab header="Comisiones">
                        <Comisiones
                            tabulator={tabulator}
                            tabulatorCode={tabulatorCode}
                            sectionName="commissions"
                            sectionCode="secComisionesDetalle"
                        />
                    </AccordionTab>
                    <AccordionTab header="Bonos de productividad">
                        <BonosProductividad
                            tabulator={tabulator}
                            tabulatorCode={tabulatorCode}
                            sectionName="productivityBonus"
                            sectionCode="secBonosRango"
                        />
                    </AccordionTab>
                    <AccordionTab header="Bonos por Litraje Surtido">
                        <BonosLitrajeSurtido
                            listaPuestos={form}
                            tabulator={tabulator}
                            schema={schema}
                            paymentPeriods={paymentPeriods}
                            tabType={tabType}
                            sectionType="BonosLitraje"
                            tabulatorCode={tabulatorCode}
                            sectionName="litersSold"
                            sectionCode="secBonoLitrajeSur"
                        />
                    </AccordionTab>
                </Accordion>
            </div>
        </>
    );
};

export default TabuladorEstacionario;
