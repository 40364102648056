import React from 'react';
import EsquemaRangoFijo from '../Comunes/EsquemaRangoFijo';

const BonosProductividad = ({
    tabulatorCode,
    sectionName,
    tabulator,
    sectionCode,
}) => (
    <>
        <EsquemaRangoFijo
            tabulatorCode={tabulatorCode}
            sectionName={sectionName}
            tabulator={tabulator}
            sectionCode={sectionCode}
        />
    </>
);

export default BonosProductividad;
