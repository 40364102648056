import axios from "../axios/custom-axios";
import { API_URL_TABULATORS } from "../constants";

class TabulatorOtifService {
  createPaymentSchema(body) {
    return axios.post(`${API_URL_TABULATORS}/payment-schema/general`, body);
  }

  deletePaymentSchema(id) {
    return axios.delete(`${API_URL_TABULATORS}/payment-schema/${id}`);
  }

  uploadFile(formData) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    return axios.post(`${API_URL_TABULATORS}/file`, formData, config);
  }

  downloadFile(fileName) {
    return axios.get(`${API_URL_TABULATORS}/file/download/${fileName}`);
  }

  getPaymentPending(id) {
    return axios.get(
      `${API_URL_TABULATORS}/payment-schema/pending?idPaymentSchema=${id}`
    );
  }

  // DETALLE DEL TABULADOR

  createTabulatorDetails(body) {
    return axios.post(`${API_URL_TABULATORS}/tabulator-general`, body);
  }

  updateTabulatorDetails(body) {
    return axios.put(`${API_URL_TABULATORS}/tabulator-general`, body);
  }

  createWeightingsSchema(body) {
    return axios.post(`${API_URL_TABULATORS}/weightings-schema/register`, body);
  }

  // SIMPLE RANGE SCHEMA

  saveSimpleRangeSchemaRow(body) {
    return axios.post(`${API_URL_TABULATORS}/simple-range-schema`, body);
  }

  updateSimpleRangeSchemaRow(body) {
    return axios.put(
      `${API_URL_TABULATORS}/simple-range-schema/${body.id}`,
      body
    );
  }

  deleteSimpleRowSchemaRow(id) {
    return axios.delete(`${API_URL_TABULATORS}/simple-range-schema/${id}`);
  }

  // FIXED PAYMENT SCHEMA

  createFixedPaymentSchema(body) {
    return axios.post(`${API_URL_TABULATORS}/fixed-payment-schema`, body);
  }

  updateFixedPaymentSchema(body) {
    return axios.put(
      `${API_URL_TABULATORS}/fixed-payment-schema/${body.id}`,
      body
    );
  }

  deleteFixedRangeSchema(id) {
    return axios.delete(`${API_URL_TABULATORS}/fixed-payment-schema/${id}`);
  }

  createLiterSoldSchema(body) {
    return axios.post(`${API_URL_TABULATORS}/liters-sold-schema`, body);
  }

  // LITERS SOLD SCHEMA

  deleteLiterSoldSchema(id) {
    return axios.delete(`${API_URL_TABULATORS}/liters-sold-schema/${id}`);
  }

  updateLiterSoldSchema(body) {
    return axios.put(
      `${API_URL_TABULATORS}/liters-sold-schema/${body.id}`,
      body
    );
  }
  // RANGE DETAIL SCHEMA

  createRangeDetailSchema(body) {
    return axios.post(`${API_URL_TABULATORS}/range-details-schema`, body);
  }

  updateRangeDetailSchema(body) {
    return axios.put(
      `${API_URL_TABULATORS}/range-details-schema/${body.id}`,
      body
    );
  }

  deleteRangeDetailSchema(id) {
    return axios.delete(`${API_URL_TABULATORS}/range-details-schema/${id}`);
  }

  deleteWeightingsSchema(id) {
    return axios.delete(`${API_URL_TABULATORS}/weightings-schema/${id}`);
  }

  updateWeightingsSchema(body) {
    return axios.put(
      `${API_URL_TABULATORS}/weightings-schema/${body.id}`,
      body
    );
  }

  // GET FULL PAYMENT SCHEMA

  getPaymentSchema(id) {
    return axios.get(`${API_URL_TABULATORS}/payment-schema/${id}`);
  }

  getCommissions(id) {
    return axios.get(`${API_URL_TABULATORS}/comissions?idFortiaLocation=${id}`);
  }

  getTabulatorsCatalog = () =>
    axios.get(`${API_URL_TABULATORS}/tabulador-secciones/tabulators`);

  getSectionsCatalog = () =>
    axios.get(`${API_URL_TABULATORS}/tabulador-secciones/sections`);

  getConfigByLocation = (locationId) =>
    axios.get(
      `${API_URL_TABULATORS}/tabulador-secciones?fortiaLocationId=${locationId}`
    );

  saveConfiguration(body) {
    return axios.post(`${API_URL_TABULATORS}/tabulador-secciones`, body);
  }

  deleteTabulator(id) {
    return axios.delete(`${API_URL_TABULATORS}/tabulador-secciones/${id}`);
  }

  // RANGE DETAIL OTIF SCHEMA
  createRangeDetailOtifSchema(body) {
    return axios.post(`${API_URL_TABULATORS}/range-details-otif-schema`, body);
  }

  updateRangeDetailOtifSchema(body) {
    return axios.put(
      `${API_URL_TABULATORS}/range-details-otif-schema/${body.id}`,
      body
    );
  }

  deleteRangeDetailOtifSchema(id) {
    return axios.delete(
      `${API_URL_TABULATORS}/range-details-otif-schema/${id}`
    );
  }

  // Empleados por locacion
  employeesByLocationId(id) {
    return axios.get(
      `${API_URL_TABULATORS}/job-positions/driversByLocation?locationId=${id}`
    );
  }
}

export default new TabulatorOtifService();
