/* eslint-disable import/no-cycle */
import { Dropdown } from "primereact/dropdown";
import React, { useState, useEffect, useContext } from "react";
import { Box, Container } from "react-bulma-components";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import MainLayout from "../Layouts/MainLayout";
import OpeningService from "../../api/OpeningService";
import PayrollPeriodService from "../../api/PayrollPeriodService";
import { API_URL_SALES } from "../../constants";
import { AuthContext } from "../../context/AuthContext/AuthContext";
const dateToFormat = (dateToTrasnform) => {
  if (dateToTrasnform) {
    const date = dateToTrasnform.getDate();
    const month = dateToTrasnform.getMonth() + 1;
    const year = dateToTrasnform.getFullYear();

    const dateString = `${year}-${month < 10 ? "0" : ""}${month}-${
      date < 10 ? "0" : ""
    }${date}`;

    return dateString;
  } else {
    return null;
  }
};

const Liquidaciones = () => {
  const [date, setDate] = useState(new Date().getTime());
  const [employees, setEmployees] = useState([]);
  const [employee, setEmployee] = useState("");
  const { auth } = useContext(AuthContext);

  const onPrintClick = () => {
    OpeningService.liquidacion()
      .then((response) => {
        console.log(response);
      })
      .catch((error) => console.log(error))
      .then(() => {});
  };

  const handleLiquidacionClick = () => {
    if (date && employee) {
      const sales_time = dateToFormat(date);
      const empleado_rfc = employee;

      window.open(
        `${API_URL_SALES}/pdfcreate?sales_time=${sales_time}&empleado_rfc=${empleado_rfc}`,
        "_blank",
        "noopener,noreferrer"
      );
    }
  };

  const optionTemplate = (option) => (
    <>{option ? <p>{`${option.employeeName} - ${option.rfc}`}</p> : ""}</>
  );

  useEffect(() => {
    PayrollPeriodService.getEmployeesByLocationId(auth.location)
      .then((response) => {
        setEmployees([
          {
            employeeName: "Selecciona un usuario",
            rfc: "",
          },
          ...response.data.result,
          {
            employeeName: "Usuario de prueba",
            rfc: "ZAAG750119APA",
          },
        ]);
      })
      .catch((error) => console.log(error));
  }, []);

  console.log(employees);

  const convertDate = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${year}-${month < 10 ? "0" : ""}${month}-${
      day < 10 ? "0" : ""
    }${day}`;
  };

  return (
    <MainLayout>
      <Box>
        <Container>
          <div className="container px-3 pb-5">
            <p className="is-size-3">Impresión de Liquidaciones</p>
            <div
              className="container mt-5"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <div>
                <p>Selecciona un empleado</p>
                <Dropdown
                  value={employee}
                  placeholder="Selecciona un empleado..."
                  className="mr-3"
                  style={{ width: "400px" }}
                  options={employees}
                  optionLabel="rfc"
                  valueTemplate={optionTemplate}
                  onChange={(e) => setEmployee(e.value)}
                  itemTemplate={optionTemplate}
                  optionValue="rfc"
                  disabled={employees.length === 0}
                  filter
                  filterBy="rfc"
                />
              </div>
              <div className="mb-3">
                <p>Selecciona una fecha</p>
                <Calendar
                  id="basic"
                  placeholder="Ingresa una fecha"
                  value={date}
                  onChange={(e) => setDate(e.value)}
                />
              </div>
            </div>
            <div className="container ">
              <Button
                disabled={!(employee && date)}
                onClick={() => {
                  const dateString = convertDate(date);
                  window.open(
                    `https://api-dev.endpoints.gpo-alerta-prenomina.cloud.goog/pdfcreator/api/v2/liquidacion?date=${dateString}&rfc=${employee}`,
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              >
                Imprimir Liquidación
              </Button>
              <Button
                className="ml-2"
                disabled={!(employee && date)}
                onClick={() => {
                  const dateString = convertDate(date);
                  window.open(
                    `https://api-dev.endpoints.gpo-alerta-prenomina.cloud.goog/pdfcreator/api/v2/prenomina?date=${dateString}&rfc=${employee}`,
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              >
                Imprimir Prenómina
              </Button>
            </div>
          </div>
        </Container>
      </Box>
    </MainLayout>
  );
};

export default Liquidaciones;
