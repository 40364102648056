/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import React, { useRef, useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import { Message } from "primereact/message";
import TabulatorOtifService from "../../../api/TabulatorOtifService";
import BonoConceptsService from "../../../api/BonoConceptsService";
import { usePaymentSchema } from "../../../context/PaymentSchemaContext";

const Ponderaciones = ({
  tabulator,
  tabulatorCode,
  sectionName,
  sectionCode,
}) => {
  const toast = useRef(null);
  const [submitted, setSubmitted] = useState(false);
  const [isEditingRow, setIsEditingRow] = useState(false);
  const [originalRows, setOriginalRows] = useState([]);
  const [bonoConcepts, setBonoConcepts] = useState([]);
  let percentageTotal = 0;

  const emptyRow = {
    ids: null,
    idPaymentPeriod: null,
    idEvaluation: null,
    idRTabulatorsectionsTabulatortypes: null,
    weightingsName: "",
    percentageValue: null,
    isRowEditing: true,
  };

  const [idTabulatorSection, setIdTabulatorSection] = useState(null);

  useEffect(() => {
    if (tabulator) {
      const result = tabulator.sections.find(
        (section) => section.sectionCode === sectionCode
      );
      setIdTabulatorSection(result.idTabulatorSection);
    }
  }, []);

  const {
    paymentPeriods,
    paymentSchemaSelected,
    setPaymentSchemaSelected,
    jobPositions,
    editValuesOnly,
    editing,
  } = usePaymentSchema();

  const { idJobPositionsFortia, idTabulator } = {
    ...paymentSchemaSelected.tabs[tabulatorCode],
  };

  const filteredJobPositions = jobPositions.filter(({ work_station_id }) =>
    idJobPositionsFortia.find((id) => id === work_station_id)
  );

  const {
    idPaymentPeriod,
    idEvaluation,
    idRTabSectionsTabTypes,
    weightingsSchema,
  } = {
    ...paymentSchemaSelected.tabs[tabulatorCode].sections[sectionName],
  };

  const onFormInputChange = (e, field) => {
    const _paymentSchemaSelected = { ...paymentSchemaSelected };
    const aux = {
      ...paymentSchemaSelected.tabs[tabulatorCode].sections[sectionName],
    };
    _paymentSchemaSelected.tabs[tabulatorCode].sections[sectionName] = {
      ...aux,
      [field]: e.value,
    };

    setPaymentSchemaSelected(_paymentSchemaSelected);
  };

  const onEditorValueChange = (field, value, rowData) => {
    const _paymentSchemaSelected = { ...paymentSchemaSelected };
    const _weightingsSchema = [...weightingsSchema];

    const rowIndex = _weightingsSchema.findIndex((val) => val === rowData);

    _weightingsSchema[rowIndex][field] = value;
    _paymentSchemaSelected.tabs[tabulatorCode].sections[
      sectionName
    ].weightingsSchema = [...weightingsSchema];

    setPaymentSchemaSelected({ ..._paymentSchemaSelected });
  };

  const handleValidation = (formAux, rowData) => {
    let error = false;

    if (
      formAux.idEvaluation === null ||
      formAux.idPaymentPeriod === null ||
      rowData.weightingsName === "" ||
      rowData.percentageValue === null
    ) {
      error = true;
    }

    return error;
  };

  const onRowEditInit = (rowData) => {
    setIsEditingRow(true);

    const _originalRows = [...originalRows];
    const _weightingsSchema = [...weightingsSchema];
    const index = _weightingsSchema.findIndex((val) => val === rowData);

    rowData.isRowEditing = true;
    _originalRows[index] = { ...weightingsSchema[index] };
    setOriginalRows(_originalRows);
  };

  const onRowEditCancel = (rowData) => {
    setIsEditingRow(false);
    setSubmitted(false);

    const _paymentSchemaSelected = { ...paymentSchemaSelected };
    const _weightingsSchema = [...weightingsSchema];
    const index = _weightingsSchema.findIndex((val) => val === rowData);

    const _originalRow = { ...originalRows[index] };
    _originalRow.isRowEditing = false;

    _weightingsSchema[index] = _originalRow;

    const _originalRows = [...originalRows];
    delete _originalRows[index];

    _paymentSchemaSelected.tabs[tabulatorCode].sections[
      sectionName
    ].weightingsSchema = [..._weightingsSchema];

    setPaymentSchemaSelected({ ..._paymentSchemaSelected });
    setOriginalRows(_originalRows);
  };

  const addNewRow = () => {
    setIsEditingRow(true);

    const _paymentSchemaSelected = { ...paymentSchemaSelected };
    const _weightingsSchema = weightingsSchema
      ? [...weightingsSchema, { ...emptyRow }]
      : [{ ...emptyRow }];

    _paymentSchemaSelected.tabs[tabulatorCode].sections[
      sectionName
    ].weightingsSchema = [..._weightingsSchema];

    setPaymentSchemaSelected({ ..._paymentSchemaSelected });

    const _originalRows = [...originalRows];
    const index = _weightingsSchema.length - 1;

    _originalRows[index] = { ..._weightingsSchema[index] };
    setOriginalRows(_originalRows);
  };

  const onRowEditSave = (rowData) => {
    setSubmitted(true);

    const aux = {
      ...paymentSchemaSelected.tabs[tabulatorCode].sections[sectionName],
    };

    if (handleValidation(aux, rowData)) {
      return;
    }
    if (percentageTotal > 100) {
      toast.current.show({
        severity: "error",
        summary: "La suma de las ponderaciones no puede ser mayor a 100%",
      });
      return;
    }
    const _paymentSchemaSelected = { ...paymentSchemaSelected };
    const _weightingsSchema = [...weightingsSchema];
    const index = _weightingsSchema.findIndex((val) => val === rowData);

    rowData.idPaymentSchema = paymentSchemaSelected.id;
    rowData.idTabulatorTypes = idTabulator || tabulator.id;
    rowData.idRTabulatorsectionsTabulatortypes =
      idRTabSectionsTabTypes || idTabulatorSection;

    rowData.idEvaluation = aux.idEvaluation;
    rowData.idPaymentPeriod = aux.idPaymentPeriod;

    if (!rowData.ids) {
      TabulatorOtifService.createWeightingsSchema(rowData)
        .then((response) => {
          const { result, message } = response.data;
          setIsEditingRow(false);

          _weightingsSchema[index] = result;
          _weightingsSchema[index].isRowEditing = false;
          _paymentSchemaSelected.tabs[tabulatorCode].sections[
            sectionName
          ].weightingsSchema = [..._weightingsSchema];

          setPaymentSchemaSelected({
            ..._paymentSchemaSelected,
          });

          toast.current.show({
            severity: "success",
            summary: message,
          });
        })
        .catch((error) => {
          setIsEditingRow(false);

          toast.current.show({
            severity: "error",
            summary: `${error.response.data.message}`,
          });
        });
    } else {
      // eslint-disable-next-line prefer-destructuring
      rowData.id = rowData.ids[0];
      TabulatorOtifService.updateWeightingsSchema(rowData)
        .then((response) => {
          setIsEditingRow(false);

          const { message } = response.data;
          _weightingsSchema[index] = rowData;
          _weightingsSchema[index].isRowEditing = false;
          _paymentSchemaSelected.tabs[tabulatorCode].sections[
            sectionName
          ].weightingsSchema = [..._weightingsSchema];

          setPaymentSchemaSelected({
            ..._paymentSchemaSelected,
          });

          toast.current.show({
            severity: "success",
            summary: message,
          });
        })
        .catch((error) => {
          setIsEditingRow(false);

          toast.current.show({
            severity: "error",
            summary: `${error.response.data.message}`,
          });
        });
    }
  };

  const onRowDelete = (rowData) => {
    const _paymentSchemaSelected = { ...paymentSchemaSelected };
    const _weightingsSchema = [...weightingsSchema];
    const index = _weightingsSchema.findIndex((val) => val === rowData);

    if (rowData.ids) {
      TabulatorOtifService.deleteWeightingsSchema(rowData.ids[0])
        .then((response) => {
          const { message } = response.data;
          _weightingsSchema.splice(index, 1);

          _paymentSchemaSelected.tabs[tabulatorCode].sections[
            sectionName
          ].weightingsSchema = [..._weightingsSchema];
          setPaymentSchemaSelected({ ..._paymentSchemaSelected });

          toast.current.show({
            severity: "success",
            summary: message,
          });
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: `${error.response.data.message}`,
          });
        });
    } else {
      _weightingsSchema.splice(index, 1);

      _paymentSchemaSelected.tabs[tabulatorCode].sections[
        sectionName
      ].weightingsSchema = [..._weightingsSchema];
      setPaymentSchemaSelected({ ..._paymentSchemaSelected });
    }
  };

  const dynamicPuestos = filteredJobPositions.map((col) => {
    const { work_station_id, work_station_name } = col;
    return (
      <div
        id={work_station_id}
        style={{
          textAlign: "left",
          border: "1px solid #e9ecef",
          borderWidth: "0 0 1px 0",
          padding: "1rem 1rem",
          background: "#ffffff",
          transition: "box-shadow 0.2s",
          outlineColor: "#a6d5fa",
        }}
      >
        {work_station_name}
      </div>
    );
  });

  const dynamicPonderaciones = weightingsSchema.map((rowData) => {
    const { weightingsName, percentageValue, isRowEditing } = rowData;
    percentageTotal += percentageValue;

    const filteredBonoConcepts = bonoConcepts.map((bono) => {
      const newBono = { ...bono };
      if (
        weightingsSchema.find(
          (schema) => schema.weightingsName === newBono.name
        )
      ) {
        newBono.disabled = true;
      }

      return newBono;
    });

    return (
      <div
        className="p-field p-col-3"
        style={{
          padding: "0.5rem",
          borderWidth: "1px 1px 0 1px",
          border: "1px solid #e9ecef",
        }}
      >
        <div
          className="p-datatable p-datatable-wrapper p-datatable-gridlines"
          style={{ height: "100%" }}
        >
          <div
            style={{
              textAlign: "left",
              border: "1px solid #e9ecef",
              borderWidth: "1px 1px 1px 1px",
              padding: "1rem 1rem",
              background: "#ffffff",
              transition: "box-shadow 0.2s",
              outlineColor: "#a6d5fa",
              height: "40%",
            }}
          >
            <Dropdown
              options={filteredBonoConcepts}
              optionLabel="name"
              optionValue="name"
              onChange={(e) =>
                onEditorValueChange("weightingsName", e.target.value, rowData)
              }
              value={weightingsName}
              disabled={!isRowEditing}
              className={classNames({
                "p-invalid": submitted && isRowEditing && weightingsName === "",
              })}
              required
            />

            {submitted && isRowEditing && !(weightingsName !== "") && (
              <Message severity="error" text="Ingresa un dato valido" />
            )}
          </div>
          <div
            style={{
              textAlign: "center",
              border: "1px solid #e9ecef",
              borderWidth: "1px 1px 1px 1px",
              padding: "1rem 1rem",
              background: "#ffffff",
              transition: "box-shadow 0.2s",
              outlineColor: "#a6d5fa",
              height: "40%",
            }}
          >
            <InputNumber
              value={percentageValue}
              onValueChange={(e) =>
                onEditorValueChange("percentageValue", e.target.value, rowData)
              }
              suffix="%"
              showButtons
              min={0}
              max={100}
              required
              className={classNames({
                "p-invalid":
                  submitted && isRowEditing && percentageValue === null,
              })}
              disabled={!isRowEditing}
            />
            {submitted && isRowEditing && !(percentageValue !== null) && (
              <Message severity="error" text="Ingresa un dato valido" />
            )}
          </div>
          <div
            style={{
              textAlign: "center",
              padding: "0.5rem",
              background: "#ffffff",
              transition: "box-shadow 0.2s",
              height: "10%",
            }}
          >
            {isRowEditing && (
              <>
                <Button
                  icon="pi pi-check"
                  className="p-button-rounded p-mr-2 p-button-text"
                  onClick={() => onRowEditSave(rowData)}
                />
                <Button
                  icon="pi pi-times"
                  className="p-button-rounded p-mr-2 p-button-text"
                  onClick={() => onRowEditCancel(rowData)}
                />
              </>
            )}
            {!isRowEditing && (
              <>
                <Button
                  icon="pi pi-fw pi-pencil"
                  className="pencil p-mr-2 p-button-text"
                  onClick={() => onRowEditInit(rowData)}
                />
                {!editing && (
                  <Button
                    icon="pi pi-trash"
                    className="trash p-mr-2"
                    onClick={() => onRowDelete(rowData)}
                    disabled={editValuesOnly}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  });

  let paymentType;
  let evaluationType;

  if (editValuesOnly) {
    paymentType = paymentPeriods.find(({ id }) => id === idPaymentPeriod);
    evaluationType = paymentPeriods.find(({ id }) => id === idEvaluation);
  }

  const addSectionWithPaymentPeriod = () => {
    const option = periodTypes.find(
      (period) => period.value === paymentSchemaSelected.period_name
    );
    const _paymentSchemaSelected = { ...paymentSchemaSelected };
    const aux = {
      ...paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode],
    };
    _paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode] = {
      ...aux,
      idPaymentPeriod: option.id,
      idEvaluation: option.id,
    };

    setPaymentSchemaSelected(_paymentSchemaSelected);
  };

  useEffect(() => {
    addSectionWithPaymentPeriod();
  }, []);

  useEffect(() => {
    BonoConceptsService.getBonoConcepts()
      .then((response) => {
        setBonoConcepts(response.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <>
      <Toast ref={toast} />
      <div className="p-formgrid p-grid-1">
        <div className="p-field p-col">
          <div className="p-field p-col">
            <div className="p-formgrid p-grid">
              <div className="p-field p-col-2" style={{ padding: "0.0rem" }}>
                <div
                  className="p-datatable p-component"
                  data-scrollselectors=".p-datatable-scrollable-body, .p-datatable-unfrozen-view .p-datatable-scrollable-body"
                  style={{
                    border: "1px solid #e9ecef",
                  }}
                >
                  <div className="p-datatable p-datatable-wrapper ">
                    <div
                      className="p-datatable-thead"
                      style={{
                        textAlign: "left",
                        padding: "1rem 1rem",
                        border: "1px solid #e9ecef",
                        borderWidth: "1px 1px 0 1px",
                        fontWeight: "600",
                        color: "#495057",
                        background: "#f8f9fa",
                        transition: "box-shadow 0.2s;",
                      }}
                    >
                      <span className="p-column-title">Puestos</span>
                    </div>
                    {dynamicPuestos}
                  </div>
                </div>
              </div>

              {dynamicPonderaciones}

              <div
                className="p-field p-col-1"
                style={{
                  textAlign: "center",
                  padding: "0.5rem",
                  width: "5%",
                  borderWidth: "1px 1px 0 1px",
                  border: "1px solid #e9ecef",
                }}
              >
                {!isEditingRow && (
                  <>
                    <div
                      style={{
                        height: "40%",
                      }}
                    >
                      {percentageTotal < 100 && !editing && (
                        <Button
                          icon="pi pi-plus"
                          className="p-button-success p-button-rounded"
                          onClick={addNewRow}
                          disabled={editValuesOnly}
                        />
                      )}
                    </div>
                    <div
                      style={{
                        height: "40%",
                      }}
                    >
                      <span>Total</span>
                      <InputNumber
                        value={percentageTotal}
                        suffix="%"
                        required
                        disabled
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Ponderaciones;
