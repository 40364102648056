import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputMask } from 'primereact/inputmask';

const UserInfoForm = ({
    userInfo,
    handleSubmit,
    handleInputChange,
    enableUpdateButton,
    restoreInfo,
}) => {
    const [editable] = useState(true);

    return (
        <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-12 p-md-12 mt-3">
                <label htmlFor="username">Nombre de Usuario</label>
                <InputText
                    id="username"
                    type="text"
                    value={userInfo.username}
                    disabled
                />
            </div>

            <div className="p-field p-col-12 p-md-6 mt-3">
                <label htmlFor="firstname">Nombre</label>
                <InputText
                    id="firstname"
                    type="text"
                    value={userInfo.firstname}
                    disabled={!editable}
                    onChange={handleInputChange}
                />
            </div>
            <div className="p-field p-col-12 p-md-6 mt-3">
                <label htmlFor="lastname">Apellido</label>
                <InputText
                    id="lastname"
                    type="text"
                    value={userInfo.lastname}
                    disabled={!editable}
                    onChange={handleInputChange}
                />
            </div>
            <div className="p-field p-col-12 p-md-6 mt-3">
                <label htmlFor="email">Correo Electrónico</label>
                <InputText
                    id="email"
                    type="text"
                    value={userInfo.email}
                    disabled
                    onChange={handleInputChange}
                />
            </div>
            <div className="p-field p-col-12 p-md-6 mt-3">
                <label htmlFor="telephone">Número de Teléfono</label>
                <InputMask
                    id="telephone"
                    mask="(999) 999-9999"
                    placeholder="(999) 999-9999"
                    value={userInfo.telephone}
                    disabled={!editable}
                    onChange={handleInputChange}
                />
            </div>
            {/* <div className="p-field p-col-12 p-md-6 mt-3">
                <label htmlFor="country">País</label>
                <InputText
                    id="country"
                    type="text"
                    value={userInfo.country}
                    disabled={!editable}
                    onChange={handleInputChange}
                />
            </div>
            <div className="p-field p-col-12 p-md-6 mt-3">
                <label htmlFor="city">Ciudad</label>
                <InputText
                    id="city"
                    type="text"
                    value={userInfo.city}
                    disabled={!editable}
                    onChange={handleInputChange}
                />
            </div> */}
            <div className="p-field p-col-12 p-md-6 mt-3">
                <label htmlFor="address">Domicilio</label>
                <InputText
                    id="address"
                    type="text"
                    value={userInfo.address}
                    disabled={!editable}
                    onChange={handleInputChange}
                />
            </div>
            {/* <div className="p-field p-col-12 p-md-6 mt-3">
                <label htmlFor="address">Rol</label>
                <InputText
                    id="roles"
                    type="text"
                    value={userInfo.roles ? userInfo.roles[0].id : ''}
                    disabled={!editable}
                    onChange={handleInputChange}
                />
            </div> */}
            <div className="p-field p-col-3 mt-3">
                <Button
                    className="secundary-button"
                    label="Actualizar datos"
                    onClick={handleSubmit}
                    disabled={enableUpdateButton()}
                />
            </div>
            <div className="p-field p-col-3 mt-3 mb-5">
                <Button
                    className="primary-color"
                    label="Restaurar datos iniciales"
                    disabled={enableUpdateButton()}
                    onClick={restoreInfo}
                />
            </div>
        </div>
    );
};

export default UserInfoForm;
