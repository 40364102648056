import Comisiones from '../TabuladorPortatil/Comisiones';
import BonosProductividad from '../TabuladorPortatil/BonosProductividad';
import Compensaciones from '../TabuladorPortatil/Compensaciones';
import ComisionesDetalle from '../TabuladorEstacionario/Comisiones';
import BonosLitrajesSurtido from '../TabuladorEstacionario/BonosLitrajeSurtido';
import ComisionesFijo from '../Otif/Comisiones';
import BonoProductividadFijo from '../Otif/BonosProductividad';
import Ponderaciones from './Ponderaciones';

const sectionsConfig = {
    secComisionesRango: Comisiones,
    secBonosRango: BonosProductividad,
    secCompensacionesRango: Compensaciones,
    secComisionesDetalle: ComisionesDetalle,
    secPonderaciones: Ponderaciones,
    secBonoLitrajeSur: BonosLitrajesSurtido,
    secBonosFijo: BonoProductividadFijo,
    secComisionesFijo: ComisionesFijo,
    secComisionesDetalleOtif: Ponderaciones,
};

export default sectionsConfig;
