import React, { useState, useEffect, useRef } from 'react';
import { Container, Box } from 'react-bulma-components';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import TypeLitersGrid from './TypeLitersGrid';
// eslint-disable-next-line import/no-cycle
import MainLayout from '../Layouts/MainLayout';
import TypeLitersService from '../../api/TypeLitersService';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';

const TypeLitersManagement = () => {
    const [typeLiter, setTypeLiter] = useState(null);
    const [typeLiters, setTypeLiters] = useState([]);

    const [deleteTypeLiterDialog, setDeleteTypeLiterDialog] = useState(false);
    const [originalRows, setOriginalRows] = useState({});
    const [editingRows, setEditingRows] = useState({});
    const [deleteRowButtonEnabled, setDeleteRowButtonEnabled] = useState(true);

    const toast = useRef(null);
    const typeLiterNameRef = useRef();

    async function fetchTypeLiters() {
        try {
            const response = await TypeLitersService.getTypeLiters();
            const { data } = response;
            setTypeLiters(data.result);
        } catch (error) {
            const { data } = error.response;
            toast.current.show({
                severity: 'error',
                summary: `${data.message}`,
            });
            setTypeLiters([]);
        }
    }

    useEffect(() => {
        fetchTypeLiters();
    }, []);

    // MÉTODOS
    const onRowEditInit = (event) => {
        const typeLiterAux = { ...typeLiters[event.index] };
        const originalRowsAux = { ...originalRows };
        originalRowsAux[event.index] = typeLiterAux;
        setOriginalRows(originalRowsAux);
        setDeleteRowButtonEnabled(false);
    };

    const onRowEditChange = (event) => {
        setEditingRows(event.data);
    };

    const hideDeleteTypeLiterDialog = () => {
        setDeleteTypeLiterDialog(false);
    };

    const setActiveRowIndex = (index, typeLitersAux) => {
        const originalRowsAux = { ...originalRows };
        originalRowsAux[index] = { ...typeLitersAux[index - 1] };
        setEditingRows({
            ...editingRows,
            ...{ [`${typeLitersAux[index - 1].id}`]: true },
        });
    };

    const handleNewTypeLiterClick = () => {
        const typeLitersAux = [...typeLiters];
        typeLitersAux.push({
            id: null,
            newTypeLiter: true,
            name: '',
            description: '',
        });
        setTypeLiters(typeLitersAux);
        setActiveRowIndex(typeLitersAux.length, typeLitersAux);
        if (deleteRowButtonEnabled) {
            setDeleteRowButtonEnabled(false);
        }
    };

    const handleValidation = (props) => {
        const rowData = props.data;
        let error = false;

        if (rowData.name.length === 0) {
            error = true;
            typeLiterNameRef.current.required = true;
            typeLiterNameRef.current.className =
                'p-inputtext p-component p-invalid';
        }

        return error;
    };

    const msgError = (error) => {
        const { data } = error.response;
        const msg =
            data.error.type === 'DataIntegrityViolationException'
                ? 'El nombre que intenta registrar, ya existe.'
                : data.message;
        toast.current.show({
            severity: 'error',
            summary: `${msg}`,
        });
        fetchTypeLiters();
    };

    const onRowEditSave = (props) => {
        const errors = handleValidation(props);

        if (!errors) {
            if (props.data.newTypeLiter) {
                TypeLitersService.createTypeLiter(props.data)
                    .then((response) => {
                        const { data } = response;
                        toast.current.show({
                            severity: 'success',
                            summary: `${data.message}`,
                        });

                        TypeLitersService.getTypeLiters()
                            .then((r) => {
                                setTypeLiters(r.data.result);
                            })
                            .catch();
                    })
                    .catch((error) => {
                        msgError(error);
                    });
            } else {
                TypeLitersService.updateTypeLiter(props.data)
                    .then((response) => {
                        const { data } = response;
                        const typeLitersAux = [...typeLiters];
                        typeLitersAux[props.index] = data.result;
                        setTypeLiters(typeLitersAux);

                        toast.current.show({
                            severity: 'success',
                            summary: `${data.message}`,
                        });
                    })
                    .catch((error) => {
                        msgError(error);
                    });
            }

            if (Object.values(editingRows).length === 1) {
                setEditingRows({});
                setDeleteRowButtonEnabled(true);
            }
        }
    };

    const deleteTypeLiter = () => {
        TypeLitersService.deleteTypeLiter(typeLiter.id)
            .then((response) => {
                const { data } = response;
                toast.current.show({
                    severity: 'success',
                    summary: `${data.message}`,
                });
            })
            .catch((error) => {
                const { data } = error.response;
                toast.current.show({
                    severity: 'error',
                    summary: `${data.message}`,
                });
            });

        const typeLitersAux = typeLiters.filter(
            (typeLiterAux) => typeLiterAux.id !== typeLiter.id
        );
        setTypeLiters(typeLitersAux);
        setDeleteTypeLiterDialog(false);
        setTypeLiter(null);
    };

    const onRowEditCancel = (event) => {
        const typeLitersAux = [...typeLiters];
        if (event.data.newTypeLiter) {
            typeLitersAux.pop();
            setTypeLiters(typeLitersAux);
            setDeleteRowButtonEnabled(true);
        } else {
            typeLitersAux[event.index] = { ...originalRows[event.index] };
            const newRows = { ...originalRows };
            delete newRows[event.index];
            setOriginalRows(newRows);
            setTypeLiters(typeLitersAux);
        }

        if (Object.values(editingRows).length === 1) {
            setDeleteRowButtonEnabled(true);
        }
    };

    const onEditorValueChange = (props, value) => {
        const updatedTypeLiters = [...props.value];
        updatedTypeLiters[props.rowIndex][props.field] = value;
        setTypeLiters(updatedTypeLiters);
    };

    const rowEditorValidator = (rowData) => rowData.name.trim().length !== 0;

    const disableNewButton = () =>
        !!typeLiters.find((_type) => _type.id === null);
    
    const confirmDeleteTypeLiter = (selectedTypeLiter) => {
        setTypeLiter(selectedTypeLiter);
        setDeleteTypeLiterDialog(true);
    };

    // COMPONENTS
    const deleteRowBody = (rowData) => (
        <>
            <Button
                icon="pi pi-trash"
                className="trash"
                disabled={!deleteRowButtonEnabled}
                onClick={() => confirmDeleteTypeLiter(rowData)}
            />
        </>
    );

    const deleteTypeLiterDialogFooter = (
        <>
            <Button
                label="No"
                icon="pi pi-times"
                className="p-button-text"
                onClick={hideDeleteTypeLiterDialog}
            />
            <Button
                label="Si"
                icon="pi pi-check"
                className="p-button-text"
                onClick={deleteTypeLiter}
            />
        </>
    );

    const inputTextEditor = (props, ref) => (
        <InputText
            type="text"
            value={props.rowData[props.field]}
            onChange={(e) => onEditorValueChange(props, e.target.value)}
            ref={ref}
            style={{ width: '100%' }}
        />
    );

    const typeLiterNameEditor = (props, ref) => (
        <div>
            {inputTextEditor(props, ref)}
            {props.rowData[props.field].trim().length === 0 ? (
                <Message
                    severity="error"
                    text="El nombre de la festividad es requerido"
                />
            ) : (
                ''
            )}
        </div>
    );

    return (
        <MainLayout>
            <Box>
                <Container>
                    <Toast ref={toast} />
                    <TypeLitersGrid
                        // VARIABLES
                        typeLiter={typeLiter}
                        typeLiters={typeLiters}
                        deleteRowButtonEnabled={deleteRowButtonEnabled}
                        editingRows={editingRows}
                        typeLiterNameRef={typeLiterNameRef}
                        deleteTypeLiterDialog={deleteTypeLiterDialog}
                        handleNewTypeLiterClick={handleNewTypeLiterClick}
                        // COMPONENTES
                        deleteRowBody={deleteRowBody}
                        typeLiterNameEditor={typeLiterNameEditor}
                        typeLiterDescEditor={inputTextEditor}
                        deleteTypeLiterDialogFooter={
                            deleteTypeLiterDialogFooter
                        }
                        // MÉTODOS
                        onRowEditInit={onRowEditInit}
                        onRowEditChange={onRowEditChange}
                        onRowEditCancel={onRowEditCancel}
                        onRowEditSave={onRowEditSave}
                        rowEditorValidator={rowEditorValidator}
                        hideDeleteTypeLiterDialog={hideDeleteTypeLiterDialog}
                        disableNewButton={disableNewButton}
                    />
                </Container>
            </Box>
        </MainLayout>
    );
};

export default TypeLitersManagement;
