import React from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';

import DetallesEsquema from '../Comunes/DetallesEsquema';
import Comisiones from './Comisiones';
import BonosProductividad from './BonosProductividad';
import Compensaciones from './Compensaciones';

const TabuladorPortatil = ({ tabulator, schema }) => {
    const tabulatorCode = 'tabPortatil';

    return (
        <>
            <DetallesEsquema
                schema={schema}
                tabulator={tabulator}
                tabulatorCode={tabulatorCode}
            />
            <div className="mt-5">
                <Accordion>
                    <AccordionTab header="Comisiones">
                        <Comisiones
                            tabulator={tabulator}
                            tabulatorCode={tabulatorCode}
                            sectionName="commissions"
                            sectionCode="secComisionesRango"
                        />
                    </AccordionTab>
                    <AccordionTab header="Bonos de productividad">
                        <BonosProductividad
                            tabulator={tabulator}
                            tabulatorCode={tabulatorCode}
                            sectionName="productivityBonus"
                            sectionCode="secBonosRango"
                        />
                    </AccordionTab>
                    <AccordionTab header="Compensaciones">
                        <Compensaciones
                            tabulator={tabulator}
                            tabulatorCode={tabulatorCode}
                            sectionName="compensations"
                            sectionCode="secCompensacionesRango"
                        />
                    </AccordionTab>
                </Accordion>
            </div>
        </>
    );
};

export default TabuladorPortatil;
