import axios from "../axios/custom-axios";
import { API_URL_SALES } from "../constants";

const SalesService = {
  getAllPayments: () => axios.get(`${API_URL_SALES}/acumulado/index`),

  getPayments: (locationId, jobPositionId, employeeId, periodType) =>
    axios.get(
      `${API_URL_SALES}/acumulado?plazaID=${locationId || ""}&puestoID=${
        jobPositionId || ""
      }&empleadoID=${employeeId || ""}&tipoPeriodo=${periodType || ""}`
    ),

  getPaymentById: (locationId, jobPositionId, employeeId) =>
    axios.get(
      `${API_URL_SALES}/acumulado/plaza/${locationId}/puesto/${jobPositionId}/empleado/${employeeId}`
    ),
  updatePayment: (data) =>
    axios.patch(`${API_URL_SALES}/acumulado/modify`, data),

  getZonaTarifas: () => axios.get(`${API_URL_SALES}/tarifa/index`),

  getZonaTarifasByplaza: (id) =>
    axios.get(`${API_URL_SALES}/tarifa/byplaza/${id}`),

  sentToFortia: (companyName = "", idPlazas) =>
    axios.post(
      `${API_URL_SALES}/acumulado/sendFortia?nombreEmpresa=${companyName}&idPlazas=${idPlazas}`
    ),

  downloadReport: (companyName = "") =>
    axios.get(`${API_URL_SALES}/report/acumulado?company=${companyName}`),

  recalculatePaymentByRfc: (rfc, date) =>
    axios.get(`${API_URL_SALES}/payroll?empleado_rfc=${rfc}&date=${date}`),
};

export default SalesService;
