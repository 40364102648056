import React, { useState, useEffect, useRef, useContext } from "react";
import { Container, Box } from "react-bulma-components";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Message } from "primereact/message";
import { AuthContext } from "../../context/AuthContext/AuthContext";
import UsersGrid from "./UsersGrid";
import MainLayout from "../Layouts/MainLayout";
import UsersService from "../../api/UsersService";
import {
  PASSWORD_PATTERN_REGEXP,
  EMAIL_PATTERN_REGEXP,
  RFC_PATTERN_REGEXP,
} from "../../constants";

import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";

const UserManagement = () => {
  // HOOKS
  const { auth } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null);
  const [deleteUserDialog, setDeleteUserDialog] = useState(false);
  const [originalRows, setOriginalRows] = useState({});
  const [editingRows, setEditingRows] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false);
  const [showHiddenPasswordColumn, setShowHiddenPasswordColumn] =
    useState(false);
  const [rfcExists, setRfcExists] = useState(false);
  const [roles, setRoles] = useState();
  const usernameRef = useRef();
  const passwordRef = useRef();
  const emailRef = useRef();
  const activeRef = useRef();
  const rfcRef = useRef();
  const rolRef = useRef();
  const toast = useRef(null);
  const statuses = [
    { label: "Activo", value: true },
    { label: "Inactivo", value: false },
  ];
  const [rfcInvalid, setRfcInvalid] = useState(false);
  const [focusOnNewRow, setFocusOnNewRow] = useState(false);

  useEffect(() => {
    async function fetchRoles() {
      const roleResp = await UsersService.getRoles();
      setRoles(roleResp.result);
    }

    async function fetchUsers() {
      // const usersResp = await UsersService.getUsers(auth.location);
      UsersService.getUsers(auth.location)
        .then((response) => {
          setUsers(response.result);
          fetchRoles();
        })
        .catch((error) => {
          console.log(error);
        });
    }

    fetchUsers();
  }, []);

  // MÉTODOS
  const onRowEditInit = (event) => {
    const userAux = { ...users[event.index] };
    const aux = { ...originalRows };
    aux[event.index] = userAux;
    if (!deleteButtonDisabled) {
      setDeleteButtonDisabled(true);
    }
    setOriginalRows(aux);
    setShowHiddenPasswordColumn(true);
  };

  const handleValidation = (props) => {
    const rowData = props.data;
    let error = false;
    if (rowData.username.length === 0) {
      error = true;
      usernameRef.current.required = true;
      usernameRef.current.className = "p-inputtext p-component p-invalid";
    }

    if (rowData.newUser) {
      if (
        rowData.password.length === 0 ||
        !PASSWORD_PATTERN_REGEXP.test(rowData.password)
      ) {
        error = true;
        passwordRef.current.required = true;
        passwordRef.current.className = "p-inputtext p-component p-invalid";
      } else {
        passwordRef.current.className = "p-inputtext p-component";
      }
    } else if (rowData.password !== originalRows[props.index].password) {
      if (
        rowData.password.length === 0 ||
        !PASSWORD_PATTERN_REGEXP.test(rowData.password)
      ) {
        error = true;
        passwordRef.current.required = true;
        passwordRef.current.className = "p-inputtext p-component p-invalid";
      } else {
        passwordRef.current.className = "p-inputtext p-component";
      }
    }

    if (rowData.email.length > 0 && !EMAIL_PATTERN_REGEXP.test(rowData.email)) {
      error = true;
      emailRef.current.required = true;
      emailRef.current.className = "p-inputtext p-component p-invalid";
    }
    if (!rfcExists && props.data.newUser) {
      error = true;
      rfcRef.current.required = true;
      rfcRef.current.className = "p-inputtext p-component p-invalid";
    }
    return error;
  };

  const onRowEditSave = (props) => {
    const errors = handleValidation(props);

    if (errors) {
      return;
    }

    if (props.data.newUser) {
      UsersService.createUser(props.data)
        .then((response) => {
          toast.current.show({
            severity: "success",
            summary: `${response.data.message}`,
          });

          UsersService.getUsers(auth.location)
            .then((r) => {
              setUsers(r.result);
            })
            .catch();
          setButtonDisabled(false);
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: `${error.response.data.message}`,
          });
        });
    } else {
      UsersService.updateUser(props.data)
        .then((response) => {
          const usersAux = [...users];
          usersAux[props.index] = response.data.result;
          setUsers(usersAux);

          console.log("hey");

          toast.current.show({
            severity: "success",
            summary: `${response.data.message}`,
          });

          if (Object.values(editingRows).length === 1) {
            setEditingRows({});
          } else {
          }
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: `${error.response.data.message}`,
          });
        });
    }

    if (Object.values(editingRows).length === 1) {
      // setEditingRows({});
      setDeleteButtonDisabled(false);
      setShowHiddenPasswordColumn(false);
    }
  };

  const onRowEditCancel = (event) => {
    if (event.data.newUser) {
      const usersAux = [...users];
      usersAux.pop();
      setUsers(usersAux);
      setButtonDisabled(false);
    } else {
      const usersAux = [...users];
      usersAux[event.index] = { ...originalRows[event.index] };
      const newRows = { ...originalRows };
      delete newRows[event.index];
      setOriginalRows(newRows);
      setUsers(usersAux);
    }
    if (Object.values(editingRows).length === 1) {
      setDeleteButtonDisabled(false);
      setShowHiddenPasswordColumn(false);
    }
  };

  const rowEditorValidator = (rowData) =>
    rowData.username.length > 0 &&
    rowData.password.length > 0 &&
    RFC_PATTERN_REGEXP.test(rowData.rfc) &&
    PASSWORD_PATTERN_REGEXP.test(rowData.password) &&
    rowData.email.length > 0 &&
    EMAIL_PATTERN_REGEXP.test(rowData.email);

  const deleteUser = () => {
    UsersService.deleteUser(user.id)
      .then((response) => {
        toast.current.show({
          severity: "success",
          summary: `${response.data.message}`,
        });
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: `${error.response.data.message}`,
        });
      });

    const usersAux = users.filter((userAux) => userAux.id !== user.id);
    setUsers(usersAux);
    setDeleteUserDialog(false);
    setUser(null);
  };

  const confirmDeleteUser = (selectedUser) => {
    setUser(selectedUser);
    setDeleteUserDialog(true);
  };

  const hideDeleteUserDialog = () => setDeleteUserDialog(false);

  const setActiveRowIndex = (index, _users) => {
    const originalRowsAux = { ...originalRows };
    originalRowsAux[index] = { ..._users[index - 1] };
    setEditingRows({
      ...editingRows,
      ...{ [`${_users[index - 1].id}`]: true },
    });
  };

  const handleNewUserClick = () => {
    setButtonDisabled(true);
    const usersAux = [...users];
    usersAux.push({
      newUser: true,
      active: true,
      username: "",
      password: "",
      email: "",
      rfc: "",
      roles: [
        {
          id: roles[0].id,
          name: roles[0].name,
          descripcion: roles[0].descripcion,
        },
      ],
      locationFortia: auth.location,
    });
    setUsers(usersAux);
    setActiveRowIndex(usersAux.length, usersAux);
    if (!deleteButtonDisabled) {
      setDeleteButtonDisabled(true);
      setShowHiddenPasswordColumn(true);
    }

    setFocusOnNewRow(true);
  };

  const onEditorValueChange = (props, value) => {
    const updatedUsers = [...props.value];
    updatedUsers[props.rowIndex][props.field] = value;
    setUsers(updatedUsers);
  };

  const validateRFC = (props) => {
    if (!RFC_PATTERN_REGEXP.test(props.rowData.rfc)) {
      rfcRef.current.required = true;
      rfcRef.current.className = "p-inputtext p-component p-invalid";
      setRfcInvalid(true);
      return;
    }

    rfcRef.current.required = false;
    rfcRef.current.className = "p-inputtext p-component";
    setRfcInvalid(false);
    return "valid";
  };

  const onEditorRolValueChange = (props, value) => {
    const rolSelected = roles.find((element) => element.id === value);
    const updateRol = {
      id: rolSelected.id,
      name: rolSelected.name,
      descripcion: rolSelected.descripcion,
    };
    const updatedUsers = [...props.value];
    updatedUsers[props.rowIndex][props.field][0] = updateRol;
    setUsers(updatedUsers);
  };

  const onRowEditChange = (e) => {
    setEditingRows(e.data);
  };

  const getStatusLabel = (status) => {
    if (status) {
      return "Activo";
    }

    return "Inactivo";
  };

  const getRolLabel = (rolesLabels) => {
    if (rolesLabels && rolesLabels[0]) {
      return rolesLabels !== undefined ? rolesLabels[0].descripcion : "";
    }

    return "";
  };

  const cleanUser = (props) => {
    const updatedUsers = [...props.value];
    updatedUsers[props.rowIndex].firstname = "";
    updatedUsers[props.rowIndex].lastname = "";
    updatedUsers[props.rowIndex].email = "";
    updatedUsers[props.rowIndex].telephone = "";
    updatedUsers[props.rowIndex].birthdate = "";
    updatedUsers[props.rowIndex].address = "";
    setUsers(updatedUsers);
  };

  const handleKeyPress = (event, props) => {
    if (event.key === "Enter") {
      cleanUser(props);
      validateRFC(props);

      if (rfcInvalid) return;

      UsersService.getUserByRfc(props.rowData.rfc, 1)
        .then((response) => {
          if (response.data.status === "OK") {
            setRfcExists(true);
            const data = response.data.result;
            const updatedUsers = [...props.value];
            updatedUsers[props.rowIndex].firstname = data.employee_name;
            updatedUsers[props.rowIndex].lastname = data.employee_last_name;
            updatedUsers[props.rowIndex].email = data.employee_email;
            updatedUsers[props.rowIndex].telephone = data.telephone_number;

            const year = data.birth_date.substring(0, 4);
            const month = data.birth_date.substring(4, 6);
            const day = data.birth_date.substring(6, 8);

            updatedUsers[props.rowIndex].birthdate = `${year}-${month}-${day}`;

            const address = `${data.street} ${data.district} ${data.city} ${data.state}`;
            updatedUsers[props.rowIndex].address = `${address}`;

            setUsers(updatedUsers);
          }
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: `${error.response.data.message}`,
          });
        });
    }
  };

  const handleRfcFilled = (props) => {
    cleanUser(props);
    validateRFC(props);

    if (validateRFC(props) !== "valid") return;

    UsersService.getUserByRfc(props.rowData.rfc, 1)
      .then((response) => {
        if (response.data.status === "OK") {
          setRfcExists(true);
          const data = response.data.result;
          const updatedUsers = [...props.value];
          updatedUsers[props.rowIndex].firstname = data.employee_name;
          updatedUsers[props.rowIndex].lastname = data.employee_last_name;
          updatedUsers[props.rowIndex].email = data.employee_email;
          updatedUsers[props.rowIndex].telephone = data.telephone_number;

          const year = data.birth_date.substring(0, 4);
          const month = data.birth_date.substring(4, 6);
          const day = data.birth_date.substring(6, 8);

          updatedUsers[props.rowIndex].birthdate = `${year}-${month}-${day}`;

          const address = `${data.street} ${data.district} ${data.city} ${data.state}`;
          updatedUsers[props.rowIndex].address = `${address}`;

          setUsers(updatedUsers);
        }
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: `${error.response.data.message}`,
        });
      });
  };

  const onEditorRFCValueChange = (props, value) => {
    onEditorValueChange(props, value.toUpperCase());
    validateRFC(props);
    // Un RFC valido debe contenter 13 caracteres
    if (value && value.length === 13) {
      handleRfcFilled(props);
    }
  };

  // COMPONENTES

  const passwordSuggestions = (props) => {
    let messageToRender = "";
    if (props.rowData.newUser) {
      messageToRender =
        props.rowData[props.field].length === 0 ||
        !PASSWORD_PATTERN_REGEXP.test(props.rowData[props.field]) ? (
          <Message
            severity="error"
            text="Contraseña es requerido, debe incluir 1 mayúscula, 1 minúscula, 1 número, 1 carácter especial y al menos 8 caracteres."
          />
        ) : (
          ""
        );
    } else if (
      props.rowData[props.field] !== originalRows[props.rowIndex][props.field]
    ) {
      messageToRender =
        props.rowData[props.field].length === 0 ||
        !PASSWORD_PATTERN_REGEXP.test(props.rowData[props.field]) ? (
          <Message
            severity="error"
            text="Contraseña es requerido, debe incluir 1 mayúscula, 1 minúscula, 1 número, 1 carácter especial y al menos 8 caracteres."
          />
        ) : (
          ""
        );
    } else {
      messageToRender = "";
    }

    return messageToRender;
  };

  const deleteBody = (rowData) => (
    <>
      <Button
        icon="pi pi-trash"
        className="trash-disabled"
        disabled={deleteButtonDisabled || rowData.id === auth.userid}
        onClick={() => confirmDeleteUser(rowData)}
      />
    </>
  );

  const deleteUserDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteUserDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteUser}
      />
    </>
  );

  const inputTextEditor = (props, ref, disabled) => (
    <InputText
      type="text"
      value={props.rowData[props.field]}
      disabled={
        (props.field !== "username" &&
          props.field !== "active" &&
          props.field !== "email" &&
          props.field !== "password") ||
        disabled
      }
      onChange={(e) => onEditorValueChange(props, e.target.value)}
      ref={ref}
      style={{ width: "100%" }}
    />
  );

  const rfcEditor = (props, ref) => (
    <div className="p-formgroup-inline p-mb-2">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="text"
          value={props.rowData[props.field]}
          disabled={!props.rowData.newUser}
          onChange={(e) => onEditorRFCValueChange(props, e.target.value)}
          ref={ref}
          style={{ width: "100%" }}
          onKeyPress={(e) => handleKeyPress(e, props)}
          maxLength="13"
        />
      </span>
      {props.rowData[props.field].length === 0 ? (
        <Message severity="error" text="RFC es requerido" />
      ) : (
        ""
      )}
      {rfcInvalid ? <Message severity="error" text="RFC inválido" /> : ""}
    </div>
  );

  const statusEditor = (props, ref) => (
    <Dropdown
      value={props.rowData.active}
      options={statuses}
      optionLabel="label"
      optionValue="value"
      onChange={(e) => onEditorValueChange(props, e.value)}
      style={{ width: "100%" }}
      placeholder="Seleccione un estatus"
      itemTemplate={(option) => (
        <span className={`product-badge status-${option.value}`}>
          {option.label}
        </span>
      )}
      ref={ref}
    />
  );

  const rolesEditor = (props, ref) => (
    <Dropdown
      value={props.rowData.roles[0].id}
      options={roles}
      optionLabel="descripcion"
      optionValue="id"
      onChange={(e) => onEditorRolValueChange(props, e.value)}
      style={{ width: "100%" }}
      placeholder="Seleccione un rol"
      itemTemplate={(option) => (
        <span className={`product-badge status-${option.id}`}>
          {option.descripcion}
        </span>
      )}
      ref={ref}
    />
  );

  const usernameEditor = (props, ref) => (
    <div>
      {inputTextEditor(props, ref, props.rowData.id)}
      {props.rowData[props.field].length === 0 ? (
        <Message severity="error" text="Usuario es requerido" />
      ) : (
        ""
      )}
    </div>
  );

  const passwordEditor = (props, ref) => (
    <div>
      {inputTextEditor(props, ref)}
      {passwordSuggestions(props)}
    </div>
  );

  const statusBodyTemplate = (rowData) => getStatusLabel(rowData.active);

  const rolesBodyTemplate = (rowData) => getRolLabel(rowData.roles);

  const passwordBodyTemplate = () => <p>**********</p>;

  useEffect(() => {
    if (usernameRef && usernameRef.current) {
      usernameRef.current.focus();
      setFocusOnNewRow(false);
    }
  }, [focusOnNewRow]);

  return (
    <MainLayout>
      <Box>
        <Container>
          <Toast ref={toast} />
          <UsersGrid
            // Valores
            users={users}
            user={user}
            editingRows={editingRows}
            buttonDisabled={buttonDisabled}
            usernameRef={usernameRef}
            passwordRef={passwordRef}
            emailRef={emailRef}
            activeRef={activeRef}
            rfcRef={rfcRef}
            rolRef={rolRef}
            showHiddenPasswordColumn={showHiddenPasswordColumn}
            // Métodos
            setUsers={setUsers}
            handleNewUserClick={handleNewUserClick}
            onRowEditInit={onRowEditInit}
            onRowEditChange={onRowEditChange}
            onRowEditCancel={onRowEditCancel}
            onRowEditSave={onRowEditSave}
            rowEditorValidator={rowEditorValidator}
            hideDeleteUserDialog={hideDeleteUserDialog}
            onEditorValueChange={onEditorValueChange}
            setEditingRows={setEditingRows}
            // Componentes
            deleteBody={deleteBody}
            statusBodyTemplate={statusBodyTemplate}
            rolesBodyTemplate={rolesBodyTemplate}
            passwordBodyTemplate={passwordBodyTemplate}
            deleteUserDialog={deleteUserDialog}
            deleteUserDialogFooter={deleteUserDialogFooter}
            inputTextEditor={inputTextEditor}
            statusEditor={statusEditor}
            rfcEditor={rfcEditor}
            usernameEditor={usernameEditor}
            passwordEditor={passwordEditor}
            rolesEditor={rolesEditor}
          />
        </Container>
      </Box>
    </MainLayout>
  );
};

export default UserManagement;
