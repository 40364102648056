import React from "react";
import "./LoaderBlue.css";

const Loader = ({ small = false }) => (
  <div
    className={small ? "lds-dual-ring-small" : "lds-dual-ring"}
    style={{ width: "10px" }}
  />
);

export default Loader;
