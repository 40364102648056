import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';

const PayrollPeriodDialog = ({
    // VALORES
    payrollPeriod,
    periodTypes,
    submitted,
    locations,
    dates,

    // MÉTODOS
    onInputChange,
    savePayrollPeriod,
    onRowEditComplete,
    onRowEditInit,
    onRowEditCancel,

    // COMPONENTES
    payrollPeriodDialog,
    payrollPeriodDialogFooter,
    hidePayrollPeriodDialog,
    dateEditor,
}) => (
    <Dialog
        visible={payrollPeriodDialog}
        breakpoints={{ '960px': '75vw' }}
        style={{ width: '80vw' }}
        header="Periodo de Nómina"
        modal
        className="p-fluid"
        footer={payrollPeriod.periods && payrollPeriod.periods.length > 0 && payrollPeriodDialogFooter}
        onHide={hidePayrollPeriodDialog}
    >
        <div className="p-formgrid p-grid">
            <div className="p-field p-col">
                <label htmlFor="periodType">Periodo</label>
                <Dropdown
                    value={payrollPeriod.periodType}
                    options={periodTypes}
                    onChange={(e) => onInputChange(e, 'periodType')}
                    optionLabel="name"
                    optionValue="id"
                    style={{ width: '100%' }}
                    placeholder="Seleciona un Tipo de Periodo"
                    className={classNames({
                        'p-invalid': submitted && !payrollPeriod.periodType,
                    })}
                    disabled={payrollPeriod.periods?.length > 0}
                />
                {submitted && !payrollPeriod.periodType ? (
                    <small className="p-error">Periodo es requerido.</small>
                ) : null}
            </div>
            <div className="p-field p-col">
                <label htmlFor="periodName">Nombre de Periodo</label>
                <InputText
                    id="periodName"
                    value={payrollPeriod.periodName}
                    onChange={(e) => onInputChange(e, 'periodName')}
                    required
                    autoFocus
                    className={classNames({
                        'p-invalid': submitted && payrollPeriod.periodName.trim().length === 0,
                    })}
                />
                {submitted && payrollPeriod.periodName.trim().length === 0 ? (
                    <small className="p-error">
                        Nombre del Periodo es requerido.
                    </small>
                ) : null}
            </div>
            <div className="p-field p-col">
                <label htmlFor="exercise">Ejercicio fiscal</label>
                <InputText
                    id="exercise"
                    value={payrollPeriod.exercise}
                    disabled
                />
            </div>
            <div className="p-field p-col">
                <label htmlFor="idLocationFortia">Plaza</label>
                <Dropdown
                    value={payrollPeriod.idLocationFortia}
                    options={locations}
                    onChange={(e) => onInputChange(e, 'idLocationFortia')}
                    optionLabel="location_name"
                    optionValue="location_id"
                    style={{ width: '100%' }}
                    placeholder="Seleciona una Plaza"
                    className={classNames({
                        'p-invalid':
                            submitted && !payrollPeriod.idLocationFortia,
                    })}
                    disabled={payrollPeriod.periods?.length > 0}
                />
                {submitted && !payrollPeriod.idLocationFortia ? (
                    <small className="p-error">Plaza es requerido.</small>
                ) : null}
            </div>
        </div>
    
        <div className="w-full"
        style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
        <Button
            type="button"
            className="btn btn-primary mb-4 disabled-button"
            onClick={savePayrollPeriod}
            style={{ width: '160px' }}
            disabled={
                !(
                    payrollPeriod.periodType &&
                    payrollPeriod.exercise &&
                    payrollPeriod.idLocationFortia
                ) || payrollPeriod.periods?.length > 0
            }
        >
            Generar periodos
        </Button>   
        
        <Button
            type="button"
            className="btn btn-primary mb-4"
            onClick={savePayrollPeriod}
            style={{ width: '160px' }}
            disabled = {!payrollPeriod.periodName}
        >
            Guardar cambios
        </Button>
        </div>
        

        

        <div className="p-fluid p-grid p-formgrid">
            <div className="p-field p-col">
                <DataTable
                    value={payrollPeriod.periods}
                    editMode="row"
                    dataKey="id"
                    paginator
                    rows={6}
                    emptyMessage="Datos no encontrados"
                    onRowEditSave={onRowEditComplete}
                    onRowEditInit={onRowEditInit}
                    onRowEditCancel={onRowEditCancel}
                >
                    <Column
                        field="initDate"
                        header="Inicio"
                        style={{ width: '40px' }}
                        editor={dateEditor}
                    />
                    <Column
                        field="finalDate"
                        header="Fin"
                        editor={dateEditor}
                        style={{ width: '40px' }}
                    />
                    <Column
                        field="paymentDate"
                        header="Pago"
                        editor={dateEditor}
                        style={{ width: '40px' }}
                    />
                    <Column
                        rowEditor
                        headerStyle={{ width: '10%', minWidth: '8rem' }}
                        bodyStyle={{ textAlign: 'center' }}
                    />
                </DataTable>
            </div>
            <div className="p-field p-col">
                <label htmlFor="paymentDates">Fechas de pago</label>
                <Calendar
                    value={dates}
                    dateFormat="dd/mm/yy"
                    selectionMode="multiple"
                    locale="es"
                    inline
                    monthNavigator
                    yearNavigator
                    yearRange={`${new Date().getFullYear()}:${new Date().getFullYear()}`}
                />
            </div>
        </div>
    </Dialog>
);

export default PayrollPeriodDialog;
