/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-cycle */
import React, { useState, useEffect, useRef } from "react";
import { Container, Box } from "react-bulma-components";
import { Toast } from "primereact/toast";
import { InputNumber } from "primereact/inputnumber";
import BaseSalaryGrid from "./BaseSalaryGrid";
import BaseSalaryService from "../../api/BaseSalaryService";
import MainLayout from "../Layouts/MainLayout";

const BaseSalary = () => {
  // VARIABLES

  const toast = useRef(null);

  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [originalRows, setOriginalRows] = useState([]);

  // FUNCTIONS

  const fetchLocations = () => {
    BaseSalaryService.getLocations()
      .then(({ data: { result } }) => {
        setLocations(result);
      })
      .catch(({ message }) => {
        toast.current.show({
          severity: "error",
          summary: message,
          life: 3000,
        });
      })
      .then(() => setLoading(false));
  };

  const onRowEditInit = ({ index }) => {
    const _originalRows = [...originalRows];
    _originalRows[index] = { ...locations[index] };
    setOriginalRows(_originalRows);
  };

  const onEditorValueChange = (index, field, value) => {
    const _locations = [...locations];
    _locations[index][field] = value;
    setLocations(_locations);
  };

  const onRowEditCancel = ({ index }) => {
    // Update table
    const _locations = [...locations];
    _locations[index] = { ...originalRows[index] };
    setLocations(_locations);
    // Delete the row of original rows
    const _originalRows = [...originalRows];
    _originalRows.splice(index, 1);
    setOriginalRows(_originalRows);
  };

  const onRowEditSave = ({ index, data }) => {
    const body = {
      baseSalaryAyu: data.baseSalaryAyu,
      baseSalaryCho: data.baseSalaryCho,
      guaranteeSalaryAyu: data.guaranteeSalaryAyu,
      guaranteeSalaryCho: data.guaranteeSalaryCho,
      guaranteeSalaryValidity: data.guaranteeSalaryValidity,
      minimumWage: data.minimumWage,
      plantSalary: data.plantSalary,
    };

    // Save the results
    BaseSalaryService.saveSalary(data.id, body)
      .then((response) => {
        const _locations = [...locations];
        _locations[index] = response.data.result;
        setLocations(_locations);
        // Delete the row of original rows
        const _originalRows = [...originalRows];
        _originalRows.splice(index, 1);
        setOriginalRows(_originalRows);
        toast.current.show({
          severity: "success",
          summary: response.data.message,
          life: 3000,
        });
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: error.response.data.message,
          life: 3000,
        });
      });
  };

  // COMPONENTS

  const salaryEditor = ({ field, rowData, rowIndex }) => {
    const handleValueChange = (e) => {
      const newValue = e.value;
      // Comprobar que el nuevo valor no es negativo antes de actualizarlo
      if (!isNaN(newValue) && parseFloat(newValue) >= 0) {
        onEditorValueChange(rowIndex, field, newValue);
      }
    };
  
    return (
      <InputNumber
        value={rowData[field]}
        onValueChange={handleValueChange}
        className="inputSize"
        mode="currency"
        currency="USD"
        locale="en-US"
      />
    );
  };

  const numberEditor = ({ field, rowData, rowIndex }) => (
    <InputNumber
      value={rowData[field]}
      onValueChange={(e) => onEditorValueChange(rowIndex, field, e.value)}
      className="inputSize"
      max={100}
    />
  );

  const salaryBodyTemplate = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);

  const header = () => (
    <div className="columns">
      <div className="column"></div>
      <div className="column">Salario Base/Mixto</div>
      <div className="column"></div>
      <div className="column">Salario Garantía</div>
      <div className="column"></div>
      <div className="column"></div>
      <div className="column">Sueldo Planta</div>
      <div className="column">Sueldo Minimo</div>
      <div className="column"></div>
      <div className="column"></div>
      <div className="column"></div>
    </div>
  );

  // HOOKS

  useEffect(() => {
    fetchLocations();
  }, []);

  return (
    <MainLayout>
      <Toast ref={toast} />
      <Box>
        <Container>
          <Toast />
          <BaseSalaryGrid
            // VARIABLES
            locations={locations}
            loading={loading}
            // FUNCTIONS
            onRowEditInit={onRowEditInit}
            onRowEditCancel={onRowEditCancel}
            onRowEditSave={onRowEditSave}
            // COMPONENTS
            salaryEditor={salaryEditor}
            salaryBodyTemplate={salaryBodyTemplate}
            header={header}
            numberEditor={numberEditor}
          />
        </Container>
      </Box>
    </MainLayout>
  );
};

export default BaseSalary;
