import React, { useState, useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { Box, Container } from 'react-bulma-components';
import TabulatorsConfigurator from './TabulatorsConfigurator';
// eslint-disable-next-line import/no-cycle
import MainLayout from '../Layouts/MainLayout';
import ShiftsService from '../../api/ShiftsService';
import TabulatorOtifService from '../../api/TabulatorOtifService';

const emptyTabulator = {
    id: null,
    idTabulator: null,
    nombre: null,
    secciones: [],
};

const TabulatorsManagement = () => {
    // REFS

    const toast = useRef(null);

    // VARIABLES

    const [plazasFortia, setPlazasFortia] = useState([]);
    const [tabulators, setTabulators] = useState([]);
    const [sections, setSections] = useState([]);
    const [paymentSchemaConfig, setPaymentSchemaConfig] = useState([]);
    const [tabulatorSelected, setTabulatorSelected] = useState();
    const [newTabulatorName, setNewTabulatorName] = useState(null);
    const [tabulatorDialog, setTabulatorDialog] = useState(false);
    const [plazaSelected, setPlazaSelected] = useState(null);
    const [loading, setLoading] = useState(true);
    const [tabulatorsFiltered, setTabulatorsFiltered] = useState([]);

    // FUNCTIONS

    const addTabulator = () => {
        setTabulatorSelected(null);
        setNewTabulatorName(null);
        setTabulatorDialog(true);
    };

    const addCreatedTabulator = () => {
        const tabulatorFinded = tabulators.find(
            (tab) => tab.id === tabulatorSelected
        );

        const tabuladorToAdd = {
            ...emptyTabulator,
            idTabulator: tabulatorFinded.id,
            nombre: tabulatorFinded.tabulatorName,
        };

        const auxConfig = [...paymentSchemaConfig];

        auxConfig.push({ ...tabuladorToAdd });
        setPaymentSchemaConfig(auxConfig);
        setTabulatorDialog(false);
    };

    const addNewTabulator = () => {
        const tabuladorToAdd = {
            ...emptyTabulator,
            nombre: newTabulatorName,
        };

        const auxConfig = [...paymentSchemaConfig];

        auxConfig.push({ ...tabuladorToAdd });
        setPaymentSchemaConfig(auxConfig);
        setTabulatorDialog(false);
    };

    const onTabulatorDialogHide = () => {
        setTabulatorDialog(false);
    };

    const handleTabulatorSelection = (event) => {
        setTabulatorSelected(event.value);
    };

    const deleteTabulator = (index) => {
        if (paymentSchemaConfig[index].id) {
            TabulatorOtifService.deleteTabulator(paymentSchemaConfig[index].id)
                .then(() => {
                    const newPaymentSchemaConfig = [...paymentSchemaConfig];
                    newPaymentSchemaConfig.splice(index, 1);
                    setPaymentSchemaConfig(newPaymentSchemaConfig);
                    toast.current.show({
                        severity: 'success',
                        summary: 'Tabulador eliminado.',
                        life: 3000,
                    });
                })
                .catch(() => {
                    toast.current.show({
                        severity: 'success',
                        summary: 'Error al eliminar tabulador.',
                        life: 3000,
                    });
                });
        } else {
            const newPaymentSchemaConfig = [...paymentSchemaConfig];
            newPaymentSchemaConfig.splice(index, 1);
            setPaymentSchemaConfig(newPaymentSchemaConfig);
        }
    };

    const handleSectionChange = (tabulatorIndex, sectionId) => {
        const newPaymentSchemaConfig = [...paymentSchemaConfig];
        const tabulatorToUpdate = { ...newPaymentSchemaConfig[tabulatorIndex] };
        const sectionsToUpdate = [...tabulatorToUpdate.secciones];
        const findResult = sectionsToUpdate.indexOf(sectionId);

        if (findResult === -1) {
            sectionsToUpdate.push(sectionId);
        } else {
            sectionsToUpdate.splice(findResult, 1);
        }

        tabulatorToUpdate.secciones = sectionsToUpdate;
        newPaymentSchemaConfig[tabulatorIndex] = tabulatorToUpdate;

        setPaymentSchemaConfig([...newPaymentSchemaConfig]);
    };

    const handlePlazaChange = (plazaId) => {
        setPlazaSelected(plazaId);
        setLoading(true);
        TabulatorOtifService.getConfigByLocation(plazaId)
            .then((response) => {
                if (response.data.result) {
                    setPaymentSchemaConfig(response.data.result.tabuladores);
                }
            })
            .catch((error) => {
                if (error.response.data.error.errorCode === 'TABULATOR020') {
                    setPaymentSchemaConfig([]);
                }
            })
            .then(() => {
                setLoading(false);
            });
    };

    const saveConfiguration = () => {
        const body = {
            plazaFortia: plazaSelected,
            tabuladores: paymentSchemaConfig,
        };

        TabulatorOtifService.saveConfiguration(body)
            .then((response) => {
                setPaymentSchemaConfig([...response.data.result.tabuladores]);
                toast.current.show({
                    severity: 'success',
                    summary: 'Configuración guardada.',
                    life: 3000,
                });
            })
            .catch(() => {
                toast.current.show({
                    severity: 'error',
                    summary: 'Debe agregar al menos una sección al tabulador.',
                    life: 3000,
                });
            });
    };

    // EFFECT COMPONENT DID MOUNT

    useEffect(() => {
        ShiftsService.getPlazasFortia()
            .then((responsePlazas) => {
                setPlazasFortia([...responsePlazas.data.result]);
                TabulatorOtifService.getTabulatorsCatalog()
                    .then((responseTabulators) => {
                        setTabulators([...responseTabulators.data.result]);
                        TabulatorOtifService.getSectionsCatalog()
                            .then((responseSections) => {
                                setSections([...responseSections.data.result]);
                            })
                            .catch(() => {
                                toast.current.show({
                                    severity: 'error',
                                    summary: 'Erro al cargar catalogos.',
                                    life: 3000,
                                });
                            })
                            .then(() => {
                                setLoading(false);
                            });
                    })
                    .catch(() => {
                        setLoading(false);
                        toast.current.show({
                            severity: 'error',
                            summary: 'Erro al cargar catalogos.',
                            life: 3000,
                        });
                    });
            })
            .catch(() => {
                setLoading(false);
                toast.current.show({
                    severity: 'error',
                    summary: 'Erro al cargar catalogos.',
                    life: 3000,
                });
            });
    }, []);

    useEffect(() => {
        console.log(paymentSchemaConfig);
        const newTabulatorsFiltered = tabulators.map((tabulator) => {
            let tabulatorToAdd = { ...tabulator };
            if (
                paymentSchemaConfig.find(
                    (tabulatorAdded) =>
                        tabulatorAdded.idTabulator === tabulator.id
                )
            ) {
                tabulatorToAdd = {
                    ...tabulatorToAdd,
                    disabled: true,
                };
            }
            return tabulatorToAdd;
        });

        console.log(newTabulatorsFiltered);

        setTabulatorsFiltered([...newTabulatorsFiltered]);
    }, [paymentSchemaConfig]);

    return (
        <MainLayout>
            <Toast ref={toast} />
            <Box>
                <Container>
                    <TabulatorsConfigurator
                        // VARIABLES
                        tabulators={tabulators}
                        sections={sections}
                        plazasFortia={plazasFortia}
                        paymentSchemaConfig={paymentSchemaConfig}
                        tabulatorSelected={tabulatorSelected}
                        tabulatorDialog={tabulatorDialog}
                        newTabulatorName={newTabulatorName}
                        plazaSelected={plazaSelected}
                        loading={loading}
                        tabulatorsFiltered={tabulatorsFiltered}
                        // FUNCTIONS
                        addTabulator={addTabulator}
                        handleTabulatorSelection={handleTabulatorSelection}
                        addCreatedTabulator={addCreatedTabulator}
                        addNewTabulator={addNewTabulator}
                        onTabulatorDialogHide={onTabulatorDialogHide}
                        setNewTabulatorName={setNewTabulatorName}
                        deleteTabulator={deleteTabulator}
                        handleSectionChange={handleSectionChange}
                        handlePlazaChange={handlePlazaChange}
                        saveConfiguration={saveConfiguration}
                    />
                </Container>
            </Box>
        </MainLayout>
    );
};

export default TabulatorsManagement;
