import React, { useContext, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Toast } from "primereact/toast";
import { AuthContext } from "../../context/AuthContext/AuthContext";
import SalesService from "../../api/SalesService";
import UsersService from "../../api/UsersService";
import { API_URL_SALES } from "../../constants";
import Collapsible from "./Collapsible";
import Circle from "./Circle";

const TabHeader = ({ data, index, checkedItems, setCheckedItems }) => {
  const labelsToShow = data.status ? status[data.status] : [];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        minWidth: "1300px",
        justifyContent: "space-between",
        padding: "0px",
        margin: "0px",
      }}
    >
      <Checkbox
        checked={checkedItems[index]}
        onChange={(e) => {
          const updatedCheckedItems = [...checkedItems];
          updatedCheckedItems[index] = e.checked;
          setCheckedItems(updatedCheckedItems);
        }}
      />

      <div style={{ display: "flex" }}>
        <p className="ml-2">{`${data.name} ${data.lastName} ${data.secondName} / RFC: ${data.rfc} / ${data.workStation}`}</p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          paddding: "0px",
          marginVertical: "0px",
          marginRight: "10px",
        }}
      >
        <>
          {labelsToShow.map((item) => (
            <Circle text={item.label} />
          ))}
        </>
      </div>
    </div>
  );
};

const EmployeeList = ({
  data = [],
  setData,
  companySelected,
  locationSelected,
}) => {
  const [checkedItems, setCheckedItems] = useState(new Array(data.length).fill(false));
  const { auth } = useContext(AuthContext);
  const permissions = auth?.permissions;

  const toast = useRef(null);

  const handleApprove = () => {
    let status = 0;
    switch (auth.rol) {
      case "ROLE_ENCARGADO_DE_PRENOMINA":
        status = 1;
        break;

      case "ROLE_JEFE_ADMINISTRATIVO_DE_PLAZA":
        status = 2;
        break;

      case "ROLE_GERENTE_DE_PLAZA":
        status = 3;
        break;

      case "ROLE_ADMINISTRACION_GENERAL":
        status = 4;
        break;
    }

    const selectedItems = checkedItems
      .map((checked, index) => (checked ? data[index].id : null))
      .filter((item) => item !== null);

    const itemsToUpdate = data.filter((item) =>
      selectedItems.find((id) => item.id === id)
    );

    const newData = [...data];

    itemsToUpdate.forEach((element) => {
      const newElement = { ...element, status };

      // find index
      const index = data.findIndex((el) => el.id === element.id);

      newData[index] = { ...newElement };
      SalesService.updatePayment(newElement)
        .then(() => {
          toast.current.show({
            severity: "success",
            summary: "Prenomina Aprobada",
          });
          UsersService.approveChanges(newElement)
            .then(() => {})
            .catch((error) => {
              console.log(error);
            });
        })
        .then(() => {
          setData([...newData]);
        });
    });
  };

  const handleSentToFortia = (companyName) => {
    SalesService.sentToFortia(companyName, locationSelected.idFortia)
      .then((response) => {
        console.log(response);
        toast.current.show({
          severity: "success",
          summary: response.data.message,
        });
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: JSON.stringify(error?.response),
        });
      });
  };

  const handleDownloadReport = (companyName) => {
    window.open(
      `${API_URL_SALES}/report/acumulado?company=${companyName}`,
      "_blank"
    );
  };

  return (
    <div className="mt-5">
      <Toast ref={toast} />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          onClick={() => {
            const updatedCheckedItems = new Array(data.length).fill(true);
            setCheckedItems(updatedCheckedItems);
          }}
        >
          Seleccionar todos
        </Button>
      </div>

      <div className="mt-5">
        {data.map((element, index) => (
          <Collapsible
            data={data}
            element={element}
            setData={setData}
            index={index}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
          />
        ))}
      </div>
      <div
        className="w-full"
        style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}
      >
        {permissions.find((permission) => permission.name === "Aprobar") ? (
          <Button onClick={handleApprove} className="mt-4" style={{ height: "40px" }}>
            Aprobar seleccionados
          </Button>
        ) : null}
        <div className="mt-4">
        <h6>Enviar para la plaza o compañía especificada</h6>
          {auth.rol === "ROLE_ADMINISTRACION_GENERAL" ? (  
            <Button
              disabled={!companySelected}
              onClick={() => handleSentToFortia(companySelected)}
              className="p-button-success mr-2"
            >
              Enviar a fortia
            </Button>
          ) : null}

          {auth.rol === "ROLE_ADMINISTRACION_GENERAL" ? (
            <Button
              disabled={!companySelected}
              onClick={() => handleDownloadReport(companySelected)}
              className="p-button-success"
            >
              Descargar reporte
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default EmployeeList;