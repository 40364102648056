/* eslint-disable no-underscore-dangle */
import React from 'react';
import EsquemaRangoSimple from '../Comunes/EsquemaRangoSimple';

const Compensaciones = ({
    tabulatorCode,
    sectionName,
    tabulator,
    sectionCode,
}) => (
    <>
        <EsquemaRangoSimple
            tabulatorCode={tabulatorCode}
            sectionName={sectionName}
            tabulator={tabulator}
            sectionCode={sectionCode}
        />
    </>
);

export default Compensaciones;
