import React, { useState, useContext, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Menu } from 'primereact/menu';
import { AuthContext } from '../context/AuthContext/AuthContext';
import emaImg from '../css/img/gpo-alerta-header.png';
import Sidenav from './Sidenav';
import AppContext from '../context/AppContext';
import ChangePassword from './changePassword/ChangePassword';
import UserInfoDialog from './changePassword/UserInfoDialog';

function Header() {
  const { auth } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  const [isActive, setActive] = useState("false");
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [showPersonalInfo, setShowPersonalInfo] = useState(false);
  const menuRef = useRef({});

  // Mostrar y ocultar el menú izquierdo
  const toggleSidenav = () => {
    setActive(!isActive);
  };

  const handleLogout = () => {
    dispatch({ type: "LOGOUT", data: null });
  };

  /**
   * Método para ocultar el Modal de cambiar Contraseña
   */
  const onHideChangePassword = () => {
    setShowChangePasswordModal(false);
  };

  const hidePersonalInfo = () => {
    setShowPersonalInfo(false);
  };

  /**
   * Los item que se muestran en el menu del Usuario.
   * @type {({icon: string, label: string, command: command})[]}
   */
  const menuItems = [
    {
      label: "Cambiar contraseña",
      icon: "pi pi-fw pi-lock",
      command: () => {
        setShowChangePasswordModal(true);
      },
    },
    {
      label: "Perfil",
      icon: "pi pi-fw pi-user",
      command: () => {
        setShowPersonalInfo(true);
      },
    },
  ];

  return (
    <>
      <header>
        <nav>
          <button
            type="button"
            className="transparent active"
            id="lefMenuToggle"
            onClick={toggleSidenav}
          >
            <i className="fas fa-bars header-icon" />
          </button>
          <Link to="/home">
            <img src={emaImg} alt="" />
          </Link>
          <ul>
            <li>
              <button type="button" className="transparent">
                <i className="fas fa-bell deactivated header-icon" />
              </button>
            </li>
            <li>
              <Menu model={menuItems} popup ref={menuRef} />
              <button
                type="button"
                onClick={(event) => menuRef.current.toggle(event)}
                className="transparent"
              >
                <i className="fas fa-user mr-2 header-icon" />
                <p className="name-user">{auth.fullname}</p>
              </button>
              <ChangePassword
                visible={showChangePasswordModal}
                onHide={onHideChangePassword}
              />
              <UserInfoDialog
                visible={showPersonalInfo}
                onHide={hidePersonalInfo}
              />
            </li>
            <li>
              <Link to="/login" onClick={handleLogout}>
                <button type="button" className="transparent" id="logout">
                  <i className="fas fa-sign-out-alt header-icon" />
                </button>
              </Link>
            </li>
          </ul>
        </nav>
      </header>
      <AppContext.Provider value={{ isActive }}>
        <Sidenav />
      </AppContext.Provider>
    </>
  );
}

export default withRouter(Header);
