import axios from "../axios/custom-axios";
import { API_URL_USERS } from "../constants";

class UsersService {
  async getUsers(location) {
    const { data } = await axios.get(
      `${API_URL_USERS}/user?location=${location}`
    );
    return data;
  }

  /**
   *
   * @param id - El id del usuario en sesión.
   * @returns {Object} - Regresa un objeto con la información del usuario en sesión
   */
  getUserById(id) {
    return axios
      .get(`${API_URL_USERS}/user/${id}`)
      .then((response) => response.data);
  }

  createUser(body) {
    return axios.post(`${API_URL_USERS}/user/register`, body);
  }

  updateUser(body) {
    return axios.put(`${API_URL_USERS}/user/${body.id}`, body);
  }

  /**
   *
   * @param id - El id del user a eliminar
   * @returns
   */
  deleteUser(id) {
    return axios.delete(`${API_URL_USERS}/user/${id}`);
  }

  /**
   *
   * @param currentPassword - El password actual del usuario logueado.
   * @param newPassword - El nuevo password para el usuario logueado.
   * @returns {Promise<any>} - Regresa la respuesta si fue exitosa o ocurrió un error del servicio de cambio de password.
   */
  async changePassword({ currentPassword, newPassword, userId }) {
    const { data } = await axios.put(
      `${API_URL_USERS}/user/${userId}/change-password`,
      {
        currentPassword,
        newPassword,
      }
    );
    return data;
  }

  getUserByRfc(rfc) {
    return axios
      .get(`${API_URL_USERS}/fortia/employee?rfc=${rfc}`)
      .then((response) => response);
  }

  async getRoles() {
    const { data } = await axios.get(`${API_URL_USERS}/user/role`);
    return data;
  }

  async getAllEmployees() {
    return axios.get(`${API_URL_USERS}/fortia/employeeAll`);
  }

  requestApproval = (body) =>
    axios.post(`${API_URL_USERS}/email/requestApproval`, body);

  approveChanges = (body) =>
    axios.post(`${API_URL_USERS}/email/approveChanges`, body);

  rejectChanges = (body) =>
    axios.post(`${API_URL_USERS}/email/rejectModifications`, body);
}

export default new UsersService();
