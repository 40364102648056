export const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        id: action.data.id,
        isAuthenticated: true,
        user: action.data.user,
        fullname: action.data.fullname,
        email: action.data.email,
        token: action.data.token,
        userid: action.data.userid,
        location: action.data.location,
        rol: action.data.rol,
        modules: action.data.modules,
        permissions: action.data.permissions,
      };
    case "LOGOUT":
      return {
        ...state,
        id: null,
        isAuthenticated: false,
        user: null,
        fullname: null,
        email: null,
        token: null,
        userid: null,
        location: null,
        rol: null,
        modules: [],
      };
    default:
      return state;
  }
};
