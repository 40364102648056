export const emptyEmployee = {
  id: null,
  idFortiaEmployee: "NULO",
  employeeName: "NO APLICA",
  employeeLastName: "",
  employeeSecondLastName: "NO APLICA",
  rfc: "NO APLICA",
  workStationId: null,
  workStationName: null,
  baseLocationName: "GASPASA MAZATLAN",
  complement: [],
  active: true,
};
