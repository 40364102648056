/* eslint-disable react/button-has-type */
import React, { useState, useContext, useRef } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import { Toast } from "primereact/toast";
import LoginLayout from "./LoginLayout";
import Loader from "./Loader";
import AuthenticationService from "../../api/AuthenticationService";
import { AuthContext } from "../../context/AuthContext/AuthContext";
import hashImg from "../../css/img/hash.png";

function Login() {
  const history = useHistory();
  const { dispatch } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);

  /* Validación de Login */
  // eslint-disable-next-line consistent-return
  const session = JSON.parse(sessionStorage.getItem("authenticatedUser"));
  const authValidation = session ? session.isAuthenticated : false;

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const showToast = (severityValue, summaryValue, detailValue) => {
    toast.current.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    AuthenticationService.executeJwtAuthenticationService(username, password)
      .then((response) => {
        if (response.data.status === "OK") {
          const data = {
            user: response.data.result.user.username,
            fullname: `${response.data.result.user.firstname} ${response.data.result.user.lastname}`,
            email: response.data.result.user.email,
            token: response.data.result.token,
            userid: response.data.result.user.id,
            location: response.data.result.user.locationFortia,
            rol: response.data.result.user.roles[0].name,
            modules: response.data.result.user.roles[0].modules,
            permissions: response.data.result.user.roles[0].permissions,
          };
          dispatch({ type: "LOGIN", data });
          history.replace("/home");
          window.location.reload();
        } else if (
          response.message === "El usuario se encuentra desactivado."
        ) {
          showToast("error", "Usuario inhabilitado", response.message);
        } else {
          showToast("warn", "Credenciales incorrectas", response.message);
        }
      })
      .catch((error) => {
        showToast(
          "error",
          error.response.data.status,
          error.response.data.message
        );
      })
      .catch(() => showToast("error", "Error"))
      .then(() => {
        setLoading(false);
      });
  };

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <LoginLayout>
      {authValidation ? <Redirect to="/home" /> : ""}
      {/* FAKE LOGIN */}
      {/* <button
        className="button is-link"
        onClick={() => {
          const data = {
            user: "test",
            fullname: "test",
            email: "test@example.com",
            token: "",
            userid: 1,
            location: 1,
            rol: "ROLE_JEFE_CAPITAL_HUMANO",
            modules: [
              { name: "shifts" },
              { name: "home" },
              { name: "prenomina" },
              { name: "payrollPeriod" },
              { name: "typeLiters" },
              { name: "bonoConcepts" },
              { name: "baseSalary" },
              { name: "liquidaciones" },
              { name: "tabulator" },
              { name: "paymentSchemas" },
              { name: "holidays" },
              { name: "users" },
              { name: "commission" },
              { name: "serviceOpening" },
              { name: "paymentEdition" },
            ],
          };
          dispatch({ type: "LOGIN", data });
          window.location.reload();
        }}
      >
        Fake login
      </button> */}

      <form id="loginForm" onSubmit={handleSubmit}>
        <ul>
          <li>
            <h1>
              <img src={hashImg} alt="" />
              <span className="login-text">Iniciar sesión</span>
            </h1>
          </li>
          <li className="error" />
          <li>
            <input
              id="username"
              type="text"
              name="username"
              value={username}
              onChange={handleUsernameChange}
              placeholder="Usuario"
              required
            />
          </li>
          <li>
            <div className="pass-wrapper">
              <input
                id="pass"
                type={passwordShown ? "text" : "password"}
                name="password"
                value={password}
                autoComplete="off"
                onChange={handlePasswordChange}
                placeholder="Contraseña"
                required
              />
              <i
                role="button"
                className={`custom-eye-icon
                                    
                                        ${
                                          passwordShown
                                            ? "fas fa-eye"
                                            : "fas fa-eye-slash"
                                        }
                                        `}
                tabIndex={0}
                onClick={togglePasswordVisibility}
              />
            </div>
          </li>

          <li>
            {loading ? (
              <div className="loadger-container">
                <Loader />
              </div>
            ) : (
              <button type="submit" className="loginButton">
                Iniciar Sesión
              </button>
            )}
          </li>
          <li>
            <div className="center">
              <Link to="/password-recovery">
                <p className="login-text">Olvidé mi contraseña</p>
              </Link>
            </div>
          </li>
        </ul>
      </form>
      <Toast ref={toast} />
    </LoginLayout>
  );
}

export default Login;
