/* eslint-disable no-underscore-dangle */
import React, { useRef, useState, useEffect } from "react";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Message } from "primereact/message";
import { Toast } from "primereact/toast";
import TabulatorOtifService from "../../../api/TabulatorOtifService";
import { usePaymentSchema } from "../../../context/PaymentSchemaContext";
import { periodTypes } from "../constants";

const Comisiones = ({ tabulator, tabulatorCode, sectionCode, sectionName }) => {
  const toast = useRef(null);
  const [submitted, setSubmitted] = useState(false);

  const [idTabulatorSection, setIdTabulatorSection] = useState(null);

  useEffect(() => {
    if (tabulator) {
      const result = tabulator.sections.find(
        (section) => section.sectionCode === sectionCode
      );
      setIdTabulatorSection(result.idTabulatorSection);
    }
  }, []);

  const {
    paymentPeriods,
    paymentSchemaSelected,
    setPaymentSchemaSelected,
    editValuesOnly,
    commissionTypes,
  } = usePaymentSchema();

  const { idPaymentPeriod, idEvaluation, idRTabSectionsTabTypes } = {
    ...paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode],
  };

  let { fixedPaymentSchema } = {
    ...paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode],
  };

  // Si es objeto fixedPaymentSchema no está agregado, le asignamos un objeto
  useEffect(() => {
    if (!fixedPaymentSchema) {
      const _paymentSchemaSelected = { ...paymentSchemaSelected };

      _paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode] = {
        idPaymentPeriod: null,
        idEvaluation: null,
        idRTabSectionsTabTypes: null,
        productivityRangeType: null,
        idSection: null,
        sectionCode: "secComisionesFijo",
        sectionCode: "Comisiones Portátil",
        fixedPaymentSchema: {},
      };

      setPaymentSchemaSelected({ ..._paymentSchemaSelected });
    }
  }, []);

  const onFormInputChange = (e, field) => {
    const _paymentSchemaSelected = { ...paymentSchemaSelected };
    const aux = {
      ...paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode],
    };
    _paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode] = {
      ...aux,
      [field]: e.value,
    };

    setPaymentSchemaSelected(_paymentSchemaSelected);
  };

  const onEditorValueChange = (e, field) => {
    const _paymentSchemaSelected = { ...paymentSchemaSelected };
    const _fixedPaymentSchema = { ...fixedPaymentSchema };

    _fixedPaymentSchema[field] = e.value;
    _paymentSchemaSelected.tabs[tabulatorCode].sections[
      sectionCode
    ].fixedPaymentSchema = _fixedPaymentSchema;

    setPaymentSchemaSelected({ ..._paymentSchemaSelected });
  };

  const handleValidation = (formAux) => {
    let error = false;

    if (
      !formAux.idEvaluation ||
      !formAux.idPaymentPeriod ||
      !formAux.fixedPaymentSchema.idCommissionType ||
      formAux.fixedPaymentSchema.driverWithoutAssistant === 0 ||
      formAux.fixedPaymentSchema.driverAssistant === 0 ||
      formAux.fixedPaymentSchema.assistant === 0
    ) {
      error = true;
    }

    return error;
  };

  const onSave = () => {
    setSubmitted(true);

    const formAux = {
      ...paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode],
    };

    if (handleValidation(formAux)) {
      return;
    }

    const _paymentSchemaSelected = { ...paymentSchemaSelected };

    formAux.fixedPaymentSchema.idPaymentPeriod = formAux.idPaymentPeriod;
    formAux.fixedPaymentSchema.idEvaluation = formAux.idEvaluation;
    formAux.fixedPaymentSchema.idRTabSectionsTabTypes =
      idRTabSectionsTabTypes || idTabulatorSection;
    formAux.fixedPaymentSchema.third = 0;

    if (!formAux.fixedPaymentSchema.id) {
      TabulatorOtifService.createFixedPaymentSchema(formAux.fixedPaymentSchema)
        .then((response) => {
          const { result, message } = response.data;

          _paymentSchemaSelected.tabs[tabulatorCode].sections[
            sectionCode
          ].fixedPaymentSchema = result;

          setPaymentSchemaSelected({
            ..._paymentSchemaSelected,
          });

          toast.current.show({
            severity: "success",
            summary: message,
          });
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: `${error.response.data.message}`,
          });
        });
    } else {
      TabulatorOtifService.updateFixedPaymentSchema(formAux.fixedPaymentSchema)
        .then((response) => {
          const { message } = response.data;

          _paymentSchemaSelected.tabs[tabulatorCode].sections[
            sectionCode
          ].fixedPaymentSchema = formAux;

          toast.current.show({
            severity: "success",
            summary: message,
          });
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: `${error.response.data.message}`,
          });
        });
    }
  };

  let paymentType;
  let evaluationType;

  if (editValuesOnly) {
    paymentType = paymentPeriods.find(({ id }) => id === idPaymentPeriod);
    evaluationType = paymentPeriods.find(({ id }) => id === idEvaluation);
  }

  if (!fixedPaymentSchema) {
    fixedPaymentSchema = {};
  }

  const addSectionWithPaymentPeriod = () => {
    const option = periodTypes.find(
      (period) => period.value === paymentSchemaSelected.period_name
    );
    const _paymentSchemaSelected = { ...paymentSchemaSelected };
    const aux = {
      ...paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode],
    };
    _paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode] = {
      ...aux,
      idPaymentPeriod: option.id,
      idEvaluation: option.id,
    };

    setPaymentSchemaSelected(_paymentSchemaSelected);
  };

  useEffect(() => {
    addSectionWithPaymentPeriod();
  }, []);

  return (
    <>
      <Toast ref={toast} />
      <div className="p-formgrid p-grid-1">
        <div className="p-field p-col-3">
          <Button
            label="Guardar Comisión"
            icon="pi pi-check"
            className="p-button-success"
            onClick={onSave}
          />
        </div>
        <div className="p-field p-col">
          <div
            className="p-formgrid p-grid"
            style={{
              padding: "0.0rem",
              border: "1px solid #e9ecef",
              color: "#495057",
              background: "#f8f9fa",
              transition: "box-shadow 0.2s;",
            }}
          >
            <div
              className="p-col-4"
              style={{
                padding: "0.0rem",
                border: "1px solid #e9ecef",
              }}
            >
              <div
                style={{
                  textAlign: "left",
                  padding: "1rem 1rem",
                  borderWidth: "1px 1px 0 1px",
                  fontWeight: "600",
                }}
              >
                <span className="p-column-title">Tipo de Comisión</span>

                <Dropdown
                  id="comision"
                  inputId="comision"
                  value={fixedPaymentSchema.idCommissionType}
                  options={commissionTypes}
                  optionLabel="commissionName"
                  optionValue="id"
                  onChange={(e) => onEditorValueChange(e, "idCommissionType")}
                  placeholder="Tipo de comisión"
                  required
                  className={classNames({
                    "w-full mt-2, p-invalid":
                      submitted && fixedPaymentSchema.idCommissionType === null,
                  })}
                  disabled={editValuesOnly}
                />
                {submitted &&
                  !(fixedPaymentSchema.idCommissionType !== null) && (
                    <Message
                      severity="error"
                      text="Seleccione un dato valido"
                    />
                  )}
              </div>
            </div>

            <div
              className="p-col"
              style={{
                padding: "0.0rem",
                border: "1px solid #e9ecef",
                height: "100%",
              }}
            >
              <div
                style={{
                  textAlign: "left",
                  padding: "1rem 1rem",
                  fontWeight: "600",
                }}
              >
                <div
                  className="p-formgrid p-grid"
                  style={{
                    padding: "0.5rem 0.5rem",
                  }}
                >
                  <div className="p-col-3">
                    <span className="p-column-title">Chofer sin Ayudante</span>
                  </div>
                  <div className="p-col">
                    <InputNumber
                      value={fixedPaymentSchema.driverWithoutAssistant}
                      onValueChange={(e) =>
                        onEditorValueChange(e, "driverWithoutAssistant")
                      }
                      mode="currency"
                      currency="MXN"
                      locale="es-MX"
                      minFractionDigits={4}
                      required
                      className={classNames({
                        "p-invalid":
                          submitted &&
                          fixedPaymentSchema.driverWithoutAssistant === 0,
                      })}
                    />
                    {submitted &&
                      !(fixedPaymentSchema.driverWithoutAssistant !== 0) && (
                        <Message
                          severity="error"
                          text="Ingresa un dato valido"
                        />
                      )}
                  </div>
                </div>

                <div
                  className="p-formgrid p-grid"
                  style={{
                    padding: "0.5rem 0.5rem",
                  }}
                >
                  <div className="p-col-3">
                    <span className="p-column-title">Chofer con Ayudante</span>
                  </div>
                  <div className="p-col">
                    <InputNumber
                      value={fixedPaymentSchema.driverAssistant}
                      onValueChange={(e) =>
                        onEditorValueChange(e, "driverAssistant")
                      }
                      mode="currency"
                      currency="MXN"
                      locale="es-MX"
                      minFractionDigits={4}
                      required
                      className={classNames({
                        "p-invalid":
                          submitted && fixedPaymentSchema.driverAssistant === 0,
                      })}
                    />
                    {submitted &&
                      !(fixedPaymentSchema.driverAssistant !== 0) && (
                        <Message
                          severity="error"
                          text="Ingresa un dato valido"
                        />
                      )}
                  </div>
                </div>
                <div
                  className="p-formgrid p-grid"
                  style={{
                    padding: "0.5rem 0.5rem",
                  }}
                >
                  <div className="p-col-3">
                    <span className="p-column-title">Ayudante</span>
                  </div>
                  <div className="p-col">
                    <InputNumber
                      value={fixedPaymentSchema.assistant}
                      onValueChange={(e) => onEditorValueChange(e, "assistant")}
                      mode="currency"
                      currency="MXN"
                      locale="es-MX"
                      minFractionDigits={4}
                      required
                      className={classNames({
                        "p-invalid":
                          submitted && fixedPaymentSchema.assistant === 0,
                      })}
                    />
                    {submitted && !(fixedPaymentSchema.assistant !== 0) && (
                      <Message severity="error" text="Ingresa un dato valido" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Comisiones;
