import React, { useState, useEffect, useContext, useRef } from 'react';
import { Toast } from 'primereact/toast';
import UserInfoForm from './UserInfoForm';
import UpdatePasswordForm from './UpdatePasswordForm';
import { AuthContext } from '../../context/AuthContext/AuthContext';
import UsersService from '../../api/UsersService';
import { PASSWORD_PATTERN_REGEXP } from '../../constants';

const UserInfo = ({ onHide }) => {
    const { auth } = useContext(AuthContext);
    const { dispatch } = useContext(AuthContext);
    const [userInfo, setUserInfo] = useState({});
    const [originalInfo, setOriginalInfo] = useState({});
    const [passwordForm, setPasswordForm] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    });

    const toast = useRef(null);

    useEffect(() => {
        UsersService.getUserById(auth.userid)
            .then((response) => {
                setUserInfo(response.result);
                setOriginalInfo(response.result);
            })
            .catch();
    }, []);

    const handleUserInputChange = (e) => {
        setUserInfo({
            ...userInfo,
            [e.target.id]: e.target.value,
        });
    };

    const handlePasswordInputChange = (e) => {
        setPasswordForm({
            ...passwordForm,
            [e.target.id]: e.target.value,
        });
    };

    const enableUpdateButton = () =>
        JSON.stringify(userInfo) === JSON.stringify(originalInfo);

    const enablePasswordButton = () => {
        const { currentPassword, newPassword, confirmPassword } = passwordForm;
        return !(
            currentPassword &&
            newPassword &&
            confirmPassword &&
            newPassword === confirmPassword &&
            currentPassword !== newPassword &&
            PASSWORD_PATTERN_REGEXP.test(newPassword)
        );
    };

    const handleUpdateClick = () => {
        UsersService.updateUser(userInfo).then((response) => {
            if (response.data.status === 'OK') {
                const data = {
                    user: auth.user,
                    fullname: `${userInfo.firstname} ${userInfo.lastname}`,
                    email: auth.email,
                    token: auth.token,
                    userid: auth.userid,
                    location: auth.location,
                    rol: auth.rol,
                };

                dispatch({ type: 'LOGIN', data });

                toast.current.show({
                    severity: `success`,
                    summary: `${response.data.message}`,
                });

                setTimeout(() => {
                    onHide();
                }, 2000);
            } else {
                toast.current.show({
                    severity: `error`,
                    summary: `${response.data.message}`,
                });
            }
        });
    };

    const handleUpdatePasswordClick = async () => {
        try {
            const { currentPassword, newPassword } = passwordForm;
            const { userid } = auth;
            const response = await UsersService.changePassword({
                currentPassword,
                newPassword,
                userId: userid,
            });

            if (response.status === 'OK') {
                toast.current.show({
                    severity: `success`,
                    summary: `${response.message}`,
                });
            }
        } catch (e) {
            toast.current.show({
                severity: 'error',
                summary: 'Hubo un problema al actualizar el password',
            });
        }
    };

    const restoreInfo = () => {
        UsersService.updateUser(originalInfo).then((response) => {
            if (response.data.status === 'OK') {
                toast.current.show({
                    severity: `success`,
                    summary: `${response.data.message}`,
                });
            } else {
                toast.current.show({
                    severity: `error`,
                    summary: `${response.data.message}`,
                });
            }
        });
        setUserInfo(originalInfo);
    };

    return (
        <>
            <Toast ref={toast} />
            {userInfo ? (
                <UserInfoForm
                    userInfo={userInfo}
                    setUserInfo={setUserInfo}
                    originalInfo={originalInfo}
                    handleInputChange={handleUserInputChange}
                    restoreInfo={restoreInfo}
                    enableUpdateButton={enableUpdateButton}
                    handleSubmit={handleUpdateClick}
                />
            ) : (
                ''
            )}
            {/* <UpdatePasswordForm
                passwordForm={passwordForm}
                handleInputChange={handlePasswordInputChange}
                enableButton={enablePasswordButton}
                handleSubmit={handleUpdatePasswordClick}
            /> */}
        </>
    );
};

export default UserInfo;
