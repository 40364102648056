import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

import 'primeflex/primeflex.css';
import PayrollPeriodDialog from './PayrollPeriodDialog';
import { Message } from 'primereact/message';

const PayrollPeriodGrid = ({
    // Valores
    payrollPeriods,
    payrollPeriod,
    payrollPeriodDialog,
    deletePayrollPeriodDialog,
    periodTypes,
    submitted,
    locations,
    dates,
    exercisePeriods,
    globalFilter,
    // Métodos
    handleNewPayrollPeriodClick,
    hidePayrollPeriodDialog,
    hideDeletePayrollPeriodDialog,
    onInputChange,
    onPaymentDateChange,
    onGlobalFilterChange,
    savePayrollPeriod,
    onRowEditComplete,
    onRowEditInit,
    onRowEditCancel,
    // Componentes
    editBody,
    deleteBody,
    payrollPeriodDialogFooter,
    deletePayrollPeriodDialogFooter,
    findLocation,
    dateEditor,
}) => (
    <>
        <div className="container px-3">
            <p className="is-size-3 gray-strench">Gestión de periodos de nómina</p>
            <p className="gray-light">Aquí podrás administrar los periodos de pagos de prenómina.</p>
            <div className="p-formgrid p-grid">
                <div className="p-field p-col">
                    <button
                        type="button"
                        className="button is-link mt-3 primary-color"
                        onClick={handleNewPayrollPeriodClick}
                    >
                        Nuevo periodo
                    </button>
                </div>
                <div className="p-field p-col">
                    <span className="p-input-icon-left mt-3 bar-full">
                        <i className="pi pi-search" />
                        <InputText
                            type="search"
                            onInput={(e) =>
                                onGlobalFilterChange(e.target.value)
                            }
                            placeholder="Buscar"
                        />
                    </span>
                </div>
            </div>
            <div className="card pt-3">
                <DataTable
                    value={payrollPeriods}
                    dataKey="id"
                    emptyMessage="Datos no encontrados"
                    globalFilter={globalFilter}
                    paginator
                    rows={10}
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    rowsPerPageOptions={[10, 25, 50]}
                >
                    <Column
                        field="id"
                        header="ID"
                        style={{ width: '60px' }}
                        sortable
                    />
                    <Column
                        field="periodName"
                        header="Nombre del Periodo"
                        style={{ width: '150px' }}
                        sortable
                    />
                    <Column
                        field="periodType"
                        header="Tipo de Periodo"
                        style={{ width: '150px' }}
                        sortable
                    />
                    <Column
                        body={findLocation}
                        header="Plaza"
                        style={{ width: '150px' }}
                        sortable
                    />
                    <Column
                        field="exercise"
                        header="Ejercicio"
                        style={{ width: '150px' }}
                        sortable
                    />
                    <Column
                        header="Editar"
                        body={editBody}
                        style={{ width: '60px' }}
                        bodyStyle={{ textAlign: 'left' }}
                    />
                    <Column
                        header="Eliminar"
                        body={deleteBody}
                        style={{ width: '60px' }}
                        bodyStyle={{ textAlign: 'left' }}
                    />
                </DataTable>
            </div>

            <PayrollPeriodDialog
                // VALORES
                payrollPeriod={payrollPeriod}
                periodTypes={periodTypes}
                submitted={submitted}
                exercisePeriods={exercisePeriods}
                locations={locations}
                dates={dates}
                // MÉTODOS
                onInputChange={onInputChange}
                onPaymentDateChange={onPaymentDateChange}
                savePayrollPeriod={savePayrollPeriod}
                onRowEditComplete={onRowEditComplete}
                // COMPONENTES
                payrollPeriodDialog={payrollPeriodDialog}
                payrollPeriodDialogFooter={payrollPeriodDialogFooter}
                hidePayrollPeriodDialog={hidePayrollPeriodDialog}
                dateEditor={dateEditor}
                onRowEditInit={onRowEditInit}
                onRowEditCancel={onRowEditCancel}
            />
            <Dialog
                visible={deletePayrollPeriodDialog}
                style={{ width: '450px' }}
                header="Confirm"
                modal
                footer={deletePayrollPeriodDialogFooter}
                onHide={hideDeletePayrollPeriodDialog}
            >
                <div className="confirmation-content">
                    <i
                        className="pi pi-exclamation-triangle p-mr-3"
                        style={{ fontSize: '2rem' }}
                    />
                    {payrollPeriod && (
                        <span>
                            ¿Desear eliminar el periodo de nómina{' '}
                            <b>{payrollPeriod.periodName}</b>?
                        </span>
                    )}
                </div>
            </Dialog>
        </div>
    </>
);

export default PayrollPeriodGrid;
