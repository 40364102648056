/* eslint-disable no-return-await */
import axios from "../axios/custom-axios";
import { API_URL_TABULATORS } from "../constants";

class CatalogsService {
  async getPaymentPeriods() {
    const { data } = await axios.get(`${API_URL_TABULATORS}/payment-periods`);
    return data;
  }

  async getCommissionTypes() {
    const { data } = await axios.get(`${API_URL_TABULATORS}/commission-types`);
    return data;
  }

  async getLocations() {
    const { data } = await axios.get(`${API_URL_TABULATORS}/fortia/locations`);
    return data;
  }

  getFortiaLocations = async () =>
    await axios.get(`${API_URL_TABULATORS}/fortia/locations`);

  async getJobPositions(location) {
    const { data } = await axios.get(
      `${API_URL_TABULATORS}/job-positions?location=${location}`
    );
    return data;
  }

  getPaymentSchemas = () =>
    axios.get(`${API_URL_TABULATORS}/payment-schema-grid`);

  async getTabulatorSections(idLocation, idPaymentSchema) {
    const { data } = await axios.get(
      `${API_URL_TABULATORS}/tabulator-type/sections?idPlazaFortia=${idLocation}&idPaymentSchema=${idPaymentSchema}`
    );
    return data;
  }

  async getTabulatorTypes(idLocation) {
    let response = null;
    try {
      response = await axios.get(
        `${API_URL_TABULATORS}/tabulator-type?idPlazaFortia=${idLocation}`
      );
    } catch (error) {
      response = error.response;
    }
    return response;
  }
}

export default new CatalogsService();
