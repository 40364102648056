export const sumDaysCurrentValue = (element) =>
  element.bonoDiasSistema +
  element.comisionDiasSistema +
  element.compensacionDiasSistema +
  element.remanenteDiasSistema +
  element.septimoDiaTrabajadoDiasSistema +
  element.complPlantaDiasSistema +
  element.complGuardiaDiasSistema +
  element.complTallerDiasSistema +
  element.complImprevistoDiasSistema +
  element.complSalarioMinimoDiasSistema +
  element.complSueldoGarantiaDiasSistema +
  element.complDiaFestivoDiasSistema +
  element.complDescansoTrabajadoDiasSistema +
  element.complPproductividadDiasSistema +
  element.complVentaDiasSistema;

export const sumDaysLastValue = (element) =>
  element.bonoDiasModificado +
  element.comisionDiasModificado +
  element.compensacionDiasModificado +
  element.remanenteDiasModificado +
  element.septimoDiaTrabajadoDiasModificado +
  element.complPlantaDiasModificado +
  element.complGuardiaDiasModificado +
  element.complTallerDiasModificado +
  element.complImprevistoDiasModificado +
  element.complSalarioMinimoDiasModificado +
  element.complSueldoGarantiaDiasModificado +
  element.complDiaFestivoDiasModificado +
  element.complDescansoTrabajadoDiasModificado +
  element.complPproductividadDiasModificado +
  element.complVentaDiasModificado;

export const sumAmountCurrentValue = (element) =>
  element.bonoImporteSistema +
  element.comisionImporteSistema +
  element.compensacionImporteSistema +
  element.remanenteImporteSistema +
  element.septimoDiaTrabajadoImporteSistema +
  element.complPlantaImporteSistema +
  element.complGuardiaImporteSistema +
  element.complTallerImporteSistema +
  element.complImprevistoImporteSistema +
  element.complSalarioMinimoImporteSistema +
  element.complSueldoGarantiaImporteSistema +
  element.complDiaFestivoImporteSistema +
  element.complDescansoTrabajadoImporteSistema +
  element.complPproductividadImporteSistema +
  element.complVentaImporteSistema;

export const sumAmountLastValue = (element) =>
  element.bonoImporteModificado +
  element.comisionImporteModificado +
  element.compensacionImporteModificado +
  element.remanenteImporteModificado +
  element.septimoDiaTrabajadoImporteModificado +
  element.complPlantaImporteModificado +
  element.complGuardiaImporteModificado +
  element.complTallerImporteModificado +
  element.complImprevistoImporteModificado +
  element.complSalarioMinimoImporteModificado +
  element.complSueldoGarantiaImporteModificado +
  element.complDiaFestivoImporteModificado +
  element.complDescansoTrabajadoImporteModificado +
  element.complPproductividadImporteModificado +
  element.complVentaImporteModificado;
