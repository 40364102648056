/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";
import { Message } from "primereact/message";
import TabulatorOtifService from "../../../api/TabulatorOtifService";
import { usePaymentSchema } from "../../../context/PaymentSchemaContext";
import { periodTypes } from "../constants";

const EsquemaRangoFijo = ({
  tabulatorCode,
  tabulator,
  sectionCode,
}) => {
  const toast = useRef(null);
  const [table, setTable] = useState([]);
  const [tableToShow, setTableToShow] = useState([]);
  const [originalRows, setOriginalRows] = useState([]);
  const [editingRows, setEditingRows] = useState({ undefined: "true" });

  const {
    paymentSchemaSelected,
    setPaymentSchemaSelected,
    paymentPeriods,
    editValuesOnly,
  } = usePaymentSchema();

  const {
    idPaymentPeriod,
    idEvaluation,
    idRTabSectionsTabTypes,
    productivityRangeType,
    fixedPaymentSchema,
    simpleRangeSchema,
  } = {
    ...paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode],
  };

  const emptyRow = {
    id: "new",
    lowerLimit: null,
    upperLimit: null,
    driverAssistant: null,
    //third: null,
    driverWithoutAssistant: null,
    assistant: null,
    cylinderBono: null,
  };

  // METODOS

  const onFormInputChange = (e, field) => {
    const _paymentSchemaSelected = { ...paymentSchemaSelected };
    const aux = {
      ...paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode],
    };
    _paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode] = {
      ...aux,
      [field]: e.value,
    };

    setPaymentSchemaSelected({ ..._paymentSchemaSelected });
  };

  const addNewRow = () => {
    if (productivityRangeType) {
      const _originalRows = [...originalRows];
      const _paymentSchemaSelected = { ...paymentSchemaSelected };
      const _simpleRangeSchema = simpleRangeSchema
        ? [...simpleRangeSchema]
        : [];
      _simpleRangeSchema.push({ ...emptyRow });
      const index = _simpleRangeSchema.length - 1;
      _paymentSchemaSelected.tabs[tabulatorCode].sections[
        sectionCode
      ].simpleRangeSchema = [..._simpleRangeSchema];

      _originalRows[index] = { ..._simpleRangeSchema[index] };
      let _editingRows = {};
      if (_simpleRangeSchema[index]) {
        _editingRows = {
          ...editingRows,
          ...{ [`${_simpleRangeSchema[index].id}`]: true },
        };
      }
      setEditingRows({ ..._editingRows });
      setOriginalRows([..._originalRows]);
      setPaymentSchemaSelected({ ..._paymentSchemaSelected });
      setEditingRows({ ..._editingRows });
      setOriginalRows([..._originalRows]);
      setPaymentSchemaSelected({ ..._paymentSchemaSelected });
    }
  };

  const onEditorValueChange = (props, value) => {
    if (!productivityRangeType) {
      const _paymentSchemaSelected = { ...paymentSchemaSelected };
      const _fixedPaymentSchema = { ...fixedPaymentSchema };
      _fixedPaymentSchema[props.field] = value;

      _paymentSchemaSelected.tabs[tabulatorCode].sections[
        sectionCode
      ].fixedPaymentSchema = { ..._fixedPaymentSchema };
      setPaymentSchemaSelected({ ..._paymentSchemaSelected });
    } else {
      const _paymentSchemaSelected = { ...paymentSchemaSelected };
      const _simpleRangeSchema = [...simpleRangeSchema];
      _simpleRangeSchema[props.rowIndex][props.field] = value;

      _paymentSchemaSelected.tabs[tabulatorCode].sections[
        sectionCode
      ].simpleRangeSchema = [..._simpleRangeSchema];
      setPaymentSchemaSelected({ ..._paymentSchemaSelected });
    }
  };

  const onRowEditInit = (event) => {
    const _originalRows = [...originalRows];
    _originalRows[event.index] = { ...table[event.index] };
    setOriginalRows(_originalRows);
  };

  const onRowEditCancel = (event) => {
    const _table = [...table];
    _table[event.index] = originalRows[event.index];

    const _originalRows = [...originalRows];
    delete _originalRows[event.index];

    setTable(_table);
    setOriginalRows(_originalRows);
  };

  const onRowDelete = ({ id }) => {
    const _table = [...table];
    const index = _table.findIndex((row) => row.id === id);

    if (rangeOption === "Fijo") {
      if (id) {
        TabulatorOtifService.deleteFixedRangeSchema(id).then(({ data }) => {
          const { message } = data;
          _table.splice(index, 1);
          toast.current.show({
            severity: "success",
            summary: "Borrado Exitoso",
            detail: message,
            life: 3000,
          });
          setTable(_table);
        });
      } else {
        _table.splice(index, 1);
        setTable(_table);
      }
    }

    if (rangeOption === "Rangos") {
      if (id) {
        TabulatorOtifService.deleteSimpleRowSchemaRow(id).then(({ data }) => {
          const { message } = data;
          _table.splice(index, 1);
          toast.current.show({
            severity: "success",
            summary: "Borrado Exitoso",
            detail: message,
            life: 3000,
          });
          setTable(_table);
        });
      } else {
        _table.splice(index, 1);
        setTable(_table);
      }
    }
  };

  const onRowEditChange = (event) => {
    setEditingRows(event.data);
  };

  const onRowEditSave = ({ valid, data, index }) => {
    if (productivityRangeType) {
      // Este valor debe de ser por default
      const section = "secBonosRango";

      const body = {
        ...data,
        idPaymentPeriod,
        idEvaluation,
        idRTabSectionsTabTypes,
      };

      body.driverAssistant = 0;
      //body.third = 0;
      body.driverWithoutAssistant = 0;
      body.assistant = 0;
      body.idCommissionType = 0;

      if (idRTabSectionsTabTypes) {
        body.idRTabSectionsTabTypes = idRTabSectionsTabTypes;
      } else {
        const { idTabulatorSection } = tabulator.sections.find(
          (_section) => _section.sectionCode === section
        );

        body.idRTabSectionsTabTypes =
          idRTabSectionsTabTypes || idTabulatorSection;
      }

      // ACTUALIZAR REGISTRO
      if (data.id && data.id !== "new") {
        TabulatorOtifService.updateSimpleRangeSchemaRow(body)
          .then((response) => {
            const { message } = response.data;
            toast.current.show({
              severity: "success",
              summary: "Actualizacion Exitosa",
              detail: message,
              life: 3000,
            });
          })
          .catch(({ response: { data: message } }) => {
            toast.current.show({
              severity: "error",
              summary: "Registro Exitoso",
              detail: message,
              life: 3000,
            });
          });
      }

      // CREAR REGISTRO
      else {
        body.id = null;
        TabulatorOtifService.saveSimpleRangeSchemaRow(body)
          .then((response) => {
            const { result, message } = response.data;

            const _simpleRangeSchema = [...simpleRangeSchema];
            const _paymentSchemaSelected = {
              ...paymentSchemaSelected,
            };

            _simpleRangeSchema[index].id = result.id;

            _paymentSchemaSelected.tabs[tabulatorCode].sections[
              sectionCode
            ].simpleRangeSchema = [..._simpleRangeSchema];

            setPaymentSchemaSelected(_paymentSchemaSelected);

            toast.current.show({
              severity: "success",
              summary: "Registro Exitoso",
              detail: message,
              life: 3000,
            });
          })
          .catch(({ response: { data: message } }) => {
            toast.current.show({
              severity: "error",
              summary: "Registro Exitoso",
              detail: message,
              life: 3000,
            });
          });
      }
    } else {
      // Este valor debe de ser por default
      const section = "secBonosFijo";

      const body = {
        ...data,
        idPaymentPeriod,
        idEvaluation,
        idCommissionType: 1,
      };

      if (idRTabSectionsTabTypes) {
        body.idRTabSectionsTabTypes = idRTabSectionsTabTypes;
      } else {
        const { idTabulatorSection } = tabulator.sections.find(
          (_section) => _section.sectionCode === section
        );

        body.idRTabSectionsTabTypes =
          idRTabSectionsTabTypes || idTabulatorSection;
      }

      // ACTUALIZAR REGISTRO
      if (body.id) {
        TabulatorOtifService.updateFixedPaymentSchema(body)
          .then((response) => {
            const { message } = response.data;
            toast.current.show({
              severity: "success",
              summary: "Actualizacion Exitosa",
              detail: message,
              life: 3000,
            });
          })
          .catch(({ response: { data: message } }) => {
            toast.current.show({
              severity: "error",
              summary: "Error al registrar",
              detail: message,
              life: 3000,
            });
          });
      }

      // CREAR REGISTRO
      else {
        TabulatorOtifService.createFixedPaymentSchema(body)
          .then((response) => {
            const { result, message } = response.data;
            toast.current.show({
              severity: "success",
              summary: "Registro Exitoso",
              detail: message,
              life: 3000,
            });
          })
          .catch(
            ({
              response: {
                data: { message },
              },
            }) => {
              toast.current.show({
                severity: "error",
                detail: message,
                life: 3000,
              });
            }
          );
      }
    }
  };

  useEffect(() => {
    const _paymentSchemaSelected = { ...paymentSchemaSelected };
    if (!_paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode]) {
      _paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode] = {
        idPaymentPeriod: null,
        idEvaluation: null,
        idRTabSectionsTabTypes: null,
        productivityRangeType: null,
        idSection: null,
        sectionCode: "secBonosFijo",
        // sectionName: 'Bonos de productividad',
        fixedPaymentSchema: {
          driverAssistant: null,
          third: null,
          driverWithoutAssistant: null,
          assistant: null,
        },
        simpleRangeSchema: [],
      };
    }
  }, []);

  const onRangeTypeChange = (e) => {
    const _paymentSchemaSelected = { ...paymentSchemaSelected };
    const sectionAux = {
      ...paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode],
    };
    if (e.target.value === "Rangos") {
      paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode] = {
        ...sectionAux,
        productivityRangeType: true,
      };
    } else {
      paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode] = {
        ...sectionAux,
        productivityRangeType: false,
      };
    }

    setPaymentSchemaSelected({ ..._paymentSchemaSelected });
  };

  const disableRadioButton = () => {
    if (fixedPaymentSchema) {
      const values = Object.values(fixedPaymentSchema);

      if (values.find((value) => value !== 0)) {
        return true;
      }

      if (simpleRangeSchema.length > 0) {
        return true;
      }

      return false;
    }
    return false;
  };

  // COMPONENTES
  const priceBodyTemplate = (rowData, field) => (
    <p>$ {(rowData[field] ? rowData[field] : 0).toFixed(4)}</p>
  );

  const numberEditor = (props) => (
    <>
      <InputNumber
        value={props.rowData[props.field]}
        onValueChange={(e) => onEditorValueChange(props, e.value)}
      />
      {!(props.rowData[props.field] !== null) && (
        <Message
          className="mt-3"
          severity="error"
          text="Ingresa un dato valido"
        />
      )}
    </>
  );

  const moneyEditor = (props) => (
    <>
      <InputNumber
        value={props.rowData[props.field]}
        mode="currency"
        currency="MXN"
        locale="es-MX"
        minFractionDigits={4}
        onValueChange={(e) => onEditorValueChange(props, e.value)}
      />
      {!(props.rowData[props.field] !== null) && (
        <Message
          className="mt-3"
          severity="error"
          text="Ingresa un dato valido"
        />
      )}
    </>
  );

  const deleteRowBody = (rowData) => (
    <Button
      icon="pi pi-trash"
      className="trash"
      onClick={() => onRowDelete(rowData)}
      disabled={editValuesOnly}
    />
  );

  useEffect(() => {
    if (productivityRangeType) {
      setTableToShow(
        Array.isArray(simpleRangeSchema)
          ? [...simpleRangeSchema]
          : [
              {
                driverAssistant: 0,
                driverWithoutAssistant: 0,
                assistant: 0,
              },
            ]
      );
    } else if (fixedPaymentSchema) {
      setTableToShow([{ ...fixedPaymentSchema }]);
    } else {
      setTableToShow([
        {
          driverAssistant: 0,
          driverWithoutAssistant: 0,
          assistant: 0,
        },
      ]);
    }
  }, [paymentSchemaSelected]);

  let paymentType;
  let evaluationType;

  if (editValuesOnly) {
    paymentType = paymentPeriods.find(({ id }) => id === idPaymentPeriod);
    evaluationType = paymentPeriods.find(({ id }) => id === idEvaluation);
  }

  const addSectionWithPaymentPeriod = () => {
    const option = periodTypes.find(
      (period) => period.value === paymentSchemaSelected.period_name
    );
    const _paymentSchemaSelected = { ...paymentSchemaSelected };
    const aux = {
      ...paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode],
    };
    _paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode] = {
      ...aux,
      idPaymentPeriod: option.id,
      idEvaluation: option.id,
    };

    setPaymentSchemaSelected(_paymentSchemaSelected);
  };

  useEffect(() => {
    addSectionWithPaymentPeriod();
  }, []);

  return (
    <div>
      <Toast ref={toast} />
      <div>
        <DataTable
          value={tableToShow}
          dataKey="id"
          editMode="row"
          onRowEditInit={onRowEditInit}
          onRowEditCancel={onRowEditCancel}
          editingRows={editingRows}
          onRowEditChange={onRowEditChange}
          onRowEditSave={onRowEditSave}
        >
          {productivityRangeType ? (
            <Column
              field="lowerLimit"
              header="Limite Inferior"
              editor={numberEditor}
            />
          ) : null}

          {productivityRangeType ? (
            <Column
              field="upperLimit"
              header="Limite Superior"
              editor={numberEditor}
            />
          ) : null}
          {productivityRangeType ? (
            <Column
              field="cylinderBono"
              header="Bono por Cilindro"
              body={(rowData) => priceBodyTemplate(rowData, "cylinderBono")}
              editor={moneyEditor}
            />
          ) : null}

          {!productivityRangeType ? (
            <Column
              field="driverAssistant"
              header="Chofer con Ayudante"
              body={(rowData) => priceBodyTemplate(rowData, "driverAssistant")}
              editor={moneyEditor}
            />
          ) : null}
          {!productivityRangeType ? (
            <Column
              field="driverWithoutAssistant"
              header="Chofer sin Ayudante"
              body={(rowData) =>
                priceBodyTemplate(rowData, "driverWithoutAssistant")
              }
              editor={moneyEditor}
            />
          ) : null}
          {!productivityRangeType ? (
            <Column
              field="assistant"
              header="Ayudante"
              body={(rowData) => priceBodyTemplate(rowData, "assistant")}
              editor={moneyEditor}
            />
          ) : null}
          <Column
            header="Editar"
            rowEditor
            headerStyle={{ width: "7rem" }}
            bodyStyle={{ textAlign: "center" }}
          />
          {/* <Column
                        header="Eliminar"
                        body={deleteRowBody}
                        headerStyle={{ width: '7rem' }}
                        bodyStyle={{ textAlign: 'center' }}
                    /> */}
        </DataTable>
      </div>
    </div>
  );
};

export default EsquemaRangoFijo;
