import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";
import { Checkbox } from "primereact/checkbox";
import CatalogsService from "../../api/CatalogsService";
import OpeningService from "../../api/OpeningService";
import ShiftsService from "../../api/ShiftsService";
import DropDownEditor from "../commons/DropDownEditor";
import { InputText } from "primereact/inputtext";
import SalesService from "../../api/SalesService";
import { SetLeftFeature } from "ag-grid-community";
import OpeningHelpers from "./OpeningHelpers";

const ServiceOpeningGrid = ({
  headerComponent,
  setShowGrid,
  setOpeningSelected,
  openings,
  setOpenings,
  locations,
  setLocations,
  location,
  setLocation,
  selectedShift,
  setSelectedShift,
  shifts,
  setShifts,
  users,
  setUsers,
  showGrid,
  catalogs,
  setCatalogs,
  sectores,
  setSectores,
  complements,
  setComplements,
  zonaTarifas,
  setZonaTarifa,
  date,
  setDate,
  setLoading,
}) => {
  const [editingRows, setEditingRows] = useState({});
  const [originalRows, setOriginalRows] = useState({});
  const [globalFilter, setGlobalFilter] = useState(null);
  const [locationInit, setLocationInit] = useState(location);

  const complementRef = useRef();
  const toast = useRef(null);

  const dateToString = (dateObj = new Date()) => {
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();
    return `${year}-${month < 10 ? "0" : ""}${month}-${
      day < 10 ? "0" : ""
    }${day}`;
  };

  const fetchLocations = () => {
    CatalogsService.getFortiaLocations()
      .then(({ data: { result } }) => {
        setLocations(result);
      })
      .catch((error) => console.log(error));
  };

  const fetchZonaTarifas = (plazaId) => {
    SalesService.getZonaTarifasByplaza(plazaId)
      .then((response) => {
        setZonaTarifa([...response.data]);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const fetchSectores = () => {
    OpeningService.getSectores()
      .then((response) => {
        setSectores([...response.data]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchComplements = () => {
    OpeningService.getComplementList()
      .then((response) => {
        setComplements([...response.data.result]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchEmployees = (locationId, shiftId) => {
    OpeningService.getEmployees(locationId, shiftId)
      .then(({ data }) => {
        const aux = [...Object.values(data.result)];
        setUsers([...aux]);
      })
      .catch((error) => {
        console.log(error, error.response);
      });
  };

  const fetchShifts = (locationId) => {
    ShiftsService.getShiftsByLocationId(locationId)
      .then((response) => {
        setShifts([...response.data.result]);
        if (result.length === 0) {
          toast.current.show({
            severity: "warn",
            summary: `Plaza sin turnos`,
            detail: `La plaza seleccionada no tiene turno asignadoss`,
          });
        }
      })
      .catch(() => {});
  };

  const fetchOpenings = (locationId, shiftId, dateString) => {
    OpeningService.getOpenings(locationId, shiftId, dateString)
      .then(({ data: { result, message } }) => {
        setOpenings(result);
        toast.current.show({
          severity: "success",
          summary: `${message}`,
        });
      })
      .catch(
        ({
          response: {
            data: { message },
          },
        }) =>
          toast.current.show({
            severity: "error",
            summary: `${message}`,
          })
      );
  };

  const actionsBody = (rowData) => (
    <>
      <Button
        className="p-button-rounded p-button-secondary"
        onClick={() => {
          setShowGrid(false);
          setOpeningSelected({ ...rowData });
        }}
      >
        Apertura
      </Button>
    </>
  );

  const vehicleTypeBody = ({ vehicleType }) => {
    let type = "";
    switch (vehicleType) {
      case "B":
      case "C":
      case "D":
        type = "Portatil";
        break;
      case "P":
      case "Q":
      case "R":
        type = "Estacionario";
        break;
      default:
        break;
    }

    return <p>{type}</p>;
  };

  const zoneBody = ({ idZone }) => {
    return <p>{catalogs?.zones.find((z) => z.id == idZone)?.zona || ""}</p>;
  };

  const complementBody = (row) => {
    const comp = row?.complement?.map((c) => c.complementName)?.join(",");
    return <>{comp}</>;
  };

  const checkboxBody = (row, field) => {
    return <Checkbox checked={row.otif[field] === true} disabled />;
  };

  const handleCheckboxChange = (id, index, data) => {
    const _users = [...users];
    _users[index].otif[id] = !data.otif[id];
    setUsers(_users);
  };

  const checkboxEditor = ({ field, rowIndex, rowData }) => {
    return (
      <Checkbox
        checked={rowData.otif[field] === true}
        onChange={() => handleCheckboxChange(field, rowIndex, rowData)}
      />
    );
  };

  const onRowEditChange = (event) => {
    setEditingRows(event.data);
  };

  const onRowEditInit = (event) => {
    const newOriginalRows = { ...originalRows };
    newOriginalRows[event.index] = JSON.parse(
      JSON.stringify(users[event.index])
    );
    setOriginalRows({ ...newOriginalRows });
  };

  const onRowEditSave = (props) => {
    const venta = props.data.complement.find(
      (c) => c.complementName === "Venta"
    );
    const compId = props.data.complement[0].id;
    const { data } = props;
    const { otif } = data;

    const body = {
      checklistUnidad: otif.checklistUnidad || false,
      licenciaTarjeta: otif.licenciaTarjeta || false,
      disciplina: otif.disciplina || false,
      gafete: otif.gafete || false,
      casaca: otif.casaca || false,
      pantalon: otif.pantalon || false,
      botas: otif.botas || false,
      faja: otif.faja || false,
      puntualidad: otif.puntualidad || false,
      folio: otif.folio || "",
      serie: otif.serie || "",
      rateZone: otif.rateZone || null,
      sector: otif.sector || 0,
      kilometers: otif.kilometers || 0,
      fuel: otif.fuel || 0,
    };

    OpeningService.updateComplement(data.id, compId, selectedShift, body)
      .then((response) => {
        const usersAux = [...users];
        usersAux[props.index].complement[0] = { ...response.data.result };
        setUsers([...usersAux]);

        toast.current.show({
          severity: "success",
          summary: `${response.data.message}`,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onRowEditCancel = (event) => {
    const usersAux = [...users];
    usersAux[event.index] = JSON.parse(
      JSON.stringify(originalRows[event.index])
    );
    setUsers([...usersAux]);
  };

  const handleDateChange = ({ value }) => {
    setDate(value);
    if (location && selectedShift) {
      fetchEmployees(location.location_id, selectedShift);
      const dateString = dateToString(value);
      fetchOpenings(location.location_id, selectedShift, dateString);
    }
  };

  const handleDropdownShow = () => {
    setLocationInit(location);
  };
  const handleDropdownHide = () => {
    if (
      location &&
      (!locationInit || location.location_id !== locationInit.location_id)
    ) {
      setLoading(true);
      fetchZonaTarifas(location.location_id);
      fetchShifts(location.location_id);
      OpeningHelpers.fetchZones(location.location_id)
        .then(({ data }) => {
          const catTemp = { ...catalogs, zones: data };
          setCatalogs(catTemp);
          OpeningHelpers.fetchEmployees(
            catTemp,
            setCatalogs,
            location.location_id,
            setLoading
          );
        })
        .catch((error) => console.log(error));

      setUsers([]);
    }
  };



  useEffect(() => {
    if (location && selectedShift) {
      fetchEmployees(location.location_id, selectedShift);
      const dateString = dateToString(date);
      fetchOpenings(location.location_id, selectedShift, dateString);
    }
  }, [showGrid]);


  const handlePlazaChange = ({ value }) => {
    setLocation(value);
  };

  const handleShiftChange = ({ value }) => {
    setSelectedShift(value);
    fetchEmployees(location.location_id, value);
    const dateString = dateToString(date);
    fetchOpenings(location.location_id, value, dateString);
  };



  useEffect(() => {
    locations.length == 0 && fetchLocations();
    sectores.length == 0 && fetchSectores();
    complements.length == 0 && fetchComplements();
  }, []);

  return (
    <>
      <Toast ref={toast} />
      <div className="container px-3">
        {headerComponent}
        <div className="p-formgrid p-grid">
          <div className="p-field p-col-3">
            <Calendar
              id="validity"
              onChange={handleDateChange}
              value={date}
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="p-field p-col-3 full-width">
            <Dropdown
              options={locations}
              onChange={handlePlazaChange}
              onHide={handleDropdownHide}
              onShow={handleDropdownShow}
              optionLabel="location_name"
              placeholder="Selecciona una plaza"
              value={location}
            />
          </div>
          <div className="p-field p-col-3 full-width">
            <Dropdown
              value={selectedShift}
              options={shifts}
              optionLabel="shiftName"
              optionValue="id"
              placeholder="Selecciona un turno"
              onChange={handleShiftChange}
              disabled={shifts.length === 0}
            />
          </div>
        </div>
        <div className="card pt-3">
          <DataTable
            value={openings}
            dataKey="id"
            className="p-datatable-sm"
            scrollable
            emptyMessage="Datos no encontrados"
            globalFilter={globalFilter}
            paginator
            rows={10}
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rowsPerPageOptions={[10, 25, 50]}
          >
            <Column field="id" header="ID" style={{ width: "140px" }} />
            <Column
              field="openingFolio"
              header="Folio"
              style={{ width: "100px" }}
              sortable
            />
            <Column
              field="fecha"
              header="Fecha"
              style={{ width: "100px" }}
              sortable
            />
            <Column
              field="numEco"
              header="No. Económico"
              style={{ width: "150px" }}
              sortable
            />
            <Column
              header="Tipo"
              style={{ width: "150px" }}
              body={vehicleTypeBody}
              sortable
            />
            <Column
              field="idZone"
              header="Zona"
              style={{ width: "100px" }}
              body={zoneBody}
              sortable
            />
            <Column
              field="idShift"
              header="Turno"
              style={{ width: "100px" }}
              sortable
            />
            <Column
              field="driverName"
              header="Chofer"
              style={{ width: "150px" }}
              sortable
            />
            <Column
              field="assistantName"
              header="Ayudante"
              style={{ width: "150px" }}
              sortable
            />
            <Column
              field="thirdName"
              header="Tercero"
              style={{ width: "150px" }}
              sortable
            />
            <Column
              field="plazaName"
              header="Plaza"
              style={{ width: "150px" }}
              sortable
            />
            <Column
              header="Acciones"
              body={actionsBody}
              style={{ width: "100px" }}
            />
          </DataTable>
        </div>
        <div className="card pt-3">
          <DataTable
            value={users}
            dataKey="id"
            className="p-datatable-sm"
            scrollable
            emptyMessage="Datos no encontrados"
            paginator
            rows={10}
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rowsPerPageOptions={[10, 25, 50]}
            onRowEditInit={onRowEditInit}
            onRowEditSave={onRowEditSave}
            onRowEditChange={onRowEditChange}
            onRowEditCancel={onRowEditCancel}
            editingRows={editingRows}
            editMode="row"
          >
            <Column
              field="id"
              header="ID"
              style={{ width: "140px" }}
              sortable
            />
            <Column
              body={(row) =>
                `${row.employeeName} ${row.employeeLastName} ${row.employeeSecondLastName}`
              }
              header="Nombre"
              style={{ width: "220px" }}
              sortable
            />
            <Column
              header="Compañía"
              field="company"
              body={(props) => props?.otif?.idCompany}
              style={{ width: "200px" }}
            />
            <Column
              header="Tarifa"
              field="zonaTarifa"
              body={(props) => {
                const rateZoneToShow = zonaTarifas.find(
                  (element) => element.id === props.otif.rateZone
                );
                if (props.otif.rateZone) {
                  return <p>{rateZoneToShow?.zonaTarifa || ""}</p>;
                } else {
                  return "";
                }
              }}
              editor={(props) => (
                <DropDownEditor
                  editorProps={props}
                  placeholder="Seleccione una Zona Tarifa"
                  options={zonaTarifas}
                  optionLabel="zonaTarifa"
                  optionValue="id"
                  value={props.rowData.otif.rateZone}
                  onChange={(event) => {
                    const { rowData, rowIndex } = props;
                    const newValue = event.value;

                    const newRowData = { ...rowData };
                    newRowData.otif.rateZone = newValue;
                    const newUsers = [...users];
                    newUsers[rowIndex] = { ...newRowData };

                    setUsers([...newUsers]);
                  }}
                />
              )}
              style={{ width: "200px" }}
            />
            <Column
              header="Sector"
              field="sector"
              body={(props) => {
                const sectorToShow = sectores.find(
                  (element) => element.id === props.otif.sector
                );
                if (props.otif.rateZone) {
                  return <p>{sectorToShow?.sector || ""}</p>;
                } else {
                  return "";
                }
              }}
              editor={(props) => (
                <DropDownEditor
                  editorProps={props}
                  placeholder="Seleccione una compañia"
                  options={sectores}
                  optionLabel="sector"
                  value={props.rowData.otif.sector}
                  optionValue="id"
                  onChange={(event) => {
                    const { rowData, rowIndex } = props;
                    const newValue = event.value;
                    const newRowData = { ...rowData };
                    newRowData.otif.sector = newValue;
                    const newUsers = [...users];
                    newUsers[rowIndex] = { ...newRowData };

                    setUsers([...newUsers]);
                  }}
                />
              )}
              style={{ width: "200px" }}
            />
            <Column
              header="Complementos"
              body={(row) => complementBody(row)}
              optionValue="id"
              editor={(props) => {
                return (
                  <DropDownEditor
                    editable
                    editorProps={props}
                    placeholder="Seleccione un complemento"
                    options={complements}
                    optionLabel="complementName"
                    optionValue="id"
                    value={props.rowData.complement[0].id}
                    onChange={(event) => {
                      const { rowData, rowIndex } = props;
                      const newValue = event.value;

                      const newRowData = { ...rowData };
                      newRowData.complement[0].id = newValue;
                      const newUsers = [...users];
                      newUsers[rowIndex] = { ...newRowData };

                      setUsers([...newUsers]);
                    }}
                  />
                );
              }}
              style={{ width: "200px" }}
            />
            <Column
              header="Clave Complemento"
              field="claveComplemento"
              body={(props) => props?.complement[0]?.id}
              style={{ width: "200px" }}
            />
            <Column
              header="Folio"
              field="folio"
              style={{ width: "200px" }}
              body={(props) => <p>{props?.otif?.folio || ""}</p>}
              editor={(props) => (
                <InputText
                  value={props.rowData.otif.folio}
                  onChange={(event, other) => {
                    const { rowData, rowIndex } = props;
                    const newValue = event.target.value;
                    const newRowData = { ...rowData };
                    newRowData.otif.folio = newValue;
                    const newUsers = [...users];
                    newUsers[rowIndex] = { ...newRowData };
                    setUsers([...newUsers]);
                  }}
                />
              )}
            />
            <Column
              header="Serie"
              field="serie"
              style={{ width: "200px" }}
              body={(props) => <p>{props?.otif?.serie || ""}</p>}
              editor={(props) => (
                <InputText
                  value={props.rowData.otif.serie}
                  onChange={(event, other) => {
                    const { rowData, rowIndex } = props;
                    const newValue = event.target.value;
                    const newRowData = { ...rowData };
                    newRowData.otif.serie = newValue;
                    const newUsers = [...users];
                    newUsers[rowIndex] = { ...newRowData };
                    setUsers([...newUsers]);
                  }}
                />
              )}
            />
            <Column
              header="Checklist"
              field="checklistUnidad"
              body={(row) => checkboxBody(row, "checklistUnidad")}
              editor={(props) => checkboxEditor(props, complementRef)}
              style={{ width: "80px" }}
            />
            <Column
              header="Licencia"
              field="licenciaTarjeta"
              body={(row) => checkboxBody(row, "licenciaTarjeta")}
              editor={(props) => checkboxEditor(props, complementRef)}
              style={{ width: "80px" }}
            />
            <Column
              header="Disciplina"
              field="disciplina"
              body={(row) => checkboxBody(row, "disciplina")}
              editor={(props) => checkboxEditor(props, complementRef)}
              style={{ width: "80px" }}
            />
            <Column
              header="Gafete"
              field="gafete"
              body={(row) => checkboxBody(row, "gafete")}
              editor={(props) => checkboxEditor(props, complementRef)}
              style={{ width: "80px" }}
            />
            <Column
              header="Casaca"
              field="casaca"
              body={(row) => checkboxBody(row, "casaca")}
              editor={(props) => checkboxEditor(props, complementRef)}
              style={{ width: "80px" }}
            />
            <Column
              header="Pantalón"
              field="pantalon"
              body={(row) => checkboxBody(row, "pantalon")}
              editor={(props) => checkboxEditor(props, complementRef)}
              style={{ width: "80px" }}
            />
            <Column
              header="Botas"
              field="botas"
              body={(row) => checkboxBody(row, "botas")}
              editor={(props) => checkboxEditor(props, complementRef)}
              style={{ width: "80px" }}
            />
            <Column
              header="Faja"
              field="faja"
              body={(row) => checkboxBody(row, "faja")}
              editor={(props) => checkboxEditor(props, complementRef)}
              style={{ width: "80px" }}
            />
            <Column
              header="Puntualidad"
              field="puntualidad"
              body={(row) => checkboxBody(row, "puntualidad")}
              editor={(props) => checkboxEditor(props, complementRef)}
              style={{ width: "100px" }}
            />
            <Column
              header="Editar"
              rowEditor
              headerStyle={{ width: "120px", textAlign: "center" }}
              bodyStyle={{ textAlign: "center" }}
            />
          </DataTable>
        </div>
      </div>
    </>
  );
};

export default ServiceOpeningGrid;
