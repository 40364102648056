import React from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";

import Loader from "../Login/LoaderBlue";

const Filters = ({
  // Locations
  locations,
  locationSelected,
  handleLocationChange,
  // Job positions
  jobPositions,
  jobPositionSelected,
  handleJobPositionChange,
  // Employees
  employees,
  employeeSelected,
  handleEmployeeChange,
  // Periods
  periods,
  periodSelected,
  handlePeriodChange,
  // Companies
  companies,
  companySelected,
  handleCompanyChange,
  // Others
  loading,
  clearFilters,
  refresh,
}) => {
  const employeeTemplate = (employee) =>
    employee?.employeeName
      ? `${employee.rfc || ""} - ${employee.employeeName || ""} ${
          employee.employeeLastName || ""
        }  ${employee.employeeSecondLastName || ""}`
      : "Selecciona un empleado";

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    > 
      <div
        className="mt-5 flex flex-row"
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          flexWrap: "wrap",
          marginTop: "20px",
          alignItems: "flex-end"
        }}
      >
        <Dropdown
          options={locations}
          value={locationSelected}
          placeholder="Selecciona una plaza"
          className="mr-3 mb-3"
          onChange={(e) => handleLocationChange(e.value)}
          optionLabel="plazaName"
          disabled={locations.length === 0 || loading}
          style={{ width: "300px" }}
          filter
          filterBy="plazaName"
          showClear
        />
        <Dropdown
          options={[...jobPositions]}
          value={jobPositionSelected}
          placeholder="Selecciona un puesto"
          className="mr-3 mb-3"
          onChange={(e) => handleJobPositionChange(e.value)}
          optionLabel="work_station_name"
          disabled={jobPositions.length === 0 || loading}
          style={{ width: "300px" }}
          filter
          filterBy="work_station_name"
          showFilterClear
          showClear
        />
        <Dropdown
          options={[...employees]}
          value={employeeSelected}
          placeholder="Selecciona un empleado opcional"
          className="mr-3 mb-3"
          onChange={(e) => handleEmployeeChange(e.value)}
          disabled={loading || employees.length === 0}
          style={{ width: "400px" }}
          optionLabel="employeeName"
          itemTemplate={employeeTemplate}
          valueTemplate={employeeTemplate}
          filter
          filterBy="employeeName"
          showFilterClear
          showClear
        />
        <Dropdown
          options={[...periods]}
          value={periodSelected}
          placeholder="Selecciona un periodo de pago"
          className="mr-3 mb-3"
          onChange={(e) => handlePeriodChange(e.value)}
          style={{ 
            width: "300px",
            height: '40px'}}
          filter
          filterBy="label"
          disabled={loading}
          showFilterClear
          showClear
        />
        <div>
          <div>
            <h6>Afecta solo para enviar a Fortia</h6>
          </div>
          <Dropdown
          options={[...companies]}
          value={companySelected}
          placeholder="Selecciona una compañía"
          className="mr-3 mb-3"
          onChange={(e) => handleCompanyChange(e.value)}
          style={{ width: "300px" }}
          filter
          filterBy="label"
          disabled={loading}
          showFilterClear
          showClear
        />
        </div>
        
        <Button
          className="mr-3 mb-3 p-button-warning"
          onClick={clearFilters}
          disabled={loading}
          style={{ height: "40px" }}
        >
          Limpiar Búsqueda
        </Button>
        <Button
          className="mr-3 mb-3 p-button-primary"
          onClick={refresh}
          style={{ height: "40px" }}
          disabled={employees.length === 0 || loading || !locationSelected}
        >
          Refrescar
        </Button>
      </div>
      <div>
        <div className="ml-5">{loading ? <Loader small /> : null}</div>
      </div>
    </div>
  );
};

export default Filters;
