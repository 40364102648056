import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { emptyEmployee } from "./constants";

const OpeningForm = ({
  // Variables
  catalogs,
  handleDropdownChange,
  openingSelected,
  shifts,
  selectedShift,
}) => {
  const [employeeListForOthers] = useState([
    { ...emptyEmployee },
    ...catalogs.driverAssistants,
  ]);

  const itemTemplate = ({
    idFortiaEmployee,
    employeeName,
    employeeLastName,
  }) => (
    <p>{`${
      idFortiaEmployee === null ? "" : idFortiaEmployee
    } - ${employeeName} ${employeeLastName}`}</p>
  );

  const valueTemplate = (option) => {
    const { idFortiaEmployee, employeeName, employeeLastName } = option || {};
    return option !== null ? (
      <p>{`${idFortiaEmployee} - ${employeeName} ${employeeLastName}`}</p>
    ) : (
      <span>Seleciona...</span>
    );
  };

  const listToShowForThird = employeeListForOthers.filter(
    (employee) =>
      employee.idFortiaEmployee !== openingSelected.idFortiaDriver &&
      employee.idFortiaEmployee !== openingSelected.idFortiaAssistant
  );

  return (
    <>
      <p className="is-size-5">Nueva Apertura</p>
      <div className="p-fluid p-formgrid p-grid mt-3">
        <div className="p-field p-col">
          <label htmlFor="car">Seleccione un vehículo:</label>
          <InputText
            optionLabel="name"
            placeholder="Vehículo"
            value={openingSelected.vehicleName}
            disabled
          />
        </div>
        <div className="p-field p-col">
          <label htmlFor="driver">Seleccione un chofer:</label>
          <Dropdown
            id="idFortiaDriver"
            value={openingSelected.idFortiaDriver}
            options={catalogs.drivers}
            onChange={handleDropdownChange}
            placeholder="Selecciona..."
            optionValue="idFortiaEmployee"
            itemTemplate={itemTemplate}
            valueTemplate={valueTemplate}
            optionLabel="employeeName"
          />
        </div>
        <div className="p-field p-col">
          <label htmlFor="driverAssistant">Seleccione un ayudante:</label>
          <Dropdown
            id="idFortiaAssistant"
            placeholder="Selecciona..."
            value={openingSelected.idFortiaAssistant}
            options={[{ ...emptyEmployee }, ...catalogs.driverAssistants]}
            onChange={handleDropdownChange}
            optionValue="idFortiaEmployee"
            itemTemplate={itemTemplate}
            valueTemplate={valueTemplate}
            optionLabel="employeeName"
          />
        </div>
        <div className="p-field p-col">
          <label htmlFor="third">Seleccione un tercero:</label>
          <Dropdown
            id="idFortiaThird"
            placeholder="Selecciona..."
            value={openingSelected.idFortiaThird}
            options={[{ ...emptyEmployee }, ...catalogs.driverAssistants]}
            onChange={handleDropdownChange}
            optionValue="idFortiaEmployee"
            itemTemplate={itemTemplate}
            valueTemplate={valueTemplate}
            optionLabel="employeeName"
          />
        </div>
        <div className="p-field p-col">
          <label htmlFor="turno">Seleccione el turno:</label>
          <Dropdown
            value={selectedShift}
            options={shifts}
            optionValue="id"
            optionLabel="shiftName"
            disabled
            placeholder="Turno"
          />
        </div>
        <div className="p-field p-col">
          <label htmlFor="zone">Seleccione zona:</label>
          <Dropdown
            id="idZone"
            placeholder="Selecciona..."
            value={openingSelected.idZone}
            options={catalogs.zones}
            onChange={handleDropdownChange}
            optionValue="id"
            optionLabel="zona"
          />
        </div>
      </div>
    </>
  );
};

export default OpeningForm;
