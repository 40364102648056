import React, { useState, useEffect, useRef } from 'react';
import { Container, Box } from 'react-bulma-components';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import BonoConceptsGrid from './BonoConceptsGrid';
import MainLayout from '../Layouts/MainLayout';
import BonoConceptsService from '../../api/BonoConceptsService';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';

const BonoConceptsManagement = () => {
    const [bonoConcept, setBonoConcept] = useState(null);
    const [bonoConcepts, setBonoConcepts] = useState([]);

    const [deleteBonoConceptDialog, setDeleteBonoConceptDialog] = useState(false);
    const [originalRows, setOriginalRows] = useState({});
    const [editingRows, setEditingRows] = useState({});
    const [deleteRowButtonEnabled, setDeleteRowButtonEnabled] = useState(true);

    const toast = useRef(null);
    const bonoConceptNameRef = useRef();
 
    async function fetchBonoConcepts() {
        try {
            const response = await BonoConceptsService.getBonoConcepts();
            const { data } = response;
            setBonoConcepts(data.result);
        } catch (error) {
            const { data } = error.response;
            toast.current.show({
                severity: 'error',
                summary: `${data.message}`,
            });
            setBonoConcepts([]);
        }
    }

    useEffect(() => {
        fetchBonoConcepts();
    }, []);

    // MÉTODOS
    const onRowEditInit = (event) => {
        const bonoConceptAux = { ...bonoConcepts[event.index] };
        const originalRowsAux = { ...originalRows };
        originalRowsAux[event.index] = bonoConceptAux;
        setOriginalRows(originalRowsAux);
        setDeleteRowButtonEnabled(false);
    };

    const onRowEditChange = (event) => {
        setEditingRows(event.data);
    };

    const hideDeleteBonoConceptDialog = () => {
        setDeleteBonoConceptDialog(false);
    };

    const setActiveRowIndex = (index, bonoConceptsAux) => {
        const originalRowsAux = { ...originalRows };
        originalRowsAux[index] = { ...bonoConceptsAux[index - 1] };
        setEditingRows({
            ...editingRows,
            ...{ [`${bonoConceptsAux[index - 1].id}`]: true },
        });
    };

    const handleNewBonoConceptClick = () => {
        const bonoConceptsAux = [...bonoConcepts];
        bonoConceptsAux.push({
            id:null,
            newBonoConcept: true,
            name: '',
            description: ''
        });
        setBonoConcepts(bonoConceptsAux);
        setActiveRowIndex(bonoConceptsAux.length, bonoConceptsAux);
        if (deleteRowButtonEnabled) {
            setDeleteRowButtonEnabled(false);
        }
    };

    const handleValidation = (props) => {
        const rowData = props.data;
        let error = false;

        if (rowData.name.length === 0) {
            error = true;
            bonoConceptNameRef.current.required = true;
            bonoConceptNameRef.current.className = 'p-inputtext p-component p-invalid';
        }

        // Additional check for 'description' field
        if (!rowData.description || rowData.description.trim().length === 0) {
            error = true;
            toast.current.show({
                severity: 'error',
                summary: 'La descripción del concepto es requerida',
            });
        }

        return error;
    };

    const msgError = (error) => {
        const { data } = error.response;
        const msg = data.error.type === 'DataIntegrityViolationException' ?
            'El nombre que intenta registrar, ya existe.': data.message
        toast.current.show({
            severity: 'error',
            summary: `${msg}`,
        });
        fetchBonoConcepts()
    }

    const onRowEditSave = (props) => {
        const errors = handleValidation(props);

        if (!errors) {
            if (props.data.newBonoConcept) {
                BonoConceptsService.createBonoConcept(props.data)
                    .then((response) => {
                        const { data } = response;
                        toast.current.show({
                            severity: 'success',
                            summary: `${data.message}`,
                        });

                        BonoConceptsService.getBonoConcepts()
                            .then((r) => {
                                setBonoConcepts(r.data.result);
                            })
                            .catch();
                    })
                    .catch((error) => {
                        msgError(error)
                    });
            } else {
                BonoConceptsService.updateBonoConcept(props.data)
                    .then((response) => {
                        const { data } = response;
                        const bonoConceptsAux = [...bonoConcepts];
                        bonoConceptsAux[props.index] = data.result;
                        setBonoConcepts(bonoConceptsAux);

                        toast.current.show({
                            severity: 'success',
                            summary: `${data.message}`,
                        });
                    })
                    .catch((error) => {
                        msgError(error)
                    });
            }

            if (Object.values(editingRows).length === 1) {
                setEditingRows({});
                setDeleteRowButtonEnabled(true);
            }
        }
    };

    const deleteBonoConcept = () => {
        BonoConceptsService.deleteBonoConcept(bonoConcept.id)
            .then((response) => {
                const { data } = response;
                toast.current.show({
                    severity: 'success',
                    summary: `${data.message}`,
                });
            })
            .catch((error) => {
                const { data } = error.response;
                toast.current.show({
                    severity: 'error',
                    summary: `${data.message}`,
                });
            });

        const bonoConceptsAux = bonoConcepts.filter(
            (bonoConceptAux) => bonoConceptAux.id !== bonoConcept.id
        );
        setBonoConcepts(bonoConceptsAux);
        setDeleteBonoConceptDialog(false);
        setBonoConcept(null);
    };

    const onRowEditCancel = (event) => {
        const bonoConceptsAux = [...bonoConcepts];
        if (event.data.newBonoConcept) {
            bonoConceptsAux.pop();
            setBonoConcepts(bonoConceptsAux);
            setDeleteRowButtonEnabled(true);
        } else {
            bonoConceptsAux[event.index] = { ...originalRows[event.index] };
            const newRows = { ...originalRows };
            delete newRows[event.index];
            setOriginalRows(newRows);
            setBonoConcepts(bonoConceptsAux);
        }

        if (Object.values(editingRows).length === 1) {
            setDeleteRowButtonEnabled(true);
        }
    };

    const onEditorValueChange = (props, value) => {
        const updatedBonoConcepts = [...props.value];
        updatedBonoConcepts[props.rowIndex][props.field] = value;
        setBonoConcepts(updatedBonoConcepts);
    };

    const rowEditorValidator = (rowData) => rowData.name.trim().length !== 0;

    const disableNewButton = () =>
        !!bonoConcepts.find((_bonoConcept) => _bonoConcept.id === null);

    const confirmDeleteBonoConcept = (selectedBonoConcept) => {
        setBonoConcept(selectedBonoConcept);
        setDeleteBonoConceptDialog(true);
    };

    // COMPONENTS
    const deleteRowBody = (rowData) => (
        <>
            <Button
                icon="pi pi-trash"
                className="trash"
                disabled={!deleteRowButtonEnabled}
                onClick={() => confirmDeleteBonoConcept(rowData)}
            />
        </>
    );

    const deleteBonoConceptDialogFooter = (
        <>
            <Button
                label="No"
                icon="pi pi-times"
                className="p-button-text"
                onClick={hideDeleteBonoConceptDialog}
            />
            <Button
                label="Si"
                icon="pi pi-check"
                className="p-button-text"
                onClick={deleteBonoConcept}
            />
        </>
    );

    const inputTextEditor = (props, ref) => (
        <InputText
            type="text"
            value={props.rowData[props.field]}
            onChange={(e) => onEditorValueChange(props, e.target.value)}
            ref={ref}
            style={{ width: '100%' }}
        />
    );

    const bonoConceptNameEditor = (props, ref) => (
        <div>
            {inputTextEditor(props, ref)}
            {props.rowData[props.field].trim().length === 0 ? (
                <Message
                    severity="error"
                    text="El nombre del concepto es requerido"
                />
            ) : (
                ''
            )}
        </div>
    );

    const bonoConceptDescEditor = (props, ref) => (
        <div>
            {inputTextEditor(props, ref)}
            {props.rowData[props.field].trim().length === 0 ? (
                <Message
                    severity="error"
                    text="La descripción del concepto es requerida"
                />
            ) : (
                ''
            )}
        </div>
    );

    return (
        <MainLayout>
            <Box>
                <Container>
                    <Toast ref={toast} />
                    <BonoConceptsGrid
                        // VARIABLES
                        bonoConcept={bonoConcept}
                        bonoConcepts={bonoConcepts}
                        deleteRowButtonEnabled={deleteRowButtonEnabled}
                        editingRows={editingRows}
                        bonoConceptNameRef={bonoConceptNameRef}
                        deleteBonoConceptDialog={deleteBonoConceptDialog}
                        handleNewBonoConceptClick={handleNewBonoConceptClick}
                        // COMPONENTES
                        deleteRowBody={deleteRowBody}
                        bonoConceptNameEditor={bonoConceptNameEditor}
                        bonoConceptDescEditor={bonoConceptDescEditor}
                        deleteBonoConceptDialogFooter={deleteBonoConceptDialogFooter}
                        // MÉTODOS
                        onRowEditInit={onRowEditInit}
                        onRowEditChange={onRowEditChange}
                        onRowEditCancel={onRowEditCancel}
                        onRowEditSave={onRowEditSave}
                        rowEditorValidator={rowEditorValidator}
                        hideDeleteBonoConceptDialog={hideDeleteBonoConceptDialog}
                        disableNewButton={disableNewButton}
                    />
                </Container>
            </Box>
        </MainLayout>
    );
};

export default BonoConceptsManagement;
