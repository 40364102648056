import React from 'react';
import { Button } from 'primereact/button';

export const LastStepButton = ({ callback }) => {
    const style = {
        margin: '0 8px',
        background: '#F75C28',
        backgroundColor: '#F75C28',
        fontWeight: 'bold',
        border: 'none',
    };

    return (
        <Button style={style} disabled={false} onClick={() => callback()}>
            Anterior
        </Button>
    );
};

export const NextStepButton = ({ callback }) => {
    const style = {
        margin: '0 8px',
        background: '#2075F7',
        backgroundColor: '#2075F7',
        fontWeight: 'bold',
        border: 'none',
    };

    return (
        <Button
            style={style}
            type="primary"
            onClick={() => callback()}
            disabled={false}
        >
            Siguiente
        </Button>
    );
};

export const FinishButton = ({ callback }) => {
    const style = {
        margin: '0 8px',
        background: '#00B851',
        backgroundColor: '#00B851',
        fontWeight: 'bold',
        border: 'none',
    };

    return (
        <Button
            style={style}
            type="primary"
            onClick={() => callback()}
            disabled={false}
        >
            Finalizar
        </Button>
    );
};
