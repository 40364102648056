/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import React, { useState, useRef, useEffect } from "react";
import { Toast } from "primereact/toast";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { usePaymentSchema } from "../../../context/PaymentSchemaContext";
import TabulatorOtifService from "../../../api/TabulatorOtifService";
import TypeLitersService from "../../../api/TypeLitersService";
import { emptyCommissionDetail } from "../../../constants";
import "./Comisiones.css";
import { periodTypes } from "../constants";

const Comisiones = ({ tabulator, tabulatorCode, sectionCode, sectionName }) => {
  const [typeLiters, setTypeLiters] = useState([]);
  const {
    paymentPeriods,
    paymentSchemaSelected,
    setPaymentSchemaSelected,
    jobPositions,
    editing,
    editValuesOnly,
  } = usePaymentSchema();

  const toast = useRef(null);

  const { idJobPositionsFortia } = paymentSchemaSelected.tabs[tabulatorCode];

  const {
    rangeDetailsSchema,
    idRTabSectionsTabTypes,
    idPaymentPeriod,
    idEvaluation,
  } = paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode]
    ? paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode]
    : {};

  const filteredJobPositions = jobPositions.filter(({ work_station_id }) =>
    idJobPositionsFortia.find((id) => id === work_station_id)
  );

  let sectionFromTabulator;

  if (tabulator) {
    sectionFromTabulator = tabulator.sections.find(
      (sec) => sec.sectionCode === sectionCode
    );
  }

  const emptyRange = {
    id: null,
    commissionName: "",
    firstTypeName: "",
    secondTypeName: "",
    firstTypeCommission: null,
    secondTypeCommission: null,
    firstTypeLts: null,
    secondTypeLts: null,
    firstPBonus: null,
    secondPBonus: null,
  };

  let section = null;

  if (tabulator) {
    section = tabulator.sections.find((tab) => tab.sectionCode === sectionCode);
  }

  const { sections } = {
    ...paymentSchemaSelected.tabs[tabulatorCode],
  };

  const sectionInfo = {
    ...sections[sectionCode],
  };

  // METODOS

  const handleNewTypeClick = (work_station_id) => {
    const _paymentSchemaSelected = {
      ...paymentSchemaSelected,
    };

    _paymentSchemaSelected.tabs[tabulatorCode].sections[
      sectionCode
    ].rangeDetailsSchema = [
      ...rangeDetailsSchema,
      { ...emptyRange, jobPositionFortia: work_station_id },
    ];

    setPaymentSchemaSelected({
      ..._paymentSchemaSelected,
    });
  };

  const handleDeleteTypeClick = (id, jobPositionFortia) => {
    if (id) {
      TabulatorOtifService.deleteRangeDetailSchema(id)
        .then(() => {
          const _rangeDetailsSchema = [...rangeDetailsSchema];
          const _paymentSchemaSelected = {
            ...paymentSchemaSelected,
          };
          const index = _rangeDetailsSchema.findIndex(
            (range) =>
              range.id === id && range.jobPositionFortia === jobPositionFortia
          );

          _rangeDetailsSchema.splice(index, 1);

          _paymentSchemaSelected.tabs[tabulatorCode].sections[
            sectionCode
          ].rangeDetailsSchema = [..._rangeDetailsSchema];

          setPaymentSchemaSelected({
            ..._paymentSchemaSelected,
          });

          toast.current.show({
            severity: "success",
            summary: "Tipo Eliminado",
            detail: "El tipo se elimino correctamente",
            life: 3000,
          });
        })
        .catch(({ message }) => {
          toast.current.show({
            severity: "error",
            summary: message,
            life: 3000,
          });
        });
    } else {
      const _rangeDetailsSchema = [...rangeDetailsSchema];
      const _paymentSchemaSelected = {
        ...paymentSchemaSelected,
      };
      const index = _rangeDetailsSchema.findIndex(
        (range) =>
          range.id === id && range.jobPositionFortia === jobPositionFortia
      );

      _rangeDetailsSchema.splice(index, 1);

      _paymentSchemaSelected.tabs[tabulatorCode].sections[
        sectionCode
      ].rangeDetailsSchema = [..._rangeDetailsSchema];

      setPaymentSchemaSelected({
        ..._paymentSchemaSelected,
      });
    }
  };

  const handleInputTextChange = (e, id, jobPositionFortia) => {
    const _rangeDetailsSchema = [...rangeDetailsSchema];
    const _paymentSchemaSelected = {
      ...paymentSchemaSelected,
    };
    const index = _rangeDetailsSchema.findIndex(
      (range) =>
        range.id === id && range.jobPositionFortia === jobPositionFortia
    );

    _rangeDetailsSchema[index][e.target.id] = e.target.value;

    _paymentSchemaSelected.tabs[tabulatorCode].sections[
      sectionCode
    ].rangeDetailsSchema = [..._rangeDetailsSchema];

    setPaymentSchemaSelected({
      ..._paymentSchemaSelected,
    });
  };

  const handleInputNumberChange = (
    originalEvent,
    value,
    id,
    jobPositionFortia
  ) => {
    const _rangeDetailsSchema = [...rangeDetailsSchema];
    const _paymentSchemaSelected = {
      ...paymentSchemaSelected,
    };
    const index = _rangeDetailsSchema.findIndex(
      (range) =>
        range.id === id && range.jobPositionFortia === jobPositionFortia
    );

    _rangeDetailsSchema[index][originalEvent.target.id] = parseFloat(
      value || 0
    );

    _paymentSchemaSelected.tabs[tabulatorCode].sections[
      sectionCode
    ].rangeDetailsSchema = [..._rangeDetailsSchema];

    setPaymentSchemaSelected({
      ..._paymentSchemaSelected,
    });
  };

  const handleDropdownChange = (inputId, value, id, jobPositionFortia) => {
    const _rangeDetailsSchema = [...rangeDetailsSchema];
    const _paymentSchemaSelected = {
      ...paymentSchemaSelected,
    };
    const index = _rangeDetailsSchema.findIndex(
      (range) =>
        range.id === id && range.jobPositionFortia === jobPositionFortia
    );

    _rangeDetailsSchema[index][inputId] = value;

    _paymentSchemaSelected.tabs[tabulatorCode].sections[
      sectionCode
    ].rangeDetailsSchema = [..._rangeDetailsSchema];

    setPaymentSchemaSelected({
      ..._paymentSchemaSelected,
    });
  };

  const onFormInputChange = (e, field) => {
    const _paymentSchemaSelected = { ...paymentSchemaSelected };
    const _section = {
      ...paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode],
      [field]: e.value,
    };

    _paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode] = {
      ..._section,
    };

    setPaymentSchemaSelected(_paymentSchemaSelected);
  };

  const saveDetailRangeSchema = (range, id, jobPositionFortsia) => {
    const body = {
      ...range,
      idPaymentPeriod: sectionInfo.idPaymentPeriod,
      idTabulatorType: tabulator.id,
      idPaymentSchema: paymentSchemaSelected.id,
      idEvaluation: sectionInfo.idEvaluation,
      idRTabSectionsTabTypes: sectionFromTabulator
        ? sectionFromTabulator.idTabulatorSection
        : idRTabSectionsTabTypes,
      idJobPositionFortia: range.jobPositionFortia,
    };

    if (id) {
      TabulatorOtifService.updateRangeDetailSchema(body)
        .then((response) => {
          const _rangeDetailsSchema = [...rangeDetailsSchema];
          const _paymentSchemaSelected = {
            ...paymentSchemaSelected,
          };

          const index = _rangeDetailsSchema.findIndex(
            (aux) =>
              aux.id === id && aux.jobPositionFortia === jobPositionFortsia
          );

          if (id !== -1) {
            _rangeDetailsSchema[index].id = response.data.result.id;

            _paymentSchemaSelected.tabs[tabulatorCode].sections[
              sectionCode
            ].rangeDetailsSchema = [..._rangeDetailsSchema];

            setPaymentSchemaSelected({
              ..._paymentSchemaSelected,
            });
          }

          toast.current.show({
            severity: "success",
            summary: "Tipo Actualizado",
            detail: "El tipo se actualizo exitosamente",
            life: 3000,
          });
        })
        .catch(({ message }) => {
          toast.current.show({
            severity: "error",
            summary: "Error al crear",
            detail: message,
            life: 3000,
          });
        });
    } else {
      TabulatorOtifService.createRangeDetailSchema(body)
        .then((response) => {
          const _rangeDetailsSchema = [...rangeDetailsSchema];
          const _paymentSchemaSelected = {
            ...paymentSchemaSelected,
          };

          const index = _rangeDetailsSchema.findIndex(
            (aux) =>
              aux.id === id && aux.jobPositionFortia === jobPositionFortsia
          );

          if (id !== -1) {
            _rangeDetailsSchema[index].id = response.data.result.id;

            _paymentSchemaSelected.tabs[tabulatorCode].sections[
              sectionCode
            ].rangeDetailsSchema = [..._rangeDetailsSchema];

            setPaymentSchemaSelected({
              ..._paymentSchemaSelected,
            });
          }

          toast.current.show({
            severity: "success",
            summary: "Tipo Creado",
            detail: "El nuevo tipo se creo exitosamente",
            life: 3000,
          });
        })
        .catch(
          ({
            response: {
              data: { message },
            },
          }) => {
            toast.current.show({
              severity: "error",
              summary: "Error al crear",
              detail: message,
              life: 3000,
            });
          }
        );
    }
  };

  let paymentType;
  let evaluationType;

  if (editValuesOnly) {
    paymentType = paymentPeriods.find(({ id }) => id === idPaymentPeriod);
    evaluationType = paymentPeriods.find(({ id }) => id === idEvaluation);
  }

  useEffect(() => {
    TypeLitersService.getTypeLiters()
      .then((response) => {
        setTypeLiters(response.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (!paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode]) {
      const findSectionInTabulator = tabulator.sections.find(
        (sec) => sec.sectionCode === sectionCode
      );
      const sectionToAdd = {
        ...findSectionInTabulator,
        ...emptyCommissionDetail,
      };
      const paymentSchemaSelectedAux = { ...paymentSchemaSelected };
      paymentSchemaSelectedAux.tabs[tabulatorCode].sections[sectionCode] = {
        ...sectionToAdd,
      };
      setPaymentSchemaSelected({ ...paymentSchemaSelectedAux });
    } else if (typeof rangeDetailsSchema !== "object") {
      const paymentSchemaSelectedAux = { ...paymentSchemaSelected };
      paymentSchemaSelectedAux.tabs[tabulatorCode].sections[
        sectionCode
      ].rangeDetailsSchema = [];
      setPaymentSchemaSelected({ ...paymentSchemaSelectedAux });
    }
  }, []);

  const addSectionWithPaymentPeriod = () => {
    console.log(
      periodTypes,
      paymentSchemaSelected,
      paymentSchemaSelected.period_name
    );
    const option = periodTypes.find(
      (period) => period.value === paymentSchemaSelected.period_name
    );
    const _paymentSchemaSelected = { ...paymentSchemaSelected };
    const aux = {
      ...paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode],
    };
    _paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode] = {
      ...aux,
      idPaymentPeriod: option?.id,
      idEvaluation: option?.id,
    };

    setPaymentSchemaSelected(_paymentSchemaSelected);
  };

  useEffect(() => {
    addSectionWithPaymentPeriod();
  }, []);

  return (
    <>
      <Toast ref={toast} />
      <div className="commisions-container">
        {filteredJobPositions.map(({ work_station_name, work_station_id }) => {
          if (rangeDetailsSchema) {
            const ranges = rangeDetailsSchema.filter(
              ({ jobPositionFortia }) => jobPositionFortia === work_station_id
            );

            const filteredTypeLiters = typeLiters.map((type) => {
              const newType = { ...type };
              if (
                ranges.find(
                  ({ firstTypeName, secondTypeName }) =>
                    firstTypeName === type.name || secondTypeName === type.name
                )
              ) {
                newType.disabled = true;
              }

              return newType;
            });

            return (
              <div className="comission-row">
                <div className="commission-job-position">
                  <h1>{work_station_name}</h1>
                </div>
                {ranges.map((range) => (
                  <table className="comission-form">
                    <tr className="commision-form-row">
                      <th
                        colSpan="2"
                        style={{
                          padding: "0 !important",
                        }}
                      >
                        <input
                          id="commissionName"
                          value={range.commissionName}
                          className="input"
                          type="text"
                          placeholder="Nombre"
                          onChange={(e) =>
                            handleInputTextChange(
                              e,
                              range.id,
                              range.jobPositionFortia
                            )
                          }
                        />
                      </th>
                      <td
                        colSpan="3"
                        style={{
                          padding: "0 !important",
                        }}
                      >
                        <button
                          type="button"
                          className="button is-danger ml-3"
                          onClick={() =>
                            handleDeleteTypeClick(
                              range.id,
                              range.jobPositionFortia
                            )
                          }
                          disabled={editValuesOnly}
                        >
                          <span className="icon">
                            <i className="fas fa-trash has-text-white" />
                          </span>
                        </button>
                      </td>
                    </tr>
                    <tr className="commision-form-row">
                      <th
                        style={{
                          padding: "0 !important",
                        }}
                      >
                        Tipo
                      </th>
                      <th
                        style={{
                          padding: "0 !important",
                        }}
                      >
                        <Dropdown
                          id="firstTypeName"
                          inputId="firstTypeName"
                          options={filteredTypeLiters}
                          optionLabel="name"
                          optionValue="name"
                          placeholder="Tipo 1"
                          onChange={({ value }) =>
                            handleDropdownChange(
                              "firstTypeName",
                              value,
                              range.id,
                              range.jobPositionFortia
                            )
                          }
                          value={range.firstTypeName}
                        />
                      </th>
                      <th
                        style={{
                          padding: "0 !important",
                        }}
                      >
                        <Dropdown
                          id="secondTypeName"
                          inputId="secondTypeName"
                          options={filteredTypeLiters}
                          optionLabel="name"
                          optionValue="name"
                          placeholder="Tipo 2"
                          onChange={({ value }) =>
                            handleDropdownChange(
                              "secondTypeName",
                              value,
                              range.id,
                              range.jobPositionFortia
                            )
                          }
                          value={range.secondTypeName}
                        />
                      </th>
                    </tr>

                    <tr className="commision-form-row">
                      <th
                        style={{
                          padding: "0 !important",
                        }}
                      >
                        Comision
                      </th>
                      <td
                        style={{
                          padding: "0 !important",
                        }}
                      >
                        <InputNumber
                          id="firstTypeCommission"
                          inputId="firstTypeCommission"
                          value={range.firstTypeCommission}
                          mode="currency"
                          currency="MXN"
                          locale="es-MX"
                          minFractionDigits={4}
                          onChange={({ originalEvent, value }) =>
                            handleInputNumberChange(
                              originalEvent,
                              value,
                              range.id,
                              range.jobPositionFortia
                            )
                          }
                          placeholder="Comisión"
                        />
                      </td>
                      <td
                        style={{
                          padding: "0 !important",
                        }}
                      >
                        <InputNumber
                          id="secondTypeCommission"
                          inputId="secondTypeCommission"
                          value={range.secondTypeCommission}
                          mode="currency"
                          currency="MXN"
                          locale="es-MX"
                          minFractionDigits={4}
                          onChange={({ originalEvent, value }) =>
                            handleInputNumberChange(
                              originalEvent,
                              value,
                              range.id,
                              range.jobPositionFortia
                            )
                          }
                          placeholder="Comisión"
                        />
                      </td>
                    </tr>
                    <tr className="commision-form-row">
                      <th
                        style={{
                          padding: "0 !important",
                        }}
                      >
                        P/Bono
                      </th>
                      <td
                        style={{
                          padding: "0 !important",
                        }}
                      >
                        <InputNumber
                          id="firstPBonus"
                          inputId="firstPBonus"
                          value={range.firstPBonus}
                          mode = "decimal"
                          minFractionDigits={4}
                          prefix = "%"
                          onChange={({ originalEvent, value }) =>
                            handleInputNumberChange(
                              originalEvent,
                              value,
                              range.id,
                              range.jobPositionFortia
                            )
                          }
                          placeholder="Bono"
                        />
                      </td>
                      <td
                        style={{
                          padding: "0 !important",
                        }}
                      >
                        <InputNumber
                          id="secondPBonus"
                          inputId="secondPBonus"
                          value={range.secondPBonus}
                          mode = "decimal"
                          minFractionDigits={4}
                          prefix = "%"
                          onChange={({ originalEvent, value }) =>
                            handleInputNumberChange(
                              originalEvent,
                              value,
                              range.id,
                              range.jobPositionFortia
                            )
                          }
                          placeholder="Bono"
                        />
                      </td>
                    </tr>
                    <tr className="commision-form-row">
                      <th
                        style={{
                          padding: "0 !important",
                        }}
                      >
                        Lts/M
                      </th>
                      <td
                        style={{
                          padding: "0 !important",
                        }}
                      >
                        <InputNumber
                          id="firstTypeLts"
                          inputId="firstTypeLts"
                          value={range.firstTypeLts}
                          mode="decimal"
                          // onBlur={handleOnBlur}
                          onChange={({ originalEvent, value }) =>
                            handleInputNumberChange(
                              originalEvent,
                              value,
                              range.id,
                              range.jobPositionFortia
                            )
                          }
                          placeholder="Litros"
                        />
                      </td>
                      <td
                        style={{
                          padding: "0 !important",
                        }}
                      >
                        <InputNumber
                          id="secondTypeLts"
                          inputId="secondTypeLts"
                          value={range.secondTypeLts}
                          mode="decimal"
                          // onBlur={handleOnBlur}
                          onChange={({ originalEvent, value }) =>
                            handleInputNumberChange(
                              originalEvent,
                              value,
                              range.id,
                              range.jobPositionFortia
                            )
                          }
                          placeholder="Litros"
                        />
                      </td>
                    </tr>
                    <tr colSpan="3" className="commision-form-row">
                      <button
                        type="button"
                        className="button is-success mt-2"
                        onClick={() =>
                          saveDetailRangeSchema(
                            range,
                            range.id,
                            range.jobPositionFortia
                          )
                        }
                      >
                        Guardar
                      </button>
                    </tr>
                  </table>
                ))}
                <div>
                  <button
                    className="button is-link"
                    type="button"
                    onClick={() => handleNewTypeClick(work_station_id)}
                    disabled={
                      ranges.find(({ id }) => id === null) ||
                      (ranges.length === 0 && editing) ||
                      editValuesOnly ||
                      editing
                    }
                  >
                    +
                  </button>
                </div>
              </div>
            );
          }
        })}
      </div>
    </>
  );
};

export default Comisiones;
