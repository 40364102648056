import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from './router/routes';
import './App.css';
import 'bulma/css/bulma.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

function App() {
    return (
        <>
            <BrowserRouter>
                <Router />
            </BrowserRouter>
        </>
    );
}

export default App;
