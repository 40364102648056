import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import CatalogService from "../../api/CatalogsService";
import PaymentSchemaDialog from "./PaymentSchemaDialog";
import ConfirmDeleteSchema from "./ConfirmDeleteSchema";
import EditPaymentSchemaDialog from "./EditPaymentSchemaDialog";
import ModifyPaymentSchemaDialog from "./ModifyPaymentSchemaDialog";
import TabulatorOtifService from "../../api/TabulatorOtifService";
import { usePaymentSchema } from "../../context/PaymentSchemaContext";
import { emptyPaymentSchema, API_URL_TABULATORS } from "../../constants";

const SchemasGrid = () => {
  const [schemas, setSchemas] = useState([]);
  const [newSchema, setNewSchema] = useState(null);
  const [schemaDialog, setSchemaDialog] = useState(false);

  const [editSchemaDialog, setEditSchemaDialog] = useState(false);
  const [modifySchemaDialog, setModifySchemaDialog] = useState(false);

  const [loading, setLoading] = useState(true);
  const [deleteSchemaDialog, setDeleteSchemaDialog] = useState(false);
  const [schemaSelected, setSchemaSelected] = useState(null);
  const [pendingSchema, setPendingSchema] = useState([]);

  const {
    paymentSchemaSelected,
    setPaymentSchemaSelected,
    setEditValuesOnly,
    setPaymentSchemaLocation,
  } = usePaymentSchema();

  const toast = useRef(null);

  const emptySchema = {
    newSchema: true,
    schemaName: "",
    file: null,
    validity: null,
  };

  // Métodos
  const fetchSchemas = async () => {
    CatalogService.getPaymentSchemas()
      .then(({ data: { result } }) => {
        setSchemas(result);
      })
      .catch(
        ({
          response: {
            data: {
              message,
              error: { errorCode },
            },
          },
        }) => {
          if (errorCode !== "PAYMENTSCHEMAS001") {
            toast.current.show([
              {
                severity: "error",
                summary: message,
                life: 3000,
              },
            ]);
          } else if (errorCode === "PAYMENTSCHEMAS001") {
            setSchemas([]);
          }
        }
      )
      .catch((error) => {
        console.log(error);
      })
      .then(() => {
        setLoading(false);
      });
  };

  const addNewSchema = () => {
    const jsonToString = JSON.stringify({ ...emptyPaymentSchema });
    setPaymentSchemaSelected(JSON.parse(jsonToString));
    const schemaToString =  JSON.stringify({ ...emptySchema })
    setNewSchema(JSON.parse(schemaToString));
    setSchemaDialog(true);
  };

  const modifySchema = (rowData) => {
    TabulatorOtifService.getPaymentSchema(rowData.id)
      .then(({ data }) => {
        const { message, status, result } = data;

        setNewSchema({
          id: result.id,
          newSchema: false,
          schemaName: result.schemaName,
          file: result.file,
          validity: result.validity,
          idPlazaFortia: result.idPlazaFortia,
          completed: result.completed,
          period_name: result.period_name,
        });

        // Distribuir los tabs correctamente en el esquema de pagos
        const tabs = [...result.tabs];
        let newTabs = {};

        tabs.forEach((tab) => {
          newTabs = { ...newTabs, [tab.tabulatorCode]: tab };
        });

        result.tabs = newTabs;

        setPaymentSchemaSelected({ ...result });

        toast.current.show([
          {
            severity: "success",
            summary: status,
            detail: message,
            life: 3000,
          },
        ]);
      })
      .catch(({ response }) => {
        const { message, status } = response.data;
        toast.current.show([
          {
            severity: "error",
            summary: status,
            detail: message,
            life: 3000,
          },
        ]);
      });

    setPaymentSchemaLocation(rowData.plaza);
    setModifySchemaDialog(true);
  };

  const hideSchemaDialog = () => {
    setSchemaDialog(false);
  };

  const hideEditSchemaDialog = () => {
    setEditSchemaDialog(false);
  };

  const hideModifySchemaDialog = () => setModifySchemaDialog(false);

  // Componentes
  const header = (
    <div
      className="table-header"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <button
        type="button"
        className="button is-link mt-3 primary-color"
        onClick={addNewSchema}
      >
        Agregar esquema de pago
      </button>
    </div>
  );

  const plazaBody = (rowData) => <p>{rowData.plaza.plazaName}</p>;

  const fileBody = (rowData) => {
    if (rowData.file && rowData.file.fileName) {
      const fileUrl = `${API_URL_TABULATORS}/file/download/${rowData.file.fileName}`;
      return (
        <Button
          label="Descargar"
          icon="pi pi-file"
          className="p-button-primary primary-color"
          onClick={() => window.open(fileUrl)}
        />
      );
    }
  };

  const hideDeleteSchemaDialog = () => {
    setPendingSchema(null);
    setDeleteSchemaDialog(false);
  };

  const deleteSchema = () => {
    TabulatorOtifService.deletePaymentSchema(schemaSelected.id)
      .then((response) => {
        fetchSchemas();
        setPendingSchema(null);

        toast.current.show({
          severity: "success",
          summary: `${response.data.message}`,
        });
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: `${error.response.data.message}`,
        });
      });

    setDeleteSchemaDialog(false);
    setSchemaSelected(null);
  };

  const confirmDeleteSchema = (selectedSchema) => {
    setSchemaSelected(selectedSchema);
    TabulatorOtifService.getPaymentPending(selectedSchema.id)
      .then((response) => {
        if (response.data.result) {
          setPendingSchema([response.data.result]);
        }

        setDeleteSchemaDialog(true);
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: `${error.response.data.message}`,
        });
      });
  };

  const deleteSchemaDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteSchemaDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteSchema}
      />
    </>
  );

  const actionBodyTemplate = (rowData) => (
    <>
      <Button
        icon="pi pi-pencil"
        className="p-mr-2 pencil"
        onClick={() => modifySchema(rowData)}
      />
      <Button
        icon="pi pi-trash"
        className="trash"
        onClick={() => confirmDeleteSchema(rowData)}
      />
    </>
  );

  useEffect(() => {
    setEditValuesOnly(false);
  }, []);

  useEffect(() => {
    fetchSchemas();
  }, []);

  return (
    <>
      <div className="container px-3">
        <Toast ref={toast} />
        <p className="is-size-3 gray-strench">Control de esquemas de pago</p>
        <p className="gray-light">
          Aquí puedes visualizar, agregar, editar y eliminar esquemas de pago de
          la plataforma.
        </p>
        <div className="card pt-3">
          <DataTable
            value={schemas}
            dataKey="id"
            header={header}
            loading={loading}
          >
            <Column selectionMode="multiple" headerStyle={{ width: "3rem" }} />
            <Column
              field="id"
              header="ID"
              sortable
              headerStyle={{ width: "5rem" }}
            />
            <Column header="Nombre de Esquema" field="schemaName" />
            <Column header="Plaza" body={plazaBody} />
            <Column
              header="Periodo de pago"
              field="period_name"
              style={{ textTransform: "capitalize" }}
            />
            <Column header="Fecha de Creacion" field="createdAt" />
            <Column header="Vigencia" field="validity" />
            <Column header="Estatus" field="status" />
            <Column
              header="Archivo"
              // field="file"
              body={fileBody}
            />
            <Column body={actionBodyTemplate} />
          </DataTable>
        </div>
      </div>
      <Dialog
        visible={schemaDialog}
        maximized
        modal
        className="p-fluid"
        onHide={hideSchemaDialog}
      >
        <PaymentSchemaDialog
          schema={newSchema}
          setSchema={setNewSchema}
          fetchSchemas={fetchSchemas}
          hideDeleteSchemaDialog={hideDeleteSchemaDialog}
          setSchemaDialog={setSchemaDialog}
        />
      </Dialog>

      {/**
       *
       *  Eliminar este dialogo al terminar los cambios
       *
       */}

      <Dialog
        visible={editSchemaDialog}
        maximized
        modal
        className="p-fluid"
        onHide={hideEditSchemaDialog}
      >
        <EditPaymentSchemaDialog />
      </Dialog>

      {/**
       *
       *  Nuevo Dialogo para Editar el Esquema de Pago
       *
       */}

      <Dialog
        visible={modifySchemaDialog}
        maximized
        modal
        className="p-fluid"
        onHide={hideModifySchemaDialog}
      >
        <ModifyPaymentSchemaDialog
          // Estos props se utilizan modal para crear el esquema de pago
          schema={newSchema || {}}
          setSchema={setNewSchema}
          fetchSchemas={fetchSchemas}
          hideDeleteSchemaDialog={hideDeleteSchemaDialog}
          setSchemaDialog={() => {
            setModifySchemaDialog(false);
          }}
        />
      </Dialog>

      {/**
       *
       *  Modal para confirmar eliminar un esquema de pago
       *
       */}

      <ConfirmDeleteSchema
        deleteSchemaDialog={deleteSchemaDialog}
        deleteSchemaDialogFooter={deleteSchemaDialogFooter}
        hideDeleteSchemaDialog={hideDeleteSchemaDialog}
        schemaSelected={schemaSelected}
        pendingSchema={pendingSchema}
        plazaBody={plazaBody}
      />
    </>
  );
};

export default SchemasGrid;
