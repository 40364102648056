import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const BaseSalaryGrid = ({
  // VARIABLES
  locations,
  loading,

  // FUNCTIONS
  onRowEditInit,
  onRowEditCancel,
  onRowEditSave,

  // COMPONENTS
  salaryEditor,
  salaryBodyTemplate,
  header,
  numberEditor,
}) => (
  <div className="container px-3">
    <p className="is-size-3">Control de Salario Base por Plaza</p>
    <p>
      Aquí puedes visualizar, crear, editar y eliminar los salarios base de cada
      plaza.
    </p>
    <div className="card pt-3">
      <DataTable
        header={header()}
        value={locations}
        editMode="row"
        className="p-datatable-sm"
        responsiveLayout="scroll"
        loading={loading}
        scrollable
        onRowEditInit={onRowEditInit}
        onRowEditCancel={onRowEditCancel}
        onRowEditSave={onRowEditSave}
        paginator
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        rows={10}
        rowsPerPageOptions={[10, 20, 50]}
      >
        <Column field="plazaName" header="Plaza" />
        <Column
          field="baseSalaryCho"
          header="Chofer"
          editor={salaryEditor}
          body={(rowData) => salaryBodyTemplate(rowData["baseSalaryCho"])}
        />
        <Column
          field="baseSalaryAyu"
          editor={salaryEditor}
          header="Ayudante"
          body={(rowData) => salaryBodyTemplate(rowData["baseSalaryAyu"])}
        />
        <Column
          field="guaranteeSalaryCho"
          editor={salaryEditor}
          header="Chofer"
          body={(rowData) => salaryBodyTemplate(rowData["guaranteeSalaryCho"])}
        />
        <Column
          field="guaranteeSalaryAyu"
          editor={salaryEditor}
          header="Ayudante"
          body={(rowData) => salaryBodyTemplate(rowData["guaranteeSalaryAyu"])}
        />
        <Column
          field="guaranteeSalaryValidity"
          editor={numberEditor}
          header="Vigencia"
        />
        <Column
          field="plantSalary"
          editor={salaryEditor}
          header="Chofer-Ayudante"
          body={(rowData) => salaryBodyTemplate(rowData["plantSalary"])}
        />
        <Column
          field="minimumWage"
          editor={salaryEditor}
          header="Chofer-Ayudante"
          body={(rowData) => salaryBodyTemplate(rowData["minimumWage"])}
        />
        <Column field="createdBy" header="Creado por" />
        <Column field="updatedBy" header="Actualizado por" />
        <Column
          rowEditor
          headerStyle={{ width: "7rem" }}
          bodyStyle={{ textAlign: "center" }}
        />
      </DataTable>
    </div>
  </div>
);

export default BaseSalaryGrid;
