export const API_URL_USERS = process.env.REACT_APP_URL_USER;
export const API_URL_CONCEPTS = process.env.REACT_APP_URL_CONCEPTS;
export const API_URL_TABULATORS = process.env.REACT_APP_URL_TABULATORS;
export const API_URL_OPENINGS = process.env.REACT_APP_URL_OPENINGS;
export const API_URL_PRENOMINA = process.env.REACT_APP_URL_PRENOMINA;
export const API_URL_SALES = process.env.REACT_APP_URL_SALES;
export const IMG_DIRECTORY = "./../../css/img/ema.png";
// Tiempo en segundos
export const IDLE_TIMER_INACTIVITY_PERIOD = 300;
export const SESSION_TIMEOUT_IDLE_WINDOW_PERIOD = 600;
export const PASSWORD_PATTERN_REGEXP =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!*"#$%&/()=?¡_.,;:¿])[A-Za-z\d!*"#$%&/()=?¡_.,;:¿]{8,30}$/;
export const EMAIL_PATTERN_REGEXP = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
export const RFC_PATTERN_REGEXP =
  /^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/i;

export const locations = [
  { name: "Guadalajara", code: "Guadalajara" },
  { name: "Tijuana", code: "Tijuana" },
  { name: "Mazatlan", code: "Mazatlan" },
  { name: "Puerto Vallarta", code: "Puerto Vallarta" },
  { name: "Sinaloa", code: "Sinaloa" },
  { name: "Monterrey", code: "Monterrey" },
  { name: "La Paz", code: "La Paz" },
];

export const abecedary = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

export const emptyTabulator = {
  tabulatorName: "Tabulador Portátil",
  tabulatorCode: "tabPortatil",
  idJobPositionsFortia: [],
  biweeklySalary: null,
  idCommissionType: null,
  sections: {},
};

export const emptyLitersSold = {
  idPaymentPeriod: null,
  idEvaluation: null,
  idRTabSectionsTabTypes: null,
  productivityRangeType: null,
  idSection: null,
  sectionCode: null,
  sectionName: null,
  litersSoldSchema: [],
};

export const emptyCommissionDetail = {
  idPaymentPeriod: null,
  idEvaluation: null,
  idRTabSectionsTabTypes: null,
  productivityRangeType: null,
  idSection: null,
  sectionCode: null,
  sectionName: null,
  rangeDetailsSchema: [],
};

export const emptyPonderacionDetail = {
  idPaymentPeriod: null,
  idEvaluation: null,
  idRTabSectionsTabTypes: null,
  productivityRangeType: null,
  idSection: null,
  sectionCode: null,
  sectionName: null,
  rangeDetailsSchema: [],
};

export const emptyPaymentSchema = {
  id: null,
  idPlaza: null,
  idPlazaFortia: null,
  schemaName: null,
  period_name: null,
  validity: null,
  file: null,
  tabs: {},
};

export const emptyShift = {
  id: null,
  shiftName: "",
  shiftType: "Matutino",
  startTime: "00:00:00",
  endTime: "00:00:00",
  commission: true,
};

export const shiftTypeOptions = [
  { id: 0, name: "Matutino", value: "Matutino" },
  { id: 1, name: "Vespertino", value: "Vespertino" },
  { id: 2, name: "Nocturno", value: "Nocturno" },
];

export const commissionOptions = [
  { id: 0, name: "Comision", value: true },
  { id: 1, name: "Fijo", value: false },
];
