import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";

const UsersGrid = ({
  // Valores
  users,
  user,
  editingRows,
  buttonDisabled,
  usernameRef,
  passwordRef,
  emailRef,
  activeRef,
  rfcRef,
  rolRef,
  showHiddenPasswordColumn,
  // Métodos
  handleNewUserClick,
  onRowEditInit,
  onRowEditChange,
  onRowEditCancel,
  onRowEditSave,
  rowEditorValidator,
  hideDeleteUserDialog,
  // Componentes
  deleteBody,
  statusBodyTemplate,
  rolesBodyTemplate,
  passwordBodyTemplate,
  deleteUserDialog,
  deleteUserDialogFooter,
  inputTextEditor,
  statusEditor,
  rfcEditor,
  usernameEditor,
  passwordEditor,
  rolesEditor,
}) => (
  <>
    <div className="container px-3">
      <p className="is-size-3 gray-strench">Control de Usuarios</p>
      <p className="gray-light">
        Aquí puedes visualizar, agregar, editar y eliminar usuarios de la
        plataforma.
      </p>
      <button
        type="button"
        className="button is-link mt-3 primary-color"
        onClick={handleNewUserClick}
        disabled={buttonDisabled}
      >
        Agregar usuario
      </button>
      <div className="card pt-3">
        <DataTable
          value={users}
          editMode="row"
          dataKey="id"
          editingRows={editingRows}
          onRowEditChange={onRowEditChange}
          onRowEditInit={onRowEditInit}
          onRowEditCancel={onRowEditCancel}
          onRowEditSave={onRowEditSave}
          rowEditorValidator={rowEditorValidator}
          className="p-datatable-sm"
          scrollable
        >
          <Column field="id" header="ID" style={{ width: "40px" }} />
          <Column
            field="username"
            header="Usuario"
            editor={(props) => usernameEditor(props, usernameRef)}
            style={{ width: "150px" }}
          />
          <Column
            field="password"
            header="Contraseña"
            body={passwordBodyTemplate}
            editor={(props) => passwordEditor(props, passwordRef)}
            style={{
              width: "200px",
              display: showHiddenPasswordColumn ? " " : "none",
            }}
          />
          <Column
            field="active"
            header="Estatus"
            body={statusBodyTemplate}
            editor={(props) => statusEditor(props, activeRef)}
            style={{ width: "150px" }}
          />
          <Column
            field="rfc"
            header="RFC"
            editor={(props) => rfcEditor(props, rfcRef)}
            style={{ width: "150px" }}
          />
          <Column
            field="firstname"
            header="Nombre"
            editor={(props) => inputTextEditor(props)}
            style={{ width: "200px" }}
          />
          <Column
            field="lastname"
            header="Apellido"
            editor={(props) => inputTextEditor(props)}
            style={{ width: "200px" }}
          />
          <Column
            field="locationFortiaName"
            header="Plaza"
            style={{ width: "120px" }}
          />
          <Column
            field="email"
            header="Correo"
            editor={(props) => inputTextEditor(props, emailRef)}
            style={{ width: "300px" }}
          />
          <Column
            field="telephone"
            header="Teléfono"
            editor={(props) => inputTextEditor(props)}
            style={{ width: "150px" }}
          />
          <Column
            field="birthdate"
            header="Fecha de nacimiento"
            editor={(props) => inputTextEditor(props)}
            style={{ width: "120px" }}
          />
          <Column
            field="address"
            header="Domicilio"
            editor={(props) => inputTextEditor(props)}
            style={{ width: "300px" }}
          />
          <Column
            field="roles"
            header="Rol"
            body={rolesBodyTemplate}
            editor={(props) => rolesEditor(props, rolRef)}
            style={{ width: "150px" }}
          />
          {/* EDIT ICON */}
          <Column
            rowEditor
            header="Editar"
            headerStyle={{ width: "7rem " }}
            bodyStyle={{ textAlign: "left" }}
            style={{ width: "150px" }}
          />
          <Column
            header="Eliminar"
            body={deleteBody}
            bodyStyle={{ textAlign: "left" }}
            style={{ width: "100px" }}
          />
        </DataTable>
      </div>
    </div>
    <Dialog
      visible={deleteUserDialog}
      style={{ width: "450px" }}
      header="Confirm"
      modal
      footer={deleteUserDialogFooter}
      onHide={hideDeleteUserDialog}
    >
      <div className="confirmation-content">
        <i
          className="pi pi-exclamation-triangle p-mr-3"
          style={{ fontSize: "2rem" }}
        />
        {user && (
          <span>
            ¿Desear eliminar el usuario <b>{user.username}</b>?
          </span>
        )}
      </div>
    </Dialog>
  </>
);

export default UsersGrid;
