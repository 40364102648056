/* eslint-disable import/no-cycle */
import Home from "../components/Home";
import UsersManagement from "../components/Users/UsersManagement";
import PayrollPeriodManagement from "../components/PayrollPeriod/PayrollPeriodManagement";
import HolidaysManagement from "../components/Holidays/HolidaysManagement";
import PaymentSchemesManagement from "../components/PaymentSchemes/PaymentSchemesManagement";
import ShiftsManagement from "../components/Shifts/ShiftsManagement";
import CommissionManagement from "../components/Commission/CommissionManagement";
import ServiceOpeningManagement from "../components/ServiceOpening/ServiceOpeningManagement";
import BaseSalary from "../components/BaseSalary/BaseSalary";
import Liquidaciones from "../components/Liquidaciones/Liquidaciones";
import TypeLitersManagement from "../components/TypeLiters/TypeLitersManagement";
import BonoConceptsManagement from "../components/BonoConcepts/BonoConceptsManagement";
import TabulatorsManagement from "../components/Tabulators/TabulatorsManagement";
import PaymentEdition from "../components/PaymentEdition/PaymentEditionScreen";

export const components = {
  home: {
    id: "home",
    title: "Home",
    icon: "fa-home",
    path: "/home",
    component: Home,
  },
  users: {
    id: "users",
    title: "Usuarios",
    icon: "fa-users",
    path: "/users",
    component: UsersManagement,
  },
  holidays: {
    id: "holidays",
    title: "Días festivos",
    icon: "fa-calendar",
    path: "/holidays",
    component: HolidaysManagement,
  },
  payrollPeriod: {
    id: "payrollPeriod",
    title: "Periodos de Nómina",
    icon: "fa-calendar",
    path: "/payrollPeriod",
    component: PayrollPeriodManagement,
  },
  paymentSchemas: {
    id: "paymentSchemas",
    title: "Esquemas de Pago",
    icon: "fa-money-bill",
    path: "/paymentSchemas",
    component: PaymentSchemesManagement,
  },
  shifts: {
    id: "shifts",
    title: "Turnos Laborales",
    icon: "fa-clock",
    path: "/shifts",
    component: ShiftsManagement,
  },
  commission: {
    id: "commission",
    title: "Administración de Comisiones",
    icon: "fa-money-bill",
    path: "/commission",
    component: CommissionManagement,
  },
  serviceOpening: {
    id: "serviceOpening",
    title: "Apertura",
    icon: "fa-truck",
    path: "/serviceOpening",
    component: ServiceOpeningManagement,
  },
  baseSalary: {
    id: "baseSalary",
    title: "Salario Base",
    icon: "fa-money-bill",
    path: "/baseSalary",
    component: BaseSalary,
  },
  typeLiters: {
    id: "typeLiters",
    title: "Tipos de Litros",
    icon: "fa-prescription-bottle",
    path: "/typeLiters",
    component: TypeLitersManagement,
  },
  bonoConcepts: {
    id: "bonoConcepts",
    title: "Conceptos de Bono",
    icon: "fa-book",
    path: "/bonoConcepts",
    component: BonoConceptsManagement,
  },
  tabulator: {
    id: "tabulator",
    title: "Tabuladores",
    icon: "fa-book",
    path: "/tabulator",
    component: TabulatorsManagement,
  },
  liquidaciones: {
    id: "liquidaciones",
    title: "Imprimir prenomina",
    icon: "fa-print",
    path: "/liquidaciones",
    component: Liquidaciones,
  },
  paymentEdition: {
    id: "paymentEdition",
    title: "Edición de Prenómina",
    icon: "fa-edit",
    path: "/paymentEdition",
    component: PaymentEdition,
  },
};
