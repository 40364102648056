import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";

const HolidaysGrid = ({
  // Valores
  holiday,
  holidays,
  holidayNameRef,
  locationRef,
  initDateRef,
  finalDateRef,
  deleteHolidayDialog,

  // Componentes
  deleteRowBody,
  holidayNameEditor,
  locationEditor,
  initDateEditor,
  finalDateEditor,
  deleteHolidayDialogFooter,
  locationBody,

  // Métodos
  handleNewHolidayClick,
  editingRows,
  onRowEditChange,
  onRowEditInit,
  onRowEditCancel,
  onRowEditSave,
  rowEditorValidator,
  hideDeleteHolidayDialog,
  disableNewButton,
}) => (
  <>
    <div className="container px-3">
      <p className="is-size-3 gray-strench">Administración de días festivos</p>
      <p className="gray-light">
        Aquí puedes visualizar, agregar, editar y eliminar días festivos de la
        plataforma.
      </p>
      <button
        type="button"
        className="button is-link mt-3 primary-color"
        onClick={handleNewHolidayClick}
        disabled={disableNewButton()}
      >
        Agregar día festivo
      </button>
      <div className="card pt-3">
        <DataTable
          value={holidays}
          editMode="row"
          dataKey="id"
          editingRows={editingRows}
          onRowEditChange={onRowEditChange}
          onRowEditInit={onRowEditInit}
          onRowEditCancel={onRowEditCancel}
          onRowEditSave={onRowEditSave}
          rowEditorValidator={rowEditorValidator}
          className="p-datatable-sm"
          scrollable
        >
          <Column field="id" header="ID" style={{ width: "40px" }} />
          <Column
            field="holidayName"
            header="Nombre del día festivo"
            editor={(props) => holidayNameEditor(props, holidayNameRef)}
            style={{ width: "150px" }}
          />
          <Column
            field="idLocationFortia"
            header="Plaza"
            editor={(props) => locationEditor(props, locationRef)}
            style={{ width: "150px" }}
            body={locationBody}
          />
          <Column
            field="initDate"
            header="Fecha de Inicio"
            editor={(props) => initDateEditor(props, initDateRef)}
            style={{ width: "150px" }}
          />
          <Column
            field="finalDate"
            header="Fecha Final"
            editor={(props) => finalDateEditor(props, finalDateRef)}
            style={{ width: "150px" }}
          />
          <Column
            rowEditor
            header="Editar"
            headerStyle={{ width: "7rem" }}
            style={{ width: "50px" }}
            bodyStyle={{ textAlign: "left" }}
          />
          <Column
            header="Eliminar"
            body={deleteRowBody}
            style={{ width: "50px" }}
            bodyStyle={{ textAlign: "left" }}
          />
        </DataTable>
      </div>
    </div>
    <Dialog
      visible={deleteHolidayDialog}
      style={{ width: "450px" }}
      header="Confirm"
      modal
      footer={deleteHolidayDialogFooter}
      onHide={hideDeleteHolidayDialog}
    >
      <div className="confirmation-content">
        <i
          className="pi pi-exclamation-triangle p-mr-3"
          style={{ fontSize: "2rem" }}
        />
        <span>
          ¿Desea eliminar el día festivo{" "}
          <b>{holiday && holiday.holidayName ? holiday.holidayName : ""}</b>?
        </span>
      </div>
    </Dialog>
  </>
);

export default HolidaysGrid;
