export const conceptsTable = {
  concepts: [
    {
      attributes: [
        { type: "label", id: "bonoAprobado" },
        { type: "label", id: "bonoClave" },
        { type: "label", id: "bonoTipo" },
        { type: "number", id: "bonoDiasSistema" },
        { type: "label", id: "bonoDiasModificado", color: "orange" },
        { type: "number", id: "bonoImporteSistema" },
        { type: "label", id: "bonoImporteModificado", color: "orange" },
      ],
    },
    {
      attributes: [
        { type: "label", id: "comisionAprobado" },
        { type: "label", id: "comisionClave" },
        { type: "label", id: "comisionTipo" },
        { type: "number", id: "comisionDiasSistema" },
        { type: "label", id: "comisionDiasModificado", color: "orange" },
        { type: "number", id: "comisionImporteSistema" },
        { type: "label", id: "comisionImporteModificado", color: "orange" },
      ],
    },
    {
      attributes: [
        { type: "label", id: "compensacionAprobado" },
        { type: "label", id: "compensacionClave" },
        { type: "label", id: "compensacionTipo" },
        { type: "number", id: "compensacionDiasSistema" },
        { type: "label", id: "compensacionDiasModificado", color: "orange" },
        { type: "number", id: "compensacionImporteSistema" },
        {
          type: "label",
          id: "compensacionImporteModificado",
          color: "orange",
        },
      ],
    },
    {
      attributes: [
        { type: "label", id: "remanenteAprobado" },
        { type: "label", id: "remanenteClave" },
        { type: "label", id: "remanenteTipo" },
        { type: "number", id: "remanenteDiasSistema" },
        { type: "label", id: "remanenteDiasModificado", color: "orange" },
        { type: "number", id: "remanenteImporteSistema" },
        { type: "label", id: "remanenteImporteModificado", color: "orange" },
      ],
    },
    {
      attributes: [
        { type: "label", id: "septimoDiaTrabajadoAprobado" },
        { type: "label", id: "septimoDiaTrabajadoClave" },
        { type: "label", id: "septimoDiaTrabajadoTipo" },
        { type: "number", id: "septimoDiaTrabajadoDiasSistema" },
        {
          type: "label",
          id: "septimoDiaTrabajadoDiasModificado",
          color: "orange",
        },
        { type: "number", id: "septimoDiaTrabajadoImporteSistema" },
        {
          type: "label",
          id: "septimoDiaTrabajadoImporteModificado",
          color: "orange",
        },
      ],
    },
    {
      attributes: [
        { type: "label", id: "complPlantaAprobado" },
        { type: "label", id: "complPlantaClave" },
        { type: "label", id: "complPlantaTipo" },
        { type: "number", id: "complPlantaDiasSistema" },
        { type: "label", id: "complPlantaDiasModificado", color: "orange" },
        { type: "number", id: "complPlantaImporteSistema" },
        { type: "label", id: "complPlantaImporteModificado", color: "orange" },
      ],
    },
    {
      attributes: [
        { type: "label", id: "complGuardiaAprobado" },
        { type: "label", id: "complGuardiaClave" },
        { type: "label", id: "complGuardiaTipo" },
        { type: "number", id: "complGuardiaDiasSistema" },
        { type: "label", id: "complGuardiaDiasModificado", color: "orange" },
        { type: "number", id: "complGuardiaImporteSistema" },
        {
          type: "label",
          id: "complGuardiaImporteModificado",
          color: "orange",
        },
      ],
    },

    {
      attributes: [
        { type: "label", id: "complTallerAprobado" },
        { type: "label", id: "complTallerClave" },
        { type: "label", id: "complTallerTipo" },
        { type: "number", id: "complTallerDiasSistema" },
        { type: "label", id: "complTallerDiasModificado", color: "orange" },
        { type: "number", id: "complTallerImporteSistema" },
        { type: "label", id: "complTallerImporteModificado", color: "orange" },
      ],
    },

    {
      attributes: [
        { type: "label", id: "complImprevistoAprobado" },
        { type: "label", id: "complImprevistoClave" },
        { type: "label", id: "complImprevistoTipo" },
        { type: "number", id: "complImprevistoDiasSistema" },
        {
          type: "label",
          id: "complImprevistoDiasModificado",
          color: "orange",
        },
        { type: "number", id: "complImprevistoImporteSistema" },
        {
          type: "label",
          id: "complImprevistoImporteModificado",
          color: "orange",
        },
      ],
    },

    {
      attributes: [
        { type: "label", id: "complSalarioMinimoAprobado" },
        { type: "label", id: "complSalarioMinimoClave" },
        { type: "label", id: "complSalarioMinimoTipo" },
        { type: "number", id: "complSalarioMinimoDiasSistema" },
        {
          type: "label",
          id: "complSalarioMinimoDiasModificado",
          color: "orange",
        },
        { type: "number", id: "complSalarioMinimoImporteSistema" },
        {
          type: "label",
          id: "complSalarioMinimoImporteModificado",
          color: "orange",
        },
      ],
    },

    {
      attributes: [
        { type: "label", id: "complSueldoGarantiaAprobado" },
        { type: "label", id: "complSueldoGarantiaClave" },
        { type: "label", id: "complSueldoGarantiaTipo" },
        { type: "number", id: "complSueldoGarantiaDiasSistema" },
        {
          type: "label",
          id: "complSueldoGarantiaDiasModificado",
          color: "orange",
        },
        { type: "number", id: "complSueldoGarantiaImporteSistema" },
        {
          type: "label",
          id: "complSueldoGarantiaImporteModificado",
          color: "orange",
        },
      ],
    },

    {
      attributes: [
        { type: "label", id: "complDiaFestivoAprobado" },
        { type: "label", id: "complDiaFestivoClave" },
        { type: "label", id: "complDiaFestivoTipo" },
        { type: "number", id: "complDiaFestivoDiasSistema" },
        {
          type: "label",
          id: "complDiaFestivoDiasModificado",
          color: "orange",
        },
        { type: "number", id: "complDiaFestivoImporteSistema" },
        {
          type: "label",
          id: "complDiaFestivoImporteModificado",
          color: "orange",
        },
      ],
    },

    {
      attributes: [
        { type: "label", id: "complDescansoTrabajadoAprobado" },
        { type: "label", id: "complDescansoTrabajadoClave" },
        { type: "label", id: "complDescansoTrabajadoTipo" },
        { type: "number", id: "complDescansoTrabajadoDiasSistema" },
        {
          type: "label",
          id: "complDescansoTrabajadoDiasModificado",
          color: "orange",
        },
        { type: "number", id: "complDescansoTrabajadoImporteSistema" },
        {
          type: "label",
          id: "complDescansoTrabajadoImporteModificado",
          color: "orange",
        },
      ],
    },

    {
      attributes: [
        { type: "label", id: "complPproductividadAprobado" },
        { type: "label", id: "complPproductividadClave" },
        { type: "label", id: "complPproductividadTipo" },
        { type: "number", id: "complPproductividadDiasSistema" },
        {
          type: "label",
          id: "complPproductividadDiasModificado",
          color: "orange",
        },
        { type: "number", id: "complPproductividadImporteSistema" },
        {
          type: "label",
          id: "complPproductividadImporteModificado",
          color: "orange",
        },
      ],
    },

    {
      attributes: [
        { type: "label", id: "complVentaAprobado" },
        { type: "label", id: "complVentaClave" },
        { type: "label", id: "complVentaTipo" },
        { type: "number", id: "complVentaDiasSistema" },
        { type: "label", id: "complVentaDiasModificado", color: "orange" },
        { type: "number", id: "complVentaImporteSistema" },
        { type: "label", id: "complVentaImporteModificado", color: "orange" },
      ],
    },
  ],
};

export const status = {
  0: [],
  1: [{ label: "EN" }],
  2: [{ label: "EN" }, { label: "JA" }],
  3: [{ label: "EN" }, { label: "JA" }, { label: "GP" }],
  4: [{ label: "EN" }, { label: "JA" }, { label: "GP" }, { label: "F" }],
};

export const employees = [
  {
    employeeName: "OMAR",
    employeeLastName: "DE LA TOVA",
    employeeSecondLastName: "BANDILLA",
    id: "9780",
    workStationName: "COORDINACION DE ARTES",
  },
  {
    employeeName: "JUAN JOSE",
    employeeLastName: "MACIAS",
    employeeSecondLastName: "MEDINA",
    id: "9781",
    workStationName: "DOCENTE CAM",
  },
  {
    employeeName: "IRMA LAURA",
    employeeLastName: "OSUNA",
    employeeSecondLastName: "HERNANDEZ",
    id: "9782",
    workStationName: "DOCENTE CAM",
  },
  {
    employeeName: "OMAR",
    employeeLastName: "DE LA TOVA",
    employeeSecondLastName: "BANDILLA",
    id: "9780",
    workStationName: "COORDINACION DE ARTES",
  },
];

export const defaultListItem = {
  plazaName: "Todos",
  work_station_name: "Todos",
  employeeName: "Todos",
  label: "Todos",
  value: "Todos",
};

export const periods = [
  {
    value: "Semanal",
    label: "Semanal",
  },
  {
    value: "Quincenal",
    label: "Quincenal",
  },
];

export const companies = [
  {
    value: "gaspasa",
    label: "Gaspasa",
  },
  {
    value: "diesgas",
    label: "Diesgas",
  },
];
