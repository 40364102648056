import React from 'react';
import EsquemaRangoSimple from '../Comunes/EsquemaRangoSimple';

const BonosProductividad = ({
    tabulatorCode,
    sectionName,
    tabulator,
    sectionCode,
}) => (
    <>
        <EsquemaRangoSimple
            tabulatorCode={tabulatorCode}
            sectionName={sectionName}
            tabulator={tabulator}
            sectionCode={sectionCode}
            estacionario
        />
    </>
);

export default BonosProductividad;
