import React from 'react';
import GeneralTabulator from './Comunes/GeneralTabulator';

const TabulatorType = (props) => {
    const {
        tabulator,
        tabType,
        schema,
        puestos,
        puestosSelected,
        tabulatorsInfo,
        setTabulatorsInfo,
    } = Object(props) || {};

    return (
        <GeneralTabulator
            tabulator={tabulator}
            schema={schema}
            puestos={puestos}
            puestosSelected={puestosSelected}
            tabType={tabType}
            tabulatorsInfo={tabulatorsInfo}
            setTabulatorsInfo={setTabulatorsInfo}
        />
    );
};

export default TabulatorType;
