import axios from '../axios/custom-axios';
import { API_URL_CONCEPTS } from '../constants';

class PayrollPeriodService {
  getPayrollPeriods = async () =>
    axios.get(`${API_URL_CONCEPTS}/payroll-periods`);

  async deletePayrollPeriod(id) {
    return axios.delete(`${API_URL_CONCEPTS}/payroll-periods/${id}`);
  }

  getPayrollPeriodDates = async (period, exercise, idPlaza) =>
    axios.get(
      `${API_URL_CONCEPTS}/payroll-periods/dates?period=${period}&exercise=${exercise}&idPlaza=${idPlaza}`
    );

  async createPayrollPeriod(body) {
    return axios.post(`${API_URL_CONCEPTS}/payroll-periods`, body);
  }

  async updatePayrollPeriod(body) {
    return axios.put(`${API_URL_CONCEPTS}/payroll-periods/${body.id}`, body);
  }

  updatePayrollPeriodDates = async (body) =>
    axios.put(`${API_URL_CONCEPTS}/payroll-periods/dates/${body.id}`, body);

  getEmployeesByLocationId = async (idLocation) =>
    axios.get(`${API_URL_CONCEPTS}/employees?fortiaLocationId=${idLocation}`);
}

export default new PayrollPeriodService();
