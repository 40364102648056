import React from 'react';
import gpoAlertaImg from '../../css/img/GA_LOGOTIPO2_BLANCO.png';

function LoginLayout({ children }) {
    /* Validación de Login */
    // eslint-disable-next-line consistent-return

    return (
        <section className="login">
            <div className="brand">
                <img className="" src={gpoAlertaImg} alt="" />
            </div>
            <div>{children}</div>
        </section>
    );
}

export default LoginLayout;
