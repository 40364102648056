import React, { useState, useEffect, useContext, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Container, Box } from 'react-bulma-components';
import { Dropdown } from 'primereact/dropdown';
import MainLayout from '../Layouts/MainLayout';
import ShiftsGrid from './ShiftsGrid';
import ShiftsService from '../../api/ShiftsService';
import { shiftTypeOptions, emptyShift } from '../../constants';
import { AuthContext } from '../../context/AuthContext/AuthContext';
import { Message } from 'primereact/message';

const ShiftsManagement = () => {
    // REFERENCIAS
    const shiftNameRef = useRef();
    const plazaRef = useRef();
    const shiftTypeRef = useRef();
    const startTimeRef = useRef();
    const endTimeRef = useRef();
    const toast = useRef(null);

    // VARIABLES
    const [shifts, setShifts] = useState([]);
    const [shift, setShift] = useState({});
    const [originalRows, setOriginalRows] = useState({});
    const [deleteShiftDialog, setDeleteShiftDialog] = useState(false);
    const [editingRows, setEditingRows] = useState({});
    const [plazasFortia, setPlazasFortia] = useState([]);
    const [loading, setLoading] = useState(true);
    const { auth } = useContext(AuthContext);

    const [timeoutId, setTimeoutId] = useState(null); // Nuevo estado para almacenar el identificador de setTimeout

    // METODOS
    const onEditorValueChange = (props, value) => {
        const _shifts = [...props.value];
        _shifts[props.rowIndex][props.field] = value;
        setShifts([..._shifts]);
    };

    const onTimeEditorValueChange = (props, value) => {
        const _shifts = [...props.value];
        _shifts[props.rowIndex][props.field] = value.toLocaleTimeString(
            navigator.language,
            {
                hour: '2-digit',
                minute: '2-digit',
            }
        );
        setShifts([..._shifts]);
    };

    const onRowEditInit = (e) => {
        const _shift = { ...shifts[e.index] };
        const _originalRows = { ...originalRows };
        _originalRows[e.index] = _shift;
        setOriginalRows({ ..._originalRows });
    };

    const onRowEditChange = (e) => {
        setEditingRows(e.data);
    };

    const setActiveRowIndex = (index, _shifts) => {
        const _originalRows = { ...originalRows };
        _originalRows[index] = { ..._shifts[index - 1] };
        setEditingRows({
            ...editingRows,
            ...{ [`${_shifts[index - 1].id}`]: true },
        });
    };

    const handleNewShiftClick = () => {
        const _shifts = [...shifts];
        _shifts.push({ ...emptyShift });
        setActiveRowIndex(_shifts.length, _shifts);
        setShifts(_shifts);
        setTimeout(() => shiftNameRef.current.focus(), 0);
    };

    const rowEditorValidator = (rowData) =>
        rowData.shiftName.trim().length !== 0;

    const onRowEditSave = ({ data }) => {
        if (!rowEditorValidator(data)) {
            return;
        }

        if (data.id === null) {
            const body = {
                ...data,
                // idPlazaFortia: auth.location,
            };

            ShiftsService.createShift(body)
                .then((response) => {
                    const _shifts = [...shifts];
                    const index = _shifts.findIndex(
                        (_shift) => _shift.id === body.id
                    );

                    _shifts[index].id = response.data.result.id;

                    setShifts([..._shifts]);

                    toast.current.show({
                        severity: 'success',
                        summary: 'Turno actualizado',
                        detail: `${response.data.message}`,
                    });
                })
                .catch(({ response }) => {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Error al actualizar',
                        detail: `${response.data.message}`,
                    });
                });
        } else {
            const body = {
                ...data,
                // idPlazaFortia: auth.location,
            };
            ShiftsService.updateShift(body)
                .then((response) => {
                    toast.current.show({
                        severity: 'success',
                        summary: 'Turno actualizado',
                        detail: `${response.data.message}`,
                    });
                })
                .catch(({ response }) => {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Error al actualizar',
                        detail: `${response.message}`,
                    });
                });
        }
    };

    const deleteShift = () => {
        if (shift.id === null) {
            const _shifts = [...shifts];
            const index = _shifts.findIndex((_shift) => _shift.id === shift.id);
            _shifts.splice(index, 1);
            setShifts([..._shifts]);
            setDeleteShiftDialog(false);
        } else {
            ShiftsService.deleteShift(shift.id)
                .then((response) => {
                    const _shifts = [...shifts];
                    const index = _shifts.findIndex(
                        (_shift) => _shift.id === shift.id
                    );
                    _shifts.splice(index, 1);
                    setShifts([..._shifts]);
                    setDeleteShiftDialog(false);

                    toast.current.show({
                        severity: 'success',
                        summary: 'Turno eliminado',
                        detail: `${response.data.message}`,
                    });
                })
                .catch(({ response }) => {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Error al eliminar',
                        detail: `${response.data.message}`,
                    });
                });
        }
    };

    const confirmDeleteShift = (rowData) => {
        setShift(rowData);
        setDeleteShiftDialog(true);
    };

    const disableNewShiftButton = () =>
        !!shifts.find((_shift) => _shift.id === null);

    const onRowEditCancel = (e) => {
        const _shifts = [...shifts];
        if (e.data.id === null) {
            _shifts.pop();
            setShifts(_shifts);
        } else {
            _shifts[e.index] = { ...originalRows[e.index] };
            const _originalRows = { ...originalRows };
            delete _originalRows[e.index];
            setOriginalRows(_originalRows);
            setShifts(_shifts);
        }
    };

    // COMPONENTES
    const deleteBody = (rowData) => (
        <>
            <Button
                icon="pi pi-trash"
                className="trash"
                onClick={() => confirmDeleteShift(rowData)}
            />
        </>
    );

    const inputTextEditor = (props, ref) => (
        <InputText
            type="text"
            value={props.rowData[props.field]}
            style={{ width: '100%' }}
            onChange={(e) => onEditorValueChange(props, e.target.value)}
            ref={ref}
        />
    );

    const timeEditor = (props, ref) => {
        const date = new Date(0);
        // Posición 0 son horas, Posición 1 son minutos
        const timeValues = props.rowData[props.field].split(':');
        date.setHours(timeValues[0]);
        date.setMinutes(timeValues[1]);
        return (
            <Calendar
                value={date}
                onChange={(e) => onTimeEditorValueChange(props, e.value)}
                hourFormat="24"
                timeOnly
                ref={ref}
                readOnlyInput
            />
        );
    };

    const shiftNameEditor = (props, ref) => (
        <>
            {' '}
            {inputTextEditor(props, ref)}
            {props.rowData[props.field].trim().length === 0 ? (
                <Message severity="error" text="El nombre es requerido" />
            ) : (
                ''
            )}
        </>
    );

    const dropdownEditor = (props, fieldOptions, optionLabel, optionValue, ref) => (
        <Dropdown
            value={props.rowData[props.field]}
            options={fieldOptions}
            optionLabel={optionLabel}
            optionValue={optionValue}
            onChange={(e) => onEditorValueChange(props, e.value)}
            placeholder="Selecciona un tipo"
            ref={ref}
            onMouseDown={(e) => e.preventDefault()}
            onShow={() => {
                if (timeoutId !== null) {
                    clearTimeout(timeoutId);
                }
            }}
            onHide={() => {
                const id = setTimeout(() => {
                    if (shifts.find((_shift) => _shift.id === null)) {
                        shiftNameRef.current.focus();
                    }
                }, 100);
                setTimeoutId(id);
            }}
        />
    );

    const shiftTypeEditor = (props, ref) =>
        dropdownEditor(props, shiftTypeOptions, "name", "value", ref);

    const plazaEditor = (props, ref) =>
        dropdownEditor(props, plazasFortia, "location_name", "location_id", ref);

    const shiftTypeBody = ({ shiftType }) => {
        const { name } = shiftTypeOptions.find(
            ({ value }) => value === shiftType
        );
        return <p>{name}</p>;
    };

    const startTimeEditor = (props, ref) => <>{timeEditor(props, ref)}</>;
    const endTimeEditor = (props, ref) => <>{timeEditor(props, ref)}</>;

    const startTimeBody = ({ startTime }) => {
        const date = new Date(0);
        // Posición 0 son horas, Posición 1 son minutos
        const timeValues = startTime.split(':');
        date.setHours(timeValues[0]);
        date.setMinutes(timeValues[1]);

        return (
            <>
                <p>
                    {date.toLocaleTimeString(navigator.language, {
                        hour: '2-digit',
                        minute: '2-digit',
                    })}
                </p>
            </>
        );
    };

    const endTimeBody = ({ endTime }) => {
        const date = new Date(0);
        // Posición 0 son horas, Posición 1 son minutos
        const timeValues = endTime.split(':');
        date.setHours(timeValues[0]);
        date.setMinutes(timeValues[1]);

        return (
            <>
                <p>
                    {date.toLocaleTimeString(navigator.language, {
                        hour: '2-digit',
                        minute: '2-digit',
                    })}
                </p>
            </>
        );
    };

    const plazaBody = ({ idPlazaFortia }) => {
        if (plazasFortia.length > 0) {
            const result = plazasFortia.find(
                (plaza) => plaza.location_id === idPlazaFortia
            );
            return result.location_name;
        } else {
            return 'Cargando...';
        }
    };

    const deleteShiftDialogFooter = () => (
        <>
            <Button
                label="No"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => setDeleteShiftDialog(false)}
            />
            <Button
                label="Yes"
                icon="pi pi-check"
                className="p-button-text"
                onClick={deleteShift}
            />
        </>
    );

    useEffect(() => {
        ShiftsService.getShifts()
            .then((response) => {
                setShifts([...response.data.result]);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });

        ShiftsService.getPlazasFortia()
            .then((response) => {
                setPlazasFortia([...response.data.result]);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (
        <MainLayout>
            <Toast ref={toast} />
            <Box>
                <Container>
                    <ShiftsGrid
                        // REFERENCIAS
                        shiftNameRef={shiftNameRef}
                        shiftTypeRef={shiftTypeRef}
                        startTimeRef={startTimeRef}
                        endTimeRef={endTimeRef}
                        plazaRef={plazaRef}
                        // VARIABLE
                        shifts={shifts}
                        originalRows={originalRows}
                        shift={shift}
                        deleteShiftDialog={deleteShiftDialog}
                        editingRows={editingRows}
                        loading={loading}
                        // METODOS
                        onRowEditInit={onRowEditInit}
                        handleNewShiftClick={handleNewShiftClick}
                        onRowEditSave={onRowEditSave}
                        setDeleteShiftDialog={setDeleteShiftDialog}
                        disableNewShiftButton={disableNewShiftButton}
                        onRowEditChange={onRowEditChange}
                        onRowEditCancel={onRowEditCancel}
                        rowEditorValidator={rowEditorValidator}
                        // COMPONENTES
                        deleteBody={deleteBody}
                        shiftNameEditor={shiftNameEditor}
                        shiftTypeEditor={shiftTypeEditor}
                        shiftTypeBody={shiftTypeBody}
                        startTimeEditor={startTimeEditor}
                        endTimeEditor={endTimeEditor}
                        startTimeBody={startTimeBody}
                        endTimeBody={endTimeBody}
                        deleteShiftDialogFooter={deleteShiftDialogFooter}
                        plazaEditor={plazaEditor}
                        plazaBody={plazaBody}
                    />
                </Container>
            </Box>
        </MainLayout>
    );
};

export default ShiftsManagement;
