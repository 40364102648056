import React, { createContext, useReducer, useEffect } from "react";
import { reducer } from "./reducer";

export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [auth, dispatch] = useReducer(reducer, [], () => {
    const sessionAuth = sessionStorage.getItem("authenticatedUser");
    return sessionAuth
      ? JSON.parse(sessionAuth)
      : {
          isAuthenticated: false,
          user: null,
          fullname: null,
          email: null,
          token: null,
          userid: null,
          location: null,
          rol: null,
          modules: [],
          permissions: [],
        };
  });

  useEffect(() => {
    sessionStorage.setItem("authenticatedUser", JSON.stringify(auth));
  }, [auth]);

  return (
    <AuthContext.Provider value={{ auth, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
