import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState, useContext } from 'react';
import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';
import UsersService from '../../api/UsersService';
import { AuthContext } from '../../context/AuthContext/AuthContext';
import { PASSWORD_PATTERN_REGEXP } from '../../constants';

function ChangePassword(props) {
    const { visible, onHide } = props;
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessages] = useState('');
    const [isValid, setIsValid] = useState(false);
    const { auth } = useContext(AuthContext);

    /**
     * Controla la variable cuando se oculta el Dialog
     */
    useEffect(() => {
        setTimeout(() => {
            setMessages('');
            setIsValid(false);
        }, 1000);
    }, [onHide]);

    /**
     * Controla los estados de newPassword y confirmPassword para
     * validar el match entre los dos y habilitar el
     * button de cambiar password.
     */
    useEffect(() => {
        if (PASSWORD_PATTERN_REGEXP.test(newPassword)) {
            if (newPassword === confirmPassword) {
                setIsValid(true);
            } else {
                setIsValid(false);
            }
        } else {
            setIsValid(false);
        }
    }, [newPassword, confirmPassword]);

    /**
     * Hace la petición a cambiar password y dependiendo
     * la respuesta lo muestra en el Dialogo.
     * @returns {Promise<void>} - La respuesta de la api.
     */
    const onChangePasswordHandle = async () => {
        setIsValid(false);
        try {
            const response = await UsersService.changePassword({
                currentPassword,
                newPassword,
                userId: auth.userid,
            });
            if (response.status === 'OK')
                setMessages('Se cambio correctamente la contraseña');
        } catch (e) {
            setMessages('Ocurrió un problema al cambiar el contraseña');
        }
        setIsValid(false);
    };

    const footerNewPassword = (
        <>
            <Divider />
            {!PASSWORD_PATTERN_REGEXP.test(newPassword) ? (
                <>
                    <b className="p-mt-2">La contraseña debe contener:</b>
                    <ul
                        className="p-pl-2 p-ml-2 p-mt-0"
                        style={{ lineHeight: '1.5' }}
                    >
                        <li>1 letra mayúscula</li>
                        <li>1 letra minúscula</li>
                        <li>1 carácter especial (!"#$%&/()*?¿) </li>
                        <li>De 8 a 30 caracteres</li>
                    </ul>
                </>
            ) : (
                <b className="p-mt-2">La contraseña es válida.</b>
            )}
        </>
    );

    const footerConfirmPassword = (
        <>
            <Divider />
            {!isValid ? (
                <>
                    <b className="p-mt-2">Las contraseñas deben coincidir.</b>
                </>
            ) : (
                <b className="p-mt-2">Las contraseñas coinciden.</b>
            )}
        </>
    );

    /**
     * Footer del dialogo.
     * @returns {*} - Retorna el Render del footer del Dialog.
     */
    function renderFooterDialog() {
        return (
            <div>
                <Button
                    label="Cancelar"
                    icon="pi pi-times"
                    onClick={onHide}
                    className="p-button-text hover-btn"
                />
                <Button
                    label="Cambiar contraseña"
                    icon="pi pi-check"
                    onClick={onChangePasswordHandle}
                    className="confirm-btn"
                    disabled={!isValid}
                    autoFocus
                />
            </div>
        );
    }

    /**
     * Mensaje que se muestra cuando regresa la respuesta del api.
     */
    const changePasswordResponse = <p>{message}</p>;

    return (
        <>
            <Dialog
                header="Cambiar contraseña"
                visible={visible}
                footer={renderFooterDialog}
                onHide={onHide}
            >
                {!message ? (
                    <form>
                        <div className="m-2">
                            <p>Password actual</p>
                            <Password
                                id="currentPassword"
                                autoComplete="off"
                                onChange={({ target }) =>
                                    setCurrentPassword(target.value)
                                }
                                toggleMask
                                feedback={false}
                            />
                        </div>
                        <div className="pl-3" />
                        <div className="m-2">
                            <p>Nuevo password</p>
                            <Password
                                id="newPassword"
                                name="newPassword"
                                autoComplete="off"
                                onChange={({ target }) =>
                                    setNewPassword(target.value)
                                }
                                toggleMask
                                footer={footerNewPassword}
                            />
                        </div>
                        <div className="m-2">
                            <p>Confirmar password:</p>
                            <Password
                                id="confirmPassword"
                                name="confirmPassword"
                                autoComplete="off"
                                onChange={({ target }) =>
                                    setConfirmPassword(target.value)
                                }
                                toggleMask
                                footer={footerConfirmPassword}
                            />
                        </div>
                    </form>
                ) : (
                    changePasswordResponse
                )}
            </Dialog>
        </>
    );
}

export default ChangePassword;
