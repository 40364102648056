/* eslint-disable no-unused-expressions */
import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import OpeningService from "../../api/OpeningService";

const ConfirmOpening = ({
  openingSelected,
  location,
  handleDropdownChange,
  setShowGrid,
  catalogs,
}) => {
  const [isOpeningNew] = useState(true);
  const toast = useRef(null);

  const showFullname = (object) => (
    <label htmlFor="firstname3" className="p-col-fixed">
      {object && `${object.employeeName} ${object.employeeLastName}`}
    </label>
  );

  const driver = catalogs.drivers?.find(
    (user) => user.idFortiaEmployee === openingSelected.idFortiaDriver
  );
  const assistant = catalogs.driverAssistants?.find(
    (user) => user.idFortiaEmployee === openingSelected.idFortiaAssistant
  );
  const third = catalogs.driverAssistants?.find(
    (user) => user.idFortiaEmployee === openingSelected.idFortiaThird
  );

  const handleOpeninigUpdate = () => {
    const requestBody = {
      ...openingSelected,
      idFortiaAssistant:
        openingSelected.idFortiaAssistant === "NULO"
          ? null
          : openingSelected.idFortiaAssistant,
      idFortiaThird:
        openingSelected.idFortiaThird === "NULO"
          ? null
          : openingSelected.idFortiaThird,
    };

    OpeningService.updateOpening(requestBody)
      .then(({ data: { message } }) => {
        toast.current.show({
          severity: "success",
          summary: `${message}`,
        });
      })
      .catch(
        ({
          response: {
            data: { message },
          },
        }) => {
          toast.current.show({
            severity: "error",
            summary: `${message}`,
          });
        }
      );
  };

  const handleCloseOpening = () => {
    const requestBody = {
      ...openingSelected,
      idFortiaAssistant:
        openingSelected.idFortiaAssistant === "NULO"
          ? null
          : openingSelected.idFortiaAssistant,
      idFortiaThird:
        openingSelected.idFortiaThird === "NULO"
          ? null
          : openingSelected.idFortiaThird,
      openingStatus: false,
    };

    OpeningService.updateOpening(requestBody)
    console.log("Hola 2")
      .then(({ data: { message } }) => {
        toast.current.show({
          severity: "success",
          summary: `${message}`,
        });
        // setShowGrid(true);
      })
      .catch(
        ({
          response: {
            data: { message },
          },
        }) => {
          toast.current.show({
            severity: "error",
            summary: `${message}`,
          });
        }
      );
  };

  return (
    <>
      <Toast ref={toast} />
      <p className="is-size-5">Datos de la apertura</p>
      <div className="p-fluid p-formgrid p-grid mt-3">
        <div className="p-field p-col">
          <div className="p-field p-grid">
            <label htmlFor="firstname3" className="p-col-fixed">
              Vehículo:
            </label>
            <label htmlFor="firstname3" className="p-col-fixed">
              {openingSelected.vehicleName}
            </label>
          </div>
          <div className="p-field p-grid">
            <label htmlFor="firstname3" className="p-col-fixed">
              Nombre del Chofer:
            </label>
            {showFullname(driver)}
          </div>
          <div className="p-field p-grid">
            <label htmlFor="firstname3" className="p-col-fixed">
              Nombre del Ayudante:
            </label>
            {showFullname(assistant)}
          </div>
          <div className="p-field p-grid">
            <label htmlFor="firstname3" className="p-col-fixed">
              Nombre del Tercero:
            </label>
            {showFullname(third)}
          </div>
          <div className="p-field p-grid">
            <label htmlFor="firstname3" className="p-col-fixed">
              Estatus de la unidad:
            </label>
            <Dropdown
              options={[
                {
                  id: 1,
                  label: "Activa",
                  unitStatus: true,
                  value: true,
                },
                {
                  id: 2,
                  label: "Inactiva",
                  unitStatus: false,
                  value: false,
                },
              ]}
              onChange={handleDropdownChange}
              id="unitStatus"
              optionValue="unitStatus"
              value={openingSelected.unitStatus}
              optionLabel="label"
              placeholder="Selecciona un estatus"
            />
          </div>
        </div>
        <div className="p-field p-col">
          <div className="p-field p-grid">
            <label htmlFor="firstname3" className="p-col-fixed">
              Zona:
            </label>
            <label htmlFor="firstname3" className="p-col-fixed">
              {catalogs?.zones.find((z) => z.id == openingSelected.idZone)
                ?.zona || ""}
            </label>
          </div>
          <div className="p-field p-grid">
            <label htmlFor="firstname3" className="p-col-fixed">
              Plaza:
            </label>
            <label htmlFor="firstname3" className="p-col-fixed">
              {location.location_name}
            </label>
          </div>
          <div className="p-field p-grid">
            <div className="p-col">
              <Button
                label="Cancelar"
                className="p-button-danger"
                onClick={() => setShowGrid(true)}
              />
            </div>
            <div className="p-col">
              <Button
                label={`${
                  !openingSelected.id ? "Crear" : "Actualizar"
                } Apertura`}
                onClick={handleOpeninigUpdate}
              />
            </div>
            <div className="p-col">
              <Button
                label="Cerrar Apertura"
                className="p-button-success"
                onClick={handleCloseOpening}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmOpening;
