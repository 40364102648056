import { Dropdown } from "primereact/dropdown";
import React from "react";

const DropDownEditor = ({
  options = [],
  optionLabel = "name",
  placeholder = "",
  optionValue = null,
  value = null,
  onChange = () => console.log("No function added"),
  ref = null,
  disabled = false,
  editorProps,
}) => {
  return (
    <Dropdown
      options={options}
      optionLabel={optionLabel}
      optionValue={optionValue}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      ref={ref}
      disabled={disabled}
      style={{
        width: "100% ",
      }}
    />
  );
};

export default DropDownEditor;
