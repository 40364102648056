import React, { useState, useEffect, useRef } from "react";
import { Container, Box } from "react-bulma-components";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Message } from "primereact/message";
import { Calendar } from "primereact/calendar";
import HolidaysGrid from "./HolidaysGrid";
import MainLayout from "../Layouts/MainLayout";
import HolidaysService from "../../api/HolidaysService";
import CatalogService from "../../api/CatalogsService";

import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";

const HolidaysManagement = () => {
  const [holiday, setHoliday] = useState(null);
  const [holidays, setHolidays] = useState([]);
  const [locations, setLocations] = useState([]);
  const [deleteHolidayDialog, setDeleteHolidayDialog] = useState(false);
  const [originalRows, setOriginalRows] = useState({});
  const [editingRows, setEditingRows] = useState({});
  const [deleteRowButtonEnabled, setDeleteRowButtonEnabled] = useState(true);

  const toast = useRef(null);
  const holidayNameRef = useRef();
  const locationRef = useRef();
  const initDateRef = useRef();
  const finalDateRef = useRef();

  const minDate = new Date();

  async function fetchHolidays() {
    try {
      const response = await HolidaysService.getHolidays();
      const { data } = response;
      setHolidays(data.result);
    } catch (error) {
      const { data } = error.response;
      toast.current.show({
        severity: "error",
        summary: `${data.message}`,
      });
      setHolidays([]);
    }
  }

  const fetchLocations = () => {
    CatalogService.getLocations()
      .then(({ result }) => {
        result.push({
          company_id: 1,
          company_name: "TODAS LAS PLAZAS",
          location_id: -1,
          location_name: "TODAS LAS PLAZAS",
        });
        setLocations(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchHolidays();
    fetchLocations();
  }, []);

  // MÉTODOS
  const onRowEditInit = (event) => {
    const holidayAux = { ...holidays[event.index] };
    const originalRowsAux = { ...originalRows };
    originalRowsAux[event.index] = holidayAux;
    setOriginalRows(originalRowsAux);
    setDeleteRowButtonEnabled(false);
  };

  const onRowEditChange = (event) => {
    setEditingRows(event.data);
  };

  const hideDeleteHolidayDialog = () => {
    setDeleteHolidayDialog(false);
  };

  const setActiveRowIndex = (index, holidaysAux) => {
    const originalRowsAux = { ...originalRows };
    originalRowsAux[index] = { ...holidaysAux[index - 1] };
    setEditingRows({
      ...editingRows,
      ...{ [`${holidaysAux[index - 1].id}`]: true },
    });
  };

  const handleNewHolidayClick = () => {
    const holidaysAux = [...holidays];
    holidaysAux.push({
      id: null,
      newHoliday: true,
      holidayName: "",
      location: "",
      initDate: "",
      finalDate: "",
    });
    setHolidays(holidaysAux);
    setActiveRowIndex(holidaysAux.length, holidaysAux);
    if (deleteRowButtonEnabled) {
      setDeleteRowButtonEnabled(false);
    }
  };

  const disableNewButton = () =>
    !!holidays.find((_holiday) => _holiday.id === null);

  const handleValidation = (props) => {
    const rowData = props.data;
    let error = false;

    if (rowData.holidayName.trim().length === 0) {
      error = true;
      holidayNameRef.current.required = true;
      holidayNameRef.current.className = "p-inputtext p-component p-invalid";
    }
    if (rowData.idLocationFortia < -1) {
      error = true;
      locationRef.current.required = true;
      locationRef.current.className = "p-inputtext p-component p-invalid";
    }
    if (rowData.initDate.length === 0) {
      error = true;
      initDateRef.current.required = true;
      initDateRef.current.className = "p-inputtext p-component p-invalid";
    }
    if (rowData.finalDate.length === 0) {
      error = true;
      finalDateRef.current.required = true;
      finalDateRef.current.className = "p-inputtext p-component p-invalid";
    }

    if (rowData.idLocationFortia) {
      const holidaysAux = [...holidays];
      holidaysAux.forEach((holidayAux, index) => {
        if (
          holidayAux.idLocationFortia === rowData.idLocationFortia &&
          index !== props.index
        ) {
          if (holidayAux.initDate === rowData.initDate) {
            error = true;
            toast.current.show({
              severity: "error",
              summary: `Esta fecha en esta plaza ya existe`,
            });
          }
        }
      });
    }

    return error;
  };

  const onRowEditSave = (props) => {
    const errors = handleValidation(props);

    if (!errors) {
      if (props.data.newHoliday) {
        HolidaysService.createHoliday(props.data)
          .then((response) => {
            const { data } = response;
            toast.current.show({
              severity: "success",
              summary: `${data.message}`,
            });

            HolidaysService.getHolidays()
              .then((r) => {
                setHolidays(r.data.result);
              })
              .catch();
          })
          .catch((error) => {
            toast.current.show({
              severity: "error",
              summary: `${error.response.data.message}`,
            });
          });
      } else {
        HolidaysService.updateHoliday(props.data)
          .then((response) => {
            const { data } = response;
            const holidaysAux = [...holidays];
            holidaysAux[props.index] = data.result;
            setHolidays(holidaysAux);

            toast.current.show({
              severity: "success",
              summary: `${data.message}`,
            });
          })
          .catch((error) => {
            const { data } = error.response;
            toast.current.show({
              severity: "error",
              summary: `${data.message}`,
            });
          });
      }

      if (Object.values(editingRows).length === 1) {
        setEditingRows({});
        setDeleteRowButtonEnabled(true);
      }
    }
  };

  const deleteHoliday = () => {
    HolidaysService.deleteHoliday(holiday.id)
      .then((response) => {
        const { data } = response;
        toast.current.show({
          severity: "success",
          summary: `${data.message}`,
        });
      })
      .catch((error) => {
        const { data } = error.response;
        toast.current.show({
          severity: "error",
          summary: `${data.message}`,
        });
      });

    const holidaysAux = holidays.filter(
      (holidayAux) => holidayAux.id !== holiday.id
    );
    setHolidays(holidaysAux);
    setDeleteHolidayDialog(false);
    setHoliday(null);
  };

  const onRowEditCancel = (event) => {
    const holidaysAux = [...holidays];
    if (event.data.newHoliday) {
      holidaysAux.pop();
      setHolidays(holidaysAux);
      setDeleteRowButtonEnabled(true);
    } else {
      holidaysAux[event.index] = { ...originalRows[event.index] };
      const newRows = { ...originalRows };
      delete newRows[event.index];
      setOriginalRows(newRows);
      setHolidays(holidaysAux);
    }

    if (Object.values(editingRows).length === 1) {
      setDeleteRowButtonEnabled(true);
    }
  };

  const onEditorValueChange = (props, value) => {
    const updatedHolidays = [...props.value];
    updatedHolidays[props.rowIndex][props.field] = value;
    setHolidays(updatedHolidays);
  };

  const rowEditorValidator = (rowData) => false;

  const confirmDeleteHoliday = (selectedHoliday) => {
    setHoliday(selectedHoliday);
    setDeleteHolidayDialog(true);
  };

  // COMPONENTS
  const deleteRowBody = (rowData) => (
    <>
      <Button
        icon="pi pi-trash"
        className="trash"
        disabled={!deleteRowButtonEnabled}
        onClick={() => confirmDeleteHoliday(rowData)}
      />
    </>
  );

  const locationBody = (rowData) => {
    const { location_name } =
      locations.find(
        ({ location_id }) => location_id === rowData.idLocationFortia
      ) || {};
    return <p>{location_name}</p>;
  };

  const deleteHolidayDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteHolidayDialog}
      />
      <Button
        label="Si"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteHoliday}
      />
    </>
  );

  const inputTextEditor = (props, ref) => (
    <InputText
      type="text"
      value={props.rowData[props.field]}
      onChange={(e) => onEditorValueChange(props, e.target.value)}
      ref={ref}
      style={{ width: "100%" }}
    />
  );

  const locationEditor = (props, ref) => (
    <>
      <Dropdown
        options={locations}
        optionLabel="location_name"
        optionValue="location_id"
        value={props.rowData[props.field]}
        style={{ width: "100%" }}
        onChange={(e) => onEditorValueChange(props, e.value)}
      />
      {!props.rowData[props.field] ? (
        <Message severity="error" text="La plaza es un campo requerido" />
      ) : (
        ""
      )}
    </>
  );

  const initDateEditor = (props, ref) => {
    const dateToShow = new Date(props.rowData.initDate);
    dateToShow.setDate(dateToShow.getDate() + 1);
    return (
      <>
        <Calendar
          id="basic"
          dateFormat="yy-mm-dd"
          value={dateToShow}
          ref={ref}
          minDate={minDate}
          readOnlyInput
          onChange={(e) => {
            const date = e.value;
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            const dateInString = `${year}-${month < 10 ? "0" : ""}${month}-${
              day < 10 ? "0" : ""
            }${day}`;
            return onEditorValueChange(props, dateInString);
          }}
        />
        {props.rowData[props.field].length === 0 ? (
          <Message
            severity="error"
            text="La fecha inicial es un campo requerido"
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const finalDateEditor = (props, ref) => {
    const dateToShow = new Date(props.rowData.finalDate);
    dateToShow.setDate(dateToShow.getDate() + 1);

    const minFinalDate =
      props.rowData.initDate === ""
        ? new Date()
        : new Date(props.rowData.initDate);
    minFinalDate.setDate(minFinalDate.getDate() + 1);

    return (
      <>
        <Calendar
          id="basic"
          dateFormat="yy-mm-dd"
          value={dateToShow}
          ref={ref}
          minDate={minFinalDate}
          readOnlyInput
          onChange={(e) => {
            const date = e.value;
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            const dateInString = `${year}-${month < 10 ? "0" : ""}${month}-${
              day < 10 ? "0" : ""
            }${day}`;
            return onEditorValueChange(props, dateInString);
          }}
        />
        {props.rowData[props.field].length === 0 ? (
          <Message
            severity="error"
            text="La fecha final es un campo requerido"
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const holidayNameEditor = (props, ref) => (
    <div>
      {inputTextEditor(props, ref)}
      {props.rowData[props.field].trim().length === 0 ? (
        <Message
          severity="error"
          text="El nombre de la festividad es requerido"
        />
      ) : (
        ""
      )}
    </div>
  );

  return (
    <MainLayout>
      <Box>
        <Container>
          <Toast ref={toast} />
          <HolidaysGrid
            // VARIABLES
            holiday={holiday}
            holidays={holidays}
            deleteRowButtonEnabled={deleteRowButtonEnabled}
            editingRows={editingRows}
            holidayNameRef={holidayNameRef}
            locationRef={locationRef}
            initDateRef={initDateRef}
            finalDateRef={finalDateRef}
            deleteHolidayDialog={deleteHolidayDialog}
            handleNewHolidayClick={handleNewHolidayClick}
            // COMPONENTES
            deleteRowBody={deleteRowBody}
            holidayNameEditor={holidayNameEditor}
            locationEditor={locationEditor}
            initDateEditor={initDateEditor}
            finalDateEditor={finalDateEditor}
            deleteHolidayDialogFooter={deleteHolidayDialogFooter}
            locationBody={locationBody}
            // MÉTODOS
            onRowEditInit={onRowEditInit}
            onRowEditChange={onRowEditChange}
            onRowEditCancel={onRowEditCancel}
            onRowEditSave={onRowEditSave}
            rowEditorValidator={rowEditorValidator}
            hideDeleteHolidayDialog={hideDeleteHolidayDialog}
            disableNewButton={disableNewButton}
          />
        </Container>
      </Box>
    </MainLayout>
  );
};

export default HolidaysManagement;
