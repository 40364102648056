import React, { useState, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { Accordion, AccordionTab } from 'primereact/accordion';
import DetallesEsquema from '../Comunes/DetallesEsquema';
import Ponderaciones from './Ponderaciones';
import BonosProductividad from './BonosProductividad';
import Comisiones from './Comisiones';
import { usePaymentSchema } from '../../../context/PaymentSchemaContext';

const Otif = ({ tabulator, schema, puestos, tabType }) => {
    const tabulatorCode = 'tabOtif';
    const initialForm = {
        puestos: [],
        sueldo: 0,
        comision: null,
    };

    const toast = useRef(null);
    const [form, setForm] = useState(initialForm);
    const [activeIndexTab, setActiveIndexTab] = useState(null);

    const { paymentPeriods, commissionTypes } = usePaymentSchema();

    const tiposBonosProd = [
        { key: 'Rangos', value: 'Rangos' },
        { key: 'Fijo', value: 'Fijos' },
    ];

    // Métodos
    const onTabChange = (index) => {
        setActiveIndexTab(index);
    };

    return (
        <>
            <Toast ref={toast} />
            <DetallesEsquema
                form={form}
                setForm={setForm}
                tabulator={tabulator}
                schema={schema}
                commissionTypes={commissionTypes}
                listaPuestos={puestos}
                commissionDisabled
                //NEW PROPS
                tabulatorCode={tabulatorCode}
            />

            <div className="mt-5">
                <Accordion
                    activeIndex={activeIndexTab}
                    onTabChange={(e) => onTabChange(e.index)}
                >
                    <AccordionTab header="Ponderaciones">
                        <Ponderaciones
                            tabulator={tabulator}
                            tabulatorCode={tabulatorCode}
                            paymentPeriods={paymentPeriods}
                            sectionName="weightings"
                            sectionCode="secPonderaciones"
                        />
                    </AccordionTab>
                    <AccordionTab header="Bonos de productividad">
                        <BonosProductividad
                            // Valores
                            paymentPeriods={paymentPeriods}
                            tiposBonosProd={tiposBonosProd}
                            tabulator={tabulator}
                            tabType={tabType}
                            tabulatorCode={tabulatorCode}
                            sectionName="productivityBonus"
                            sectionCode="secBonosRango"
                        />
                    </AccordionTab>
                    <AccordionTab header="Comisiones Portátiles">
                        <Comisiones
                            tabulator={tabulator}
                            tabulatorCode={tabulatorCode}
                            sectionName="commissions"
                            sectionCode="secComisionesFijo"
                            paymentPeriods={paymentPeriods}
                            commissionTypes={commissionTypes}
                        />
                    </AccordionTab>
                </Accordion>
            </div>
        </>
    );
};

export default Otif;
