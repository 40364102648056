export const periodTypes = [
  {
    id: 2,
    label: "Quincenal",
    value: "Quincenal",
  },
  {
    id: 3,
    label: "Semanal",
    value: "Semanal",
  },
];
