import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';

const TypeLitersGrid = ({
    // Valores
    typeLiter,
    typeLiters,
    typeLiterNameRef,
    deleteTypeLiterDialog,

    // Componentes
    deleteRowBody,
    typeLiterNameEditor,
    typeLiterDescEditor,
    deleteTypeLiterDialogFooter,

    // Métodos
    handleNewTypeLiterClick,
    editingRows,
    onRowEditChange,
    onRowEditInit,
    onRowEditCancel,
    onRowEditSave,
    rowEditorValidator,
    hideDeleteTypeLiterDialog,
    disableNewButton
}) => (
    <>
        <div className="container px-3">
            <p className="is-size-3 gray-strench">Administración de tipos de litros</p>
            <p className="gray-light">
                Aquí puedes visualizar, agregar, editar y eliminar tipos de litros
                de la plataforma.
            </p>
            <button
                type="button"
                className="button is-link mt-3 primary-color"
                onClick={handleNewTypeLiterClick}
                disabled={disableNewButton()}
            >
                Agregar tipo de litro
            </button>
            <div className="card pt-3">
                <DataTable
                    value={typeLiters}
                    editMode="row"
                    dataKey="id"
                    editingRows={editingRows}
                    onRowEditChange={onRowEditChange}
                    onRowEditInit={onRowEditInit}
                    onRowEditCancel={onRowEditCancel}
                    onRowEditSave={onRowEditSave}
                    rowEditorValidator={rowEditorValidator}
                    className="p-datatable-sm"
                    scrollable
                >
                    <Column field="id" header="ID" style={{ width: '40px' }} />
                    <Column
                        field="name"
                        header="Nombre"
                        editor={(props) =>
                            typeLiterNameEditor(props, typeLiterNameRef)
                        }
                        style={{ width: '150px' }}
                    />
                    <Column
                        field="description"
                        header="Descripción"
                        editor={(props) =>
                            typeLiterDescEditor(props, typeLiterNameRef)
                        }
                        style={{ width: '150px' }}
                    />
                    <Column
                        rowEditor
                        header="Editar"
                        headerStyle={{ width: '7rem' }}
                        style={{ width: '50px' }}
                    />
                    <Column
                        header="Eliminar"
                        body={deleteRowBody}
                        style={{ width: '50px' }}
                    />
                </DataTable>
            </div>
        </div>
        <Dialog
            visible={deleteTypeLiterDialog}
            style={{ width: '450px' }}
            header="Confirmación"
            modal
            footer={deleteTypeLiterDialogFooter}
            onHide={hideDeleteTypeLiterDialog}
        >
            <div className="confirmation-content">
                <i
                    className="pi pi-exclamation-triangle p-mr-3"
                    style={{ fontSize: '2rem' }}
                />
                <span>
                    ¿Desea elimiar el tipo de litro{' '}
                    <b>
                        {typeLiter && typeLiter.typeLiterName
                            ? typeLiter.typeLiterName
                            : ''}
                    </b>
                    ?
                </span>
            </div>
        </Dialog>
    </>
);

export default TypeLitersGrid;
