/* eslint-disable camelcase */
import React, { useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { TabView, TabPanel } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import TabuladorPortatil from "./TabuladorPortatil/TabuladorPortatil";
import TabuladorEstacionario from "./TabuladorEstacionario/TabuladorEstacionario";
import Otif from "./Otif/Otif";
import { usePaymentSchema } from "../../context/PaymentSchemaContext";
import CatalogsService from "../../api/CatalogsService";
import TabulatorOtifService from "../../api/TabulatorOtifService";

const EditPaymentSchemaDialog = () => {
  const toast = useRef(null);
  const tabulatorComponents = {
    tabPortatil: <TabuladorPortatil disabledDetails />,
    tabTanqueEstacionario: <TabuladorEstacionario disabledDetails />,
    tabOtif: <Otif disabledDetails />,
  };

  const {
    paymentSchemaSelected,
    setJobPositions,
    locations,
    setLocations,
    setPaymentPeriods,
    setCommissionTypes,
    setEditing,
  } = usePaymentSchema();
  const { idPlazaFortia, schemaName, tabs } = paymentSchemaSelected;

  const selectedDate = new Date(paymentSchemaSelected.validity);
  const dateToShow = new Date(selectedDate.setDate(selectedDate.getDate() + 1));

  const tabsToShow = tabs
    ? Object.values(tabs).map(({ tabulatorName, tabulatorCode }) => (
        <TabPanel header={tabulatorName} tabulatorCode={tabulatorCode}>
          {tabulatorComponents[tabulatorCode]}
        </TabPanel>
      ))
    : null;

  // Importar catalogos y guardarlos en el contexto

  const fetchLocationsToContext = () => {
    CatalogsService.getLocations().then(({ result }) => {
      setLocations(result);
    });
  };

  const fetchCommissionTypesToContext = () => {
    CatalogsService.getCommissionTypes().then(({ result }) => {
      setCommissionTypes(result);
    });
  };

  const fetchJobPositionsToContext = (location) => {
    CatalogsService.getJobPositions(location).then(({ result }) => {
      setJobPositions(result);
    });
  };

  const fetchPaymentPeriodsToContext = () => {
    CatalogsService.getPaymentPeriods().then(({ result }) => {
      setPaymentPeriods(result);
    });
  };

  const fetchFile = async () => {
    TabulatorOtifService.downloadFile(paymentSchemaSelected.file.fileName)
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${paymentSchemaSelected.file.fileName}`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(({ response }) => {
        toast.current.show({
          severity: "error",
          summary: `${response.data.message}`,
        });
      });
  };

  useEffect(() => {
    fetchLocationsToContext();
    fetchCommissionTypesToContext();
    fetchPaymentPeriodsToContext();
    setEditing(true);
  }, []);

  useEffect(() => {
    if (locations && locations.length > 0) {
      const result = locations.find(
        ({ location_id }) => location_id === idPlazaFortia
      );
      if (result) {
        fetchJobPositionsToContext(result.location_name);
      }
    }
  }, [locations, idPlazaFortia]);

  return (
    <>
      <div className="px-3">
        <Toast ref={toast} />
        <div>
          <div className="mt-3 columns pt-1">
            <div className="column is-one-quarter">
              <h5>Nombre del esquema de pago</h5>
              <InputText value={schemaName} required disabled />
            </div>
            <div className="column is-one-quarter">
              <h5>Plaza</h5>
              <Dropdown
                value={idPlazaFortia}
                options={locations}
                optionLabel="location_name"
                optionValue="location_id"
                placeholder="Selecciona una plaza"
                required
                disabled
              />
            </div>
            <div className="column is-one-quarter">
              <label htmlFor="icon">Vigencia</label>
              <Calendar
                id="validity"
                value={paymentSchemaSelected.validity && new Date(dateToShow)}
                disabled
                showIcon
                dateFormat="yy-mm-dd"
              />
            </div>
            <div className="column is-one-quarter">
              <h5>Archivo</h5>
              <Button
                label={
                  paymentSchemaSelected.file
                    ? paymentSchemaSelected.file.fileName
                    : ""
                }
                className="p-button-text"
                onClick={fetchFile}
                style={
                  paymentSchemaSelected.file
                    ? { display: "block" }
                    : { display: "none" }
                }
              />

              <FileUpload
                mode="basic"
                name="demo[]"
                url="https://primefaces.org/primereact/showcase/upload.php"
                accept="image/*"
                maxFileSize={1000000}
                disabled
                required
              />
            </div>
          </div>

          {/* TABS */}
          <div className="tabview-demo">
            <div className="card">
              <TabView>{tabsToShow}</TabView>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPaymentSchemaDialog;
