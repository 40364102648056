/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useRef, useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import EditPaymentSchemaDialog from '../PaymentSchemes/EditPaymentSchemaDialog';
import CatalogsService from '../../api/CatalogsService';
import TabulatorOtifService from '../../api/TabulatorOtifService';
import { usePaymentSchema } from '../../context/PaymentSchemaContext';

const CommissionGrid = () => {
  const toast = useRef(null);
  const [locations, setLocations] = useState([]);
  const [plazaSelected, setPlazaSelected] = useState(null);
  const [commissions, setCommissions] = useState([]);
  const [editSchemaDialog, setEditSchemaDialog] = useState(false);
  const [locationsDisabled, setLocationsDisabled] = useState(false);
  const {
    setPaymentSchemaSelected,
    setJobPositions,
    jobPositions,
    setEditValuesOnly,
  } = usePaymentSchema();

  const fetchJobPositionsToContext = (location) => {
    CatalogsService.getJobPositions(location).then(({ result }) => {
      setJobPositions(result);
    });
  };

  const filterPaymentSchemaByJobPositionId = (paymentSchema, id) => {
    const _paymentSchema = { ...paymentSchema };

    // FILTRAR TABULADOR TANQUE ESTACIONARIO
    // COMISIONES
    const rangeDetailsSchema =
      _paymentSchema.tabs.tabTanqueEstacionario.sections.commissions.rangeDetailsSchema.filter(
        ({ jobPositionFortia }) => jobPositionFortia === id
      );

    console.log(rangeDetailsSchema, id);

    _paymentSchema.tabs.tabTanqueEstacionario.sections.commissions.rangeDetailsSchema =
      [...rangeDetailsSchema];

    // BONOS LITRAJE SURTIDO

    const litersSoldSchema =
      _paymentSchema.tabs.tabTanqueEstacionario.sections.litersSold.litersSoldSchema.filter(
        ({ jobPositionFortia }) => jobPositionFortia === id
      );

    _paymentSchema.tabs.tabTanqueEstacionario.sections.litersSold.litersSoldSchema =
      [...litersSoldSchema];

    return _paymentSchema;
  };

  const editPaymentSchema = (rowData) => {
    TabulatorOtifService.getPaymentSchema(rowData.idPaymentSchema)
      .then(({ data }) => {
        const { message, status, result } = data;

        const jobPosition = jobPositions.find(
          ({ work_station_name }) => work_station_name === rowData.jobPosition
        );

        // Cambiar tabs de arreglo a objeto
        const tabs = [...result.tabs];
        let newTabs = {};

        tabs.forEach((tab) => {
          newTabs = { ...newTabs, [tab.tabulatorCode]: tab };
        });

        result.tabs = newTabs;

        const filteredPaymentSchema = filterPaymentSchemaByJobPositionId(
          result,
          jobPosition.work_station_id
        );

        setPaymentSchemaSelected({ ...filteredPaymentSchema });
        toast.current.show([
          {
            severity: 'success',
            summary: status,
            detail: message,
            life: 3000,
          },
        ]);
      })
      .catch(({ response }) => {
        const { message, status } = response.data;
        toast.current.show([
          {
            severity: 'error',
            summary: status,
            detail: message,
            life: 3000,
          },
        ]);
      });

    setEditSchemaDialog(true);
  };

  const hideEditSchemaDialog = () => {
    setEditSchemaDialog(false);
  };

  const actionsBody = (rowData) => (
    <>
      <Button
        icon="pi pi-eye"
        className="p-button-rounded p-button-secondary"
        onClick={() => editPaymentSchema(rowData)}
      />
    </>
  );

  const fetchLocations = () => {
    CatalogsService.getLocations().then(({ result }) => {
      setLocations(result);
    });
  };

  const fetchCommissions = (id) => {
    TabulatorOtifService.getCommissions(id)
      .then(({ data }) => {
        const { message, status, result } = data;

        setCommissions(result);
        toast.current.show([
          {
            severity: 'success',
            summary: status,
            detail: message,
            life: 3000,
          },
        ]);
      })
      .catch(({ response }) => {
        const { message, status } = response.data;
        setCommissions(null);
        toast.current.show([
          {
            severity: 'error',
            summary: status,
            detail: message,
            life: 3000,
          },
        ]);
      });
  };

  const handlePlazaChange = (e) => {
    setPlazaSelected(e.value);
    fetchCommissions(e.value.location_id);
  };

  useEffect(() => {
    fetchLocations();
    setEditValuesOnly(true);

    const authenticatedUser = JSON.parse(
      sessionStorage.getItem('authenticatedUser')
    );
    const rol =
      authenticatedUser && authenticatedUser.rol ? authenticatedUser.rol : null;

    if (rol === 'ROLE_GERENTE_DE_PLAZA') {
      const locationValue = locations.find(
        (element) => element.location_id === authenticatedUser.location
      );
      setLocationsDisabled(true);
      setPlazaSelected(locationValue);
      fetchCommissions(authenticatedUser.location);
    }
  }, []);

  useEffect(() => {
    if (locations && locations.length > 0 && plazaSelected) {
      const result = locations.find(
        ({ location_id }) => location_id === plazaSelected.location_id
      );
      if (result) {
        fetchJobPositionsToContext(result.location_name);
      }
    }
  }, [locations, plazaSelected]);

  return (
    <>
      <div className="container px-3">
        <Toast ref={toast} />
        <p className="is-size-3 gray-strench">Administración de comisiones</p>
        <p className="gray-light">
          Aquí podrás conocer las comisiones que cuentan los puestos
          registrados en la prenómina.
        </p>
        <div className="column is-one-third full-width no-padding-left">
          <h5>Plaza</h5>
          <Dropdown
            value={plazaSelected}
            options={locations}
            onChange={handlePlazaChange}
            optionLabel="location_name"
            placeholder="Selecciona una plaza"
            disabled={locationsDisabled}
          />
        </div>
        <div className="card pt-3">
          <DataTable
            value={commissions}
            dataKey="id"
            emptyMessage="Datos no encontrados"
            paginator
            rows={10}
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rowsPerPageOptions={[10, 25, 50]}
          >
            <Column
              field="idPaymentSchema"
              header="ID"
              sortable
              headerStyle={{ width: '5rem' }}
            />
            <Column header="Puesto" field="jobPosition" />
            <Column header="Plaza" field="plaza" />
            <Column body={actionsBody} style={{ width: '100px' }} />
          </DataTable>
        </div>
      </div>
      <Dialog
        visible={editSchemaDialog}
        maximized
        modal
        className="p-fluid"
        onHide={hideEditSchemaDialog}
      >
        <EditPaymentSchemaDialog />
      </Dialog>
    </>
  );
};

export default CommissionGrid;
