import axios from "../axios/custom-axios";
import { API_URL_USERS } from "../constants";

class AuthenticationService {
  // Solicitar el token de autorización
  executeJwtAuthenticationService(username, password) {
    return axios.post(`${API_URL_USERS}/auth/login`, {
      username,
      password,
    });
  }
}

export default new AuthenticationService();
