/* eslint-disable no-plusplus */
/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useRef } from 'react';
import { Container, Box } from 'react-bulma-components';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import MainLayout from '../Layouts/MainLayout';
import PayrollPeriodGrid from './PayrollPeriodGrid';
import PayrollPeriodService from '../../api/PayrollPeriodService';
import CatalogsService from '../../api/CatalogsService';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';

const PayrollPeriodManagement = () => {
    // HOOKS
    const [payrollPeriods, setPayrollPeriods] = useState([]);
    const [locations, setLocations] = useState([]);
    const [originalRows, setOriginalRows] = useState({});
    const emptyPayrollPeriod = {
        id: null,
        periodName: '',
        periodType: '',
        idLocationFortia: '',
        exercise: new Date().getFullYear(),
        periods: [],
    };
    const [payrollPeriod, setPayrollPeriod] = useState(emptyPayrollPeriod);
    const [payrollPeriodDialog, setPayrollPeriodDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [deletePayrollPeriodDialog, setDeletePayrollPeriodDialog] =
        useState(false);
    const [dates, setDates] = useState([]);
    const toast = useRef(null);
    const [exercisePeriods, setExercisePeriods] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const periodTypes = [
        { id: 'Semanal', name: 'Semanal' },
        { id: 'Quincenal', name: 'Quincenal' },
    ];
    const fetchLocations = () => {
        CatalogsService.getLocations().then(({ result }) => {
            setLocations(result);
        });
    };

    const findLocation = (rowData) => {
        const locationName = locations.find(
            (location) => location.location_id === rowData.idLocationFortia
        );
        return <p>{locations.length > 0 && locationName?.location_name}</p>;
    };

    const fetchPayrollPeriods = async () => {
        PayrollPeriodService.getPayrollPeriods()
            .then(({ data: { result } }) => {
                setPayrollPeriods(result);
                if(payrollPeriodDialog){
                    const p = result.find((pr) => pr.id === payrollPeriod.id)
                    setPayrollPeriod(p)
                }
            })
            .catch(
                ({
                    response: {
                        data: {
                            message,
                            error: { errorCode },
                        },
                    },
                }) => {
                    if (errorCode !== 'PAYROLL001')
                        toast.current.show({
                            severity: 'error',
                            summary: `${message}`,
                        });
                }
            );
    };

    const hidePayrollPeriodDialog = () => {
        setSubmitted(false);
        setPayrollPeriodDialog(false);
        setExercisePeriods([]);
        setDates([]);
    };

    // MÉTODOS
    const handleNewPayrollPeriodClick = () => {
        setPayrollPeriod(emptyPayrollPeriod);
        setSubmitted(false);
        setPayrollPeriodDialog(true);
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        const payrollPeriodAux = { ...payrollPeriod };
        payrollPeriodAux[`${name}`] = val;

        setPayrollPeriod(payrollPeriodAux);
    };

    const onInputNumberChange = (value, name) => {
        const val = value || 0;
        const payrollPeriodAux = { ...payrollPeriod };
        payrollPeriodAux[`${name}`] = val;

        setPayrollPeriod(payrollPeriodAux);
    };

    const addDays = (date, days) => {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    };

    const formatDate = (newDate) => {
        const date = new Date(newDate);
        const year = date.getFullYear().toString();
        let month = (date.getMonth() + 1).toString();
        let day = date.getDate().toString();
        month = month.length === 1 ? `0${month}` : month;
        day = day.length === 1 ? `0${day}` : day;

        return `${day}/${month}/${year}`;
    };

    const onPaymentDateChange = (value, name) => {
        const daysOfYear = [];
        const exercisePeriodsAux = [];
        if (value !== null) {
            const end = new Date(`December 31, ${new Date().getFullYear()}`);

            let id = 1;
            for (
                let d = new Date(new Date().getFullYear(), 0, 1);
                d <= end;
                d.setDate(d.getDate() + 1)
            ) {
                const result = addDays(d, value);
                daysOfYear.push(addDays(d, value));
                exercisePeriodsAux.push({
                    id,
                    startDate: formatDate(d),
                    endDate: formatDate(result),
                });
                d = result;
                id += 1;
            }
        }
        setExercisePeriods(exercisePeriodsAux);
        onInputNumberChange(value, name);
    };

    const setDatesPayrollPeriod = (periods) => {
        const _dates = periods.map((per)=> new Date(`${per.paymentDate} 00:00:00`));
        setDates(_dates)
    }

    const editPayrollPeriod = (rowData) => {
        onPaymentDateChange(rowData.paymentDate, 'paymentDate');
        setPayrollPeriod({ ...rowData });
        setDatesPayrollPeriod(rowData.periods)
        setPayrollPeriodDialog(true);
    };

    addLocale('es', {
            firstDayOfWeek: 1,
            dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
            monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        });

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < payrollPeriods.length; i++) {
            if (payrollPeriods[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const handleValidation = () => {
        let error = false;
        const payrollPeriodAux = { ...payrollPeriod };

        if (payrollPeriodAux.periodType.length === 0) {
            error = true;
        }
        if (payrollPeriodAux.periodName.trim().length === 0) {
            error = true;
        }
        if (payrollPeriodAux.idLocationFortia.length === 0) {
            error = true;
        }
        return error;
    };

    const savePayrollPeriod = () => {
        setSubmitted(true);
        const payrollPeriodsAux = payrollPeriods ? [...payrollPeriods] : [];
        const payrollPeriodAux = { ...payrollPeriod };
        
        if (handleValidation()) {
            return;
        }

        if (payrollPeriod.id) {
            PayrollPeriodService.updatePayrollPeriod(payrollPeriodAux)
                .then((response) => {
                    if (response.data.status === 'OK') {
                        const index = findIndexById(payrollPeriod.id);
                        payrollPeriodsAux[index] = payrollPeriodAux;

                        setPayrollPeriods(payrollPeriodsAux);

                        toast.current.show({
                            severity: 'success',
                            summary: `${response.data.message}`,
                        });
                    }
                })
                .catch((error) => {
                    toast.current.show({
                        severity: 'error',
                        summary: `${error.response.data.message}`,
                    });
                });
        } else {
            PayrollPeriodService.createPayrollPeriod(payrollPeriodAux)
                .then((response) => {
                    if (response.data.status === 'OK') {
                        const _payrollPeriod = response.data.result;
                        payrollPeriodsAux.push({ ..._payrollPeriod });
                        setPayrollPeriod(_payrollPeriod);
                        setPayrollPeriods(payrollPeriodsAux);

                        toast.current.show({
                            severity: 'success',
                            summary: `${response.data.message}`,
                        });
                    }
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: 'error',
                        summary: `${error.response.data.message}`,
                    });
                });
        }
    };

    const confirmDeletePayrollPeriod = (selectedPayrollPeriod) => {
        setPayrollPeriod(selectedPayrollPeriod);
        setDeletePayrollPeriodDialog(true);
    };

    const hideDeletePayrollPeriodDialog = () =>
        setDeletePayrollPeriodDialog(false);

    const deletePayrollPeriod = () => {
        PayrollPeriodService.deletePayrollPeriod(payrollPeriod.id)
            .then((response) => {
                if (response.data.status === 'OK') {
                    toast.current.show({
                        severity: 'success',
                        summary: `${response.data.message}`,
                    });
                    const payrollPeriodsAux = payrollPeriods.filter(
                        (payrollPeriodAux) => payrollPeriodAux.id !== payrollPeriod.id
                    );
                    setPayrollPeriods(payrollPeriodsAux);
                }
            })
            .catch((error) => {
                toast.current.show({
                    severity: 'error',
                    summary: `${error.response.data.message}`,
                });
            });

        
        setDeletePayrollPeriodDialog(false);
    };

    const onGlobalFilterChange = (value) => {
        setGlobalFilter(value);
    };

    const dateToString = (dateObj) => {
        const year = dateObj.getFullYear();
        const month = dateObj.getMonth() + 1;
        const date = dateObj.getDate();
        return `${year}-${month < 10 ? '0' : ''}${month}-${
            date < 10 ? '0' : ''
        }${date}`;
    };

    const handleDateChange = (date, index, data, field) => {
        const _payrollPeriod = { ...payrollPeriod };
        const _periods = [...payrollPeriod.periods];
        _periods[index][field] = dateToString(date);
        _payrollPeriod.periods = [..._periods];
        setPayrollPeriod(_payrollPeriod);
        setDatesPayrollPeriod(_payrollPeriod.periods)
    };

    const onRowEditInit = (props) => {
        const newOriginalRows = {
            ...originalRows,
            [props.index]: { ...props.data },
        };
        setOriginalRows(newOriginalRows);
    };

    const onRowEditCancel = (props) => {
        const newPayrollPeriod = { ...payrollPeriod };
        newPayrollPeriod.periods[props.index] = originalRows[props.index];
        const newOriginalRows = { ...originalRows };
        delete newOriginalRows[props.index];
        setPayrollPeriod(newPayrollPeriod);
        setOriginalRows(newOriginalRows);
    };

    // COMPONENTES

    const editBody = (rowData) => (
        <>
            <Button
                icon="pi pi-pencil"
                className="pencil p-mr-2"
                onClick={() => editPayrollPeriod(rowData)}
            />
        </>
    );

    const deleteBody = (rowData) => (
        <>
            <Button
                icon="pi pi-trash"
                className="trash"
                onClick={() => confirmDeletePayrollPeriod(rowData)}
            />
        </>
    );

    const deletePayrollPeriodDialogFooter = (
        <>
            <Button
                label="No"
                icon="pi pi-times"
                className="p-button-text"
                onClick={hideDeletePayrollPeriodDialog}
            />
            <Button
                label="Yes"
                icon="pi pi-check"
                className="p-button-text"
                onClick={deletePayrollPeriod}
            />
        </>
    );

    const payrollPeriodDialogFooter = (
        <>
            <Button
                label="Cerrar"
                icon="pi pi-times"
                className="p-button-text"
                onClick={hidePayrollPeriodDialog}
            />
        </>
    );

    const onRowEditComplete = (e) => {
        PayrollPeriodService.updatePayrollPeriodDates(e.data)
            .then((response) => {
                fetchPayrollPeriods()
                toast.current.show({
                    severity: 'success',
                    summary: `${response.data.message}`,
                });
            })
            .catch((error) => {
                const newPayrollPeriod = { ...payrollPeriod };
                const newPeriods = [...newPayrollPeriod.periods];
                newPeriods[e.index] = {
                    ...originalRows[e.index],
                };
                newPayrollPeriod.periods = [...newPeriods];
                setPayrollPeriod(newPayrollPeriod);
                toast.current.show({
                    severity: 'error',
                    summary: `${error.response.data.message}`,
                });
            });
    };

    const dateEditor = (props) => {
        const date = new Date(props.rowData[props.field]);
        date.setDate(date.getDate() + 1);
        return (
            <Calendar
                id="basic"
                value={date}
                dateFormat="yy/mm/dd"
                onChange={(e) =>
                    handleDateChange(
                        e.value,
                        props.rowIndex,
                        props.rowData,
                        props.field
                    )
                }
            />
        );
    };

    useEffect(() => {
        fetchLocations();
        fetchPayrollPeriods();
    }, []);

    return (
        <MainLayout>
            <Box>
                <Container>
                    <Toast ref={toast} />
                    <PayrollPeriodGrid
                        // Valores
                        payrollPeriods={payrollPeriods}
                        payrollPeriod={payrollPeriod}
                        payrollPeriodDialog={payrollPeriodDialog}
                        deletePayrollPeriodDialog={deletePayrollPeriodDialog}
                        periodTypes={periodTypes}
                        submitted={submitted}
                        locations={locations}
                        dates={dates}
                        exercisePeriods={exercisePeriods}
                        globalFilter={globalFilter}
                        // Métodos
                        handleNewPayrollPeriodClick={
                            handleNewPayrollPeriodClick
                        }
                        hidePayrollPeriodDialog={hidePayrollPeriodDialog}
                        hideDeletePayrollPeriodDialog={
                            hideDeletePayrollPeriodDialog
                        }
                        onInputChange={onInputChange}
                        onPaymentDateChange={onPaymentDateChange}
                        onGlobalFilterChange={onGlobalFilterChange}
                        savePayrollPeriod={
                            savePayrollPeriod
                        }
                        onRowEditComplete={onRowEditComplete}
                        onRowEditInit={onRowEditInit}
                        onRowEditCancel={onRowEditCancel}
                        // Componentes
                        editBody={editBody}
                        deleteBody={deleteBody}
                        payrollPeriodDialogFooter={payrollPeriodDialogFooter}
                        deletePayrollPeriodDialogFooter={
                            deletePayrollPeriodDialogFooter
                        }
                        findLocation={findLocation}
                        dateEditor={dateEditor}
                    />
                </Container>
            </Box>
        </MainLayout>
    );
};

export default PayrollPeriodManagement;
