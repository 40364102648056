import React from "react";
import EsquemaRangoSimple from "../Comunes/EsquemaRangoSimple";

const Comisiones = ({ tabulatorCode, sectionName, tabulator, sectionCode }) => (
  <>
    <EsquemaRangoSimple
      tabulatorCode={tabulatorCode}
      sectionName={sectionName}
      tabulator={tabulator}
      sectionCode={sectionCode}
    />
  </>
);

export default Comisiones;
