import React from "react";
import IdleTimerContainer from "../IdleTimer/IdleTimerContainer";
import Header from "../Header";

const MainLayout = ({ children }) => (
  <>
    <IdleTimerContainer />
    <Header />
    <div id="jobApp">
      <main>{children}</main>
    </div>
  </>
);

export default MainLayout;
