/* eslint-disable import/no-cycle */
import React from 'react';
import { Container, Box } from 'react-bulma-components';
import SchemasGrid from './SchemasGrid';
import MainLayout from '../Layouts/MainLayout';
import { PaymentSchemaProvider } from '../../context/PaymentSchemaContext';

const PaymentSchemesManagement = () => (
    <MainLayout>
        <Box>
            <Container>
                <SchemasGrid />
            </Container>
        </Box>
    </MainLayout>
);

export default (props) => (
    <PaymentSchemaProvider>
        <PaymentSchemesManagement {...props} />
    </PaymentSchemaProvider>
);
