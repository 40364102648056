import axios from '../axios/custom-axios';
import { API_URL_CONCEPTS } from '../constants';

class BonoConceptsService {
    async getBonoConcepts() {
        const response = await axios.get(`${API_URL_CONCEPTS}/bono-concepts`);
        return response;
    }

    async createBonoConcept(body) {
        const response = await axios.post(
            `${API_URL_CONCEPTS}/bono-concepts`,
            body
        );
        return response;
    }

    async updateBonoConcept(body) {
        const response = await axios.put(
            `${API_URL_CONCEPTS}/bono-concepts/${body.id}`,
            body
        );
        return response;
    }

    async deleteBonoConcept(id) {
        const response = await axios.delete(
            `${API_URL_CONCEPTS}/bono-concepts/${id}`
        );
        return response;
    }
}

export default new BonoConceptsService();
