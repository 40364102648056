import React, { useState } from "react";
import { Container, Box } from "react-bulma-components";
import { Button } from "primereact/button";
// eslint-disable-next-line import/no-cycle
import MainLayout from "../Layouts/MainLayout";
import ServiceOpening from "./ServiceOpening";
import ServiceOpeningGrid from "./ServiceOpeningGrid";

const ServiceOpeningManagement = () => {
  const [showGrid, setShowGrid] = useState(true);
  const [openingSelected, setOpeningSelected] = useState({});
  const [openings, setOpenings] = useState([]);
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState(null);
  const [selectedShift, setSelectedShift] = useState(null);
  const [shifts, setShifts] = useState([]);
  const [users, setUsers] = useState([]);
  const [complements, setComplements] = useState([]);
  const [sectores, setSectores] = useState([]);
  const [zonaTarifas, setZonaTarifa] = useState([]);
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const [catalogs, setCatalogs] = useState({
    cars: [],
    drivers: [],
    driverAssistants: [],
    thirds: [],
    shifts: [],
    zones: [],
  });

  const headerComponent = (
    <div className="mb-5">
      <p className="is-size-3 gray-strench">Control de aperturas</p>
      <p className="gray-light">
        Aquí puedes visualizar, agregar, editar y eliminar las aperturas de la
        plataforma.
      </p>
    </div>
  );

  return (
    <MainLayout>
      <Box>
        <Container>
          {!showGrid && (
            <Button onClick={() => setShowGrid(true)}>Regresar</Button>
          )}

          {showGrid ? (
            <ServiceOpeningGrid
              showGrid={showGrid}
              headerComponent={headerComponent}
              setShowGrid={setShowGrid}
              setOpeningSelected={setOpeningSelected}
              openings={openings}
              setOpenings={setOpenings}
              locations={locations}
              setLocations={setLocations}
              location={location}
              setLocation={setLocation}
              selectedShift={selectedShift}
              setSelectedShift={setSelectedShift}
              shifts={shifts}
              setShifts={setShifts}
              complements={complements}
              setComplements={setComplements}
              users={users}
              setUsers={setUsers}
              catalogs={catalogs}
              setCatalogs={setCatalogs}
              sectores={sectores}
              setSectores={setSectores}
              zonaTarifas={zonaTarifas}
              setZonaTarifa={setZonaTarifa}
              date={date}
              setDate={setDate}
              setLoading={setLoading}
            />
          ) : (
            <ServiceOpening
              headerComponent={headerComponent}
              openingSelected={openingSelected}
              setShowGrid={setShowGrid}
              setOpeningSelected={setOpeningSelected}
              openings={openings}
              setOpenings={setOpenings}
              locations={locations}
              setLocations={setLocations}
              location={location}
              setLocation={setLocation}
              selectedShift={selectedShift}
              shifts={shifts}
              setShifts={setShifts}
              complements={complements}
              setComplements={setComplements}
              users={users}
              setUsers={setUsers}
              catalogs={catalogs}
              loading={loading}
            />
          )}
        </Container>
      </Box>
    </MainLayout>
  );
};

export default ServiceOpeningManagement;
