import React from "react";

const Circle = ({ text }) => (
  <div
    style={{
      width: "50px",
      height: "50px",
      backgroundColor: "gray",
      borderRadius: "25px",
      justifyContent: "center",
      textAlign: "center",
      alignItems: "center",
      marginLeft: "5px",
    }}
  >
    <p style={{ paddingTop: "14px", color: "white" }}>{text}</p>
  </div>
);

export default Circle;
