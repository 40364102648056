/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { TabView, TabPanel } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { Dropdown } from "primereact/dropdown";
import { Steps } from "primereact/steps";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { classNames } from "primereact/utils";
import CatalogsService from "../../api/CatalogsService";
import TabulatorType from "./TabulatorSections";
import TabulatorOtifService from "../../api/TabulatorOtifService";
import { usePaymentSchema } from "../../context/PaymentSchemaContext";
import { emptyTabulator } from "../../constants";
import { periodTypes } from "./constants";

const PaymentSchemaDialog = ({
  schema,
  setSchema,
  fetchSchemas,
  setSchemaDialog,
}) => {
  const toast = useRef(null);
  const [plazaSelected, setPlazaSelected] = useState(null);
  const [puestosSelected, setPuestosSelected] = useState([]);
  const [puestos, setPuestos] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [savedPayment, setSavedPayment] = useState(false);
  const upload = useRef(null);
  const {
    paymentSchemaSelected,
    setPaymentSchemaSelected,
    setJobPositions,
    locations,
    setLocations,
    setPaymentPeriods,
    setCommissionTypes,
    setEditing,
  } = usePaymentSchema();

  const { idPlazaFortia } = paymentSchemaSelected;

  const today = new Date();
  const minDate = new Date(today);
  const maxDate = new Date(today.setFullYear(today.getFullYear() + 1));
  const selectedDate = new Date(schema.validity);
  const dateToShow = new Date(selectedDate.setDate(selectedDate.getDate() + 1));

  // Importar catalogos y guardarlos en el contexto

  const fetchLocationsToContext = () => {
    CatalogsService.getLocations().then(({ result }) => {
      setLocations(result);
    });
  };

  const fetchCommissionTypesToContext = () => {
    CatalogsService.getCommissionTypes().then(({ result }) => {
      setCommissionTypes(result);
    });
  };

  const fetchJobPositionsToContext = (location) => {
    CatalogsService.getJobPositions(location).then(({ result }) => {
      setJobPositions(result);
    });
  };

  const fetchPaymentPeriodsToContext = () => {
    CatalogsService.getPaymentPeriods().then(({ result }) => {
      setPaymentPeriods(result);
    });
  };

  useEffect(() => {
    fetchLocationsToContext();
    fetchCommissionTypesToContext();
    fetchPaymentPeriodsToContext();
  }, []);

  useEffect(() => {
    if (locations && locations.length > 0) {
      const result = locations.find(
        ({ location_id }) => location_id === idPlazaFortia
      );
      if (result) {
        fetchJobPositionsToContext(result.location_name);
      }
    }
  }, [locations]);

  const [tabuladores, setTabuladores] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const initialItemStep = {
    label: "Información General",
    id: "infoGeneral",
  };
  const [itemsSteps, setItemsSteps] = useState([initialItemStep]);
  const [tabActive, setTabActive] = useState(itemsSteps[activeIndex].id);
  const [tabActiveContent, setTabActiveContent] = useState(
    itemsSteps[activeIndex].content
  );

  const activateTab = () => {
    setActiveIndex(activeIndex + 1);
    setTabActive(itemsSteps[activeIndex + 1].id);
    setTabActiveContent(itemsSteps[activeIndex + 1].content);
  };

  const fetchTabulatorsPerJobPosition = async (idLocation) => {
    const response = await CatalogsService.getTabulatorTypes(idLocation);
    const tabs = [];

    switch (response.data.status) {
      case "OK":
        response.data.result.map((item) => (
          <>
            {tabs.push({
              id: item.id,
              label: item.tabulatorName,
            })}
            {itemsSteps.push({
              id: item.id,
              label: item.tabulatorName,
            })}
          </>
        ));

        break;
      case "ERROR":
        toast.current.show({
          severity: "error",
          summary: `${response.data.message}`,
        });
        setItemsSteps([initialItemStep]);
        break;
      default:
    }

    setTabuladores(tabs);
  };

  const fetchTabulatorSectionsPerPaymentSchema = async (
    idLocation,
    idPaymentSchema
  ) => {
    const response = await CatalogsService.getTabulatorSections(
      idLocation,
      idPaymentSchema
    );
    const tabs = [];
    const items = [initialItemStep];

    const schemaAux = { ...schema };
    schemaAux.newSchema = false;
    schemaAux.id = idPaymentSchema;

    response.result.map((item) => (
      <>
        {tabs.push({
          id: item.id,
          label: item.tabulatorName,
        })}
        {items.push({
          id: item.id,
          label: item.tabulatorName,
          content: TabulatorType({
            tabulator: item,
            tabType: item.tabulatorCode,
            schema: schemaAux,
            puestos,
            puestosSelected,
          }),
        })}
      </>
    ));

    setTabuladores([...tabs]);
    setItemsSteps([...items]);

    return response;
  };

  const handleValidationPaymentSchema = () => {
    let error = false;
    const schemaAux = { ...schema };

    if (schemaAux.schemaName.trim().length === 0) {
      error = true;
    }
    if (schemaAux.validity === null) {
      error = true;
    }
    return error;
  };

  const savePaymentSchema = () => {
    setSubmitted(true);
    const schemaAux = { ...schema };

    if (handleValidationPaymentSchema()) {
      return;
    }
    if (schemaAux.newSchema) {
      TabulatorOtifService.createPaymentSchema(schemaAux)
        .then((response) => {
          switch (response.data.status) {
            case "OK": {
              schemaAux.newSchema = false;
              schemaAux.id = response.data.result.id;
              setSchema(schemaAux);

              fetchTabulatorSectionsPerPaymentSchema(
                response.data.result.idPlazaFortia,
                response.data.result.id
              ).then((newResponse) => {
                let paymentSchemaSelectedAux = {
                  ...paymentSchemaSelected,
                };

                const tabsResult = [...newResponse.result];
                let tabsToAdd = {};

                tabsResult.forEach((tab) => {
                  let sectionsToAdd = {};
                  tab.sections.forEach((section) => {
                    sectionsToAdd = {
                      ...sectionsToAdd,
                      [section.sectionCode]: {
                        ...section,
                      },
                    };
                  });

                  tabsToAdd = {
                    ...tabsToAdd,
                    [tab.tabulatorCode]: {
                      ...emptyTabulator,
                      tabulatorName: tab.tabulatorName,
                      tabulatorCode: tab.tabulatorCode,
                      idTabulator: tab.idTabulator,
                      idTabulatorType: tab.id,
                      sections: sectionsToAdd,
                    },
                  };
                });

                // eslint-disable-next-line no-case-declarations
                paymentSchemaSelectedAux = {
                  ...paymentSchemaSelected,
                  id: response.data.result.id,
                  idPaymentSchema: response.data.result.id,
                  period_name: response.data.result.period_name,
                };

                paymentSchemaSelectedAux.tabs = tabsToAdd;

                setPaymentSchemaSelected({
                  ...paymentSchemaSelectedAux,
                });

                setSubmitted(false);
                setSavedPayment(true);

                toast.current.show({
                  severity: "success",
                  summary: `${response.data.message}`,
                });

                fetchSchemas();
              });

              break;
            }
            default:
          }
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: `${error.response.data.message}`,
          });
        });
    } else {
      activateTab();
    }
  };

  const fetchFile = async () => {
    TabulatorOtifService.downloadFile(schema.file.fileName)
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${schema.file.fileName}`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(({ response }) => {
        toast.current.show({
          severity: "error",
          summary: `${response.data.message}`,
        });
      });
  };

  const onEditorValueChange = (value, field) => {
    const schemaAux = { ...schema };
    schemaAux[`${field}`] = value;

    setSchema(schemaAux);
  };

  const handlePlazaChange = (e) => {
    setPlazaSelected(e.value);
    onEditorValueChange(e.value.location_id, "idPlazaFortia");
    fetchJobPositionsToContext(e.value.location_name);
    fetchTabulatorsPerJobPosition(e.value.location_id);
  };

  const handlePeriodChange = (e) => {
    setSchema({ ...schema, period_name: e.value });
    onEditorValueChange(e.value, "period_name");
  };

  const dateToString = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month < 10 ? `0${month}` : month}-${
      day < 10 ? `0${day}` : day
    }`;
  };

  const handleDateChange = (value, field) => {
    onEditorValueChange(dateToString(value), field);
  };

  const next = () => {
    activateTab();
  };

  useEffect(() => {
    setEditing(false);
  }, []);

  useEffect(() => {
    const schemaAux = { ...schema };
    if (
      itemsSteps[activeIndex + 1] &&
      itemsSteps[activeIndex + 1].content &&
      schemaAux.file !== null
    ) {
      activateTab();
    }
  }, itemsSteps);

  const prev = () => {
    setActiveIndex(activeIndex - 1);
    setTabActive(itemsSteps[activeIndex - 1].id);
    setTabActiveContent(itemsSteps[activeIndex - 1].content);
  };

  const dynamicTabs = tabuladores.map((item) => (
    <TabPanel
      header={item.label}
      id={item.id}
      disabled={tabActive === item.id ? "" : true}
    >
      {tabActiveContent}
    </TabPanel>
  ));

  const lastStepButton = () => {
    const style = {
      margin: "0 8px",
      background: "#F75C28",
      backgroundColor: "#F75C28",
      fontWeight: "bold",
      border: "none",
    };

    return (
      <Button style={style} disabled={false} onClick={() => prev()}>
        Anterior
      </Button>
    );
  };

  const nextStepButton = () => {
    const style = {
      margin: "0 8px",
      background: "#2075F7",
      backgroundColor: "#2075F7",
      fontWeight: "bold",
      border: "none",
    };

    return (
      <Button style={style} type="primary" onClick={() => next()}>
        Siguiente
      </Button>
    );
  };

  const finishButton = () => {
    const style = {
      margin: "0 8px",
      background: "#00B851",
      backgroundColor: "#00B851",
      fontWeight: "bold",
      border: "none",
    };

    return (
      <Button
        style={style}
        type="primary"
        onClick={() => setSchemaDialog(false)}
      >
        Finalizar
      </Button>
    );
  };

  const uploadFile = async (file) => {
    const schemaAux = { ...schema };
    const formData = new FormData();

    formData.append("file", file);
    formData.append("idPaymentSchema", schemaAux.id);

    TabulatorOtifService.uploadFile(formData)
      .then((response) => {
        const { message, result } = response.data;
        onEditorValueChange(result, "file");
        upload.current.clear();
        toast.current.show({
          severity: "success",
          summary: `${message}`,
        });
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: `${error.response.data.message}`,
        });
      });
  };

  const fileUploadHandler = ({ files }) => {
    const [file] = files;
    uploadFile(file);
  };

  const handleValidation = () => {
    let error = false;

    const schemaAux = { ...schema };

    if (schemaAux.schemaName.trim().length === 0) {
      error = true;
    }
    if (schemaAux.validity === null) {
      error = true;
    } else {
      setSubmitted(true);
    }
    if (!schema.idPlazaFortia) {
      error = true;
    }
    return error;
  };

  useEffect(() => {
    if (!handleValidation()) {
      savePaymentSchema();
    }
  }, [schema.validity]);

  return (
    <>
      <div className="px-3">
        <Toast ref={toast} />
        <div className="pt-5">
          <Steps model={itemsSteps} activeIndex={activeIndex} />
        </div>
        <div className="p-grid">
          <div className="p-col" />
          <div className="p-col" />
          <div className="p-col">
            <div className="columns mt-5">
              <div className="column">
                {activeIndex > 0 && lastStepButton()}
              </div>
              <div className="column">
                {activeIndex < itemsSteps.length - 1 && nextStepButton()}
              </div>
              <div className="column">
                {itemsSteps.length > 1 &&
                  activeIndex === itemsSteps.length - 1 &&
                  finishButton()}
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="mt-3 columns pt-1">
            <div className="column ">
              <h5>Nombre del esquema de pago</h5>
              <InputText
                value={schema.schemaName}
                disabled={tabActive !== "infoGeneral"}
                onChange={(e) =>
                  onEditorValueChange(e.target.value, "schemaName")
                }
                required
                className={classNames({
                  "p-invalid": submitted && !schema.schemaName,
                })}
              />
              {submitted && schema.schemaName.trim().length === 0 && (
                <small className="p-error">Nombre es requerido.</small>
              )}
            </div>
            <div className="column ">
              <h5>Plaza</h5>
              <Dropdown
                value={plazaSelected}
                options={locations}
                onChange={handlePlazaChange}
                optionLabel="location_name"
                placeholder="Selecciona una plaza"
                disabled={tabActive !== "infoGeneral" || !schema.newSchema}
                required
                className={classNames({
                  "p-invalid": submitted && !schema.idPlazaFortia,
                })}
                filter
                showClear
                filterBy="location_name"
              />
              {submitted && !schema.idPlazaFortia && (
                <small className="p-error">Plaza es requerido.</small>
              )}
            </div>
            <div className="column">
              <label htmlFor="period_name">Periodo de pago</label>
              <Dropdown
                value={schema.period_name || null}
                options={[...periodTypes]}
                placeholder="Selecciona un tipo de pago"
                onChange={handlePeriodChange}
                disabled={tabActive !== "infoGeneral" || !schema.newSchema}
                required
                className={classNames({
                  "p-invalid": submitted && !schema.idPlazaFortia,
                })}
              />
            </div>
            <div className="column">
              <label htmlFor="icon">Vigencia</label>
              <Calendar
                id="validity"
                value={schema.validity && new Date(dateToShow)}
                onChange={(e) => handleDateChange(e.value, "validity")}
                disabled={tabActive !== "infoGeneral" || !schema.newSchema}
                showIcon
                dateFormat="yy-mm-dd"
                minDate={minDate}
                maxDate={maxDate}
              />
            </div>

            <div className="column">
              <h5>Archivo</h5>
              <Button
                label={schema.file ? schema.file.fileName : ""}
                className="p-button-text"
                onClick={fetchFile}
                style={schema.file ? { display: "block" } : { display: "none" }}
              />
              <FileUpload
                mode="basic"
                maxFileSize={1000000}
                disabled={!savedPayment || tabActive !== "infoGeneral"}
                required
                customUpload
                uploadHandler={fileUploadHandler}
                chooseLabel="Seleccionar archivo"
                ref={upload}
              />
            </div>
          </div>

          {/* TABS */}
          <div
            className="tabview-demo"
            style={{
              width: "100%",
              height: "300px",
            }}
          >
            <div className="card">
              <TabView activeIndex={activeIndex}>{dynamicTabs}</TabView>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default (props) => <PaymentSchemaDialog {...props} />;
