import axios from '../axios/custom-axios';
import { API_URL_USERS } from '../constants';

class PasswordRecoveryService {
    executePasswordRecoveryService(email) {
        return axios.post(`${API_URL_USERS}/email/recoveryPassword`, {
            email,
        });
    }
}

export default new PasswordRecoveryService();
