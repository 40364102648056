/* eslint-disable import/no-cycle */
import React from 'react';
import { Container, Box } from 'react-bulma-components';
import MainLayout from '../Layouts/MainLayout';
import CommissionGrid from './CommissionGrid';
import { PaymentSchemaProvider } from '../../context/PaymentSchemaContext';

const CommissionManagement = () => (
    <MainLayout>
        <Box>
            <Container>
                <CommissionGrid/>
            </Container>
        </Box>
    </MainLayout>
);

export default (props) => ( 
    <PaymentSchemaProvider>
        <CommissionManagement {...props} />
    </PaymentSchemaProvider>
);
