import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';

const BonoConceptsGrid = ({
    // Valores
    bonoConcept,
    bonoConcepts,
    bonoConceptNameRef,
    deleteBonoConceptDialog,

    // Componentes
    deleteRowBody,
    bonoConceptNameEditor,
    bonoConceptDescEditor,
    deleteBonoConceptDialogFooter,

    // Métodos
    handleNewBonoConceptClick,
    editingRows,
    onRowEditChange,
    onRowEditInit,
    onRowEditCancel,
    onRowEditSave,
    hideDeleteBonoConceptDialog,
    disableNewButton
}) => {

    // New validator
    const descriptionValidator = (rowData) => {
        if (!rowData.description || rowData.description.trim() === "") {
            return false; // return false if description is empty or null
        }
        return true; // return true if description is not empty and not null
    }

    return (
        <>
            <div className="container px-3">
                <p className="is-size-3 gray-strench ">Administración de los conceptos de bono</p>
                <p className="gray-light">
                    Aquí puedes visualizar, crear, editar y eliminar conceptos de bono
                    de la plataforma.
                </p>
                <button
                    type="button"
                    className="button is-link mt-3 primary-color"
                    onClick={handleNewBonoConceptClick}
                    disabled={disableNewButton()}
                >
                    Agregar concepto de bono
                </button>
                <div className="card pt-3">
                    <DataTable
                        value={bonoConcepts}
                        editMode="row"
                        dataKey="id"
                        editingRows={editingRows}
                        onRowEditChange={onRowEditChange}
                        onRowEditInit={onRowEditInit}
                        onRowEditCancel={onRowEditCancel}
                        onRowEditSave={onRowEditSave}
                        rowEditorValidator={descriptionValidator} // Use new validator
                        className="p-datatable-sm"
                        scrollable
                    >
                        <Column field="id" header="ID" style={{ width: '40px' }} />
                        <Column
                            field="name"
                            header="Nombre"
                            editor={(props) =>
                                bonoConceptNameEditor(props, bonoConceptNameRef)
                            }
                            style={{ width: '150px' }}
                        />
                        <Column
                            field="description"
                            header="Descripción"
                            editor={(props) =>
                                bonoConceptDescEditor(props, bonoConceptNameRef)
                            }
                            style={{ width: '150px' }}
                        />
                        <Column
                            rowEditor
                            header="Editar"
                            headerStyle={{ width: '7rem' }}
                            style={{ width: '50px' }}
                        />
                        <Column
                            header="Eliminar"
                            body={deleteRowBody}
                            style={{ width: '50px' }}
                        />
                    </DataTable>
                </div>
            </div>
            <Dialog
                visible={deleteBonoConceptDialog}
                style={{ width: '450px' }}
                header="Confirm"
                modal
                footer={deleteBonoConceptDialogFooter}
                onHide={hideDeleteBonoConceptDialog}
            >
                <div className="confirmation-content">
                    <i
                        className="pi pi-exclamation-triangle p-mr-3"
                        style={{ fontSize: '2rem' }}
                    />
                    <span>
                        ¿Desea elimiar el tipo de litro{' '}
                        <b>
                            {bonoConcept && bonoConcept.bonoConceptName
                                ? bonoConcept.bonoConceptName
                                : ''}
                        </b>
                        ?
                    </span>
                </div>
            </Dialog>
        </>
    );
};

export default BonoConceptsGrid;
