/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";
import { Message } from "primereact/message";
import TabulatorOtifService from "../../../api/TabulatorOtifService";
import { usePaymentSchema } from "../../../context/PaymentSchemaContext";
import { periodTypes } from "../constants";

const EsquemaRangoSimple = ({
  tabulatorCode,
  tabulator,
  sectionCode,
  // Si la sección es para tabulador estacionario, no se mostrará el campo de chofer sin ayudante en la tabla
}) => {
  const toast = useRef(null);

  console.log(tabulator, tabulatorCode);

  const [idTabulatorSection, setIdTabulatorSection] = useState(null);
  const [originalRows, setOriginalRows] = useState([]);
  const [editingRows, setEditingRows] = useState({});
  const {
    editing,
    paymentSchemaSelected,
    setPaymentSchemaSelected,
    paymentPeriods,
    editValuesOnly,
  } = usePaymentSchema();

  const {
    idPaymentPeriod,
    idEvaluation,
    idRTabSectionsTabTypes,
    simpleRangeSchema,
  } = {
    ...paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode],
  };

  const emptyRow = {
    id: null,
    lowerLimit: null,
    upperLimit: null,
    driverAssistant: null,
    driverWithoutAssistant: null,
    assistant: null,
    remainderDriver: null,
    remainderAssistant: null,
  };

  const estacionario = tabulatorCode === "tabTanqueEstacionario";

  useEffect(() => {
    if (tabulator) {
      const result = tabulator.sections.find(
        (section) => section.sectionCode === sectionCode
      );
      setIdTabulatorSection(result.idTabulatorSection);
    }
  }, []);

  // METODOS
  const onFormInputChange = (e, field) => {
    const _paymentSchemaSelected = { ...paymentSchemaSelected };
    const aux = {
      ...paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode],
    };
    _paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode] = {
      ...aux,
      [field]: e.value,
    };

    setPaymentSchemaSelected(_paymentSchemaSelected);
  };

  const addNewRow = () => {
    const _paymentSchemaSelected = { ...paymentSchemaSelected };
    const _simpleRangeSchema = simpleRangeSchema
      ? [...simpleRangeSchema, { ...emptyRow }]
      : [{ ...emptyRow }];
    const _originalRows = [...originalRows];
    const index = _simpleRangeSchema.length - 1;

    _originalRows[index] = { ..._simpleRangeSchema[index] };

    const _editingRows = {
      ...editingRows,
      ...{ [`${_simpleRangeSchema[index].id}`]: true },
    };

    _paymentSchemaSelected.tabs[tabulatorCode].sections[
      sectionCode
    ].simpleRangeSchema = [..._simpleRangeSchema];

    setEditingRows(_editingRows);
    setOriginalRows(_originalRows);
    setPaymentSchemaSelected({ ..._paymentSchemaSelected });
  };

  const onEditorValueChange = (props, value) => {
    const _paymentSchemaSelected = { ...paymentSchemaSelected };
    const _simpleRangeSchema = [...simpleRangeSchema];

    _simpleRangeSchema[props.rowIndex][props.field] = value;
    _paymentSchemaSelected.tabs[tabulatorCode].sections[
      sectionCode
    ].simpleRangeSchema = [..._simpleRangeSchema];

    setPaymentSchemaSelected({ ..._paymentSchemaSelected });
  };

  const onRowEditInit = (event) => {
    const _originalRows = [...originalRows];
    _originalRows[event.index] = { ...simpleRangeSchema[event.index] };
    setOriginalRows(_originalRows);
  };

  const onRowEditCancel = (event) => {
    const _paymentSchemaSelected = { ...paymentSchemaSelected };
    const _simpleRangeSchema = [...simpleRangeSchema];

    const _originalRows = [...originalRows];
    _originalRows.splice(event.index, 1);

    if (!_simpleRangeSchema[event.index].id) {
      _simpleRangeSchema.splice(event.index, 1);
    } else {
      _simpleRangeSchema[event.index] = originalRows[event.index];
    }

    _paymentSchemaSelected.tabs[tabulatorCode].sections[
      sectionCode
    ].simpleRangeSchema = [..._simpleRangeSchema];

    setPaymentSchemaSelected({ ..._paymentSchemaSelected });
    setOriginalRows(_originalRows);
  };

  const onRowDelete = ({ id }) => {
    const _paymentSchemaSelected = { ...paymentSchemaSelected };
    const _simpleRangeSchema = [...simpleRangeSchema];

    const index = _simpleRangeSchema.findIndex((row) => row.id === id);

    if (id) {
      TabulatorOtifService.deleteSimpleRowSchemaRow(id).then(({ data }) => {
        const { message } = data;
        _simpleRangeSchema.splice(index, 1);
        toast.current.show({
          severity: "success",
          summary: "Borrado Exitoso",
          detail: message,
          life: 3000,
        });
        _paymentSchemaSelected.tabs[tabulatorCode].sections[
          sectionCode
        ].simpleRangeSchema = [..._simpleRangeSchema];
        setPaymentSchemaSelected({ ..._paymentSchemaSelected });
      });
    } else {
      _simpleRangeSchema.splice(index, 1);
      _paymentSchemaSelected.tabs[tabulatorCode].sections[
        sectionCode
      ].simpleRangeSchema = [..._simpleRangeSchema];
      setPaymentSchemaSelected({ ..._paymentSchemaSelected });
    }
  };

  const onRowEditChange = (event) => {
    setEditingRows(event.data);
  };

  const onRowEditSave = ({ valid, data, index }) => {
    const _paymentSchemaSelected = { ...paymentSchemaSelected };
    const _simpleRangeSchema = [...simpleRangeSchema];
    const body = {
      idPaymentPeriod,
      idEvaluation,
      idRTabSectionsTabTypes: idRTabSectionsTabTypes || idTabulatorSection,
      cylinderBono: 0,
      ...data,
    };

    if (valid) {
      // ACTUALIZAR REGISTRO
      if (data.id) {
        TabulatorOtifService.updateSimpleRangeSchemaRow(body)
          .then((response) => {
            const { message } = response.data;
            toast.current.show({
              severity: "success",
              summary: "Actualizacion Exitosa",
              detail: message,
              life: 3000,
            });
          })
          .catch(() => {
            // error.response.data.message regresa nulo
            // se agrega mensaje fijo
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Error al actualizar",
              life: 3000,
            });
          });
      }

      // CREAR REGISTRO
      else {
        TabulatorOtifService.saveSimpleRangeSchemaRow(body)
          .then((response) => {
            const { result, message } = response.data;
            _simpleRangeSchema[index] = result;
            toast.current.show({
              severity: "success",
              summary: "Registro Exitoso",
              detail: message,
              life: 3000,
            });
            _paymentSchemaSelected.tabs[tabulatorCode].sections[
              sectionCode
            ].simpleRangeSchema = [..._simpleRangeSchema];

            setPaymentSchemaSelected({
              ..._paymentSchemaSelected,
            });
          })
          .catch((error) => {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: error.response.data.message,
              life: 3000,
            });
          });
      }
    }
  };

  const rowEditorValidator = (rowData) =>
    rowData.lowerLimit !== null &&
    rowData.upperLimit !== null &&
    rowData.driverAssistant !== null &&
    rowData.assistant !== null;

  const addRowButtonDisabled = () => {
    if (!idPaymentPeriod || !idEvaluation || editValuesOnly) {
      return true;
    }

    return false;
  };

  const addCommasToNumber = (initialNumber) => {
    if (initialNumber) {
      const initialNumberArray = String(initialNumber).split("");
      let numberWithCommas = "";
      for (let index = 0; initialNumberArray.length > 0; index += 1) {
        numberWithCommas =
          index !== 0 && index % 3 === 0
            ? `${initialNumberArray.pop()},${numberWithCommas}`
            : initialNumberArray.pop() + numberWithCommas;
      }
      return numberWithCommas;
    }

    return "";
  };

  // COMPONENTES

  const numberBody = (rowData, field) => (
    <p>{addCommasToNumber(rowData[field])}</p>
  );

  const priceBodyTemplate = (rowData, field) => (
    <p>$ {(rowData[field] || 0).toFixed(4)}</p>
  );

  const numberEditor = (props) => (
    <>
      <InputNumber
        value={props.rowData[props.field]}
        mode="decimal"
        min={0}
        max={10000000}
        locale="en-US"
        onValueChange={(e) => onEditorValueChange(props, e.value)}
      />
      {!(props.rowData[props.field] !== null) && (
        <Message
          className="mt-3"
          severity="error"
          text="Ingresa un dato valido"
        />
      )}
    </>
  );

  const moneyEditor = (props) => (
    <>
      <InputNumber
        value={props.rowData[props.field]}
        mode="currency"
        currency="MXN"
        locale="es-MX"
        minFractionDigits={4}
        onValueChange={(e) => onEditorValueChange(props, e.value)}
      />
      {!(props.rowData[props.field] !== null) && (
        <Message
          className="mt-3"
          severity="error"
          text="Ingresa un dato valido"
        />
      )}
    </>
  );

  const deleteRowBody = (rowData) => (
    <Button
      icon="pi pi-trash"
      className="trash"
      onClick={() => onRowDelete(rowData)}
      disabled={editValuesOnly}
    />
  );

  let paymentType;
  let evaluationType;

  if (editValuesOnly) {
    paymentType = paymentPeriods.find(({ id }) => id === idPaymentPeriod);
    evaluationType = paymentPeriods.find(({ id }) => id === idEvaluation);
  }

  const addSectionWithPaymentPeriod = () => {
    const option = periodTypes.find(
      (period) => period.value === paymentSchemaSelected.period_name
    );

    const _paymentSchemaSelected = { ...paymentSchemaSelected };
    const aux = {
      ...paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode],
    };
    _paymentSchemaSelected.tabs[tabulatorCode].sections[sectionCode] = {
      ...aux,
      idPaymentPeriod: option.id,
      idEvaluation: option.id,
    };

    setPaymentSchemaSelected(_paymentSchemaSelected);
  };

  useEffect(() => {
    addSectionWithPaymentPeriod();
  }, []);

  return (
    <div>
      <Toast ref={toast} />
      <div>
        <div className="my-3">
          <button
            className="button is-link"
            type="button"
            onClick={addNewRow}
            disabled={addRowButtonDisabled() || editing}
          >
            + Agregar Nuevos Rangos
          </button>
        </div>
        <DataTable
          value={simpleRangeSchema}
          dataKey="id"
          editMode="row"
          onRowEditInit={onRowEditInit}
          onRowEditCancel={onRowEditCancel}
          editingRows={editingRows}
          onRowEditChange={onRowEditChange}
          onRowEditSave={onRowEditSave}
          rowEditorValidator={rowEditorValidator}
        >
          <Column
            field="lowerLimit"
            header="Limite Inferior"
            editor={numberEditor}
            body={(rowData) => numberBody(rowData, "lowerLimit")}
          />
          <Column
            field="upperLimit"
            header="Limite Superior"
            body={(rowData) => numberBody(rowData, "upperLimit")}
            editor={numberEditor}
          />
          <Column
            field="driverAssistant"
            header={`Chofer ${!estacionario ? "con Ayudante" : ""} `}
            body={(rowData) => priceBodyTemplate(rowData, "driverAssistant")}
            editor={moneyEditor}
          />
          {estacionario ? (
            <Column
              field="remainderDriver"
              header="Remanente Chofer"
              body={(rowData) => priceBodyTemplate(rowData, "remainderDriver")}
              editor={moneyEditor}
            />
          ) : null}
          {estacionario ? null : (
            <Column
              field="driverWithoutAssistant"
              header="Chofer sin Ayudante"
              body={(rowData) =>
                priceBodyTemplate(rowData, "driverWithoutAssistant")
              }
              editor={moneyEditor}
            />
          )}

          <Column
            field="assistant"
            header="Ayudante"
            body={(rowData) => priceBodyTemplate(rowData, "assistant")}
            editor={moneyEditor}
          />

          {estacionario ? (
            <Column
              field="remainderAssistant"
              header="Remanente Ayudante"
              body={(rowData) =>
                priceBodyTemplate(rowData, "remainderAssistant")
              }
              editor={moneyEditor}
            />
          ) : null}
          <Column
            header="Editar"
            rowEditor
            headerStyle={{ width: "7rem" }}
            bodyStyle={{ textAlign: "center" }}
          />
          <Column
            header="Eliminar"
            body={deleteRowBody}
            headerStyle={{ width: "7rem" }}
            bodyStyle={{ textAlign: "center" }}
          />
        </DataTable>
      </div>
    </div>
  );
};

export default EsquemaRangoSimple;
