import React from 'react';
import { Dialog } from 'primereact/dialog';
import UserInfo from '../Users/UserInfo';

function UserInfoDialog(props) {
    const { visible, onHide } = props;

    return (
        <>
            <Dialog
                header="Información del Usuario"
                visible={visible}
                onHide={onHide}
            >
                <UserInfo onHide={onHide} />
            </Dialog>
        </>
    );
}

export default UserInfoDialog;
