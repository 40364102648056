import React from 'react';

import { Accordion, AccordionTab } from 'primereact/accordion';
import DetallesEsquema from './DetallesEsquema';
import sectionsConfig from './sectionsConfig';

const GeneralTabulator = ({ tabulator, schema }) => (
    <>
        <DetallesEsquema
            schema={schema}
            tabulator={tabulator}
            tabulatorCode={tabulator.tabulatorCode}
        />
        <div className="mt-5">
            <Accordion>
                {tabulator.sections.map((section) => {
                    const SectionToRender = sectionsConfig[section.sectionCode];
                    return (
                        <AccordionTab
                            key={section.idTabulatorSection}
                            header={section.sectionName}
                        >
                            <SectionToRender
                                tabulator={tabulator}
                                tabulatorCode={tabulator.tabulatorCode}
                                sectionName={section.sectionName}
                                sectionCode={section.sectionCode}
                            />
                        </AccordionTab>
                    );
                })}
            </Accordion>
        </div>
    </>
);

export default GeneralTabulator;
