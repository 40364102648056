import React, { useRef, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import { useTimer } from 'react-timer-hook';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { AuthContext } from '../../context/AuthContext/AuthContext';
import {
    IDLE_TIMER_INACTIVITY_PERIOD,
    SESSION_TIMEOUT_IDLE_WINDOW_PERIOD,
} from '../../constants';

function IdleTimerContainer() {
    const history = useHistory();
    let idleTimer = null;
    const sessionTimeoutRef = useRef(null);
    const expiryTimestamp = new Date();
    expiryTimestamp.setSeconds(
        expiryTimestamp.getSeconds() + SESSION_TIMEOUT_IDLE_WINDOW_PERIOD * 1000
    );
    const { seconds, minutes, restart } = useTimer({
        expiryTimestamp,
    });
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const { dispatch } = useContext(AuthContext);

    const stayActive = () => {
        idleTimer.reset();
        clearTimeout(sessionTimeoutRef.current);
        setModalIsOpen(false);
    };

    const handleLogout = () => {
        clearTimeout(sessionTimeoutRef.current);
        setModalIsOpen(false);
        dispatch({ type: 'LOGOUT', data: null });
        history.replace('/login');
    };

    const onIdle = () => {
        setModalIsOpen(true);
        // Restablecer el valor del conteo regresivo
        const timeCountdown = new Date();
        timeCountdown.setSeconds(
            timeCountdown.getSeconds() + SESSION_TIMEOUT_IDLE_WINDOW_PERIOD
        );
        restart(timeCountdown);
        // Establecer el tiempo límite y cerrado de sesión por inactividad
        sessionTimeoutRef.current = setTimeout(
            handleLogout,
            SESSION_TIMEOUT_IDLE_WINDOW_PERIOD * 1000
        );
    };

    return (
        <div>
            <ConfirmDialog
                visible={modalIsOpen}
                onHide={() => setModalIsOpen(false)}
                message={`De lo contrario la sesión se cerrará en  ${
                    minutes < 10 ? '0' : ''
                }${minutes} : ${seconds < 10 ? '0' : ''}${seconds} minuto(s)`}
                header="¿Sigues ahí?"
                icon="pi pi-exclamation-triangle"
                acceptLabel="Estoy aquí"
                rejectLabel="Cerrar sesión"
                accept={stayActive}
                reject={handleLogout}
            />
            <IdleTimer
                ref={(ref) => {
                    idleTimer = ref;
                }}
                timeout={IDLE_TIMER_INACTIVITY_PERIOD * 1000}
                onIdle={onIdle}
                stopOnIdle
            />
        </div>
    );
}

export default IdleTimerContainer;
