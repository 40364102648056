import React, { useState } from "react";
import PaymentTable from "./PaymentTable";
import { status } from "./constants";
import { Checkbox } from "primereact/checkbox";
import Circle from "./Circle";

const Collapsible = ({
  data,
  element,
  setData,
  index,
  checkedItems,
  setCheckedItems,
}) => {
  console.log(data);
  const [showTable, setShowTable] = useState(false);

  const labelsToShow = element.status ? status[element.status] : [];

  return (
    <div
      style={{
        width: "100%",
        padding: "10px 14px",
        backgroundColor: "#EDE8E8",
        borderBottom: "2px solid #B3B3B3",
      }}
    >
      <div style={{ display: "flex", marginBottom: "12px" }}>
        <div
          style={{ width: "100px", display: "flex", justifyContent: "center" }}
        >
          <Checkbox
            checked={checkedItems?.[`${index}`]}
            onChange={() => {
              setCheckedItems({
                ...checkedItems,
                [index]: !checkedItems[`${index}`],
              });
            }}
          />
        </div>
        <div
          style={{
            userSelect: "none",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
          onClick={() => setShowTable(!showTable)}
        >
          <p className="ml-2">{`${element.name} ${element.lastName} ${element.secondName} / RFC: ${element.rfc} / ${element.workStation}`}</p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddding: "0px",
              marginVertical: "0px",
              marginRight: "10px",
            }}
          >
            <>
              {labelsToShow.map((item) => (
                <Circle text={item.label} />
              ))}
            </>
          </div>{" "}
        </div>
      </div>
      <div
        style={{
          backgroundColor: "white",
        }}
      >
        {showTable ? (
          <PaymentTable
            data={data}
            element={element}
            setData={setData}
            index={index}
          />
        ) : null}
      </div>
    </div>
  );
  1;
};

export default Collapsible;
