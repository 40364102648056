/* eslint-disable import/no-cycle */
import React, { useContext } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Link, useLocation } from "react-router-dom";
import AppContext from "../context/AppContext";
import { components } from "../config/roles";

function Sidenav() {
  const { isActive } = useContext(AppContext);
  const location = useLocation().pathname.replace("/", "");
  const authenticatedUser = JSON.parse(
    sessionStorage.getItem("authenticatedUser")
  );

  const modules =
    authenticatedUser && authenticatedUser.modules
      ? authenticatedUser.modules
      : null;

  const groupModules = (moduleList) => {
    // Filter out the "CommissionManagement" module from the list
    const filteredModules = moduleList.filter(({ name }) => name !== "commission");

    const sections = filteredModules.filter(
      (module, index) =>
        filteredModules.findIndex(
          ({ sectionGroup }) => module.sectionGroup === sectionGroup
        ) === index
    );

    const sectionGroups = sections.map((section, index) => {
      const modulesInTheGroup = filteredModules.filter(
        ({ sectionGroup }) => section.sectionGroup === sectionGroup
      );

      return {
        index,
        sectionName: section.sectionGroup,
        modules: modulesInTheGroup,
      };
    });

    return sectionGroups;
  };

  const NewMenuComponent = () => {
    const sections = groupModules(modules);
    return sections.map((section) => {
      if (section) {
        return (
          <li key={section.index}>
            <Accordion style={{ padding: "0px" }}>
              <AccordionTab
                header={section.sectionName}
                style={{ padding: "0px" }}
              >
                <ul>
                  {section.modules.map((module) => {
                    const { icon, title, id } = components[module.name] || {};
                    return (
                      <li key={id}>
                        <Link to={`/${id}`}>
                          <div
                            style={{
                              flexDirection: "row",
                              display: "flex",
                              padding: "15px 20px 15px 20px",
                            }}
                          >
                            <div style={{ marginRight: "10px" }}>
                              <i className={`fas ${icon}`} />
                            </div>
                            <div>
                              <p>{title}</p>
                            </div>
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </AccordionTab>
            </Accordion>
          </li>
        );
      }
    });
  };

  const MenuComponent = () => {
    return modules.map(({ name }) => {
      if (components[name]) {
        const { icon, title, id } = components[name];

        return (
          <li key={id}>
            {/* <Link to={`/${id}`}>
              <button
                type="button"
                id={id}
                // className={`transparent ${location === id ? "active" : ""}`}
                title={title}
              >
                <i className={`fas header-icon ${icon}`} />
                <p>{title}</p>
              </button>
            </Link> */}
          </li>
        );
      }
    });
  };

  return (
    <aside className={`left-aside ${isActive ? "active" : null}`} id="leftMenu">
      <ul>
        {components && modules && modules.length > 0 ? (
          // <MenuComponent />
          <NewMenuComponent />
        ) : null}
      </ul>
    </aside>
  );
}

export default Sidenav;