import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import LoginLayout from './LoginLayout';
import PasswordRecoveryService from '../../api/PasswordRecoveryService';
import { EMAIL_PATTERN_REGEXP } from '../../constants';

const PasswordRecovery = () => {
    const [email, setEmail] = useState('');
    const [isEmailSent, setIsEmailSent] = useState(false);
    const toast = useRef(null);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const showToast = (severityValue, summaryValue, detailValue) => {
        toast.current.show({
            severity: severityValue,
            summary: summaryValue,
            detail: detailValue,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        PasswordRecoveryService.executePasswordRecoveryService(email)
            .then((response) => {
                if (response.data.status === 'OK') {
                    setIsEmailSent(true);
                } else {
                    showToast(
                        'error',
                        response.data.status,
                        response.data.message
                    );
                }
            })
            .catch(({ response }) => {
                showToast('error', response.data.status, response.data.message);
            });
    };

    return (
        <LoginLayout>
            <form id="loginForm" onSubmit={handleSubmit}>
                <ul>
                    <li>
                        <h1 className="login-text">
                            <span className="login-text">
                                Restaurar contraseña:
                            </span>
                        </h1>
                    </li>
                    {!isEmailSent ? (
                        <>
                            <li className="">
                                <p
                                    style={{
                                        color: 'white',
                                        fontSize: '17px',
                                        width: '25rem',
                                        textAlign: 'justify',
                                    }}
                                >
                                    Escribe la dirección de email con la que
                                    está registrada tu cuenta y se te enviará
                                    una nueva contraseña a dicha dirección.
                                </p>
                            </li>
                            <li>
                                {!EMAIL_PATTERN_REGEXP.test(email) && email ? (
                                    <b
                                        style={{
                                            color: 'white',
                                            fontSize: '17px',
                                            width: '25rem',
                                            textAlign: 'justify',
                                        }}
                                    >
                                        * El formato de email no es correcto.
                                    </b>
                                ) : (
                                    ''
                                )}
                            </li>
                            <li>
                                <input
                                    id="email"
                                    name="userEmail"
                                    value={email}
                                    onChange={handleEmailChange}
                                    placeholder="correo@electronico.com"
                                    required
                                    type="text"
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                />
                            </li>
                            <li>
                                <button
                                    type="submit"
                                    className="passwordButton"
                                >
                                    Restaurar Contraseña
                                </button>
                            </li>
                        </>
                    ) : (
                        <>
                            <li className="">
                                <p
                                    style={{
                                        color: 'white',
                                        fontSize: '17px',
                                        width: '25rem',
                                        textAlign: 'justify',
                                    }}
                                >
                                    Se ha enviado una nueva contraseña generada
                                    al correo{' '}
                                    <b className="emailBold">{email}.</b>
                                </p>
                            </li>
                            <li className="">
                                <p
                                    style={{
                                        color: 'white',
                                        fontSize: '17px',
                                        width: '25rem',
                                        textAlign: 'justify',
                                    }}
                                >
                                    ¿No ha recibido el correo aún?
                                    <br />
                                    Por favor revise su carpeta de correo no
                                    deseado o intente de nuevo.
                                </p>
                            </li>
                        </>
                    )}
                    <li>
                        <div className="center">
                            <Link to="/login">
                                <p className="login-text">
                                    Regresar a Iniciar Sesión
                                </p>
                            </Link>
                        </div>
                    </li>
                </ul>
            </form>
            <Toast ref={toast} />
        </LoginLayout>
    );
};

export default PasswordRecovery;
