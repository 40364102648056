import React, { useState, useRef, useContext } from "react";
import SalesService from "../../api/SalesService";
import { conceptsTable } from "./constants";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { AuthContext } from "../../context/AuthContext/AuthContext";
import UsersService from "../../api/UsersService";
import {
  sumAmountCurrentValue,
  sumAmountLastValue,
  sumDaysCurrentValue,
  sumDaysLastValue,
} from "./helpers";
import { ProgressSpinner } from "primereact/progressspinner";

const PaymentTable = ({ data, setData, index, element }) => {
  const [originalElement, setOriginalElement] = useState({ ...element });
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);

  const { auth } = useContext(AuthContext);
  const permissions = auth?.permissions;

  const handleApprove = () => {
    let status = 0;
    switch (auth.rol) {
      case "ROLE_ENCARGADO_DE_PRENOMINA":
        status = 1;
        break;

      case "ROLE_JEFE_ADMINISTRATIVO_DE_PLAZA":
        status = 2;
        break;

      case "ROLE_GERENTE_DE_PLAZA":
        status = 3;
        break;

      case "ROLE_ADMINISTRACION_GENERAL":
        status = 4;
        break;
    }

    const newElement = { ...element, status };

    const newData = [...data];
    newData[index] = { ...newElement };

    SalesService.updatePayment(newElement).then(() => {
      toast.current.show({
        severity: "success",
        summary: "Prenomina Aprobada",
      });
      setData([...newData]);

      UsersService.approveChanges(newElement);
    });
  };

  const handleReject = () => {
    UsersService.rejectChanges(element)
      .then(() => {
        toast.current.show({
          severity: "success",
          summary: "Correo de rechazo enviado",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDataUpdate = (status) => {
    const requestBody = { ...element };

    if (requestBody.bonoDiasModificado !== requestBody.bonoDiasSistema) {
      requestBody.bonoDiasModificado = originalElement.bonoDiasSistema;
      requestBody.bonoAprobado = auth.rol;
    }

    if (requestBody.bonoImporteModificado !== requestBody.bonoImporteSistema) {
      requestBody.bonoImporteModificado = originalElement.bonoImporteSistema;
      requestBody.bonoAprobado = auth.rol;
    }

    if (
      requestBody.comisionDiasModificado !== requestBody.comisionDiasSistema
    ) {
      requestBody.comisionDiasModificado = originalElement.comisionDiasSistema;
      requestBody.comisionAprobado = auth.rol;
    }

    if (
      requestBody.comisionImporteModificado !==
      requestBody.comisionImporteSistema
    ) {
      requestBody.comisionImporteModificado =
        originalElement.comisionImporteSistema;
      requestBody.comisionAprobado = auth.rol;
    }

    if (
      requestBody.compensacionDiasModificado !==
      requestBody.compensacionDiasSistema
    ) {
      requestBody.compensacionDiasModificado =
        originalElement.compensacionDiasSistema;
      requestBody.compensacionAprobado = auth.rol;
    }

    if (
      requestBody.compensacionImporteModificado !==
      requestBody.compensacionImporteSistema
    ) {
      requestBody.compensacionImporteModificado =
        originalElement.compensacionImporteSistema;
      requestBody.compensacionAprobado = auth.rol;
    }

    if (
      requestBody.remanenteDiasModificado !== requestBody.remanenteDiasSistema
    ) {
      requestBody.remanenteDiasModificado =
        originalElement.remanenteDiasSistema;
      requestBody.remanenteAprobado = auth.rol;
    }
    if (requestBody.remanenteImporteModificado !== requestBody) {
      requestBody.remanenteImporteModificado =
        originalElement.remanenteImporteSistema;
      requestBody.remanenteAprobado = auth.rol;
    }

    if (
      requestBody.septimoDiaTrabajadoDiasModificado !==
      requestBody.septimoDiaTrabajadoDiasSistema
    ) {
      requestBody.septimoDiaTrabajadoDiasModificado =
        originalElement.septimoDiaTrabajadoDiasSistema;
      requestBody.septimoDiaTrabajadoAprobado = auth.rol;
    }

    if (
      requestBody.septimoDiaTrabajadoImporteModificado !==
      requestBody.septimoDiaTrabajadoImporteSistema
    ) {
      requestBody.septimoDiaTrabajadoImporteModificado =
        originalElement.septimoDiaTrabajadoImporteSistema;
      requestBody.septimoDiaTrabajadoAprobado = auth.rol;
    }

    if (
      requestBody.complPlantaImporteModificado !==
      requestBody.complPlantaImporteSistema
    ) {
      requestBody.complPlantaImporteModificado =
        originalElement.complPlantaImporteSistema;
      requestBody.complPlantaAprobado = auth.rol;
    }

    if (
      requestBody.complPlantaDiasModificado !==
      requestBody.complPlantaDiasSistema
    ) {
      requestBody.complPlantaDiasModificado =
        originalElement.complPlantaDiasSistema;
      requestBody.complPlantaAprobado = auth.rol;
    }

    if (
      requestBody.complGuardiaDiasModificado !==
      requestBody.complGuardiaDiasSistema
    ) {
      requestBody.complGuardiaDiasModificado =
        originalElement.complGuardiaDiasSistema;
      requestBody.complGuardiaAprobado = auth.rol;
    }

    if (
      requestBody.complGuardiaImporteModificado !==
      requestBody.complGuardiaImporteSistema
    ) {
      requestBody.complGuardiaImporteModificado =
        originalElement.complGuardiaImporteSistema;
      requestBody.complGuardiaAprobado = auth.rol;
    }

    if (
      requestBody.complTallerDiasModificado !==
      requestBody.complTallerDiasSistema
    ) {
      requestBody.complTallerDiasModificado =
        originalElement.complTallerDiasSistema;
      requestBody.complTallerAprobado = auth.rol;
    }

    if (
      requestBody.complTallerImporteModificado !==
      requestBody.complTallerImporteSistema
    ) {
      requestBody.complTallerImporteModificado =
        originalElement.complTallerImporteSistema;
      requestBody.complTallerAprobado = auth.rol;
    }

    if (
      requestBody.complImprevistoDiasModificado !==
      requestBody.complImprevistoDiasSistema
    ) {
      requestBody.complImprevistoDiasModificado =
        originalElement.complImprevistoDiasSistema;
      requestBody.complImprevistoAprobado = auth.rol;
    }

    if (
      requestBody.complImprevistoImporteModificado !==
      requestBody.complImprevistoImporteSistema
    ) {
      requestBody.complImprevistoImporteModificado =
        originalElement.complImprevistoImporteSistema;
      requestBody.complImprevistoAprobado = auth.rol;
    }

    if (
      requestBody.complSalarioMinimoDiasModificado !==
      requestBody.complSalarioMinimoDiasSistema
    ) {
      requestBody.complSalarioMinimoDiasModificado =
        originalElement.complSalarioMinimoDiasSistema;
      requestBody.complSalarioMinimoAprobado = auth.rol;
    }

    if (
      requestBody.complSalarioMinimoImporteModificado !==
      requestBody.complSalarioMinimoImporteSistema
    ) {
      requestBody.complSalarioMinimoImporteModificado =
        originalElement.complSalarioMinimoImporteSistema;
      requestBody.complSalarioMinimoAprobado = auth.rol;
    }

    if (
      requestBody.complSueldoGarantiaDiasModificado !==
      requestBody.complSueldoGarantiaDiasSistema
    ) {
      requestBody.complSueldoGarantiaDiasModificado =
        originalElement.complSueldoGarantiaDiasSistema;
      requestBody.complSueldoGarantiaAprobado = auth.rol;
    }
    if (
      requestBody.complSueldoGarantiaImporteModificado !==
      requestBody.complSueldoGarantiaImporteSistema
    ) {
      requestBody.complSueldoGarantiaImporteModificado =
        originalElement.complSueldoGarantiaImporteSistema;
      requestBody.complSueldoGarantiaAprobado = auth.rol;
    }

    if (
      requestBody.complDiaFestivoDiasModificado !==
      requestBody.complDiaFestivoDiasSistema
    ) {
      requestBody.complDiaFestivoDiasModificado =
        originalElement.complDiaFestivoDiasSistema;
      requestBody.complDiaFestivoAprobado = auth.rol;
    }

    if (
      requestBody.complDiaFestivoImporteModificado !==
      requestBody.complDiaFestivoImporteSistema
    ) {
      requestBody.complDiaFestivoImporteModificado =
        originalElement.complDiaFestivoImporteSistema;
      requestBody.complDiaFestivoAprobado = auth.rol;
    }

    if (
      requestBody.complDescansoTrabajadoDiasModificado !==
      requestBody.complDescansoTrabajadoDiasSistema
    ) {
      requestBody.complDescansoTrabajadoDiasModificado =
        originalElement.complDescansoTrabajadoDiasSistema;
      requestBody.complDescansoTrabajadoAprobado = auth.rol;
    }
    if (
      requestBody.complDescansoTrabajadoImporteModificado !==
      requestBody.complDescansoTrabajadoImporteSistema
    ) {
      requestBody.complDescansoTrabajadoImporteModificado =
        originalElement.complDescansoTrabajadoImporteSistema;
      requestBody.complDescansoTrabajadoAprobado = auth.rol;
    }

    if (
      requestBody.complPproductividadDiasModificado !==
      requestBody.complPproductividadDiasSistema
    ) {
      requestBody.complPproductividadDiasModificado =
        originalElement.complPproductividadDiasSistema;
      requestBody.complPproductividadAprobado = auth.rol;
    }
    if (
      requestBody.complPproductividadImporteModificado !==
      requestBody.complPproductividadImporteSistema
    ) {
      requestBody.complPproductividadImporteModificado =
        originalElement.complPproductividadImporteSistema;
      requestBody.complPproductividadAprobado = auth.rol;
    }

    // modificando
    if (
      requestBody.complVentaDiasModificado !== requestBody.complVentaDiasSistema
    ) {
      requestBody.complVentaDiasModificado =
        originalElement.complVentaDiasSistema;
      requestBody.complVentaAprobado = auth.rol;
    }
    if (
      requestBody.complVentaImporteMoficado !==
      requestBody.complVentaImporteSistema
    ) {
      requestBody.complVentaImporteMoficado =
        originalElement.complVentaImporteSistema;
      requestBody.complVentaAprobado = auth.rol;
    }

    SalesService.updatePayment(requestBody)
      .then(() => {
        const newData = [...data];
        newData[index] = { ...requestBody };
        setData([...newData]);

        setOriginalElement({ ...requestBody });
        toast.current.show({
          severity: "success",
          summary: "Información actualizada",
        });

        UsersService.requestApproval(requestBody)
          .then(() => {
            toast.current.show({
              severity: "success",
              summary: "Correo de aprobación enviado",
            });
          })
          .catch((emailError) => {
            toast.current.show({
              severity: "error",
              summary: "Error al enviar el correo",
            });
          })
          .then(() => {
            console.log("Se ejecuta la llamada del correo");
          });
      })
      .catch((updateError) => {
        toast.current.show({
          severity: "error",
          summary:
            updateError?.response?.data?.message ||
            "Error al actualizar prenómina, intentar nuevamente",
        });
      })
      .then(() => console.log("Finished!"));
  };

  const disableModificar = () => {
    if (auth.rol === "ROLE_ENCARGADO_DE_PRENOMINA" && element.status === 0)
      return false;
    if (
      auth.rol === "ROLE_JEFE_ADMINISTRATIVO_DE_PLAZA" &&
      element.status === 1
    )
      return false;
    if (auth.rol === "ROLE_GERENTE_DE_PLAZA" && element.status === 2)
      return false;

    return true;
  };
  const disableAprobar = () => {
    if (auth.rol === "ROLE_ENCARGADO_DE_PRENOMINA" && element.status === 0) {
      return false;
    }

    if (
      auth.rol === "ROLE_JEFE_ADMINISTRATIVO_DE_PLAZA" &&
      element.status === 1
    )
      return false;

    if (auth.rol === "ROLE_GERENTE_DE_PLAZA" && element.status === 2)
      return false;

    if (auth.rol === "ROLE_ADMINISTRACION_GENERAL" && element.status === 3)
      return false;

    return true;
  };

  const disableRechazar = () => {
    if (auth.rol === "ROLE_ADMINISTRACION_GENERAL" && element.status === 3)
      return false;

    return true;
  };

  const handleRecalculatePayment = (rfc, date) => {
    setLoading(true);
    SalesService.recalculatePaymentByRfc(rfc, date)
      .then(() => {
        toast.current.show({
          severity: "success",
          summary: "Recalculo exitoso!",
        });
      })
      .catch((error) => {
        console.log(error);
        toast.current.show({
          severity: "error",
          summary: error.response.data.message,
        });
      })
      .then(() => setLoading(false));
  };

  console.log(element, element.rfc);

  return (
    <div>
      <Toast ref={toast} />
      <table style={{ width: "100%" }}>
        <tr>
          <th rowSpan={2} style={{ backgroundColor: "#E0E0E0", padding: 10 }}>
            Modificado por
          </th>
          <th rowSpan={2} s style={{ backgroundColor: "#E0E0E0", padding: 10 }}>
            Cve. Concepto
          </th>
          <th rowSpan={2} s style={{ backgroundColor: "#E0E0E0", padding: 10 }}>
            Concepto
          </th>
          <th
            style={{
              backgroundColor: "#E0E0E0",
              padding: 10,
              textAlign: "center",
            }}
            colSpan="2"
          >
            Cant. Días
          </th>
          <th
            style={{
              backgroundColor: "#E0E0E0",
              padding: 10,
              textAlign: "center",
            }}
            colSpan="2"
          >
            Importe
          </th>
        </tr>
        <tr>
          <th style={{ textAlign: "center", backgroundColor: "#E0E0E0" }}>
            Valor actual
          </th>
          <th style={{ textAlign: "center", backgroundColor: "#E0E0E0" }}>
            Valor Anterior
          </th>
          <th style={{ textAlign: "center", backgroundColor: "#E0E0E0" }}>
            Valor actual
          </th>
          <th style={{ textAlign: "center", backgroundColor: "#E0E0E0" }}>
            Valor Anterior
          </th>
        </tr>

        {conceptsTable.concepts.map((concept) => (
          <tr>
            {concept.attributes.map((attribute) => {
              if (attribute.type === "label") {
                return (
                  <td>
                    <p
                      style={{
                        padding: 10,
                        color: attribute.color || null,
                      }}
                    >
                      {element[attribute.id]}
                    </p>
                  </td>
                );
              }

              if (attribute.type === "number") {
                return (
                  <td style={{ padding: 10, width: "150px" }}>
                    <InputNumber
                      max={10000}
                      value={element[attribute.id]}
                      inputStyle={{ color: attribute.color || null }}
                      inputId={attribute.id}
                      id={attribute.id}
                      disabled={
                        auth.rol === "ROLE_GERENTE_DE_PLAZA" ||
                        auth.rol === "ROLE_ADMINISTRACION_GENERAL"
                      }
                      onChange={(event) => {
                        let newElement = {
                          ...element,
                          [event.originalEvent.target.id]: event.value,
                        };

                        const newData = [...data];
                        newData[index] = { ...newElement };

                        setData([...newData]);
                      }}
                    />
                  </td>
                );
              }
            })}
          </tr>
        ))}

        <tr>
          <td colSpan={3}></td>
          <td style={{ padding: "10px" }}>{sumDaysCurrentValue(element)}</td>
          <td style={{ padding: "10px", color: "orange" }}>
            {sumDaysLastValue(element)}
          </td>
          <td style={{ padding: "10px" }}>{sumAmountCurrentValue(element)}</td>
          <td style={{ padding: "10px", color: "orange" }}>
            {sumAmountLastValue(element)}
          </td>
        </tr>
      </table>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
        <div style={{ width: "50px", height: "50px" }}>
          {loading ? (
            <ProgressSpinner
              strokeWidth="2"
              style={{ width: "50px", height: "50px" }}
            />
          ) : null}
        </div>
        {permissions.find((permission) => permission.name === "Modificar") ? (
          <Button onClick={handleDataUpdate} disabled={disableModificar()}>
            Modificar
          </Button>
        ) : null}

        {permissions.find((permission) => permission.name === "Aprobar") ? (
          <Button
            onClick={handleApprove}
            disabled={disableAprobar()}
            className="ml-4"
          >
            Aprobar
          </Button>
        ) : null}
        {permissions.find((permission) => permission.name === "Rechazar") ? (
          <Button
            onClick={handleReject}
            disabled={disableRechazar()}
            className="ml-4"
          >
            Rechazar
          </Button>
        ) : null}
        {auth.rol === "ROLE_ADMINISTRACION_GENERAL" ? (
          <Button
            onClick={() => handleRecalculatePayment(element.rfc, "2023-05-20")}
            className="p-button-warning ml-4"
            disabled={loading}
          >
            Re-calcular prenominas seleccionadas
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default PaymentTable;
