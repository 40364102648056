import axios from "../axios/custom-axios";
import { API_URL_CONCEPTS } from "../constants";

class ShiftsService {
  // locationId es opcional
  getShifts = async (locationId) =>
    axios.get(
      `${API_URL_CONCEPTS}/shifts${
        locationId ? `?fortiaLocationId=${locationId}` : ""
      }`
    );

  createShift(body) {
    return axios.post(`${API_URL_CONCEPTS}/shifts`, body);
  }

  updateShift(body) {
    return axios.put(`${API_URL_CONCEPTS}/shifts/${body.id}`, body);
  }

  deleteShift(id) {
    return axios.delete(`${API_URL_CONCEPTS}/shifts/${id}`);
  }

  getPlazasFortia() {
    return axios.get(`${API_URL_CONCEPTS}/fortia/locationAll`);
  }

  getShiftsByLocationId = (id) =>
    axios.get(
      `${API_URL_CONCEPTS}/shifts/shift-location-fortia?fortiaLocationId=${id}`
    );
}

export default new ShiftsService();
