import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import React from 'react';

const ShiftsGrid = ({
    // REFERENCIAS
    shiftNameRef,
    shiftTypeRef,
    startTimeRef,
    endTimeRef,
    plazaRef,

    // VARIABLES
    shift,
    shifts,
    deleteShiftDialog,
    editingRows,
    loading,

    // METODOS,
    onRowEditInit,
    handleNewShiftClick,
    onRowEditSave,
    setDeleteShiftDialog,
    disableNewShiftButton,
    onRowEditChange,
    onRowEditCancel,
    rowEditorValidator,

    // COMPONENTES
    deleteBody,
    shiftNameEditor,
    shiftTypeEditor,
    shiftTypeBody,
    startTimeEditor,
    endTimeEditor,
    startTimeBody,
    endTimeBody,
    deleteShiftDialogFooter,
    plazaEditor,
    plazaBody,
}) => (
    <div className="container px-3  ">
        <p className="is-size-3 gray-strench">Administración de turnos</p>
        <p className="gray-light">
            Aquí puedes visualizar, agregar, editar y eliminar los turnos de tu
            plaza.
        </p>
        <button
            type="button"
            className="button is-link mt-3 primary-color"
            onClick={handleNewShiftClick}
            disabled={disableNewShiftButton()}
        >
            Agregar turno
        </button>
        <div className="card pt-3">
            <DataTable
                value={shifts}
                editMode="row"
                dataKey="id"
                editingRows={editingRows}
                onRowEditInit={onRowEditInit}
                onRowEditSave={onRowEditSave}
                onRowEditChange={onRowEditChange}
                onRowEditCancel={onRowEditCancel}
                rowEditorValidator={rowEditorValidator}
                loading={loading}
                className="p-datatable-sm"
            >
                <Column field="id" header="ID" style={{ width: '40px' }} />
                <Column
                    field="shiftName"
                    header="Nombre"
                    editor={(props) => shiftNameEditor(props, shiftNameRef)}
                />
                <Column
                    style={{ width: '260px' }}
                    field="idPlazaFortia"
                    header="Plaza"
                    body={plazaBody}
                    editor={(props) => plazaEditor(props, plazaRef)}
                />
                <Column
                    field="shiftType"
                    header="Tipo de Turno"
                    body={shiftTypeBody}
                    editor={(props) => shiftTypeEditor(props, shiftTypeRef)}
                />
                <Column
                    field="startTime"
                    header="Hora de Entrada"
                    body={startTimeBody}
                    editor={(props) => startTimeEditor(props, startTimeRef)}
                />
                <Column
                    field="endTime"
                    header="Hora de Salida"
                    body={endTimeBody}
                    editor={(props) => endTimeEditor(props, endTimeRef)}
                />
                <Column
                    rowEditor
                    header="Editar"
                    headerStyle={{ width: '7rem ' }}
                />
                <Column
                    header="Eliminar"
                    body={deleteBody}
                    style={{ width: '100px' }}
                />
            </DataTable>
        </div>
        <Dialog
            visible={deleteShiftDialog}
            style={{ width: '450px' }}
            header="Confirmar"
            modal
            footer={deleteShiftDialogFooter}
            onHide={() => setDeleteShiftDialog(false)}
        >
            <div className="confirmation-content">
                <i
                    className="pi pi-exclamation-triangle p-mr-3"
                    style={{ fontSize: '2rem' }}
                />
                {shift && (
                    <span>
                        ¿Desear eliminar el turno <b>{shift.shiftName}</b>?
                    </span>
                )}
            </div>
        </Dialog>
    </div>
);

export default ShiftsGrid;
