import axios from 'axios';

// Instancia de axios para realizar peticiones
const axiosInstance = axios.create();

// Agregar el header de autorización en el interceptor
axiosInstance.interceptors.request.use((config) => {
    const sessionAuth = sessionStorage.getItem('authenticatedUser');
    const sessionAuthParsed = JSON.parse(sessionAuth);
    const configuration = config;
    // Agregar el token del contexto al header
     configuration.headers.Authorization = sessionAuthParsed.token
         ? `Bearer ${sessionAuthParsed.token}`
         : '';
    return configuration;
});

axiosInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export default axiosInstance;
