import React, { useEffect, useState } from "react";
import { Box, Container } from "react-bulma-components";
import MainLayout from "../Layouts/MainLayout";
import Filters from "./Filters";
import EmployeeList from "./EmployeeList";
import TabulatorOtifService from "../../api/TabulatorOtifService";
import BaseSalaryService from "../../api/BaseSalaryService";
import OpeningService from "../../api/OpeningService";
import SalesService from "../../api/SalesService";
import { companies, periods } from "./constants";

const PaymentEditionScreen = () => {
  const [locations, setLocations] = useState([]);
  const [locationSelected, setLocationSelected] = useState(null);
  const [jobPositions, setJobPositions] = useState([]);
  const [jobPositionSelected, setJobPositionSelected] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [employeeSelected, setEmployeeSelected] = useState(null);
  const [periodSelected, setPeriodSelected] = useState(null);
  const [companySelected, setCompanySelected] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  // Fetching
  const fetchLocations = () => {
    setLoading(true);
    BaseSalaryService.getLocations()
      .then((response) => {
        setLocations([...response.data.result]);
      })
      .catch((error) =>
        console.log("Error al traer las plazas", error.response?.message)
      )
      .then(() => {
        setLoading(false);
      });
  };

  const fetchJobPositions = (locationId) => {
    setLoading(true);
    TabulatorOtifService.employeesByLocationId(locationId || "")
      .then((response) => {
        setJobPositionSelected(null);
        const result = [...response.data.result];
        setJobPositions(result);
      })
      .catch((error) => {
        console.log(error.response.data);
      })
      .then(() => {
        setLoading(false);
      });
  };

  const fetchEmployees = (jobPositionId, locationId) => {
    setLoading(true);
    OpeningService.getEmployeesByLocationAndJobPosition(
      jobPositionId || "",
      locationId || ""
    )
      .then((response) => {
        setEmployees([...response.data.result]);
      })
      .catch((error) => {
        console.log(error.response);
      })
      .then(() => {
        setLoading(false);
      });
  };

  // Handle Selection
  const handleLocationChange = (location) => {
    setLocationSelected(location);
    setJobPositions([]);
    setJobPositionSelected(null);
    if (location) {
      fetchJobPositions(location.idFortia);
    }
  };

  const handleJobPositionChange = (jobPosition) => {
    setJobPositionSelected(jobPosition);
    setEmployees([]);
    setEmployeeSelected(null);
    if (jobPosition?.work_station_id) {
      fetchEmployees(jobPosition.work_station_id, locationSelected.idFortia);
    }
  };

  const handlePeriodChange = (period) => {
    setPeriodSelected(period);
  };

  const handleCompanyChange = (company) => {
    setCompanySelected(company);
  };

  const handleEmployeeChange = (employee) => {
    setEmployeeSelected({ ...employee });
  };

  const fetchPayments = (locationId, jobPositionId, employeeId, periodType) => {
    setLoading(true);
    setData([]);
    SalesService.getPayments(
      locationId,
      jobPositionId || "",
      employeeId || "",
      periodType || ""
    )
      .then((response) => {
        setData([...response.data.result]);
      })
      .catch((error) => {
        console.log(error);
      })
      .then(() => {
        setLoading(false);
      });
  };

  const refresh = () => {
    if (locationSelected) {
      fetchPayments(
        locationSelected?.idFortia,
        jobPositionSelected?.work_station_id,
        employeeSelected?.idFortiaEmployee,
        periodSelected
      );
    }
  };

  const clearFilters = () => {
    setLocationSelected(null);
    setJobPositionSelected(null);
    setEmployeeSelected(null);
    setPeriodSelected(null);
    setCompanySelected(null);
    setData([]);
  };

  // Use Effect
  useEffect(() => {
    fetchLocations();
  }, []);

  useEffect(() => {
    if (locationSelected) {
      fetchPayments(
        locationSelected?.idFortia,
        jobPositionSelected?.work_station_id,
        employeeSelected?.idFortiaEmployee,
        periodSelected
      );
    }
  }, [locationSelected, jobPositionSelected, employeeSelected, periodSelected]);

  return (
    <MainLayout>
      <Box>
        <Container>
          <p className="is-size-3 gray-strench">Edición de Prenómina</p>
          <p className="gray-light">
            Aquí puedes editar la prenómina de los empleados de una plaza.
          </p>

          <div style={{
            marginTop: '20px',
            marginBottom: '-20px'
          }}>
            <h6>Filtro para edición y envío a Fortia</h6> 
          </div>

          <Filters
            // Locations
            locations={locations}
            locationSelected={locationSelected}
            handleLocationChange={handleLocationChange}
            // Job positions
            jobPositions={jobPositions}
            jobPositionSelected={jobPositionSelected}
            handleJobPositionChange={handleJobPositionChange}
            // Employees
            employees={employees}
            employeeSelected={employeeSelected}
            handleEmployeeChange={handleEmployeeChange}
            // Periods
            periods={periods}
            handlePeriodChange={handlePeriodChange}
            periodSelected={periodSelected}
            // Companies
            companies={companies}
            companySelected={companySelected}
            handleCompanyChange={handleCompanyChange}
            // Others
            loading={loading}
            clearFilters={clearFilters}
            refresh={refresh}
          />

          <EmployeeList
            data={data}
            setData={setData}
            companySelected={companySelected}
            locationSelected={locationSelected}
          />
        </Container>
      </Box>
    </MainLayout>
  );
};

export default PaymentEditionScreen;
