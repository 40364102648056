import React, { useState, useMemo } from "react";

const PaymentSchemaContext = React.createContext();

export function PaymentSchemaProvider(props) {
  const [paymentSchema, setPaymentSchema] = useState({});

  const [paymentSchemaSelected, setPaymentSchemaSelected] = useState({});
  const [jobPositions, setJobPositions] = useState([]);
  const [locations, setLocations] = useState([]);
  const [paymentPeriods, setPaymentPeriods] = useState([]);
  const [commissionTypes, setCommissionTypes] = useState([]);
  const [editing, setEditing] = useState(false);
  const [editValuesOnly, setEditValuesOnly] = useState(false);
  const [paymentSchemaLocation, setPaymentSchemaLocation] = useState(null);

  const value = useMemo(
    () => ({
      paymentSchema,
      setPaymentSchema,
      paymentSchemaSelected,
      setPaymentSchemaSelected,
      jobPositions,
      setJobPositions,
      locations,
      setLocations,
      paymentPeriods,
      setPaymentPeriods,
      commissionTypes,
      setCommissionTypes,
      editing,
      setEditing,
      editValuesOnly,
      setEditValuesOnly,
      paymentSchemaLocation,
      setPaymentSchemaLocation,
    }),
    [
      paymentSchema,
      paymentSchemaSelected,
      jobPositions,
      locations,
      paymentPeriods,
      commissionTypes,
      editing,
      paymentSchemaLocation,
    ]
  );

  return <PaymentSchemaContext.Provider value={value} {...props} />;
}

export function usePaymentSchema() {
  const context = React.useContext(PaymentSchemaContext);
  if (!context) {
    throw new Error(
      "usePaymentSchema debe ser usado dentro del proveedor PaymentSchemaContext"
    );
  }
  return context;
}
