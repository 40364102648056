import React, { useState, useEffect } from "react";
import ConfirmOpening from "./ConfirmOpening";
import OpeningForm from "./OpeningForm";
import OpeningHelpers from "./OpeningHelpers";
import { ProgressSpinner } from "primereact/progressspinner";

const ServiceOpening = ({
  headerComponent,
  openingSelected,
  setShowGrid,
  setOpeningSelected,
  location,
  selectedShift,
  shifts,
  users,
  catalogs,
  loading,
}) => {
  const [form, setForm] = useState({
    driver: null,
    driverAssistant: null,
    third: null,
  });

  // Metodos
  const handleDropdownChange = (e) => {
    setOpeningSelected({
      ...openingSelected,
      [e.target.id]: e.value,
    });
  };

  return (
    <div className="container px-3">
      {headerComponent}
      {loading ? (
        <ProgressSpinner
          strokeWidth="2"
          style={{ width: "50px", height: "50px" }}
        />
      ) : (
        <div className="container mt-3">
          <OpeningForm
            catalogs={catalogs}
            form={form}
            setForm={setForm}
            handleDropdownChange={handleDropdownChange}
            openingSelected={openingSelected}
            users={users}
            shifts={shifts}
            selectedShift={selectedShift}
            setOpeningSelected={setOpeningSelected}
          />
          <ConfirmOpening
            form={form}
            openingSelected={openingSelected}
            users={users}
            shifts={shifts}
            location={location}
            handleDropdownChange={handleDropdownChange}
            setShowGrid={setShowGrid}
            catalogs={catalogs}
          />
        </div>
      )}
    </div>
  );
};

export default ServiceOpening;
